import React, { useEffect, useRef } from 'react'
import { Form }                     from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Row, Col, Spin }           from 'antd'
import dayjs                        from 'dayjs'
import { Scrollbars }               from 'react-custom-scrollbars'
import config                       from 'config'
import { FormField }                from 'components'
import { format }                   from 'tools'

import './staff-training.scss'

const trainings = Object.keys( config.defs.pprStudies )
const formatView = config.format.monthView
const formatAPI = config.format.monthAPI

const StaffTraining = ( props ) => {
    const formRef = useRef()
    const lastPeriod = props.periods?.[ 0 ] ? dayjs( props.periods?.[ 0 ], formatAPI ) : dayjs()
    const disabled = !dayjs( props.period, formatView ).isSame( lastPeriod, 'month' )
    const [ form ] = Form.useForm()


    useEffect(() => {
        props.form.resetFields()
    }, [ props.data, props.restaurant, props.period, props.employeeUuid ])

    const onChangePeriod = ( _, value ) => {
        if ( dayjs( props.period ).isSame( value, 'month' )) { return }
        if ( props.onChangePeriod ) { props.onChangePeriod( value ) }
    }

    const fields = () => [
        {
            field: 'pprUuid',
            type: 'hidden',
            value: props.data?.pprUuid
        },
        {
            field: 'employeeUuid',
            type: 'hidden',
            value: props.employeeUuid,
        },
        {
            field: 'period',
            label: 'Период PPR',
            type: 'month',
            className: 'period-datepicker',
            disabledDate: ( date ) => !props.periods?.some( period => dayjs( period, formatAPI ).isSame( date, 'month' )),
            value: props.period,
            onChange: onChangePeriod
        },
        {
            type: 'row',
            field: 'positionRow',
            items: [
                {
                    field: 'position',
                    label: 'Позиция сотрудника',
                    type: 'select',
                    placeholder: 'Выберите из списка',
                    span: 8,
                    byKey: true,
                    disabled,
                    options: format.generate.optionsr(
                        props.positions,
                        'id',
                        'name'
                    ),
                    value: props.data?.position?.id,
                    rules: [ config.rules.required ]
                },
                {
                    field: 'positionStartDate',
                    label: 'Старт в должности',
                    type: 'date',
                    format: config.format.dayView,
                    disabled,
                    span: 8,
                    rules: [ config.rules.required ],
                    value: props.data?.positionStartDate
                },
            ],
        },
        {
            type: 'row',
            field: 'rcl',
            items: [
                {
                    field: 'rclCode',
                    label: 'Код RCL',
                    type: 'select',
                    placeholder: 'Выберите из списка',
                    span: 8,
                    byKey: true,
                    disabled,
                    options: format.generate.optionsr(
                        props.rcl,
                        'id',
                        'name'
                    ),
                    value: props.data?.rclCode?.id
                },
                {
                    field: 'rclDate',
                    label: 'Дата последнего RCL',
                    type: 'date',
                    format: config.format.dayView,
                    disabled,
                    span: 8,
                    value: props.data?.rclDate
                },
            ],
        },
        {
            type: 'row',
            field: 'status',
            items: [
                {
                    field: 'positionStatus',
                    label: 'Статус сотрудника',
                    type: 'select',
                    placeholder: 'Выберите из списка',
                    span: 8,
                    byKey: true,
                    disabled,
                    options: format.generate.optionsr(
                        props.statuses,
                        'id',
                        'name'
                    ),
                    rules: [ config.rules.required ],
                    value: props.data?.positionStatus?.id
                },
                {
                    field: 'isBench',
                    label: 'Bench',
                    type: 'checkbox',
                    disabled,
                    span: 8,
                    value: props.data?.isBench
                },
            ],
        },
        {
            type: 'separator',
            field: 'sep-1',
        },
        {
            type: 'row',
            field: 'finishedTraining',
            items: [
                {
                    field: 'finishedTrainings',
                    label: 'Пройденное обучение',
                    type: 'checkgroup',
                    span: 24,
                    disabled,
                    value: trainings.filter( key => !!props.data?.[ key ]),
                    options: Object.entries( config.defs.pprStudies ).map(([ value, label ]) => ({ label, value })),
                }
            ]
        },
        {
            field: 'text',
            label: 'Примечание',
            type: 'text',
            disabled,
            value: props.data?.text,
            className: 'training-textarea',
            rules: [
                config.rules.length(
                    0,
                    255,
                    'Поле не может быть длиннее 255 символов',
                    true
                ),
            ],
        },
        {
            field: 'plan',
            label: 'План действий',
            type: 'text',
            disabled,
            value: props.data?.plan,
            className: 'training-textarea',
            rules: [
                config.rules.length(
                    0,
                    255,
                    'Поле не может быть длиннее 255 символов',
                    true
                ),
            ],
        },
        {
            type: 'separator',
            field: 'sep-2',
        },
        {
            type: 'row',
            field: 'buttons',
            items: [
                {
                    type: 'space',
                    field: 'space',
                    span: 12,
                },
                {
                    type: 'button',
                    style: 'ghost',
                    field: 'unsave',
                    disabled,
                    span: 6,
                    text: 'Не сохранять',
                    handler: () => props.form.resetFields()
                },
                {
                    type: 'submit',
                    field: 'submit',
                    disabled,
                    text: 'Сохранить',
                    span: 6,
                },
            ],
        },
    ]

    const field = ( cfg ) => {
        let val = props.employee ? props.employee[ cfg.field ] : null;

        ( !val || val === 'null' ) && ( val = cfg.value )

        if ( cfg.type === 'row' ) {
            return row( cfg )
        }

        return (
            <FormField
                {...cfg}
                value={val}
                key={cfg.field}
                form={props.form}
                data={props.employee}
                //onChange={this.remember}
            />
        )
    }

    const row = ( cfg ) => {
        const res = []

        if ( cfg.hide && cfg.hide( props.employee )) { return null }

        for ( let i = 0; i < cfg.items.length; i++ ) {
            if ( !cfg.items[ i ].hide ) {
                res.push(
                    <Col span={cfg.items[ i ].span} key={cfg.items[ i ].field}>
                        {field( cfg.items[ i ])}
                    </Col>
                )
            }
        }

        return (
            <Row gutter={24} key={cfg.field} className={cfg.field}>
                {res}
            </Row>
        )
    }

    const saveEmployeePpr = ( e ) => {
        e.preventDefault()

        props.form.validateFieldsAndScroll(( err, values ) => {
            if ( err ) { return }

            if ( props.onSave ) { props.onSave( values ) }
        })
    }

    return (
        <Scrollbars {...config.ui.scrolls}>
            <Spin spinning={!!props.loading}>
                <Form
                    ref={formRef}
                    {...config.ui.wideForm}
                    onFinish={saveEmployeePpr}
                    className="staff-training"
                    name='StaffTraining'
                    form={form}
                >
                    {fields().map( field )}
                </Form>
            </Spin>
        </Scrollbars>
    )
}

export default StaffTraining
