/* VENDOR */
import React, { useState, useEffect }    from 'react'
import dayjs, { Dayjs }                  from 'dayjs'
import { Form, Modal, Col, Row, Button } from 'antd'
import { FormInstance }                  from 'antd/lib/form'
import { RangePickerTimeProps }          from 'antd/lib/date-picker/generatePicker'

/* APPLICATION */
import { format } from 'tools'
import config     from 'config'
import { locale } from '../../../config/ui'
import TimePicker from '../../layout/TimePicker/TimePicker'

interface EventProps {
    title: string;
    extendedProps: {
        _duration: string;
    };
}

interface AddEventProps {
    visible: boolean;
    date: Dayjs | null;
    event: EventProps | null;
    onSubmit: ( values: any ) => void;
    onCancel: () => void;
}

const AddEvent: React.FC<AddEventProps> = ({ visible, date, event, onSubmit, onCancel }) => {
    const [ form ] = Form.useForm<FormInstance>()
    const [ timeStart, setTimeStart ] = useState<Dayjs>(
        date ? ( date.isSame( dayjs(), 'day' ) ? dayjs().add( 1, 'hour' ).set( 'minutes', 0 ) : date ) : dayjs()
    )
    const [ isTimePickerOpen, setTimePickerOpen ] = useState( false )

    useEffect(() => {
        if ( date ) {
            setTimeStart( date.isSame( dayjs(), 'day' ) ? dayjs().add( 1, 'hour' ).set( 'minutes', 0 ) : date )
        }
    }, [ date ])

    useEffect(() => {
        if ( date ) {
            const initialTime = date.isSame( dayjs(), 'day' ) ? dayjs().add( 1, 'hour' ).set( 'minutes', 0 ) : date
            setTimeStart( initialTime )
            //@ts-ignore
            form.setFieldsValue({ timeStart: initialTime })
        }
    }, [ date, form ])

    const getDisabledTime = (): RangePickerTimeProps<Dayjs>['disabledTime'] => {
        const today = dayjs()

        if ( date && date.isSame( today, 'day' )) {
            const disabledHours = today.minute() > 30
                ? format.generate.range( 0, today.hour() + 1 )
                : format.generate.range( 0, today.hour())

            const disabledMinutes = ( hour: number ) => {
                return hour === today.hour() ? format.generate.range( 0, today.minute() + 1 ) : []
            }

            return {
                // @ts-ignore
                disabledHours: () => disabledHours,
                disabledMinutes: ( hour: number ) => disabledMinutes( hour ),
            }
        }

        return {
            // @ts-ignore
            disabledHours: () => [],
            disabledMinutes: () => [],
        }
    }

    const handleTimeChange = ( time: Dayjs | Dayjs[]) => {
        //@ts-ignore
        setTimeStart( time )
        //@ts-ignore
        form.setFieldsValue({ timeStart: time })
    }

    const handleOk = ( time: Dayjs | null ) => {
        if ( time ) {
            const formattedTime = time.add( 1, 'hour' ).set( 'minutes', 0 )
            //@ts-ignore
            setTimeStart( formattedTime )
            //@ts-ignore
            form.setFieldsValue({ timeStart: formattedTime })
        }
    }

    const preSubmit = () => {
        form.validateFields()
            .then(( values ) => {
                const formattedTimeStart = timeStart
                onSubmit({
                    ...values,
                    timeStart: formattedTimeStart,
                })
                form.resetFields()
            })
            .catch(( errorInfo ) => {
                console.error( 'Ошибка при валидации полей:', errorInfo )
            })
    }

    if ( !event || !date ) {
        return null
    }

    return (
        <Modal
            title={event.title}
            className="kfc-popup no-footer kfc-calendar-popup"
            centered={true}
            open={visible}
            okText="Запланировать"
            okButtonProps={{ style: { display: 'none' } }}
            onOk={preSubmit}
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={onCancel}
        >
            <p className="text-helper">
                {date.format( config.format.onlyDayView )}, {date.format( 'ddd' ).toUpperCase()} • {event.extendedProps._duration}
            </p>
            <Form form={form} {...config.ui.wideForm}>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name="timeStart"
                            rules={[ { required: true, message: 'Пожалуйста, выберите время' } ]}
                        >
                            <TimePicker
                                value={timeStart}
                                onChange={handleTimeChange}
                                open={isTimePickerOpen}
                                onCalendarChange={handleTimeChange}
                                onOpenChange={setTimePickerOpen}
                                //@ts-ignore
                                locale={locale}
                                minuteStep={15}
                                format="HH:mm"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item>
                            <Button type='primary' onClick={preSubmit}>
                                Запланировать
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default AddEvent
