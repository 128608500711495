/* VENDOR */
import { update } from 'reduxigen'

/* APPLICATION */
import * as api   from './api'
import { format } from 'tools'

const asyncUpdate = format.update.asyncu,
      helper = format.helper.basic( 'performance' )

/* BASIC */

export const flushPerformance = update( 'performance.flush', ( value, state ) => ({
    ...state,
    performance: {},
}))

/* REPORTS */

export const fetchPerformanceReport = asyncUpdate(
    'performance.report',
    ( params ) => api.getPerformance( params ),
    ( event, state ) => helper( 'report', event?.data?.data, state )
)

export const fetchPerformanceSummary = asyncUpdate(
    'performance.summary',
    ( body ) => {
        /* const np = format.copy.object( params )

        if ( format.check.now( np.dateStart, np.dateEnd )) {
            np.dateEnd = dayjs().format( config.format.date )
        } */

        return api.searchPerformance( body )
    },
    ( event, state ) => helper( 'summary', event?.data, state )
)

export const fetchPerformancePlanAndFact = asyncUpdate(
    'performance.planAndFact',
    ( params ) => api.getPerformancePlanAndFact( params ),
    ( event, state ) =>
        helper( 'planAndFact', event.data ? {
            plan: format.extract.graph( event.data.data, 'time', 'planSpmh' ),
            fact: format.extract.graph( event.data.data, 'time', 'factSpmh' ),
        } : event.data, state )
)

export const fetchSPMHGoal = asyncUpdate(
    'performance.spmhGoal.fetch',
    ( params ) => api.getSPMHGoal( params ),
    ( event, state ) =>
        helper( 'spmhGoal', event.data || { goal: null }, state )
)

export const updateSPMHGoal = asyncUpdate(
    'performance.spmhGoal.update',
    ( body ) => api.setSPMHGoal( body ),
    ( event, state ) => state
)
