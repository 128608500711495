/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'

/* APPLICATION */
import './relation.scss'

class Relation extends Component {
    static propTypes = {
        plan: PropTypes.number,
        fact: PropTypes.number,
        value: PropTypes.number,
        hit: PropTypes.number,

        addon: PropTypes.string,
        invert: PropTypes.bool,
        useHit: PropTypes.bool,

        format: PropTypes.func,
        status: PropTypes.func,
    }

    status = () => {
        const { fact, plan, invert, status } = this.props,
              rplan = plan || 0

        if ( status ) {
            return status( plan, fact, invert )
        }

        if ( fact === plan ) { return '' }

        const relation = invert
            ? parseFloat( fact ) < parseFloat( rplan )
            : parseFloat( fact ) > parseFloat( rplan )

        return relation ? 'good' : 'bad'
    }

    render () {
        const { fact, plan, addon, value, hit, useHit } = this.props,
              format = this.props.format ? this.props.format : ( n ) => n

        return (
            <span className={'data-relation ' + this.status()}>
                {( !!value || value === 0 ) && (
                    <span className="relation-value">{format( value )}</span>
                )}

                <span className="relation-fact">{format( fact )} </span>

                {( !!fact || fact === 0 || fact === null ) && ( !!plan || plan === 0 || plan === null ) && (
                    <span className="relation-separator"> / </span>
                )}

                {( !!plan || plan === 0 || plan === null ) && (
                    <span className="relation-plan">{format( plan )} </span>
                )}

                <span className="relation-addon"> {addon}</span>

                {useHit && <span className="relation-hit">{hit ? hit : 0}%</span>}
            </span>
        )
    }
}

export default Relation
