/* VENDOR */
import dayjs from 'dayjs'

/* APPLICATION */
import { format } from 'tools'
import config     from 'config'

import Template from './Template.js'
import './statistics-days.scss'

const heads = ( data ) => {
    const res = [],
          first = { time: '   ', },
          second = { time: ' ', }

    config.defs.days.forEach(( d ) => {
        const date = dayjs( data.days[ d - 1 ].date )

        first[ 'factTransactions' + d ] = format.strings.dowDateR( date )
        first[ 'factTurnover' + d ] = ' '

        second[ 'factTransactions' + d ] = 'ТРЗ'
        second[ 'factTurnover' + d ] = 'ТОВ'
    })

    res.push( first )
    res.push( second )

    return res
},
      rows = ( data ) => {
          const res = []

          if ( !data ) { return null }

          for ( let h = 0; h < 24; h++ ) {
              let row = {
                  time: format.strings.mktime( h ),
                  secondtime: format.strings.mktime( h ),
              }

              config.defs.days.forEach(( d ) => {
                  const day = data.days[ d - 1 ],
                        hour = day.hours.find(( hour ) => hour.hour === h )

                  row[ 'factTransactions' + d ] = hour
                      ? hour.factTransactions
                      : config.ui.nullSymbol
                  row[ 'factTurnover' + d ] = hour
                      ? format.strings.thousand( hour.factTurnover )
                      : config.ui.nullSymbol
              })

              res.push( row )
          }

          return res
      },
      width = () => {
          const res = [ '5%' ]

          config.defs.days.forEach(() => {
              res.push( 'auto' )
              res.push( 'auto' )
              res.push( '3%' )
          })

          res.push( 'auto' )

          return res
      },
      columns = () => {
          const cols = [ 'time' ]

          config.defs.days.forEach(( d ) => {
              cols.push( 'factTransactions' + d )
              cols.push( 'factTurnover' + d )
              cols.push( 'space' + d )
          })

          cols.push( 'secondtime' )

          return format.generate.rowReader( cols )
      }

class StatisticsDays extends Template {
    constructor ( props ) {
        super( props )

        this.state = {
            data: rows( props.data ),
            width: width(),
            heads: heads( props.data ),
            columns: columns( props.data ),
            className: 'statistics-days',
        }
    }

    data = ( data ) => rows( data )
    columns = ( data ) => columns( data )
}

export default StatisticsDays
