import * as api                   from './api'
import { storeAsyncUpdate }       from 'store'
import {
    PostStatisticsFeedbacksSummaryPayload,
    PostFeedbacksSearchBody,
    PostStatisticsFeedbacksSummaryResponse,
    PostFeedbacksSearchResponse
} from './type'

/*
    @description Функция, вызывается при инициализации таба "Консолидированная статистика". Данные используются для первой строки таблицы и общего спидометра
 */
export const postStatisticsFeedbacksSummary = storeAsyncUpdate<PostStatisticsFeedbacksSummaryPayload, PostStatisticsFeedbacksSummaryResponse>(
    'guestVote.statisticsFeedbacksSummary',
    ( body ) => api.postStatisticsFeedbacksSummary( body ),
    ( event, state ) => {
        if ( event.status === 200 ) {
            return {
                ...state,
                guestVote: {
                    ...state.guestVote,
                    statisticsFeedbacksSummary: event.data
                }
            }
        }
        return state
    }
)

/*
    @description Функция, вызывается при инициализации таба "Консолидированная статистика". Данные используются для заполнения таблицы
 */
export const postFeedbacksSearch = storeAsyncUpdate<PostFeedbacksSearchBody, PostFeedbacksSearchResponse>(
    'guestVote.feedbacksSearch',
    ( body ) => api.postFeedbacksSearch({ body }),
    ( event, state ) => {
        if ( event.status === 200 ) {
            return {
                ...state,
                guestVote: {
                    ...state.guestVote,
                    feedbacksSearch: event.data
                }
            }
        }
        return state
    }
)

/*
    @description Функция, вызывается при пагинации в таблице на табе "Консолидированная статистика". Данные используются для добавления строк в таблицу
 */
export const appendFeedbacksSearch = storeAsyncUpdate<{
    body: PostFeedbacksSearchBody,
    params: { page: number }
}, PostFeedbacksSearchResponse>(
    'guestVote.feedbacksSearchAppend',
    ( payload ) => api.postFeedbacksSearch( payload ),
    ( event, state ) => {
        if ( event.status === 200 ) {
            return {
                ...state,
                guestVote: {
                    ...state.guestVote,
                    feedbacksSearch: {
                        ...state.guestVote.feedbacksSearch,
                        content: [
                            ...state.guestVote.feedbacksSearch.content,
                            ...event.data.content
                        ],
                    }
                }
            }
        }
        return state
    }
)
