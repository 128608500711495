/* IMPORT */
import { businessActions, staffActions } from 'services'

/* EXPORT */
export const mapStateToProps = ( state ) => ({
    period: state.managers.period,
    day: state.managers.date,
    manager: state.managers.manager,
    details: state.managers.details,
    report: state.managers.fullReport,
    deviations: state.managers.deviations,
    assignees: state.staff.assignees,
    executors: state.staff.executors,
    restaurants: state.restaurants.list,
    restaurantId: state.request.restaurantId,
    user: state.user,
})

export const allActions = {
    ...businessActions,
    ...staffActions,
}
