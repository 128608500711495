import dayjs from 'dayjs'

import { format } from 'tools'
import * as get   from './get'

export const monthDay = ( res ) =>
    Object.keys( res ).forEach(( date ) => {
        const classNames = [],
              { count, duration, missed } = get.eventSumm( res[ date ]),
              m = dayjs( date ),
              today = dayjs()

        res[ date ].events = []
        m.isBefore( today.startOf( 'day' )) && classNames.push( 'fc-e-past' )

        res[ date ].events.push({
            title: format.strings.clearCount( count, [ 'задача', 'задачи', 'задач' ]),
            date,
            classNames,
            weight: 0,
        })

        if ( m.isAfter( today.startOf( 'day' )) || m.isSame( today, 'day' )) {
            res[ date ].events.push({
                title: format.extract.duration( duration, true ),
                date,
                classNames: [ 'fc-e-summary' ],
                weight: 1,
            })
        }

        if ( m.isBefore( today.startOf( 'day' )) && missed > 0 ) {
            res[ date ].events.push({
                title: format.strings.clearCount( missed, [
                    'не выполнена',
                    'не выполнены',
                    'не выполнено',
                ]),
                date,
                classNames: [ 'fc-e-past', 'fc-e-summary' ],
                weight: 1,
            })
        }
    })

export const eventInfo = ( event ) => ({
    date: event.start,
    deadline: event.deadline,
    description: event.extendedProps.description,
    done: event.extendedProps.done,
    duration: event.extendedProps.duration,
    group: event.extendedProps.groupName,
    groupUI: event.extendedProps.groupNameUI,
    taskId: event.extendedProps.taskId,
    title: event.title,
    end: event.end,

    ...extractRepeat( event ),
})

export const extractRepeat = ( event ) => {
    if ( !event.extendedProps.repeat ) {
        return { repeat: null }
    }

    const res = format.copy.object( event.extendedProps.repeat )

    res.repeat = true

    return res
}
