import React, { useEffect }          from 'react'
import cn                            from 'classnames'
import { GuestVoteFilters, Spinner } from 'components'
import GuestVoteTable                from '../../GuestVoteTable/GuestVoteTable'
import { GuestVotePieCharts }        from '../../GuestVotePieCharts/GuestVotePieCharts'
import styles                        from './statistics-tab.module.scss'
import { connect }                   from 'react-redux'
import { FormatedDateFilters }       from 'pages/GuestVote/types'
import { DataGroupType }             from 'services/guestVote/statistic/type'
import {
    allActions, ConsolidatedStatisticsStoreProps,
    mapStateToProps,
} from './connector'
import config                     from 'config'
import { useGetCurrentDataGroup } from '../helpers/hooks'

type ConsolidatedStatisticsTabProps = {
    className?: string;
    dateFilters: FormatedDateFilters;
    disabledDropTypes?: DataGroupType[];
};

const ConsolidatedStatisticsTab = ( props: ConsolidatedStatisticsTabProps & ConsolidatedStatisticsStoreProps ) => {
    const {
        className,
        currentFilters,
        dateFilters,
        postFeedbacksSearch,
        postStatisticsFeedbacksSummary,
        feedbacksSearch,
        feedbacksSearchLoading,
        appendFeedbacksSearch,
        feedbacksSearchAppendLoading,
        statisticFeedbacksSummary,
        statisticFeedbacksSummaryLoading,
        disabledDropTypes
    } = props

    useGetCurrentDataGroup( disabledDropTypes )

    // Эффект, отвечающий за запрос данных для графиков и таблицы
    useEffect(() => {
        postStatisticsFeedbacksSummary({
            ...currentFilters,
            ...dateFilters,
        })
        postFeedbacksSearch({
            ...currentFilters,
            ...dateFilters,
        })
    }, [ currentFilters, dateFilters ])


    return (
        <div className={cn( className, styles.statistics_tab )}>
            <GuestVoteFilters dateFilters={dateFilters} />

            {statisticFeedbacksSummaryLoading ? (
                <Spinner className={styles.spinner}/>
            ) : (
                <GuestVotePieCharts data={statisticFeedbacksSummary} />
            )}

            <GuestVoteTable
                disabledDropTypes={disabledDropTypes}
                currentFilters={currentFilters}
                dateFilters={dateFilters}
                rowKey="id"
                // postDataQuery={postFeedbacksSearch}
                appendDataQuery={appendFeedbacksSearch}
                data={feedbacksSearch}
                columnsRender={
                    config.tables.guestVote.getConsolidatedStatisticsTable
                }
                loading={feedbacksSearchLoading}
                buttonLoading={feedbacksSearchAppendLoading}
            />
        </div>
    )
}

export default connect( mapStateToProps, allActions )( ConsolidatedStatisticsTab )
