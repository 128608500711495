import dayjs from 'dayjs'

import { format } from 'tools'
import config     from 'config'

import * as datetime from './datetime'
import * as shifts   from './shifts'

export const prepare = ( raw, duration ) => {
    const schedule = format.copy.deep( raw )

    schedule.days.forEach(( day ) => {
        const dayDate = datetime.offsetDate( raw.dateStart, day.dayOfWeek )

        day.positions.forEach(( pos ) => {
            pos.shifts = shifts.prepare( pos.shifts, pos, dayDate )

            if ( pos.unassignedShifts?.length > 0 ) {
                pos.unassignedShifts.forEach( shift => {
                    shifts.addUnassigned( pos, shift )
                })
            }

            /* if ( pos.paidHours.delta < 0 && dayDate.isAfter( dayjs())) {
                shifts.unassigned( pos, dayDate, duration )
            } */
        })
    })

    return schedule
}

export const date = ( schedule ) => {
    switch ( schedule ) {
        case 'next':
            return dayjs().add( 1, 'week' )
        case 'current':
            return dayjs()
        default:
            return dayjs( schedule )
    }
}
