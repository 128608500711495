import { Card, Checkbox, Modal, Spin } from 'antd'
import { Spinner }                     from 'components'
import React, { useEffect, useState }  from 'react'
import { connect }                     from 'react-redux'
import { allActions, mapStateToProps } from './connector'
import './show-data.scss'

const GROSSTEXT = 'Использовать gross показатели для отображения данных по фактическим продажам'
const BIOMETRYTEXT = 'Использовать биометрию для получения отметок прихода и ухода сотрудников'


const ShowData = ( props ) => {
    const [ sendingGross, setSendingGross ] = useState( false )
    const [ sendingBiometry, setSendingBiometry ] = useState( false )

    const [ loading, setLoading ] = useState( true )

    const [ showModal, setShowModal ] = useState( false )

    const update = () => {
        props.searchRestaurantOptions({ factsNumbers: [ props.restaurantId ] }).finally(() => {
            setLoading( false )
        })
    }

    useEffect(() => {
        update()
    }, [])

    useEffect(() => {
        setSendingGross( false )
        setSendingBiometry( false )
    }, [ props.showData?.isGrossAccounting, props.showData?.isBiometry ])

    useEffect(() => {
        update()
    }, [ props.restaurantId ])

    const onChangeGross = ( e ) => {
        setSendingGross( true )
        if ( props.showData?.uuid ) {
            props.updateRestaurantOptions({
                uuid: props.showData.uuid,
                factsNumber: props.restaurantId,
                isGrossAccounting: e.target.checked,
                isBiometry: Boolean( props.showData?.isBiometry )
            }).finally(() => setSendingGross( false ))
        } else {
            props.postRestaurantOptions({
                factsNumber: props.restaurantId,
                isGrossAccounting: e.target.checked,
                isBiometry: Boolean( props.showData?.isBiometry )
            }).finally(() => setSendingGross( false ))
        }
    }
    const onChangeBiometry = ( checked ) => {
        setSendingBiometry( true )
        if ( props.showData?.uuid ) {
            props.updateRestaurantOptions({
                uuid: props.showData.uuid,
                factsNumber: props.restaurantId,
                isGrossAccounting: Boolean( props.showData?.isGrossAccounting ),
                isBiometry: checked
            }).finally(() => setSendingBiometry( false ))
        } else {
            props.postRestaurantOptions({
                factsNumber: props.restaurantId,
                isGrossAccounting: Boolean( props.showData?.isGrossAccounting ),
                isBiometry: checked
            }).finally(() => setSendingBiometry( false ))
        }
    }

    return (
        <div className='show-data'>

            <Modal
                title="Вы действительно хотите отменить использование данных биометрии, все фактические отметки надо будет вносить вручную?"
                className="kfc-popup kfc-origin-modal"
                centered={true}
                open={showModal}
                width={535}
                okText="Сохранить"
                cancelText="Отмена"
                onOk={() => {
                    onChangeBiometry( false )
                    setShowModal( false )
                }}
                onCancel={() => setShowModal( false )}
            >
            </Modal>

            <Card bordered={false}>
                <h2>Отображение данных</h2>
                {!props.showDataError && ( loading ?
                        <Spinner />
                    :
                        <>
                            <div className='checkbox-wrapper'>
                                {sendingGross ?
                                        <>
                                            <Spin size="small" />
                                            <span style={{ paddingLeft: 8 }}>{GROSSTEXT}</span>
                                        </>
                                    :
                                        <Checkbox
                                            onChange={onChangeGross}
                                            checked={!!props.showData?.isGrossAccounting}
                                        >{GROSSTEXT}</Checkbox>
                                }
                            </div>
                            <div className='checkbox-wrapper'>
                                { sendingBiometry ?
                                        <>
                                            <Spin size="small" />
                                            <span style={{ paddingLeft: 8 }}>{BIOMETRYTEXT}</span>
                                        </>
                                    :
                                        <Checkbox
                                            onChange={( e ) => {
                                                props.showData?.isBiometry
                                                    ? setShowModal( true )
                                                    : onChangeBiometry( true )
                                            }}
                                            checked={!!props.showData?.isBiometry}
                                        >{BIOMETRYTEXT}</Checkbox>

                                }
                            </div>
                        </>
                )}

            </Card>
        </div>
    )
}

export default connect( mapStateToProps, allActions )( ShowData )
