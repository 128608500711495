/* eslint-disable no-unused-vars */
/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Card, Tooltip }    from 'antd'
import Icon                 from '@ant-design/icons'
import dayjs                from 'dayjs'
import ReactExport          from 'react-data-export'

/* APPLICATION */
import {
    ReportsControls,
    PrintReport,
    Spinner,
    Icons
} from 'components'
import { format } from 'tools'
import config     from 'config'
import * as defs  from '../defs'
import excel      from '../excel'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

const titles = {
    'daily-schedule': 'Дневное расписание',
    schedule: 'Расписание',
    short: 'Компактное расписание',
    worktime: 'Плановое рабочее время',
    factworktime: 'Фактическое рабочее время',
    ready: 'Готовые выйти на замену',
    statistics: 'Статистика',
    stations: 'Выручка по станциям',
    workedtime: 'Отчет по отработанному времени',
},
      templates = {
          'daily-schedule': [ 'daily-schedule' ],
          schedule: [ 'schedule-table' ],
          short: [ 'short-schedule' ],
          worktime: [ 'managers-report' ],
          factworktime: [ 'managers-report' ],
          ready: [ 'ready-report' ],
          statistics: [ 'statistics-report', 'statistics-summary' ],
          stations: [ 'stations' ],
          workedtime: [ 'workedtime' ],
      }

const excelReports = [ 'statistics', 'factworktime', 'stations', 'workedtime' ]

class PrintReports extends Component {
    static propTypes = {
        reports: PropTypes.object,
        restaurant: PropTypes.string,

        date: PropTypes.object,
        dates: PropTypes.arrayOf( PropTypes.object ),
        current: PropTypes.string,
        workHours: PropTypes.object,

        getType: PropTypes.func,
        onChange: PropTypes.func,
        onSelect: PropTypes.func,
        onPrint: PropTypes.func,
    }

    weekReports = () => {
        const { current, reports, date, dates, restaurant, getType, workHours } =
        this.props,
              data = reports[ getType( current ) ],
              start = current === 'stations' ? dates[ 0 ] : date,
              end = current === 'stations' ? dates[ 1 ] : dayjs( start ).endOf( 'week' ),
              diff = Math.abs( start.diff( end, 'days' )),
              res = []

        const isError = this.props.reports[ `${current}_error` ]

        if ( isError ) { return null }

        if ( !data ) { return <Spinner /> }

        for ( let i = 0; i <= diff; i++ ) {
            let day = dayjs( start ).add( i, 'days' ),
                date = day.format( config.format.dayAPI ),
                dateTitle = format.strings.capitalize( day.format( 'dddd, D MMMM' )),
                title = current === 'daily-schedule' ? dateTitle : titles[ current ]

            const dataReport = data.days.find(( d ) =>
                current === 'daily-schedule' ?
                    d.workStart.split( 'T' )[ 0 ] === date
                    :
                    d.date.split( 'T' )[ 0 ] === date
            )

            if ( dataReport ) {
                res.push(
                    <PrintReport
                        key={date}
                        data={dataReport}
                        restaurant={restaurant}
                        templates={templates[ current ]}
                        dates={[ start, end ]}
                        workHours={workHours}
                        title={title}
                    />
                )
            }
        }

        return res
    }

    singleReport = () => {
        const { restaurant, current, date, dates, reports, getType, workHours } =
        this.props,
              start = current === 'stations' ? dates[ 0 ] : date,
              end = current === 'stations' ? dates[ 1 ] : dayjs( date ).endOf( 'week' )

        const isError = this.props.reports[ `${current}_error` ]

        return (
            <PrintReport
                title={titles[ current ]}
                restaurant={restaurant}
                templates={templates[ current ]}
                dates={[ start, end ]}
                data={reports[ getType( current ) ]}
                error={isError}
                workHours={workHours}
            />
        )
    }

    reports = () =>
        this.props.current === 'daily-schedule' || this.props.current === 'ready'
            ? this.weekReports()
            : this.singleReport()

    prepareExcel = () => {
        const { current, reports, getType, restaurant, date, dates } = this.props
        const gray = '007B7B7B'
        const start = current === 'stations' ? dates[ 0 ] : date
        const end = current === 'stations' ? dates[ 1 ] : dayjs( date ).endOf( 'week' )
        const data = reports[ getType( current ) ]

        if ( !data ) { return [] }

        switch ( current ) {
            case 'factworktime': {
                return excel.factworktime.report({
                    restaurant,
                    data,
                    start,
                    end
                })
            }
            case 'statistics': {
                return excel.statistics.report({
                    restaurant,
                    data,
                    start,
                    end
                })
            }
            case 'stations': {
                return excel.stations.report({
                    restaurant,
                    data,
                    start,
                    end
                })
            }
            case 'workedtime': {
                return excel.workedtime.report({
                    restaurant,
                    data,
                    start,
                    end
                })
            }
            default: {
                return [
                    {
                        columns: [
                            { title: '' },
                        ],
                        data: [
                            [ { value: '' } ]
                        ]
                    }
                ]
            }
        }
    }

    checkEmptyExcel = () => {
        const { reports, getType, current, workHours } = this.props
        const data = reports[ getType( current ) ]
        const rows = format.copy.array( defs.statisticsSummaryRows )

        if ( !workHours ) { return true }
        if ( !data ) { return true }

        if ( current === 'statistics' ) {
            return [
                format.check.allContainsNullOrZero([ data.week ], rows.map( row => row.key )),
                format.check.allContainsNullOrZero([ ...data.days.map( day => day.hours ) ], [ 'factTurnover', 'factTransactions' ]),
                format.check.allContainsNullOrZero([ ...data.days.map( day => day.summary ) ], rows.map( row => row.key ))
            ].every( check => check )
        }

        if ( current === 'factworktime' ) {
            return data.employees.map(( employee ) => {
                return employee.days.every( day => day.total === null ) &&
                format.check.allContainsNullOrZero([ employee.summary ], [ 'hours', 'paid', 'shifts' ])
            }).every( check => check )
        }

        if ( current === 'stations' ) {
            return format.check.allContainsNullOrZero( data.stations, [ 'checksCount', 'avgNet', 'sumNet' ])
        }

        if ( current === 'workedtime' ) {
            return !data?.employees?.length || data?.employees?.length === 0
        }

        return false
    }

    render () {
        const { date, dates, current, workHours, reports } = this.props,
              { onChange, onSelect, onPrint } = this.props

        const hasExcelReport = !!excelReports.find( rep => rep === current )
        const disabledExportExcel = hasExcelReport && this.checkEmptyExcel()

        const isError = reports[ `${current}_error` ]
        const errorMsg = reports[ `${current}_error_msg` ]

        return (
            <div className="section-cards">
                <aside className="section-cards-aside">
                    <Card bordered={false}>
                        <ReportsControls
                            date={current === 'stations' ? dates : date}
                            type={current === 'stations' ? 'range' : 'week'}
                            current={current}
                            disabledExportExcel={disabledExportExcel}
                            download={
                                hasExcelReport && (
                                    disabledExportExcel ?
                                            <Tooltip
                                                title="Нет данных для построения отчета"
                                            >
                                                <span className={'item-controls disabled'} >
                                                    <Icon component={Icons.Download.def} onClick={e => e.stopPropagation()} />
                                                </span>
                                            </Tooltip>
                                        :
                                            <ExcelFile
                                                filename={`${titles[ current ]}_${dayjs().format( 'YYYY-MM-DDTHH:mm:ss' )}`}
                                                element={
                                                    <span className={'item-controls'} >
                                                        <Icon component={Icons.Download.def} />
                                                    </span>}
                                            >
                                                <ExcelSheet dataSet={this.prepareExcel()} name={titles[ current ]}/>
                                            </ExcelFile>
                                )}
                            onChange={onChange}
                            onSelect={onSelect}
                            onPrint={onPrint}
                        />
                    </Card>
                </aside>

                <section className="section-cards-content">
                    <Card bordered={false} className="reports-container">
                        {isError && <div className='reports-error'>{errorMsg}</div>}
                        {!isError && workHours && this.reports()}
                        {!isError && !workHours && <Spinner />}
                    </Card>
                </section>



            </div>
        )
    }
}

export default PrintReports
