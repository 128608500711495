import dayjs from 'dayjs'

export const month = ( deadline, date, isWeekly ) =>
    isWeekly
        ? ( deadline.isAfter( dayjs( date ).startOf( 'month' )) ||
        deadline.isSame( dayjs( date ).startOf( 'month' ), 'day' )) &&
      ( deadline.isBefore( dayjs( date ).endOf( 'month' ).endOf( 'week' )) ||
        deadline.isSame( dayjs( date ).endOf( 'month ' ).endOf( 'week' ), 'day' ))
        : ( deadline.isAfter( dayjs( date ).startOf( 'month' )) ||
        deadline.isSame( dayjs( date ).startOf( 'month' ), 'day' )) &&
      ( deadline.isBefore( dayjs( date ).endOf( 'month' )) ||
        deadline.isSame( dayjs( date ).endOf( 'month ' ), 'day' ))

export const week = ( deadline, date ) =>
    ( deadline.isAfter( date ) || deadline.isSame( date, 'day' )) &&
  ( deadline.isBefore( dayjs( date ).endOf( 'week' )) ||
    deadline.isSame( dayjs( date ).endOf( 'week ' ), 'day' ))

export const moreThanWeekly = ( ev, view, date ) => {
    if ( ev.groupName.indexOf( 'Weekly' ) > -1 || ev.groupName.indexOf( 'Daily' ) > -1 ) { return false }

    if ( view === 'week' ) {
        return (
            dayjs( ev.deadline ).isSame( dayjs( date ), 'month' ) ||
      dayjs( ev.deadline ).isSame( dayjs( date ).endOf( 'week' ), 'month' )
        )
    }

    return dayjs( ev.deadline ).isSame( dayjs( date ), 'month' )
}

export const outOfEvents = ( date, selected, today ) =>
    !selected &&
  date.isBefore( dayjs( today ).startOf( 'month' )) &&
  dayjs( date ).endOf( 'week' ).isBefore( dayjs( today ).startOf( 'month' ))
