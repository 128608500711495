import {
    Modal,
    Input,
    Button
} from 'antd'
import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
    useState
} from 'react'
import { format } from 'tools'
import './edit-ac-comment-modal.scss'

const { TextArea } = Input

const maxTextareaLength = 255

const EditAcCommentModal = forwardRef(( props, ref ) => {
    const [ visible, setVisible ] = useState( false )
    const [ text, setText ] = useState( '' )
    const [ loading, setLoading ] = useState( false )
    const [ managerSurnameName, setManagerSurnameName ] = useState( '' )
    const employeeUuidRef = useRef( null )
    const pprEmployeeUuidRef = useRef( null )
    const factsNumberRef = useRef( null )

    useImperativeHandle( ref, () => ({
        open: ({ acComment, employeeUuid, pprEmployeeUuid, managerSurnameName }, factsNumber ) => {
            setText( acComment || '' )
            employeeUuidRef.current = employeeUuid
            pprEmployeeUuidRef.current = pprEmployeeUuid
            factsNumberRef.current = factsNumber
            setManagerSurnameName( managerSurnameName )
            setVisible( true )
            setLoading( false )
        },
        close: () => setVisible( false ),
    }))

    const onCancel = () => {
        if ( loading ) { return }
        setVisible( false )
    }

    const handleOk = () => {
        setLoading( true )
        format.generate.put(
            'staff',
            `employees/${employeeUuidRef.current}/ppr/${pprEmployeeUuidRef.current}/ac-comment`
            , { acComment: text }, '', '', ''
        ).then(( result ) => {
            if ( result.status === 200 ) {
                setVisible( false )
                props.updateAcComment({
                    factsNumber: factsNumberRef.current,
                    employeeUuid: employeeUuidRef.current,
                    acComment: text
                })
            } else {
                props.addServerError({
                    code: result.status,
                    text: result.data?.msg || 'Произошла ошибка при отправке данных'
                })
            }

        }).catch(( error ) => props.addServerError({
            code: error.status,
            text: error.data?.msg || 'Произошла ошибка при отправке данных'
        })).finally(() => {
            setLoading( false )
        })
    }

    return (
        <Modal
            className='edit-ac-comment-modal'
            title={
                <div className='header'>
                    <div>Комментарий · </div>
                    <div className='name'>{managerSurnameName}</div>
                </div>
            }
            open={visible}
            onCancel={onCancel}
            footer={[
                <Button key='cancel' onClick={onCancel} disabled={loading}>
                  Не сохранять
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                  Сохранить
                </Button>,
            ]}
        >
            <div className='textarea-wrapper'>
                <TextArea
                    maxLength={255}
                    style={{ height: 120 }}
                    onChange={( e ) => setText( e.target.value )}
                    value={text}
                />
                <div className='count-block'>
                    {text.length} / {maxTextareaLength}
                </div>
            </div>

        </Modal>
    )
})

export default EditAcCommentModal
