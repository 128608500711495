/* VENDOR */
import axios from 'axios'

/* APPLICATION */
import config     from 'config'
import { format } from 'tools'
import { params } from '../../tools/format/generate.js'

const
    rid = [ 'restaurantId' ],
    api = {
        workHours: 'settings/work-hours',
        positions: 'settings/positions',
    },
    service = 'planning'

export const getWorkHours  = params => format.generate.get( service, api.workHours, params, '', rid )
export const getPositions  = params => format.generate.get( service, api.positions, params, '', rid )
export const getAdminPositionsCategories = () => format.generate.get( service, 'admin/positions/categories' )
export const getAdminPositions = () => format.generate.get( service, 'admin/positions' )
export const getPprRestaurant = ({ restaurantId, ...params }) =>
    format.generate.get( 'restaurant', `restaurants/${restaurantId}/ppr`, params )

export const setWorkHours = body => axios.post(
    config.api.planning + api.workHours,
    body.data,
    {
        ...config.api.getOptions(),
        params: body.params,
    })

export const setPosition = ( body ) =>
    axios.post( config.api.planning + api.positions, body.data, {
        ...config.api.getOptions(),
        params: body.params
    }
    )

export const createPosition = body => axios.post(
    config.api.planning + 'admin/positions',
    body,
    config.api.getOptions()
)

export const updatePosition = ( body ) => axios.put(
    config.api.planning + 'admin/positions/' + body.positionUuid,
    body,
    config.api.getOptions()
)

export const removePosition = pos => axios.post(
    config.api.planning + 'admin/positions/' + pos.positionGuid + '/delete',
    {},
    config.api.getOptions()
)

export const restorePosition = pos => axios.post(
    config.api.planning + 'admin/positions/' + pos.positionGuid + '/restore',
    {},
    config.api.getOptions()
)

export const createPprRestaurant = ({ restaurantId, body }) =>
    format.generate.post( 'restaurant', `restaurants/${restaurantId}/ppr`, body, '', '', '', '', { timeout: 300000 })

export const updatePprRestaurant = ({ restaurantId, pprId, body }) =>
    format.generate.put( 'restaurant', `restaurants/${restaurantId}/ppr/${pprId}`, body, '', '', '' )

export const searchRestaurantOptions = ( body ) => format.generate.post( 'restaurant', 'restaurants/options/search', body )

export const postRestaurantOptions = ( body ) => format.generate.post( 'restaurant', 'restaurants/options', body )

export const updateRestaurantOptions = ({ uuid, ...body }) => format.generate.put( 'restaurant', `restaurants/options/${uuid}`, body )
