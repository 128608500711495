/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { connect }          from 'react-redux'
import { Card }             from 'antd'

/* APPLICATION */
import { ReportTable } from 'components'

import './report.scss'

class Report extends Component {
    static propTypes = {
        unit: PropTypes.oneOf([ 'percent', 'currency', 'none' ]),

        data: PropTypes.object,
        columns: PropTypes.array.isRequired,

        rowKey: PropTypes.string,
        filter: PropTypes.bool,

        prepare: PropTypes.func,
        onMore: PropTypes.func,
    }

    static defaultProps = { rowKey: 'label', }

    render () {
        return (
            <Card bordered={false} className="report-table report-page">
                {this.props.children}
                <ReportTable unit={this.props.request.unitOfMeasure} {...this.props} />
            </Card>
        )
    }
}

const mapStateToProps = ( state ) => ({ request: state.request, })

export default connect( mapStateToProps )( Report )
