/* VENDOR */
import React, { Component }                                   from 'react'
import PropTypes                                              from 'prop-types'
import { Modal, Checkbox, Spin, Input, Divider, List, Radio } from 'antd'
import { Scrollbars }                                         from 'react-custom-scrollbars'

/* APPLICATION */
import { Spinner } from 'components'
import { format }  from 'tools'
import config      from 'config'

import './granted-restaurants.scss'

const { Search } = Input,
      itemCls = 'restaurant-item'

class GrantedRestaurants extends Component {
    static propTypes = {
        data: PropTypes.object,
        selected: PropTypes.array,

        filterType: PropTypes.oneOf([ 'restaurants', 'fz', 'areacoach' ]),

        onSubmit: PropTypes.func,
        onCancel: PropTypes.func,
        onSearch: PropTypes.func,
        onMore: PropTypes.func,
        onGranted: PropTypes.func,
        onFilterType: PropTypes.func,

        visible: PropTypes.bool,
    }

    constructor ( props ) {
        super( props )

        this.state = {
            restaurants: [],
            selection: format.copy.array( props.selected ),

            search: '',

            fetching: false,
            onlyGranted: false,
        }

        this.set = format.generate.set( this )
    }

    componentWillUnmount () {
        this._watcher && clearInterval( this._watcher )
    }

    componentDidUpdate ( prevProps ) {
        const { filterType } = this.props

        if ( filterType !== prevProps.filterType ) {
            this.set.search( '' )
            this.scrolls && this.scrolls.scrollToTop()
        }
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { data, visible } = this.props

        if ( nextProps.visible !== visible ) {
            if ( nextProps.visible ) {
                this.set.state({
                    restaurants: nextProps.data ? nextProps.data.content : [],
                    selection: format.copy.array( nextProps.selected ),
                })
                this._watcher = setInterval( this.watch, 100 )
            } else {
                this.set.state({
                    selection: format.copy.array( nextProps.selected ),
                    search: '',
                })
                this._watcher && clearInterval( this._watcher )
            }
        }

        if ( nextProps.data !== data ) {
            this.set.state({
                restaurants: nextProps.data ? nextProps.data.content : [],
                fetching: false,
            })
            this._watcher = setInterval( this.watch, 100 )
        }
    }

    watch = () => {
        const { fetching, search, onlyGranted } = this.state,
              { data, visible, isError } = this.props,
              container = document.querySelector( '.granted-list > div > div' )

        if ( !container || !visible || fetching || isError || data.last || onlyGranted ) { return }

        if (
            container.scrollHeight - container.clientHeight - container.scrollTop <
      200
        ) {
            this.props.onMore( search )
            this.set.fetching( true )
        }
    }

    search = ( e ) => {
        const { filterType } = this.props,
              str = e.target.value

        if ( str.length >= 2 ) {
            this.props.onSearch( filterType, str )
            this.scrolls && this.scrolls.scrollToTop()
        } else if ( this.state.search.length > 1 ) {
            this.props.onSearch( filterType, '' )
        }

        this.set.search( str )
    }

    toggle = ( item ) => {
        return () => {
            const { filterType } = this.props,
                  { selection } = this.state,
                  founds = this.getAdded( item )

            if ( founds.length > 0 ) {
                founds.forEach(( id ) => selection.splice( selection.indexOf( id ), 1 ))
            } else {
                switch ( filterType ) {
                    case 'areacoach':
                    case 'fz':
                        item.grantedRestaurants.forEach(( id ) => selection.push( id ))
                        break

                    case 'restaurants':
                    default:
                        selection.push( item.factsNumber )
                }
            }

            this.set.selection( selection )
        }
    }

    getAdded = ( item ) => {
        const { filterType } = this.props,
              { selection } = this.state

        switch ( filterType ) {
            case 'areacoach':
            case 'fz':
                return item.grantedRestaurants.filter(
                    ( id ) => selection.indexOf( id ) > -1
                )

            case 'restaurants':
            default:
                // eslint-disable-next-line no-case-declarations
                const found = selection.find(( d ) => d === item.factsNumber )

                return found ? [ found ] : []
        }
    }

    isAdded = ( item ) => {
        const { filterType } = this.props,
              { selection } = this.state

        switch ( filterType ) {
            case 'areacoach':
            case 'fz':
                if ( !item.grantedRestaurants?.length ) { return 'none' }

                // eslint-disable-next-line no-case-declarations
                const added = item.grantedRestaurants.filter(
                    ( fn ) => this.state.selection.indexOf( fn ) > -1
                )

                return added.length === item.grantedRestaurants.length
                    ? 'all'
                    : added.length === 0
                        ? 'none'
                        : 'some'

            case 'restaurants':
            default:
                return selection.indexOf( item.factsNumber ) > -1 ? 'all' : 'none'
        }
    }

    item = ( item ) => {
        if ( !item ) { return null }

        const cls = [ itemCls ],
              added = this.isAdded( item )

        return (
            <List.Item className={cls.join( ' ' ).trim()}>
                <Checkbox checked={added !== 'none'} onChange={this.toggle( item )}>
                    <span className="item-title">
                        {item.restaurantName || item.fullName || item.franchiseeGroupName}
                    </span>
                </Checkbox>
                {added === 'some' && <span className="item-aside">Частично</span>}
            </List.Item>
        )
    }

    submit = () => {
        this.set.onlyGranted( false )
        this.props.onSubmit( this.state.selection )
    }

    added = () =>
        this.state.selection.filter(
            ( item ) => !this.props.selected.find(( i ) => i === item )
        ).length
    removed = () =>
        this.props.selected.filter(
            ( item ) => !this.state.selection.find(( i ) => i === item )
        ).length

    addText = () => {
        const added = this.added(),
              removed = this.removed()

        if ( added === 0 && removed === 0 ) { return 'Нечего добавить' }

        if ( removed > 0 && added === 0 ) {
            return (
                'Убрать ' +
        format.strings.clearCount( removed, [
            'ресторан',
            'ресторана',
            'ресторанов',
        ])
            )
        }

        if ( added > 0 && removed === 0 ) {
            return (
                'Добавить ' +
        format.strings.clearCount( added, [
            'ресторан',
            'ресторана',
            'ресторанов',
        ])
            )
        }

        return (
            'Убрать ' +
      format.strings.clearCount( removed, [
          'ресторан',
          'ресторана',
          'ресторанов',
      ]) +
      ', добавить ' +
      added
        )
    }

    toggleOnlyGranted = () =>
        this.set.state(
            { onlyGranted: !this.state.onlyGranted, },
            () => this.props.onGranted( this.state.onlyGranted, this.state.selection )
        )

    onCancel = () => {
        this.set.onlyGranted( false )
        this.scrolls && this.scrolls.scrollToTop()
        this.props.onCancel()
    }

    groupBy = ( key, list ) => {
        const res = []

        if ( !list ) { return null }

        list.forEach(( item ) => {
            const id = item?.[ key ],
                  exist = res.find(( r ) => r && r.id === id )

            exist
                ? exist.grantedRestaurants.push( item?.factsNumber )
                : res.push({
                    id,
                    fullName: id,
                    grantedRestaurants: [ item?.factsNumber ],
                })
        })

        return res
    }

    getList = () => {
        const { filterType } = this.props,
              { restaurants, onlyGranted, search } = this.state,
              sorter = ( a, b ) => a.fullName.localeCompare( b.fullName )

        if ( restaurants && restaurants[ 0 ] === undefined ) { return [] }

        switch ( filterType ) {
            case 'areacoach':
                return onlyGranted
                    ? this.groupBy( 'areaCoachName', restaurants )
                        ?.filter(( r ) => r && format.find.strInProp( r, 'fullName', search ))
                        .sort( sorter )
                    : restaurants

            case 'fz':
                return onlyGranted
                    ? this.groupBy( 'franchiseeGroupName', restaurants )
                        ?.filter(( r ) => r && format.find.strInProp( r, 'fullName', search ))
                        .sort( sorter )
                    : restaurants.sort(( a, b ) =>
                        a.franchiseeGroupName.localeCompare( b.franchiseeGroupName )
                    )

            case 'restaurants':
            default:
                return onlyGranted
                    ? restaurants
                        ?.filter(( r ) =>
                            r && format.find.strInProp( r, 'restaurantName', search )
                        )
                        .sort(( a, b ) => a.restaurantName.localeCompare( b.restaurantName ))
                    : restaurants?.filter(( r ) => r && !!r.factsNumber )
        }
    }

    render () {
        const { search, selection, fetching, onlyGranted } = this.state,
              { data, visible, filterType } = this.props,
              rests = this.getList()

        return (
            <Modal
                className="kfc-popup kfc-granted-restaurants"
                centered={true}
                open={visible}
                title="Связанные рестораны"
                okText={this.addText()}
                onOk={this.submit}
                okButtonProps={{ disabled: this.added() < 1 && this.removed() < 1 }}
                onCancel={this.onCancel}
                cancelText="Отмена"
            >
                <div className="filter-container">
                    <Search
                        placeholder="Начните вводить название"
                        value={search}
                        onChange={this.search}
                    />

                    <Radio.Group onChange={this.props.onFilterType} value={filterType}>
                        <Radio value="areacoach">по ТУ</Radio>
                        <Radio value="fz">по FZ-партнёрам</Radio>
                        <Radio value="restaurants">по ресторанам</Radio>
                    </Radio.Group>
                </div>

                <Divider />

                {data && data.content ? (
                    <div className="granted-list">
                        <Scrollbars
                            {...config.ui.scrolls}
                            ref={( node ) => ( this.scrolls = node )}
                        >
                            <List
                                size="small"
                                dataSource={rests}
                                renderItem={this.item}
                                locale={{ emptyText: rests ? 'Ничего не найдено' : '' }}
                            />
                        </Scrollbars>

                        <Divider />

                        <div className="list-overspin">
                            {selection.length > 0 && (
                                <Checkbox
                                    checked={onlyGranted}
                                    onClick={this.toggleOnlyGranted}
                                >
                  Только связанные
                                </Checkbox>
                            )}
                            {fetching && <Spin size="small" />}
                        </div>
                    </div>
                ) : (
                    <Spinner className="granted-list" />
                )}
            </Modal>
        )
    }
}

export default GrantedRestaurants
