/* VENDOR */
import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { Layout }           from 'antd'
import dayjs                from 'dayjs'

/* APPLICATION */
import {
    AppHeader,
    InnerContent,
    PrintReports,
    InteractiveReports,
    ViewTypeSwitch,
} from 'components'

import { format } from 'tools'
import config     from 'config'

import { mapStateToProps, allActions } from './connector'
import './reports.scss'
import { getPredef }                   from '../../tools/format/dates.js'

const { Content, Header } = Layout

class Reports extends Component {
    constructor ( props ) {
        super( props )

        this.state = {
            view: 'print',
            type: 'daily-schedule',
            restaurant: '...',
            date: dayjs().startOf( 'week' ),
            dates: [ dayjs().startOf( 'week' ), dayjs().endOf( 'week' ) ],
        }
    }

    componentDidMount () {
        const { restaurantId, workHours, fetchWorkHours, request } = this.props,
              { date, type } = this.state

        !workHours && fetchWorkHours( request )
        this.setRestaurant( this.props )
        this.load( restaurantId, date, type )
        this.props.fetchCompanies()
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { restaurants, restaurantId } = this.props

        if (
            restaurantId !== nextProps.restaurantId ||
            restaurants !== nextProps.restaurants
        ) {
            this.setRestaurant( nextProps )
        }
    }

    load = ( restaurantId, rawDate, type ) => {
        let datesInStorage = JSON.parse( sessionStorage.getItem( 'reportsData' ))
        var dateStart, dateEnd
        if ( datesInStorage ) {
            dateStart = dayjs( datesInStorage.start )
            dateEnd = dayjs( datesInStorage.end )
            if ( type !== 'stations' ) {
                dateStart = dayjs( datesInStorage.start ).startOf( 'week' )
                dateEnd = dayjs( datesInStorage.end ).endOf( 'week' )
            }
            this.setState({
                date: dateStart,
                dates: [ dateStart, dateEnd ],
            })
        } else {
            dateStart = Array.isArray( rawDate ) ? rawDate[ 0 ] : rawDate
            dateEnd = Array.isArray( rawDate ) ? rawDate[ 1 ] : rawDate

            if ( type !== 'stations' ) {
                dateStart = dayjs( dateStart ).startOf( 'week' )
                dateEnd = dayjs( dateEnd ).endOf( 'week' )
            }
        }

        sessionStorage.setItem( 'reportsData', JSON.stringify({
            start: dayjs( dateStart ).format( config.format.dateFull ),
            end: dayjs( dateEnd ).format( config.format.dateFull ),
        }))

        const { rtype, method } = this.getType( type ),
              req = {
                  params: {
                      restaurantId,
                      dateStart: dateStart.format( config.format.date ),
                      dateEnd: dateEnd.format( config.format.date ),
                  },
              }

        if ( type === 'statistics' ) {
            delete req.params.restaurantId
            req.params.factsNumber = restaurantId
        }

        if ( rtype === 'daily-schedule' ) {
            delete req.params.dateEnd
            req.params.dateStart = dateStart.format( 'YYYY-MM-DD' )
        }

        if ( rtype === 'workedtime' ) {
            delete req.params.dateEnd
            delete req.params.dateStart
            req.params.factsNumber = req.params.restaurantId
            req.params.dateStart = dateStart.format( config.format.dayAPI )
            req.params.dateEnd = dateEnd.format( config.format.dayAPI )
            delete req.params.restaurantId
        }

        if ( rtype === 'stations' ) {
            const end = dayjs( req.params.dateEnd )
            req.params.dateEnd = end.endOf( 'day' ).format( config.format.date )
        }

        this.props.flushReport( rtype, method )
        this.props[ method ]( req )
    }

    getType = ( type ) => ({
        rtype: this.type( type ),
        method: 'fetch' + format.strings.toCamelCase( this.type( type )) + 'Report',
    })

    setView = ( view ) => this.setState({ view })

    setRestaurant = ( props ) => {
        const restaurant =
            props.restaurants &&
            props.restaurants.find(
                ( r ) => r.factsNumber === parseInt( props.restaurantId )
            )

        const { type, dates, date } = this.state

        this.setState({ restaurant: restaurant ? restaurant.restaurantName : 'KFC', })

        this.load( props.restaurantId, type === 'stations' ? dates : date, type )
    }

    setDate = ( date ) => {
        const datesInStorage = JSON.parse( sessionStorage.getItem( 'reportsData' )),
              dashboardDatesInStorage = JSON.parse( sessionStorage.getItem( 'filtersData' )),
              newDatesInStorage = format.copy.object( datesInStorage ),
              newDashboardDatesInStorage = format.copy.object( dashboardDatesInStorage )

        if ( !date ) { return }

        if ( datesInStorage ) {

            newDatesInStorage.start = dayjs( date ).startOf( 'week' )
            newDatesInStorage.end = dayjs( date ).endOf( 'week' )
            newDatesInStorage.lastPage = 'reports'
            newDatesInStorage.predef = 'week'

            newDashboardDatesInStorage.start = dayjs( date ).startOf( 'week' ).format( config.format.dateFull )
            newDashboardDatesInStorage.end = dayjs( date ).endOf( 'week' ).format( config.format.dateFull )
            newDashboardDatesInStorage.activeDate = true
            newDashboardDatesInStorage.predef = 'week'
            newDashboardDatesInStorage.group = 'day'
            newDashboardDatesInStorage.allow = { min: null, max: null }
            newDashboardDatesInStorage.allow.min = 'day'
            newDashboardDatesInStorage.allow.max = 'day'

            sessionStorage.setItem( 'reportsData', JSON.stringify( newDatesInStorage ))
            sessionStorage.setItem( 'filtersData', JSON.stringify( newDashboardDatesInStorage ))

        }

        this.setState({
            date: dayjs( date ).startOf( 'week' ),
            dates: [ dayjs( date ).startOf( 'week' ), dayjs( date ).endOf( 'week' ) ],
        })


        this.load( this.props.restaurantId, date, this.state.type )
    }

    setDates = ( dates ) => {
        let datesInStorage = JSON.parse( sessionStorage.getItem( 'reportsData' )),
            dashboardDatesInStorage = JSON.parse( sessionStorage.getItem( 'filtersData' )),
            newDatesInStorage = format.copy.object( datesInStorage ),
            newDashboardDatesInStorage = format.copy.object( dashboardDatesInStorage ),
            currentPredef = getPredef( dayjs( dates[ 0 ]).startOf( 'week' ), dayjs( dates[ 1 ]).endOf( 'week' ))

        if ( datesInStorage ) {
            if ( datesInStorage ) {
                newDatesInStorage.start = dayjs( dates[ 0 ])
                newDatesInStorage.end = dayjs( dates[ 1 ])
                newDatesInStorage.lastPage = 'reports'
                newDatesInStorage.predef = currentPredef

                newDashboardDatesInStorage.start = dayjs( dates[ 0 ]).startOf( 'week' ).format( config.format.dateFull )
                newDashboardDatesInStorage.end = dayjs( dates[ 1 ]).endOf( 'week' ).format( config.format.dateFull )
                newDashboardDatesInStorage.activeDate = true
                newDashboardDatesInStorage.predef = 'week'
                newDashboardDatesInStorage.group,
                newDashboardDatesInStorage.allow.min,
                newDashboardDatesInStorage.allow.max = 'day'


                sessionStorage.setItem( 'reportsData', JSON.stringify( newDatesInStorage ))
                sessionStorage.setItem( 'filtersData', JSON.stringify( newDashboardDatesInStorage ))
            }
        }

        this.setState({
            dates,
            date: dayjs( dates[ 0 ]),
        })

        this.load( this.props.restaurantId, dates, this.state.type )
    }

    setType = ( data ) => {
        const { restaurantId } = this.props,
              { date, dates } = this.state

        if ( this.state.type === data.key ) { return }

        this.setState({ type: data.key, })

        this.load( restaurantId, data.key === 'stations' ? dates : date, data.key )
    }

    print = () => {
        window.print()
    }

    type = ( t ) => ( t === 'short' ? 'schedule' : t )

    content () {
        const { reports, companies, workHours } = this.props,
              { restaurant, date, dates, type } = this.state,
              datesInStorage = JSON.parse( sessionStorage.getItem( 'reportsData' ))

        switch ( this.state.view ) {
            case 'interactive':
                return (
                    <InteractiveReports
                        data={reports ? reports.interactive : null}
                        companies={companies}
                        flush={this.props.flushIByTimeReport}
                        fetch={this.props.fetchIByTimeReport}
                    />
                )
            case 'print':
            default:
                return (
                    <PrintReports
                        reports={reports}
                        restaurant={restaurant}
                        workHours={workHours}
                        date={datesInStorage ? dayjs( datesInStorage.start ) : date}
                        dates={datesInStorage ? [ dayjs( datesInStorage.start ), dayjs( datesInStorage.end ) ] : dates}
                        current={type}
                        onChange={type === 'stations' ? this.setDates : this.setDate}
                        onSelect={this.setType}
                        onPrint={this.print}
                        getType={this.type}
                    />
                )
        }
    }

    render () {
        const { restaurantId, reports } = this.props,
              { view } = this.state,
              tabs = {
                  print: 'Печатные',
                  interactive: 'Интерактивные',
              },
              cls = [
                  'kfc-reports kfc-tabbed-page scroll-container',
                  `kfc-${view}-report`,
              ]

        return (
            <section className={cls.join( ' ' )}>
                <Layout>
                    <Header>
                        <AppHeader
                            hideRestaurants={view === 'interactive'}
                            ready={!!reports}
                            timeData={parseInt( restaurantId )}
                        />
                    </Header>
                    <Content>
                        <ViewTypeSwitch update={this.setView} tabs={tabs} current={view}/>
                        <InnerContent>
                            <div
                                className="tabbed-content"
                                style={{ display: 'flex', flexDirection: 'column' }}
                            >
                                {this.content()}
                            </div>
                        </InnerContent>
                    </Content>
                </Layout>
            </section>
        )
    }
}

export default connect( mapStateToProps, allActions )( Reports )
