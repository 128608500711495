/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Card }             from 'antd'

/* APPLICATION */
import { ReportTable } from 'components'
import { format }      from 'tools'
import config          from 'config'

class TimeSettings extends Component {
    static propTypes = {
        data: PropTypes.array,
        onChange: PropTypes.func,
    }

    prepare = ( record ) => {
        record.disabled = record.disabled || {}
        record.disabled.noctidial = record.holiday

        if ( record.holiday || record.noctidial ) {
            record.disabled.timeStart = true
            record.disabled.timeEnd = true

            if ( record.holiday ) {
                record.noctidial = false
                record.shifts = []
            }
        } else {
            record.disabled.timeStart = false
            record.disabled.timeEnd = false
        }

        !record.timeEnd && ( record.timeEnd = undefined )
        !record.timeStart && ( record.timeStart = undefined )

        // record.shifts.forEach(( shift ) => {
        //     !shift.timeEnd && ( shift.timeEnd = '23:45' )
        //     !shift.timeStart && ( shift.timeStart = '00:00' )
        // })

        return record
    }

    render () {
        return (
            <Card bordered={false}>
                <div className="report-table time-table">
                    <ReportTable
                        data={format.generate.noPager( this.props.data )}
                        columns={config.tables.settings.time}
                        rowKey="dayOfWeek"
                        filter={false}
                        onChange={this.props.onChange}
                        prepare={this.prepare}
                    />
                </div>
            </Card>
        )
    }
}

export default TimeSettings
