/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import dayjs                from 'dayjs'

import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'

import {
    Input,
    InputNumber,
    Select,
    Radio,
    Button,
    Checkbox,
} from 'antd'
import Icon from '@ant-design/icons'

/* APPLICATION */
import { AddButton, Icons, Link, ChartTime } from 'components'
import { format }                            from 'tools'
import config                                from 'config'

import './form-field.scss'
import TimePicker     from '../TimePicker/TimePicker'
import { DatePicker } from 'antd-v5'

const Option = Select.Option,
      { WeekPicker, RangePicker, MonthPicker } = DatePicker,
      { TextArea } = Input

class FormField extends Component {
    static propTypes = {
        sub: PropTypes.object,

        value: PropTypes.any,
        bordered: PropTypes.bool,
        minuteStep: PropTypes.number,
        min: PropTypes.number,

        type: PropTypes.string,
        label: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
        field: PropTypes.string,
        text: PropTypes.string,
        style: PropTypes.string,
        addOn: PropTypes.string,
        format: PropTypes.string,
        container: PropTypes.string,
        placeholder: PropTypes.string,
        empty: PropTypes.any,

        hide: PropTypes.oneOfType([ PropTypes.bool, PropTypes.func ]),
        hidden: PropTypes.oneOfType([ PropTypes.bool, PropTypes.func ]),
        open: PropTypes.bool,
        byKey: PropTypes.bool,
        autocomplete: PropTypes.bool,
        noPreserve: PropTypes.bool,

        disabled: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.string,
            PropTypes.func,
        ]),
        disabledDate: PropTypes.func,
        disabledHours: PropTypes.func,
        disabledMinutes: PropTypes.func,
        handler: PropTypes.func,
        onChange: PropTypes.func,
        prepare: PropTypes.func,
        parse: PropTypes.func,
        search: PropTypes.func,
        transform: PropTypes.func,
        visibleChange: PropTypes.func,
    }

    constructor ( props ) {
        super( props )
        this.state = {
            input: this.input( props ),
            rules: this.rules( props ),
            value: this.value( props ),
        }
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        this.setState({
            input: this.input( nextProps ),
            rules: this.rules( nextProps ),
            value: this.value( nextProps ),
        })
    }

    option = ( props ) => {
        return ( key ) => (
            <Option value={key} key={key} disabled={key === '_none'}>
                {props.options[ key ]}
            </Option>
        )
    }

    optionO = ({ value, label }) => (
        <Option value={value} key={value} disabled={value === '_none'}>
            {label}
        </Option>
    )

    disabledDate = ( props ) => {
        return ( current ) =>
            props.disabledDate ? props.disabledDate( current ) : false
    }

    disabledHours = ( props ) => {
        return () => ( props.disabledHours ? props.disabledHours() : [])
    }

    disabledMinutes = ( props ) => {
        return ( hour ) =>
            props.disabledMinutes ? props.disabledMinutes( hour ) : []
    }

    disabled = ( props ) => {
        const { disabled, data, form } = props

        if ( typeof disabled === 'string' ) {
            if ( !data ) { return true }

            return disabled[ 0 ] === '!' ? !data[ disabled.substr( 1 ) ] : data[ disabled ]
        }

        if ( typeof disabled === 'function' ) {
            return disabled( data, form )
        }

        return disabled
    }

    container = ( props ) => {
        return () =>
            props.container ? document.querySelector( props.container ) : document.body
    }

    input = ( props ) => {
        const {
            type,
            options,
            text,
            handler,
            min,
            style,
            addOn,
            open,
            label,
            minuteStep,
            search,
            placeholder,
            empty,
            autoFocus,
        } = props

        switch ( type ) {
            case 'remove':
                return (
                    <span className="remove-row-control" onClick={handler}>
                        <Icon component={Icons.Remove.active} />
                        {text || 'Удалить'}
                    </span>
                )
            case 'add':
                return (
                    <AddButton
                        text={text}
                        disabled={this.disabled( props )}
                        action={handler}
                    />
                )
            case 'button':
                return (
                    <Button
                        type={style}
                        disabled={this.disabled( props )}
                        onClick={handler}
                    >
                        {text}
                    </Button>
                )
            case 'arrow':
                return (
                    <button
                        type="button"
                        disabled={this.disabled( props )}
                        onClick={handler}>
                        {text}
                    </button>
                )
            case 'submit':
                return (
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={this.disabled( props )}
                    >
                        {text}
                    </Button>
                )
            case 'select':
                return (
                    <Select
                        loading={props.isLoading}
                        showSearch={!!search}
                        notFoundContent={empty}
                        disabled={this.disabled( props )}
                        onChange={this.onChange( 'select' )}
                        filterOption={search}
                        getPopupContainer={( trigger ) => trigger.parentNode}
                        onDropdownVisibleChange={this.props.visibleChange}
                        placeholder={placeholder}
                        mode={props.mode}
                        maxTagCount={1}
                    >
                        {Array.isArray( options )
                            ? options.map( this.optionO )
                            : Object.keys( options ).map( this.option( props ))}
                    </Select>
                )
            case 'radio':
                return (
                    <Radio.Group
                        options={options}
                        disabled={this.disabled( props )}
                        onChange={this.onChange( 'value' )}
                    />
                )
            case 'yesno':
                return (
                    <Radio.Group
                        className="yesno-radio"
                        options={[
                            {
                                value: 'true',
                                label: 'Да',
                            },
                            {
                                value: 'false',
                                label: 'Нет',
                            },
                        ]}
                        disabled={this.disabled( props )}
                        onChange={this.onChange( 'value' )}
                    />
                )
            case 'checkbox':
                return (
                    <Checkbox
                        disabled={this.disabled( props )}
                        onChange={this.onChange( 'checked' )}
                    >
                        {label}
                    </Checkbox>
                )
            case 'checkgroup':
                return (
                    <Checkbox.Group
                        options={options}
                        disabled={this.disabled( props )}
                        onChange={this.onChange( 'normal' )}
                    />
                )
            case 'hidden':
                return <Input type="hidden" />
            case 'date':
                return (
                    <DatePicker
                        showToday={!props.hideToday}
                        locale={config.ui.locale}
                        disabled={this.disabled( props )}
                        disabledDate={this.disabledDate( props )}
                        format={props.format || config.format.day}
                        onChange={this.onChange( 'select' )}
                        getCalendarContainer={( trigger ) => trigger.parentNode}
                    />
                )
            case 'datetime':
                return (
                    <DatePicker
                        showTime={{
                            minuteStep: minuteStep || 15,
                            disabledTime: () => ({
                                disabledHours: this.disabledHours( props ),
                                disabledMinutes: this.disabledMinutes( props ),
                            }),
                            format: config.format.time,
                        }}
                        locale={config.ui.locale}
                        disabled={this.disabled( props )}
                        disabledDate={this.disabledDate( props )}
                        format={'DD MMMM, ' + config.format.time}
                        onChange={this.onChange( 'select' )}
                        getCalendarContainer={( trigger ) => trigger.parentNode}
                        onCalendarChange={this.onChange( 'select' )}
                    />
                )
            case 'week':
                return (
                    <WeekPicker
                        locale={config.ui.locale}
                        disabled={this.disabled( props )}
                        disabledDate={this.disabledDate( props )}
                        format={'С ' + config.format.day}
                        open={open}
                        onChange={this.onChange( 'select' )}
                        getPopupContainer={this.container( props )}
                        popupClassName={this.props.popupClassName}
                    />
                )
            case 'month':
                return (
                    <MonthPicker
                        locale={config.ui.locale}
                        disabled={this.disabled( props )}
                        disabledDate={this.disabledDate( props )}
                        format={props.format || config.format.monthView}
                        onChange={this.onChange( 'select' )}
                        getCalendarContainer={( trigger ) => trigger.parentNode}
                    />
                )
            case 'range':
                return (
                    <RangePicker
                        locale={config.ui.locale}
                        disabled={this.disabled( props )}
                        disabledDate={this.disabledDate( props )}
                        format={config.format.dayViewShort}
                        onChange={this.onChange( 'select' )}
                        getCalendarContainer={( trigger ) => trigger.parentNode}
                    />
                )
            case 'time':
                return (
                    <TimePicker
                        locale={config.ui.locale}
                        disabled={this.disabled( props )}
                        disabledTime={() => ({
                            disabledHours: this.disabledHours( props ),
                            disabledMinutes: this.disabledMinutes( props ),
                        })}
                        format={config.format.time}
                        minuteStep={minuteStep || 15}
                        onChange={this.onChange( 'select' )}
                        getPopupContainer={( trigger ) => trigger.parentNode}
                        placeholder="Время"
                        onCalendarChange={this.onChange( 'select' )}
                    />
                )
            case 'chartTime':
                return (
                    <ChartTime
                        disabled={this.disabled( props )}
                        disabledHours={this.disabledHours( props )() || []}
                        disabledMinutes={this.disabledMinutes( props )}
                        format={config.format.time}
                        minuteStep={minuteStep || 15}
                        onChange={this.onChange( 'select' )}
                        getPopupContainer={( trigger ) => trigger.parentNode}
                        bordered={this.props.bordered}
                    />
                )
            case 'number':
                return (
                    <InputNumber
                        placeholder={placeholder}
                        disabled={this.disabled( props )}
                        formatter={( value ) =>
                            addOn
                                ? `${format.strings.thousand( parseFloat( value ))} ${addOn}`
                                : value}
                        parser={format.generate.parser( addOn, ',' )}
                        min={min || 0}
                        onChange={this.onChange( 'select' )}
                    />
                )
            case 'text':
                return (
                    <TextArea
                        rows={4}
                        placeholder={placeholder}
                        disabled={this.disabled( props )}
                        onChange={this.onChange( 'value' )}
                    />
                )
            case 'autoheight':
                return (
                    <TextArea
                        autoFocus={autoFocus}
                        rows={3}
                        placeholder={placeholder}
                        disabled={this.disabled( props )}
                        onChange={this.onChange( 'value' )}
                        autosize={{ minRows: 3 }}
                    />
                )
            case 'label':
                return null
            case 'link':
                return <Link onClick={( e ) => handler( props, e )}>{label}</Link>
            case 'info':
                return <span>{props.render ? props.render() : label}</span>
            case 'string':
            default:
                return (
                    <Input
                        autoComplete={this.props.autocomplete ? 'on' : 'off'}
                        placeholder={placeholder}
                        disabled={this.disabled( props )}
                        onChange={this.onChange( 'value' )}
                    />
                )
        }
    }

    onChange = ( type ) => {
        return ( e ) => {
            const { field, onChange } = this.props

            if ( !onChange ) { return }

            switch ( type ) {
                case 'checked':
                    onChange( field, e.target.checked )
                    return
                case 'value':
                    onChange( field, e.target.value )
                    return
                default:
                    onChange( field, e )
            }
        }
    }

    value = ( props ) => {
        const { type, value, options, prepare, transform } = props
        let rval = transform ? transform( value ) : value

        if ( this.props.field === 'rateDictionary' ) {
            rval = value?.rate || value
        }
        if ( rval === null || rval === undefined || type === 'label' ) { return rval }

        switch ( type ) {
            case 'checkbox':
                return !!rval

            case 'select':
                if ( props?.mode === 'multiple' ) {
                    return rval?.map( String )
                }
                return this.props.byKey ? rval.toString() : options[ rval.toString() ]

            case 'week':
                return rval ? dayjs( rval, config.format.dayAPI ).startOf( 'week' ) : null

            case 'datetime':
                return rval

            case 'date':
                return rval ? dayjs( rval, config.format.dayAPI ) : null
            case 'month':
                return rval ? dayjs( rval, config.format.monthView ) : null
            case 'time':
                return rval ? dayjs( rval, config.format.time ) : null

            case 'chartTime':
                return rval

            case 'range':
                return rval
                    ? [
                            dayjs( rval[ 0 ], config.format.dayAPI ),
                            dayjs( rval[ 1 ], config.format.dayApi ),
                        ]
                    : null

            case 'checkgroup':
                return prepare ? prepare( rval ) : rval

            default:
                return prepare ? prepare( rval ) : rval.toString()
        }
    }

    rules = ( props ) => {
        const { type, rules } = props,
              res = rules || []

        switch ( type ) {
            case 'week':
            case 'date':
            case 'time':
                res.type = 'object'
                break
            default:
      //nothing to do
        }

        return res
    }

    normalize = ( val ) => {
        const { type } = this.props

        switch ( type ) {
            case 'week':
                return val ? val.startOf( 'week' ) : val
            default:
                return val
        }
    }

    hasLabel = ( type ) => {
        const noLabel = [ 'checkbox', 'remove', 'link' ]

        return !noLabel.includes( type )
    }

    addProps = ( type ) => {
        switch ( type ) {
            case 'checkbox':
                return { valuePropName: 'checked', }
            default:
                return {}
        }
    }

    render () {
        const { value, rules, input } = this.state,
              { field, label, type, hide, hidden, sub, className, noPreserve } =
        this.props,
              { getFieldDecorator } = this.props.form,
              cls = [ `fi-type-${type}`, `fi-field-${field}`, className ]

        if ( hide ) {
            if ( typeof hide === 'function' ) {
                if ( hide( this.props )) {
                    return null
                }
            } else {
                return null
            }
        }

        if ( hidden ) {
            if ( typeof hidden === 'function' ) {
                hidden( this.props ) && cls.push( 'fi-hidden' )
            } else {
                cls.push( 'fi-hidden' )
            }
        }

        if ( type === 'separator' ) { return <hr key={field} /> }
        if ( type === 'space' ) { return <span></span> }


        return (
            <React.Fragment>
                <Form.Item
                    key={field}
                    label={this.hasLabel( type ) ? label : null}
                    className={cls.join( ' ' )}
                >
                    {type !== 'label' &&
            getFieldDecorator( field, {
                initialValue: value,
                rules: rules,
                validateFirst: true,
                normalize: this.normalize,
                preserve: !noPreserve,
                ...this.addProps( type ),
            })( input )}
                </Form.Item>
                {sub && (
                    <span className="fi-sub">
                        <FormField {...this.props} {...sub} sub={null} />
                    </span>
                )}
            </React.Fragment>
        )
    }
}

export default FormField
