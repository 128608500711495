import dayjs    from 'dayjs'
import stations from '../PrintReport/templates/Stations.js'

export const report = ({
    restaurant,
    data,
    start,
    end
}) => {
    const font = { name: 'Arial', sz: '10', bold: false }
    const borderBottom = { bottom: { style: 'thick' } }
    const excelHeader = {
        columns: [
            {
                title: `Выручка по станциям ${restaurant} ${dayjs( start ).format( 'DD' )} - ${dayjs( end ).format( 'DD MMMM YYYY' )}`,
                style: { font: { sz: '24', bold: true, name: 'Arial' } }
            }
        ],
        data: [ [ { value: '' } ] ]
    }
    const formatValue = ( value ) => value === null ? '-' : value

    return [
        excelHeader,
        {
            ySteps: -1,
            columns: [
                { title: '', width: { wpx: 120 } },
                { title: 'Чеки', style: { ...font, bold: true } },
                { title: 'Средний чек', style: { ...font, bold: true }, width: { wpx: 120 } },
                { title: 'Товарооборот', style: { ...font, bold: true } },
            ],
            data: [
                ...data.stations.map(( station, index ) => {
                    const isLast = index === data.stations.length - 1
                    return ([
                        {
                            value: station.stationName,
                            style: {
                                font,
                                ...( isLast && { border: borderBottom })
                            }
                        },
                        {
                            value: formatValue( station.checksCount ),
                            style: {
                                font,
                                ...( isLast && { border: borderBottom })
                            }
                        },
                        {
                            value: formatValue( station.factAverageCheck ),
                            style: {
                                font,
                                ...( isLast && { border: borderBottom })
                            }
                        },
                        {
                            value: formatValue( station.factTurnover ),
                            style: {
                                font,
                                ...( isLast && { border: borderBottom })
                            }
                        }
                    ])
                }),
                [
                    {
                        value: 'Общий итог',
                        style: {
                            font: { ...font, bold: true },
                            border: borderBottom
                        }
                    },
                    {
                        value: formatValue( data.summary.checksCount ),
                        style: {
                            font: { ...font, bold: true },
                            border: borderBottom
                        }
                    },
                    {
                        value: formatValue( data.summary.factAverageCheck ),
                        style: {
                            font: { ...font, bold: true },
                            border: borderBottom
                        }
                    },
                    {
                        value: formatValue( data.summary.factTurnover ),
                        style: {
                            font: { ...font, bold: true },
                            border: borderBottom
                        }
                    }
                ]
            ]
        },
        {
            columns: [ { title: '*Отчет сформирован на основании смен R-Keeper', style: { font } } ],
            data: [ [ { value: '' } ] ]
        }
    ]
}
