import React from 'react'
import dayjs from 'dayjs'

import { format } from 'tools'
import column     from './helpers'
import shared     from './shared'
import * as defs  from '../defs'

export default {
    staffRating: [
        column( 'Дата', 'date', {
            width: 100,
            sorter: 'bymoment',
            render: ( text ) =>
                isNaN( parseInt( text )) ? text : dayjs( text ).format( 'DD MMM YY' ),
        }),
        column( 'Позиция', 'position', {
            sorter: 'locale',
            render: ( text ) => (
                <span className="cut-width" title={text}>
                    {text}
                </span>
            ),
        }),
        column( 'Рейтинг', 'rating', {
            format: 'intPercentage',
            width: 60,
            sorter: 'bynull',
        }),
        column( 'Сертификация', 'certification', {
            width: 100,
            sorter: ( a, b, order ) => {
                if ( format.sort.alwaysOnTop( a )) { return order === 'ascend' ? -1 : 1 }
                if ( format.sort.alwaysOnTop( b )) { return order === 'ascend' ? 1 : -1 }

                if ( a.certification && !b.certification ) { return 1 }
                if ( b.certification && !a.certification ) { return -1 }
                return 0
            },
            render: ( val ) =>
                val ? (
                    <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.99857 5.06111C6.65372 5.06111 4.74594 6.9687 4.74594 9.31355C4.74594 11.6586 6.65372 13.5662 8.99857 13.5662C11.3434 13.5662 13.251 11.6586 13.251 9.31355C13.251 6.9687 11.3434 5.06111 8.99857 5.06111Z"
                            fill="#1F86FF"/>
                        <path
                            d="M17.8562 12.1917L16.5666 9.31364L17.8562 6.43559L15.1214 4.8651L14.473 1.77868L11.3371 2.11578L8.99852 0L6.65972 2.11578L3.52404 1.77887L2.87567 4.86529L0.140625 6.43559L1.43023 9.31364L0.140625 12.1917L2.87567 13.7622L3.22686 15.4343L0.307434 21.6377L3.3543 21.6776L5.32708 24L8.1967 17.902L8.99852 18.6273L9.80016 17.902L12.67 24L14.6426 21.6776L17.6894 21.6377L14.77 15.4343L15.1214 13.7622L17.8562 12.1917ZM8.99852 14.9723C5.87823 14.9723 3.33984 12.4339 3.33984 9.31364C3.33984 6.19335 5.87823 3.65496 8.99852 3.65496C12.1186 3.65496 14.6572 6.19353 14.6572 9.31364C14.6572 12.4339 12.1186 14.9723 8.99852 14.9723Z"
                            fill="#1F86FF"/>
                    </svg>
                ) : (
                    ''
                ),
        }),
    ],

    absence: [
        column( 'Сотрудник', 'employeeGuid', {
            editable: true,
            as: 'select',
            items: 'employees',
            focusOnCreate: true,
            width: 260,
            search: true,
        }),
        column( 'Причина', 'reason', {
            editable: true,
            as: 'select',
            items: 'reasons',
            width: 170,
        }),
        shared.dateStart,
        shared.dateEnd,
        shared.remove,
    ],

    fixed: {
        days: [ shared.days( 'shifts', 'day', defs.shifts ) ],
        shifts: [
            shared.employee,
            shared.daySelect,
            column( 'Позиция', 'position', {
                editable: true,
                as: 'select',
                items: 'positions',
                width: 130,
            }),
            column( 'Начало', 'timeStart', {
                editable: true,
                as: 'time',
                width: 110,
            }),
            column( 'Окончание', 'timeEnd', {
                editable: true,
                as: 'time',
                width: 110,
            }),
            shared.remove,
        ],
    },

    positions: [
        column( 'Название', 'name', {
            editable: true,
            as: 'select',
            items: 'positions',
            width: 150,
            disabled: ( record ) => !!record.name,
            focusOnCreate: true,
            popover: ( record ) =>
                record.name
                    ? 'Нельзя сменить название позиции. Удалите и создайте новую.'
                    : false,
        }),
        column( 'Описание', 'description', { disabled: ( record ) => !record.name, }),
        column( 'Рейтинг', 'rate', {
            editable: true,
            as: 'select',
            items: 'rates',
            width: 80,
            disabled: ( record ) => !record.name,
        }),
        column( 'Ограничения', 'blocked', {
            as: 'boolean',
            disabled: ( record ) => !record.name,
        }),
        shared.dateStart,
        shared.dateEnd,
        column( 'Изменения рейтинга', 'dateModify', {
            editable: false,
            as: 'date',
        }),
        shared.remove,
    ],

    opportunities: [
        column( 'День', 'dayOfWeek', {
            as: 'dayOfWeekSelect',
            className: 'capitalize',
            width: 100,
        }),
        column( 'Начало', 'startTime', {
            editable: true,
            as: 'time',
            width: 80,
        }),
        column( 'Окончание', 'endTime', {
            editable: true,
            as: 'time',
            width: 120,
        }),
        column( 'Фикс', 'fixed', {
            editable: true,
            as: 'boolean',
            width: 60,
        }),
        column( 'Позиция', 'positionGuid', {
            editable: true,
            as: 'select',
            items: 'positions',
            width: 150,
            hide: ( record ) => !record.fixed,
        }),
        column( 'Временно', 'timed', {
            as: 'boolean',
            width: 80,
            wait: false,
            hide: ( record ) => record.fixed,
        }),
        column( 'Дата начала', 'dateStart', {
            editable: true,
            as: 'date',
            width: 150,
            hide: ( record ) => record.fixed,
        }),
        column( 'Дата окончания', 'dateEnd', {
            editable: true,
            as: 'date',
            width: 150,
            hide: ( record ) => record.fixed,
        }),

        shared.remove,
    ],
}
