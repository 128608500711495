/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Card }             from 'antd'

/* APPLICATION */
import { ReportTable, AddButton } from 'components'
import { format }                 from 'tools'
import config                     from 'config'

class Duration extends Component {
    static propTypes = {
        duration: PropTypes.object,
        update: PropTypes.func,
        remove: PropTypes.func,
    }

    constructor ( props ) {
        super( props )
        this.state = { data: [], }
    }

    componentDidMount () {
        this.props.duration && this.setData( this.props.duration.data )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        if ( this.props.duration !== nextProps.duration ) {
            this.setData( nextProps.duration ? nextProps.duration.data : null )
        }
    }

    sort = ( a, b ) => a.priority - b.priority

    setData = ( data ) =>
        this.setState({ data: data ? data.sort( this.sort ) : [], })

    change = ( record, key, val ) => {
        const rec = format.copy.object( record )

        rec[ key ] = val

        this.props.update( rec )
    }

    removeUnsaved = ( record ) => {
        const data = format.copy.array( this.state.data )

        data.splice( data.indexOf( record ), 1 )
        this.setState({ data })
    }

    removeSaved = ( record ) => this.props.remove( record )

    remove = ( record ) =>
        record.__unsaved ? this.removeUnsaved( record ) : this.removeSaved( record )

    add = () => {
        const rec = format.copy.object( config.defs.duration ),
              data = format.copy.array( this.state.data )

        rec.durationId = format.generate.guid()
        rec.__unsaved = true

        data.push( rec )
        this.setState({ data })
    }

    render () {
        return (
            <div className="manning-duration">
                <Card bordered={false}>
                    <div className="report-table">
                        <ReportTable
                            data={format.generate.noPager( this.state.data )}
                            columns={config.tables.duration}
                            rowKey="durationId"
                            loading={!this.props.duration}
                            onCellBlur={this.change}
                            onRemove={this.remove}
                        />
                    </div>
                    <div className="tabbed-footer nopad">
                        <AddButton text="Добавить" action={this.add} />
                    </div>
                </Card>
            </div>
        )
    }
}

export default Duration
