/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'

import { Modal } from 'antd'

/* APPLICATION */
import { AddEventForm } from 'components'

class EventModal extends Component {
    static propTypes = {
        title: PropTypes.string,
        view: PropTypes.string,
        okBtnText: PropTypes.string,
        deleteBtn: PropTypes.bool,

        visible: PropTypes.bool,
        position: PropTypes.object,
        event: PropTypes.object,

        onClose: PropTypes.func,
        onSubmit: PropTypes.func,
        onRemove: PropTypes.func,
    }

    addForm = React.createRef()

    close = ( e ) => {
        this.addForm.current &&
      setTimeout(() => this.addForm.current.resetFields(), 400 )
        this.props.onClose( e )
    }

    render () {
        const { title, view, visible, event, okBtnText, deleteBtn } = this.props,
              { onSubmit, onRemove } = this.props

        return (
            <Modal
                className="kfc-popup no-footer no-margin kfc-calendar-popup"
                centered={true}
                open={visible}
                okText="Добавить"
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
                onCancel={this.close}
            >
                <AddEventForm
                    ref={this.addForm}
                    event={event}
                    title={title}
                    view={view}
                    okBtnText={okBtnText}
                    deleteBtn={deleteBtn}
                    onSubmit={onSubmit}
                    onCancel={this.close}
                    onRemove={onRemove}
                />
            </Modal>
        )
    }
}

export default EventModal
