/* VENDOR */
import React, { Component } from 'react'
import dayjs                from 'dayjs'

/* APPLICATION */
import { format } from 'tools'

import './session-timeout.scss'

let inter = null

class SessionTimeout extends Component {
    constructor ( props ) {
        super( props )

        this.state = { time: format.strings.parseJwt( localStorage.getItem( 'access_token' ))?.exp }

        inter = setInterval( this.update, 60000 )
    }

    componentWillUnmount () {
        clearInterval( inter )
    }

    update = () =>
        this.setState({ time: format.strings.parseJwt( localStorage.getItem( 'access_token' ))?.exp })

    time = () => {
        const { time } = this.state,
              till = dayjs.unix( time ),
              now = dayjs(),
              hours = till.diff( now, 'hours' ),
              minutes = till.diff( now, 'minutes' )

        if ( hours > 0 ) { return `< ${hours + 1} ч` }
        return `< ${Math.max( 0, minutes ) + 1} мин`
    }

    render () {
        return (
            <div className="session-timeout">
                <svg
                    width="10"
                    height="10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M6.93512 6.62507L5.25012 5.62507V3.36007C5.25012 3.16007 5.09012 3.00007 4.89012 3.00007H4.86013C4.66013 3.00007 4.50012 3.16007 4.50012 3.36007V5.72007C4.50012 5.89507 4.59012 6.06007 4.74512 6.15007L6.57013 7.24507C6.74013 7.34507 6.96013 7.29507 7.06013 7.12507C7.16513 6.95007 7.11012 6.72507 6.93512 6.62507ZM9.59013 1.50507L8.05013 0.22507C7.84013 0.0500702 7.52513 0.0750702 7.34513 0.29007C7.17013 0.50007 7.20012 0.81507 7.41012 0.99507L8.94512 2.27507C9.15512 2.45007 9.47012 2.42507 9.65012 2.21007C9.83012 2.00007 9.80013 1.68507 9.59013 1.50507ZM1.05012 2.27507L1.81762 1.63507L2.58512 0.99507C2.80012 0.81507 2.83013 0.50007 2.65013 0.29007C2.47513 0.0750702 2.16012 0.0500702 1.95012 0.22507L0.410125 1.50507C0.200125 1.68507 0.170125 2.00007 0.350125 2.21007C0.525125 2.42507 0.840125 2.45007 1.05012 2.27507ZM5.00012 1.00007C2.51513 1.00007 0.500125 3.01507 0.500125 5.50007C0.500125 7.98507 2.51513 10.0001 5.00012 10.0001C7.48512 10.0001 9.50012 7.98507 9.50012 5.50007C9.50012 3.01507 7.48512 1.00007 5.00012 1.00007ZM5.00012 9.00007C3.07012 9.00007 1.50012 7.43007 1.50012 5.50007C1.50012 3.57007 3.07012 2.00007 5.00012 2.00007C6.93012 2.00007 8.50012 3.57007 8.50012 5.50007C8.50012 7.43007 6.93012 9.00007 5.00012 9.00007Z"
                        fill="#fff"
                    />
                </svg>
                {this.time()}
            </div>
        )
    }
}

export default SessionTimeout
