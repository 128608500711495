import { consolidatedStatisticsActions, guestVoteActions } from 'services'
import { IState }                                          from 'store'

export const allActions = { ...guestVoteActions, ...consolidatedStatisticsActions }

export const mapStateToProps = ( state: IState ) => ({
    currentFilters: state.guestVote.currentFilters,
    feedbacksSearch: state.guestVote.feedbacksSearch,
    feedbacksSearchLoading: state.guestVote.feedbacksSearch_loading,
    statisticFeedbacksSummary: state.guestVote.statisticsFeedbacksSummary,
    statisticFeedbacksSummaryLoading: state.guestVote.statisticsFeedbacksSummary_loading,
    feedbacksSearchAppendLoading: state.guestVote.feedbacksSearchAppend_loading
})


export type ConsolidatedStatisticsStoreProps = ReturnType<typeof mapStateToProps> & typeof allActions
