/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import {
    Modal,
    Radio,
    Input,
    Divider,
    Empty,
    Row,
    Col,
    Select,
    Checkbox,
    Table,
} from 'antd'
import { Scrollbars } from 'react-custom-scrollbars'

/* APPLICATION */
import { Spinner } from 'components'
import { format }  from 'tools'
import config      from 'config'

import './origin-modal.scss'
import dayjs      from 'dayjs'
import TimePicker from '../../layout/TimePicker/TimePicker'

const { Search } = Input,
      itemCls = 'restaurant-item'

class OriginModal extends Component {
    static propTypes = {
        data: PropTypes.array,
        selected: PropTypes.object,

        onSubmit: PropTypes.func,
        onCancel: PropTypes.func,

        visible: PropTypes.bool,
    }

    constructor ( props ) {
        super( props )

        this.state = {
            search: '',
            type: 'any',
            timeFrom: null,
            timeTo: null,
            noctidial: false,
            filtered: props.data,
            selected: props.selected || null,
        }
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { selected, data } = this.props

        selected !== nextProps.selected &&
      this.setState({ selected: nextProps.selected })
        data !== nextProps.data && this.filter( nextProps )
    }

    close = () => {
        this.setState({
            search: '',
            filtered: this.props.data,
            selected: null,
        })
        this.props.onCancel()
    }

    submit = () => {
        this.props.onSubmit( this.state.selected )
        this.close()
    }

    select = ( selected ) => {
        return () => this.setState({ selected })
    }

    filter = ( rawProps ) => {
        const props = rawProps || this.props,
              { data } = props,
              { search, type, timeFrom, timeTo, noctidial } = this.state,
              int = parseInt( search ),
              filtered = data?.filter(
                  ( r ) =>
                      ( format.find.strInProp( r, 'restaurantName', search ) ||
            format.find.strInProp( r, 'factsNumber', search ) ||
            r.ident === int ||
            r.factsNumber === int ) &&
          ( !type || type === 'any' || r.facilityTypeName === type ) &&
          ( noctidial ||
            !timeFrom ||
            r.weekdaysOpeningHour === timeFrom.format( 'HH:mm:00' )) &&
          ( noctidial ||
            !timeTo ||
            r.weekdaysClosingHour === timeTo.format( 'HH:mm:00' )) &&
          ( !noctidial || r.noctidial === noctidial )
              )

        this.setState({ filtered })
    }

    setFilter = ( type ) => ( e ) => {
        const value =
      !e || typeof e === 'string' || !e.target
          ? e
          : e.target.type === 'checkbox'
              ? e.target.checked
              : e.target.value

        this.setState({ [ type ]: value }, () => this.filter())
    }

    item = ( item ) => {
        const cls = [ itemCls ]

        return (
            <Radio
                key={item.ident}
                className={cls.join( ' ' ).trim()}
                value={item.ident}
                onChange={this.select( item )}
            >
                <span className="item-title">{item.restaurant}</span>
            </Radio>
        )
    }

    render () {
        const { visible, data } = this.props,
              { filtered, selected, search, type, timeFrom, timeTo, noctidial } =
        this.state

        return (
            <Modal
                className="kfc-popup kfc-origin-modal"
                centered={true}
                open={visible}
                title="Выбор аналога"
                width={858}
                okText="Выбрать"
                onOk={this.submit}
                okButtonProps={{ disabled: !selected }}
                onCancel={this.close}
                cancelText="Отмена"
            >
                <div className="filter-container">
                    <Row gutter={24} style={{ userSelect: 'none' }}>
                        <Col span={8}>
                            <label htmlFor="search">
                                <span>Поиск</span>
                                <Search
                                    id="search"
                                    placeholder="Название или ID"
                                    disabled={!data}
                                    value={search}
                                    onChange={this.setFilter( 'search' )}
                                />
                            </label>
                        </Col>
                        <Col span={4}>
                            <label htmlFor="type">
                                <span>Тип ресторана</span>
                                <Select
                                    id="type"
                                    value={type}
                                    style={{ width: '100%' }}
                                    getPopupContainer={( trigger ) => trigger.parentNode}
                                    onChange={this.setFilter( 'type' )}
                                >
                                    <Select.Option key="any">Любой</Select.Option>
                                    <Select.Option key="FC">FC</Select.Option>
                                    <Select.Option key="FS">FS</Select.Option>
                                    <Select.Option key="IL">IL</Select.Option>
                                    <Select.Option key="FSDT">FSDT</Select.Option>
                                    <Select.Option key="ILDT">ILDT</Select.Option>
                                </Select>
                            </label>
                        </Col>
                        <Col span={8}>
                            <>
                                <span>График работы</span>
                                <Row gutter={8}>
                                    <Col span={12}>
                                        <TimePicker
                                            id="time-start"
                                            placeholder="Начало"
                                            format="HH:mm"
                                            minuteStep={15}
                                            disabled={noctidial}
                                            defaultOpenValue={dayjs().set( 'minutes', 0 )}
                                            value={
                                                noctidial
                                                    ? dayjs().set( 'hours', 0 ).set( 'minutes', 0 )
                                                    : timeFrom
                                            }
                                            style={{ width: '100%' }}
                                            onChange={this.setFilter( 'timeFrom' )}
                                            onCalendarChange={this.setFilter( 'timeFrom' )}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <TimePicker
                                            id="time-end"
                                            placeholder="Завершение"
                                            format="HH:mm"
                                            minuteStep={15}
                                            disabled={noctidial}
                                            defaultOpenValue={dayjs().set( 'minutes', 0 )}
                                            value={
                                                noctidial
                                                    ? dayjs().set( 'hours', 0 ).set( 'minutes', 0 )
                                                    : timeTo
                                            }
                                            style={{ width: '100%' }}
                                            onChange={this.setFilter( 'timeTo' )}
                                            onCalendarChange={this.setFilter( 'timeTo' )}
                                        />
                                    </Col>
                                </Row>
                            </>
                        </Col>
                        <Col span={4}>
                            <label>
                                <span>&nbsp;</span>
                                <Checkbox
                                    value={noctidial}
                                    onChange={this.setFilter( 'noctidial' )}
                                >
                  Круглосуточно
                                </Checkbox>
                            </label>
                        </Col>
                    </Row>
                </div>

                <Divider />

                {filtered ? (
                    <div className="rest-list report-table">
                        <Scrollbars
                            {...config.ui.scrolls}
                            ref={( node ) => ( this.scrolls = node )}
                        >
                            {filtered.length > 0 ? (
                                <Table
                                    rowKey="factsNumber"
                                    columns={config.tables.origins}
                                    dataSource={filtered}
                                    pagination={false}
                                    locale={{ emptyText: 'Ничего не найдено' }}
                                    onRow={( row ) => ({
                                        onClick: () => this.setState({ selected: row }),
                                        className:
                      row.factsNumber === selected?.factsNumber
                          ? 'row-selected'
                          : '',
                                    })}
                                />
                            ) : (
                                <Empty
                                    image={null}
                                    description="Ничего не найдено"
                                />
                            )}
                        </Scrollbars>
                    </div>
                ) : (
                    <Spinner />
                )}
            </Modal>
        )
    }
}

export default OriginModal
