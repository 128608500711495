/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import dayjs                from 'dayjs'

import { Modal, Button } from 'antd'

import config from 'config'

/* APPLICATION */

class EventInfo extends Component {
    static propTypes = {
        hideActions: PropTypes.bool,
        visible: PropTypes.bool,

        event: PropTypes.object,
        position: PropTypes.object,

        onClose: PropTypes.func,
        onDone: PropTypes.func,
        onEdit: PropTypes.func,
    }

    render () {
        if ( !this.props.event ) { return null }

        const { event, visible, position, hideActions } = this.props,
              { onClose, onDone, onEdit } = this.props,
              start = dayjs( event.start ),
              deadline = dayjs( event.extendedProps.deadline ),
              end = dayjs( event.end ),
              group = event.extendedProps?.groupName?.toLowerCase()

        return (
            <Modal
                title={event.title}
                className="kfc-popup no-footer no-margin kfc-calendar-popup"
                centered={false}
                open={!!visible}
                style={position}
                okText="Запланировать"
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
                onCancel={onClose}
            >
                {event.extendedProps.description && (
                    <React.Fragment>
                        <p className="text-helper">{event.extendedProps.description}</p>
                        <hr className="shaded-hr" />
                    </React.Fragment>
                )}
                <p className="text-helper">
                    {start.format( config.format.onlyDayView )},{' '}
                    {start.format( 'ddd' ).toUpperCase()} • {start.format( 'HH:mm' )} —{' '}
                    {end.format( 'HH:mm' )}
                </p>
                <p className="text-helper">
          Выполнить до {deadline.add( 1, 'day' ).format( config.format.dayView )}
                </p>
                {!hideActions && (
                    <>
                        <Button type="primary" onClick={onDone} className="offset-top">
              Выполнено
                        </Button>
                        {( group === 'coachdefault' || group === 'rgmdefault' ) && onEdit && (
                            <Button
                                className="offset-top offset-left"
                                type="secondary"
                                onClick={onEdit}
                            >
                Редактировать
                            </Button>
                        )}
                    </>
                )}
            </Modal>
        )
    }
}

export default EventInfo
