/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'

/* APPLICATION */
import { Spinner } from 'components'
import { format }  from 'tools'

import './user-avatar.scss'

class UserAvatar extends Component {
    static propTypes = {
        icon: PropTypes.object,
        image: PropTypes.string,
        name: PropTypes.string,
        userId: PropTypes.string,
        loading: PropTypes.bool,
    }

    constructor ( props ) {
        super( props )

        this.state = { error: false, }
    }

    componentDidUpdate ( prevProps ) {
        const { userId } = this.props

        userId !== prevProps.userId && userId && this.setState({ error: false })
    }

    error = () => this.setState({ error: true })

    text = () =>
        !this.props.name || this.props.loading ? (
            <Spinner />
        ) : (
            format.strings.abbr( this.props.name )
        )

    imageByUrl = () => <img alt="" src={this.props.image} onError={this.error} />

    content = () => {
        const { image, icon } = this.props,
              { error } = this.state

        if ( !error ) {
            if ( image ) { return this.imageByUrl() }
        }

        if ( icon ) { return icon }

        return null
    }

    render () {
        const { icon } = this.props,
              cls = [ 'user-avatar' ]

        icon && cls.push( 'with-icon' )

        return (
            <span className={cls.join( ' ' )}>
                {this.content()}
                {this.text()}
            </span>
        )
    }
}
export default UserAvatar
