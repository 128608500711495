/* VENDOR */
import React from 'react'
import dayjs from 'dayjs'

import { Checkbox, Input, InputNumber, Popover, Progress, Select, } from 'antd'
import Icon                                                         from '@ant-design/icons'

/* APPLICATION */
import { AddButton, ChartTime, Icons, SpinCheck, Spinner, TopStar, UserAvatar, ViewTypeDrop } from 'components'
import TimePicker                                                                             from 'components/layout/TimePicker/TimePicker'
import ruRU                                                                                   from 'antd/es/date-picker/locale/ru_RU'

import { format }     from 'tools'
import config         from 'config'
import { DatePicker } from 'antd-v5'

const { TextArea } = Input

const dow = ( text ) => dayjs().day( text ).format( 'ddd' ),
      dowFull = ( text ) => dayjs().day( text ).format( 'dddd' ),

      stop = ( e ) => e.stopPropagation(),

      getContainer = ( trigger ) => {
          let c = trigger.parentNode

          while ( c.className.indexOf( 'ant-table-content' ) < 0 && c.parentNode ) {
              c = c.parentNode
          }

          return c
      },

      disabled = ( col, record ) => {
          if ( !record ) {
              return true
          }
          if ( record.disabled === true || record.disabled === 'true' ) {
              return true
          }
          if ( record.disabled && record.disabled[ col.dataIndex ]) {
              return true
          }
          if ( col.disabled ) {
              return typeof col.disabled === 'function'
                  ? col.disabled( record )
                  : col.disabled
          }
          return false
      },

      cell = ( col, cfg ) => {
          return ( text, record, index ) => {
              if ( !record.as ) {
                  return text
              }
              if ( typeof record.as === 'function' ) {
                  return record.as( col, cfg, text, record, index )
              }
              if ( !handlers[ record.as ]) {
                  return text
              }
              return handlers[ record.as ]( col, cfg )( text, record, index )
          }
      },

      label = ( col, cfg ) => {
          return ( text, record, index ) => {
              if ( record.select && col.items && col.editable ) {
                  col.className = 'render-as-label'
                  return dropdown( col, cfg )( text, record, index )
              }

              let cls = 'render-as-label'

              record.__spoiler && ( cls += ' spoiler' )
              record.__open && ( cls += ' open' )
              record.__inner && ( cls += ' inner' )

              const res = (
                  <span className={cls} onClick={record.handler}>
                      {text}
                  </span>
              )

              if ( record.full && !col.allowFull ) {
                  return null
              }
              if ( !record.full || !col.allowFull ) {
                  return res
              }

              return {
                  children: res,
                  props: {
                      className: 'spoiler-row',
                      //colSpan: cfg.totalCols - ( record.__offset || 0 )
                  },
              }
          }
      },

      variants = ( col ) => {
          return ( text ) => {
              const found = Object.keys( col.items ).find(( key ) => key === text )

              return found ? col.items[ found ] : text
          }
      },

      tooltip = ( col ) => {
          const render = col.render ? col.render : ( val ) => val

          return ( text, record ) =>
              text ? (
                  <Popover
                      className="td-popover"
                      placement="bottom"
                      content={render( text, record )}
                      trigger="click"
                  >
                      <span>
                          <em>{render( text, record )}</em>
                      </span>
                  </Popover>
              ) : null
      },

      lock = () => {
          return ( val ) => (
              <span className="cell-lock">
                  {!val && <Icon component={Icons.Lock.def}/>}
              </span>
          )
      },

      range = ( col ) => {
          return ( text, record ) => {
              const dates = col.itemKey
                  ? [ record[ col.itemKey[ 0 ] ], record[ col.itemKey[ 1 ] ] ]
                  : text

              return (
                  <span className="cell-range">
                      {dates &&
                          dates[ 0 ] &&
                          dates[ 1 ] &&
                          dayjs( dates[ 0 ], col.rawFormat || config.format.dayAPI ).format(
                              col.dateFormat || config.format.day
                          ) +
                          ' – ' +
                          dayjs( dates[ 1 ], col.rawFormat || config.format.dayAPI ).format(
                              col.dateFormat || config.format.day
                          )}
                  </span>
              )
          }
      },

      text = ( col, cfg ) => {
          const render = col.render ? col.render : ( val ) => val

          return col.editable
              ? ( text, record, index ) => (
                      <Input
                          type="text"
                          className={col.cls}
                          defaultValue={text}
                          addonAfter={col.addOn}
                          disabled={disabled( col, record )}
                          onChange={cfg.onText( record, col.dataIndex, cfg.parent, index )}
                          onBlur={cfg.onBlur( record, col.dataIndex, cfg.parent, index )}
                          onDragStart={( e ) => {
                              e.preventDefault()
                              e.stopPropagation()
                          }}
                      />
                  )
              : ( text ) => render( text )
      },

      textArea = ( col, cfg ) => {
          const render = col.render ? col.render : ( val ) => val

          return col.editable
              ? ( text, record, index ) => (
                      <TextArea
                          type="text"
                          autoSize
                          className={col.cls}
                          defaultValue={text}
                          addonAfter={col.addOn}
                          disabled={disabled( col, record )}
                          onChange={cfg.onText( record, col.dataIndex, cfg.parent, index )}
                          onBlur={cfg.onBlur( record, col.dataIndex, cfg.parent, index )}
                          onDragStart={( e ) => {
                              e.preventDefault()
                              e.stopPropagation()
                          }}
                      />
                  )
              : ( text ) => render( text )
      },

      action = ( col, cfg ) => {
          const render = col.render ? col.render : ( text ) => text

          return ( text, record ) => {
              if ( !cfg.actions ) {
                  return render( text, record )
              }

              const key = col.dataIndex,
                    handler = ( e ) => {
                        e.preventDefault()
                        cfg.actions[ key ]( record )
                    }

              if ( !cfg.actions[ key ]) {
                  return text
              }

              return (
              // eslint-disable-next-line
                <a href="#" onClick={handler}>
                      {render( text, record )}
                  </a>
              )
          }
      },

      deltaNumbers = ( col, cfg ) => {
          const separator = ','
          const frmt = col.rawFormat && format.strings[ col.rawFormat ] ? format.strings[ col.rawFormat ] : format.strings.thousand
          const render = col.render ?
              col.render
              :
                  ( val ) => frmt(
                      format.number.parseCustomNumber(
                          val && typeof val === 'string' ? val.replace( /\.$/, '.0' ) : val,
                          col.fixedDecimals
                      )
                  )

          const formatter = ( value ) => {
              if ( col.addOn && value ) {
                  return `${frmt( format.number.parseCustomNumber( value ), col.fixedDecimals )} ${col.addOn}`
              }

              if ( value ) {
                  return frmt( value, col.fixedDecimals )
              }

              return config.ui.nullSymbol
          }
          const parser = format.generate.parser( col.addOn, separator )

          return col.editable
              ? ( text, record, index ) => {

                      const isFactLessThanPlan = record?.factTransactions < record?.planTransactions
                      const inputStyle = { color: isFactLessThanPlan ? 'red' : 'green', }

                      if ( record._no_edit ) {
                          return text
                      }

                      const props = {
                          className: col.cls,
                          disabled: disabled( col, record ),
                          hover: col.hover ? col.hover( record ) : null,

                          defaultValue: text,
                          formatter,
                          parser,
                          style: inputStyle,

                          step: col.step || 1,
                          min: col.min || 0,
                          max: col.max || Infinity,
                          decimalSeparator: separator,

                          onChange: cfg.onVal( record, col.dataIndex, cfg.parent, index, parser ),
                          onBlur: cfg.onBlur( record, col.dataIndex, cfg.parent, index, parser ),
                          onFocus: cfg.onFocus(
                              record,
                              col.dataIndex,
                              cfg.parent,
                              index,
                              parser
                          ),
                          onClick: stop,
                      }

                      if ( col.useValue ) {
                          delete props.defaultValue
                          props.value = text
                      }

                      return col.popover && col.popover( record, cfg ) ? (
                          <Popover
                              content={col.popover( record, cfg )}
                              overlayClassName="table-cell-popover"
                              style={inputStyle}
                          >
                              <InputNumber {...props} />
                          </Popover>
                      ) : (
                          <InputNumber {...props} />
                      )
                  }
              : ( text, record ) => {
                      const isFactLessThanPlan = record?.[ col.objForDelta.fact ] < record?.[ col.objForDelta.plan ]
                      return (
                          <span
                              style={{ color: record?.[ col.objForDelta.fact ] !== null ? ( isFactLessThanPlan ? 'red' : 'green' ) : 'black' }}>
                              {render( text, record )}
                          </span>
                      )
                  }

      },

      number = ( col, cfg ) => {
          const separator = ','
          const frmt = col.rawFormat && format.strings[ col.rawFormat ] ? format.strings[ col.rawFormat ] : format.strings.thousand
          const render = col.render ?
              col.render
              :
                  ( val ) => frmt(
                      format.number.parseCustomNumber(
                          val && typeof val === 'string' ? val.replace( /\.$/, '.0' ) : val,
                          col.fixedDecimals
                      )
                  )

          const formatter = ( value ) => {
              if ( col.nullable && value === '0' ) {
                  return config.ui.nullSymbol
              }

              if ( col.addOn && ( value || value === 0 )) {
                  return `${frmt( format.number.parseCustomNumber( value ), col.fixedDecimals )} ${col.addOn}`
              }

              if ( value || value === 0 ) {
                  return frmt( value, col.fixedDecimals )
              }

              return config.ui.nullSymbol
          }
          const parser = format.generate.parser( col.addOn, separator )

          return col.editable
              ? ( text, record, index ) => {

                      if ( record._no_edit ) {
                          return text
                      }

                      const props = {
                          className: col.cls,
                          disabled: disabled( col, record ),
                          hover: col.hover ? col.hover( record ) : null,

                          defaultValue: text,
                          formatter,
                          parser,

                          step: col.step || 1,
                          min: col.min || 0,
                          max: col.max || Infinity,
                          decimalSeparator: separator,

                          onChange: cfg.onVal( record, col.dataIndex, cfg.parent, index, parser ),
                          onBlur: cfg.onBlur( record, col.dataIndex, cfg.parent, index, parser ),
                          onFocus: cfg.onFocus(
                              record,
                              col.dataIndex,
                              cfg.parent,
                              index,
                              parser
                          ),
                          onClick: stop,
                      }

                      if ( col.useValue ) {
                          delete props.defaultValue
                          props.value = text
                      }

                      return col.popover && col.popover( record, cfg ) ? (
                          <Popover
                              content={col.popover( record, cfg )}
                              overlayClassName="table-cell-popover"
                          >
                              <InputNumber {...props} />
                          </Popover>
                      ) : (
                          <InputNumber {...props} />
                      )
                  }
              : ( text, record ) => render( text, record )
      },

      currency = ( col, cfg ) => {
          const curr = format.extract.currency( col.currency || cfg.unitOfMeasure ),
                rawRender = col.render

          col.render = ( value, record ) => {
              if ( record._no_edit && !value && value !== 0 ) {
                  return value
              }

              const frmt =
                    col.rawFormat && format.strings[ col.rawFormat ]
                        ? format.strings[ col.rawFormat ]
                        : format.strings.thousand,
                    val = col.hideCurrency ? frmt( value ) : frmt( value, ' ' + curr )

              return col._was_render ? (
                  rawRender( val, record )
              ) : col.popover && col.popover( record, cfg ) ? (
                  <Popover
                      content={col.popover( record, cfg )}
                      overlayClassName="table-cell-popover"
                      placement={col.placement || 'top'}
                  >
                      <span
                          className={
                              val === config.ui.nullSymbol || !val ? 'empty' : 'not-empty'
                          }
                      >
                          {val}
                      </span>
                  </Popover>
              ) : (
                  <span
                      title={val}
                      className={val === config.ui.nullSymbol || !val ? 'empty' : 'not-empty'}
                  >
                      {val}
                  </span>
              )
          }
          !col.hideCurrency && ( col.addOn = curr )
          col.cls = 'currency'

          return number( col, cfg )
      },

      deltaCurrency = ( col, cfg ) => {
          const curr = format.extract.currency( col.currency || cfg.unitOfMeasure ),
                rawRender = col.render

          col.render = ( value, record ) => {
              if ( record._no_edit && !value && value !== 0 ) {
                  return value
              }

              const frmt =
                    col.rawFormat && format.strings[ col.rawFormat ]
                        ? format.strings[ col.rawFormat ]
                        : format.strings.thousand,
                    val = col.hideCurrency ? frmt( value ) : frmt( value, ' ' + curr )


              const colorStyle = col.isRedPositive ?
                  format.number.parseCustomNumber( val ) < 0 ? '#0b7b56' : ( format.number.parseCustomNumber( val ) > 0 ? '#aa0e0b' : '#000000' )
                  // TODO BSR-4607 Откат изменений по цветам, снизу пофикшеный вариант
                  // format.number.parseCustomNumber(val) < 0 ? '#07a72a' : (format.number.parseCustomNumber(val) > 0 ? '#a70000' : '#000000')
                  :
                  format.number.parseCustomNumber( val ) < 0 ? '#aa0e0b' : ( format.number.parseCustomNumber( val ) > 0 ? '#0b7b56' : '#000000' )
                  // TODO BSR-4607 Откат изменений по цветам, снизу пофикшеный вариант
                  // format.number.parseCustomNumber(val) < 0 ? '#a70000' : (format.number.parseCustomNumber(val) > 0 ? '#07a72a' : '#000000')


              return col._was_render ? (
                  rawRender( val, record )
              ) : col.popover && col.popover( record, cfg ) ? (
                  <Popover
                      content={col.popover( record, cfg )}
                      overlayClassName="table-cell-popover"
                      placement={col.placement || 'top'}
                  >
                      <span
                          style={{ color: colorStyle }}
                          className={
                              val === config.ui.nullSymbol || !val ? 'empty' : 'not-empty'
                          }
                      >
                          {val === config.ui.nullSymbol || !val ? '—' : val}
                      </span>
                  </Popover>
              ) : (
                  <span
                      style={{ color: colorStyle }}
                      title={val}
                      className={val === config.ui.nullSymbol || !val ? 'empty' : 'not-empty'}
                  >
                      {val === config.ui.nullSymbol || !val ? '—' : val}
                  </span>
              )
          }
          !col.hideCurrency && ( col.addOn = curr )
          col.cls = 'currency'

          return number( col, cfg )
      },

      delta = ( col ) => {
          return ( text ) => {
              const formatter =
                col.rawFormat && format.strings[ col.rawFormat ]
                    ? format.strings[ col.rawFormat ]
                    : format.number.parseCustomNumber( text ) === format.number.parseCustomNumber( text )
                        ? ( val ) => val
                        : format.strings.float

              if ( !text && format.number.parseCustomNumber( text ) !== 0 ) {
                  return config.ui.nullSymbol
              }
              if ( format.number.parseCustomNumber( text ) === 0 ) {
                  return '0'
              }

              return format.number.parseCustomNumber( text ) >= 0 ? '+' + formatter( text ) : formatter( text )
          }
      },

      na = () => {
          return ( text ) => ( text ? text : config.ui.nullSymbol )
      },

      topstar = ( col, cfg ) => {
          return ( text, item ) => (
              <TopStar
                  item={item}
                  text={text}
                  position={col.starPos}
                  onClick={
                      cfg.actions &&
                    cfg.actions.toggle &&
                    cfg.actions.toggle( item, col.dataIndex )
                  }
              />
          )
      },

      boolean = ( col, cfg ) => {
          return ( val, record, index ) => (
              <SpinCheck
                  wait={col.wait}
                  checked={val}
                  disabled={disabled( col, record )}
                  onChange={cfg.onCheck( record, col.dataIndex, cfg.parent, index )}
              />
          )
      },

      dayOfWeek = ( col ) => {
          return col.full ? dowFull : dow
      },

      dayOfWeekSelect = ( col, cfg ) => {
          return ( text, record, index ) => (
              <Select
                  value={text}
                  onChange={cfg.onSelect( record, col.dataIndex, cfg.parent, index )}
                  disabled={disabled( col, record )}
                  getPopupContainer={( trigger ) => trigger.parentNode.parentNode}
              >
                  {config.defs.days.map(( d ) => (
                      <Select.Option value={d} key={d}>
                          {dow( d )}
                      </Select.Option>
                  ))}
              </Select>
          )
      },

      dropdown = ( col, cfg ) => {
          return ( text, record, index ) => (
              <span className={col.className}>
                  <ViewTypeDrop
                      items={
                          cfg.items &&
                          cfg.items[ col.items ] &&
                          format.generate.keyval( cfg.items[ col.items ])
                      }
                      current={text}
                      update={cfg.onSelect( record, col.dataIndex, cfg.parent, index )}
                      disabled={disabled( col, record )}
                      placeholder={col.placeholder || record.placeholder || record.label}
                      container={getContainer}
                  />
              </span>
          )
      },

      select = ( col, cfg ) => {
          return ( text, record, index ) => {
              if ( !col.editable || disabled( col, record )) {
                  const found =
                        cfg.items && cfg.items[ col.items ]
                            ? cfg.items[ col.items ].find(
                                ( opt ) =>
                                    text && opt.value && opt.value.toString() === text.toString()
                            )
                            : null,
                        value = found ? found.label : text,
                        rend = <span className={col.className}>{value}</span>

                  return col.popover && col.popover( record, cfg ) ? (
                      <Popover
                          content={col.popover( record, cfg )}
                          overlayClassName="table-cell-popover"
                      >
                          {rend}
                      </Popover>
                  ) : (
                      rend
                  )
              }

              const onVisible = ( state ) => {
                  if ( cfg.actions ) {
                      if ( !state && cfg.actions.blur ) {
                          cfg.actions.blur( record, col )
                      }
                      if ( state && cfg.actions.focus ) {
                          cfg.actions.focus( record, col )
                      }
                  }
              },
                    items =
                    cfg.items && cfg.items[ col.items ]
                        ? col.nullValue
                            ? [ ...cfg.items[ col.items ], { value: 'null', label: col.nullValue } ]
                            : cfg.items[ col.items ]
                        : null,
                    value = col.nullValue ? ( !text ? 'null' : text ) : text,
                    rend = (
                        <span
                            className={col.className}
                            draggable={true}
                            onDragStart={( e ) => {
                                e.preventDefault()
                                e.stopPropagation()
                            }}
                        >
                            <Select
                                bordered={true}
                                value={value}
                                showSearch={
                                    col.search &&
                                    !!( cfg.actions && ( cfg.actions.search || cfg.actions.insearch ))
                                }
                                disabled={disabled( col, record )}
                                autoFocus={col.focusOnCreate && !disabled( col, record ) && !text}
                                defaultOpen={col.focusOnCreate && !disabled( col, record ) && !text}
                                onSearch={
                                    cfg.actions &&
                                    cfg.actions.search &&
                                    cfg.actions.search( record, col )
                                }
                                onChange={cfg.onSelect( record, col.dataIndex, cfg.parent, index )}
                                onDropdownVisibleChange={onVisible}
                                popupClassName={col.className + '-dropdown'}
                                getPopupContainer={getContainer}
                                filterOption={
                                    cfg.actions && cfg.actions.insearch
                                        ? cfg.actions.insearch( record, col )
                                        : () => true
                                }
                            >
                                {items &&
                                    ( items === 'spin' ? (
                                        <Select.Option value="spin" key="spin">
                                            <Spinner size="small"/>
                                        </Select.Option>
                                    ) : (
                                        items.map(( d ) => (
                                            <Select.Option value={d.value} key={d.key || d.value}>
                                                {d.label}
                                            </Select.Option>
                                        ))
                                    ))}
                            </Select>
                        </span>
                    )

              return col.popover && col.popover( record, cfg ) ? (
                  <Popover
                      content={col.popover( record, cfg )}
                      overlayClassName="table-cell-popover"
                  >
                      {rend}
                  </Popover>
              ) : (
                  rend
              )
          }
      },

      chartTime = ( col, cfg ) => {
          const isEnd = ( m ) => m.minute() > 45,
                nowHours = ( m ) => ( isEnd( m ) ? m.add( 1, 'hour' ).hour() : m.hour()),
                nowMinutes = ( m, date ) => ( hour ) =>
                    isEnd( m ) || hour !== nowHours( m ) || !m.isSame( date, 'day' )
                        ? []
                        : format.generate.range( 0, m.minute()),
                min =
                col.checkToday && cfg.isToday
                    ? ( m ) => Math.max( col.min || 0, nowHours( m ))
                    : () => col.min || 0,
                max = col.max || 24

          return ( text, record, index ) => {
              if ( !col.editable ) {
                  return dayjs( text ).format( config.format.time )
              }

              const now = dayjs()

              return (
                  <ChartTime
                      minuteStep={15}
                      value={dayjs( text )}
                      format={config.format.time}
                      disabled={disabled( col, record )}
                      disabledHours={
                          col.dataIndex === 'shiftEnd'
                              ? []
                              : format.generate.exrange( 0, 24, min( now ), max )
                      }
                      disabledMinutes={
                          col.dataIndex === 'shiftEnd'
                              ? () => []
                              : nowMinutes( now, record.__real_date )
                      }
                      onChange={cfg.onTime( record, col.dataIndex, cfg.parent, index )}
                  />
              )
          }
      },

      time = ( col, cfg ) => {
          if ( col.editable ) {
              return ( text, record, index ) => (
                  <TimePicker
                      minuteStep={15}
                      locale={ruRU}
                      value={text ? dayjs( text, config.format.time ) : undefined}
                      format={config.format.time}
                      suffixIcon={null}
                      allowClear={false}
                      disabled={disabled( col, record )}
                      onChange={cfg.onTime( record, col.dataIndex, cfg.parent, index )}
                      getPopupContainer={( trigger ) => trigger.parentNode}
                      onCalendarChange={cfg.onTime( record, col.dataIndex, cfg.parent, index )}
                  />
              )
          }

          return col.render
      },

      date = ( col, cfg ) => {
          if ( col.editable ) {
              return ( text, record, index ) => {
                  if ( !text ) {
                      return null
                  }

                  return (
                      <DatePicker
                          value={dayjs( text, col.rawFormat || config.format.dayAPI )}
                          format={col.dateFormat || config.format.day}
                          disabled={disabled( col, record )}
                          bordered={false}
                          onChange={cfg.onDate( record, col.dataIndex, cfg.parent, index )}
                          getCalendarContainer={( trigger ) => trigger.parentNode}
                      />
                  )
              }
          }

          return ( text ) =>
              text &&
            dayjs( text, col.rawFormat || config.format.dayAPI ).format(
                col.dateFormat || config.format.day
            )
      },

      remove = ( col, cfg ) => {
          return ( text, record, index ) => {
              if ( !col.editable || record.disableRemove ) {
                  return null
              }

              return (
                  <span
                      className="remove-row-control"
                      onClick={cfg.onRemove( record, cfg.parent, index )}
                  >
                      <Icon component={Icons.Remove.active}/>
                      {!col.small && 'Удалить'}
                  </span>
              )
          }
      },

      restore = ( col, cfg ) => {
          return ( text, record, index ) => {
              if ( !col.editable || record.disableRemove ) {
                  return null
              }

              return (
                  <span
                      className="remove-row-control"
                      onClick={cfg.onRestore( record, cfg.parent, index )}
                  >
                      <Icon component={Icons.Undo.active}/>
                      {!col.small && 'Удалить'}
                  </span>
              )
          }
      },

      add = ( col, cfg ) => {
          return ( text, record, index ) => (
              <AddButton
                  icon={col.icon}
                  text={!col.small ? 'Добавить' : ''}
                  action={cfg.onAdd( record, cfg.parent, index )}
              />
          )
      },

      addNested = ( col, cfg ) => {
          return ( text, record ) => {
              if ( record.holiday ) {
                  return null
              }

              return <AddButton text="Добавить" action={cfg.onAddNested( record )}/>
          }
      },


      percentBar = ( col ) => {
          return ( text, record ) => {
              let value = format.number.parseCustomNumber( text ) || 0,
                  cvalue = col.colorIndex ? format.number.parseCustomNumber( record[ col.colorIndex ]) : value,
                  color = '#83D395'

              cvalue < 100 && ( color = '#FFE58F' )
              cvalue < 95 && ( color = '#EE986D' )
              cvalue < 90 && ( color = '#DD4A4A' )

              return (
                  <div className="percent-bar">
                      <span>{value.toString()}%</span>
                      <Progress percent={value} showInfo={false} strokeColor={color}/>
                  </div>
              )
          }
      },

      percent = ( col ) => {
          return ( text ) => {
              let value = format.number.parseCustomNumber( text ) || 0

              return (
                  <div className="percent">
                      <span>{value.toString()}%</span>
                  </div>
              )
          }
      },

      percentage = ( col ) => {
          return ( text ) => {
              return (
                  <div className="percent">
                      <span>{!text ? '—' : `${text}%`}</span>
                  </div>
              )
          }
      },


      deltaPercentage = ( col ) => {
          return ( text ) => {
              return (
                  <div className="percent">
                      <span>{text === 0 ? `${text}%` : ( text === null ? config.ui.nullSymbol : `${text}%` )}</span>
                  </div>
              )
          }
      },

      deltaPercentageColor = ( col ) => {
          return ( text ) => {
              const parsedValue = format.number.parseCustomNumber( text )
              const color = parsedValue < 0 ? '#aa0e0b' : ( parsedValue > 0 ? '#0b7b56' : '#000000' )
              // TODO BSR-4607 Откат изменений по цветам, снизу пофикшеный вариант
              // const color = parsedValue < 0 ? '#a70000' : (parsedValue > 0 ? '#07a72a' : '#000000');

              return (
                  <div className="percent">
                      <span style={{ color }}>
                          {text === 0 ? `${text}%` : ( text === null ? config.ui.nullSymbol : `${text}%` )}
                      </span>
                  </div>
              )
          }
      },

      avatar = () => {
          return ( text, record ) => (
              <UserAvatar
                  image={text}
                  name={record.fullName || record.userName || record.name || '?'}
                  userId={record.userId}
              />
          )
      },

      hitPercentage = () => {
          return ( text ) => <span className="hit-percentage">{text ? text : 0}%</span>
      },

      showPopup = ( col, cfg ) => {
          const render = col.render ? col.render : ( val ) => val

          return ( raw, record ) => {
              const arr = col.prepare ? col.prepare( raw, record ) : raw,
                    content = col.popover ? col.popover( record, cfg ) : null,
                    checkContent = (
                        <span
                            className="show-popup-wrapper-check"
                            onClick={cfg.actions.popup( col.popup, record )}
                        >
                            <Checkbox
                                checked={arr && arr.length > 0}
                                disabled={disabled( col, record )}
                                onChange={cfg.actions.popup( col.popup, record )}
                            />
                            <span className="show-popup-text">
                                {arr && arr.length > 0 ? render( arr, record ) : null}
                            </span>
                        </span>
                    )

              return col.check ? (
                  content ? (
                      <Popover content={content}>{checkContent}</Popover>
                  ) : (
                      checkContent
                  )
              ) : (
                  <span
                      className="show-popup-wrapper"
                      onClick={cfg.actions.popup( col.popup, record )}
                  >
                      <span className="show-popup-icon">{col.icon && col.icon( record )}</span>
                      <span className="show-popup-text">
                          {arr && arr.length > 0 ? render( arr, record ) : null}
                      </span>
                      <span className="show-popup-elipsis"></span>
                  </span>
              )
          }
      },

      checkDate = ( col, cfg ) => {
          return ( text, record, index ) => {
              const state = !!text,
                    apply = ( val ) =>
                        cfg.onDate( record, col.dataIndex, cfg.parent, index )( val ),
                    toggle = ( e ) => {
                        if ( e.target.checked && !text ) {
                            apply( dayjs().add( 1, 'day' ))
                        } else if ( e.target.checked && text ) {
                            apply( dayjs( text, config.format.dayAPI ))
                        } else {
                            apply( null )
                        }
                    }

              return (
                  <div className="check-date">
                      <Checkbox onChange={toggle} checked={state}/>
                      <DatePicker
                          value={
                              text ? dayjs( text, config.format.dayAPI ) : dayjs().add( 1, 'day' )
                          }
                          format={col.dateFormat || config.format.day}
                          disabled={disabled( col, record )}
                          disabledDate={col.disabledDate}
                          onChange={cfg.onDate( record, col.dataIndex, cfg.parent, index )}
                          getCalendarContainer={( trigger ) => trigger.parentNode}
                      />
                  </div>
              )
          }
      },

      restaurantWithTime = () => {
          return ( text, record ) => {
              if ( record.timeZoneName ) {
                  const timeZone = record.timeZoneName.replace( 'GMT', '' )
                  const offset = format.number.parseCustomNumber( timeZone ) - -new Date().getTimezoneOffset() / 60

                  const time = dayjs().add( offset, 'hours' ).format( 'HH:mm' )
                  return (
                      <p>
                          {text}
                          {' '}
                          <b>
                              {time} (UTC{timeZone})
                          </b>
                      </p>
                  )
              }
              return text
          }
      },

      handlers = {
          action,
          add,
          addNested,
          avatar,
          boolean,
          cell,
          chartTime,
          currency,
          deltaCurrency,
          date,
          dayOfWeek,
          dayOfWeekSelect,
          delta,
          dropdown,
          hitPercentage,
          label,
          lock,
          na,
          number,
          deltaNumbers,
          percentBar,
          percent,
          percentage,
          deltaPercentage,
          deltaPercentageColor,
          variants,
          remove,
          select,
          text,
          textArea,
          time,
          topstar,
          showPopup,
          checkDate,
          tooltip,
          restore,
          range,
          restaurantWithTime,
      },

      renderAs = ( cfg ) => {
          cfg.handlers = handlers

          return ( orig ) => {
              const col = format.copy.object( orig ),
                    handler = col.render_as,
                    raw = col.render

              // eslint-disable-next-line camelcase
              col._was_render = !!col.render

              if ( handlers[ handler ]) {
                  col.render = handlers[ handler ]( col, cfg )
              }

              if ( handler === 'handlers' ) {
                  col.render = ( text, record, index ) => raw( text, record, index, cfg, handlers )
              }

              const warmRender = col.render

              if ( col.hide ) {
                  col.render = ( text, record, index ) => {
                      if ( col.hide( record )) {
                          return null
                      }

                      return warmRender( text, record, index )
                  }
              }

              if ( col.children ) {
                  col.children = col.children.map( renderAs( cfg ))
              }

              return col
          }
      }

export default renderAs
