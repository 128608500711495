/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Link }             from 'react-router-dom'
import Icon                 from '@ant-design/icons'

/* APPLICATION */
import './view-tab.scss'

class ViewTab extends Component {
    static propTypes = {
        icon: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        short: PropTypes.string,
        route: PropTypes.string.isRequired,
        active: PropTypes.bool,
        bottom: PropTypes.bool,
        redirect: PropTypes.bool,
        openOnNewTab: PropTypes.bool,
    }

    constructor ( props ) {
        super( props )
        this.state = { hover: false, }
    }

    hover = ( res ) => {
        return () => this.setState({ hover: res })
    }

    getClass = () => {
        let { bottom, active } = this.props,
            cls = 'view-tab'

        bottom && ( cls += ' bottom' )
        active && ( cls += ' current' )

        return cls
    }

    getComponent = () =>
        this.props.active || this.state.hover
            ? this.props.icon.active
            : this.props.icon.def

    render () {
        if ( this.props.redirect ) {
            return (
                <a
                    style={this.props.disable ? { pointerEvents: 'none' } : {}}
                    href={this.props.route}
                    className={this.getClass()}
                    target={this.props.openOnNewTab ? '_blank' : ''}
                    onMouseOver={this.hover( true )}
                    onMouseLeave={this.hover( false )} rel="noreferrer">
                    <Icon component={this.getComponent()} />
                    <div className='view-tab-title'>
                        {this.props.short || this.props.title}
                        {this.props.disable && <div>{'(Не использовать)'}</div>}
                    </div>
                </a>
            )
        }

        return (
            <Link
                style={this.props.disable ? { pointerEvents: 'none' } : {}}
                to={this.props.route}
                className={this.getClass()}
                onMouseOver={this.hover( true )}
                onMouseLeave={this.hover( false )}
            >
                <Icon component={this.getComponent()} />
                <div className='view-tab-title'>
                    {this.props.short || this.props.title}
                    {this.props.disable && <div>{'(Не использовать)'}</div>}
                </div>
            </Link>
        )
    }
}

export default ViewTab
