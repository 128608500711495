/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Alert }            from 'antd'
import { Scrollbars }       from 'react-custom-scrollbars'

/* APPLICATION */
import { ReportTable, Spinner } from 'components'
import { format }               from 'tools'
import config                   from 'config'

import './staff-rating.scss'

class StaffRating extends Component {
    static propTypes = {
        data: PropTypes.object,
        add: PropTypes.object,
        draft: PropTypes.bool,
        more: PropTypes.func,
    }

    constructor ( props ) {
        super( props )
        this.state = {
            data: null,
            current: null,
        }
    }

    componentDidMount () {
        this.props.data && this.setData( this.props.data )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        this.props.data !== nextProps.data && this.setData( nextProps.data )
    }

    setData = ( data ) =>
        this.setState({
            data,
            current:
        data && data.content[ 0 ]
            ? data.content[ 0 ].employeeRatingId === 0
                ? data.content[ 1 ]
                : data.content[ 0 ]
            : null,
        })

    data = () => {
        const { data, current } = this.state,
              { add } = this.props

        if ( !data ) { return { content: [], last: true } }

        const res = format.copy.object( data )

        !res.content.find(( i ) => i.employeeRatingId === 0 ) &&
            res.content.unshift({
                employeeRatingId: 0,
                date: 'Средняя',
                rating: add ? add.average : 0,
                _alwaysOnTop: true,
            })

        if ( current ) {
            res.content.forEach(( r ) => {
                r._current = r.employeeRatingId === current.employeeRatingId
            })
        }

        return res
    }

    select = ( current ) =>
        current.employeeRatingId !== 0 && this.setState({ current })

    field = ( label, value ) => (
        <div className="ant-form-item">
            <div className="ant-form-item-label">
                <label>{label}</label>
            </div>
            <div className="ant-form-item-control">{value}</div>
        </div>
    )

    status = ( label, status ) => (
        <div
            key={label}
            className={`status-line ${status ? 'status-good' : 'status-bad'}`}
        >
            <span>{label}</span>
        </div>
    )

    info = ( data ) => (
        <div className="ant-form">
            {this.field( 'Оценил сотрудник', data.ratedBy )}
            {data.comment && this.field( 'Комментарий', data.comment )}
            {data.ratingGroups.map(( group ) => (
                <div className="status-group" key={group.groupTitle}>
                    <h3>{group.groupTitle}</h3>
                    {group.inspectionRemarks.map(( remark ) =>
                        this.status( remark.label, remark.status )
                    )}
                </div>
            ))}
            {data.rating === 100 && (
                <span className="max-rating">Полное соответствие стандартам</span>
            )}
        </div>
    )

    content = () => {
        const { data, more } = this.props,
              { current } = this.state

        return (
            <div className="section-cards">
                <aside className="section-cards-aside">
                    <Scrollbars {...config.ui.scrolls}>
                        <ReportTable
                            data={this.data()}
                            columns={config.tables.staffRating}
                            rowKey="employeeRatingId"
                            loading={!data}
                            onMore={more}
                            onCellClick={this.select}
                        />
                    </Scrollbars>
                </aside>
                {current && (
                    <section className="section-cards-content">
                        <Scrollbars {...config.ui.scrolls}>{this.info( current )}</Scrollbars>
                    </section>
                )}
            </div>
        )
    }

    render () {
        const { data, draft } = this.props

        return (
            <div className="staff-rating tabbed-container">
                <div className="report-table">
                    {draft ? (
                        <Alert
                            message="Заполните и сохраните анкету сотрудника, чтобы увидеть результаты его оценок."
                            type="warning"
                        />
                    ) : data ? (
                        this.content()
                    ) : (
                        <Spinner />
                    )}
                </div>
            </div>
        )
    }
}

export default StaffRating
