/* VENDOR */

/* APPLICATION */
import { format } from 'tools'

import Template from './Template.js'
import './stations.scss'

const rows = ( data ) => [
    {
        stationName: '',
        checksCount: 'Чеки',
        factAverageCheck: 'Средний чек',
        factTurnover: 'Товарооборот',
    },
    ...format.copy.array( data.stations ),
    {
        ...data.summary,
        stationName: 'Общий итог',
    },
],
      columns = () => {
          const cols = {
              stationName: ( r ) => r.stationName,
              checksCount: ( r ) => format.strings.thousand( r.checksCount ),
              factAverageCheck: ( r ) => format.strings.thousand( r.factAverageCheck ),
              factTurnover: ( r ) => format.strings.thousand( r.factTurnover ),
              empty: () => null,
          }

          return cols
      }

class Stations extends Template {
    constructor ( props ) {
        super( props )

        this.state = {
            data: rows( props.data ),
            width: [ '20%', '15%', '15%', '15%', '35%' ],
            columns: columns( props.data ),
            className: 'stations',
            hideFooter: true,
        }
    }

    data = ( data ) => rows( data )
    columns = ( data ) => columns( data )
}

export default Stations
