/* VENDOR */
import dayjs from 'dayjs'

/* APPLICATION */
import { format } from 'tools'
import config     from 'config'
import * as defs  from '../../defs'

import Template from './Template.js'
import './statistics-summary.scss'

const header = ( data ) => {
    const res = {
        key: 'header',
        week: 'Неделя',
    }

    config.defs.days.forEach(( d ) => {
        const date = dayjs( data.days[ d - 1 ].date )
        res[ 'day' + d ] = format.strings.dowDateR( date )
    })

    return res
},
      rows = ( data ) => {
          const res = format.copy.array( defs.statisticsSummaryRows )

          res.forEach(( row ) => {
              const f = row.format || (( d ) => d )

              config.defs.days.forEach(( d ) => {
                  row[ 'day' + d ] = f( data.days[ d - 1 ].summary[ row.key ])
              })

              row[ 'week' ] = f( data.week[ row.key ])
          })

          return res
      },
      width = () => {
          const res = [ '18%' ]

          config.defs.days.forEach(() => {
              res.push( 'auto' )
              res.push( '2%' )
          })

          res.push( 'auto' )

          return res
      },
      columns = () =>
          format.generate.rowReader([
              'label',
              'day1',
              'space1',
              'day2',
              'space2',
              'day3',
              'space3',
              'day4',
              'space4',
              'day5',
              'space5',
              'day6',
              'space6',
              'day7',
              'space7',
              'week',
          ])

class StatisticsSummary extends Template {
    constructor ( props ) {
        super( props )

        this.state = {
            data: rows( props.data ),
            width: width(),
            heads: [ header( props.data ) ],
            columns: columns( props.data ),
            className: 'statistics-summary',
        }
    }

    data = ( data ) => rows( data )
    columns = ( data ) => columns( data )
}

export default StatisticsSummary
