/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'

/* APPLICATION */
import { ReportTable, SyncScroll } from 'components'

import { format } from 'tools'
import config     from 'config'

import * as helpers from '../helpers'
import logic        from '../../logic'
import './schedule-table.scss'

class ScheduleTableView extends Component {
    resizeTimer = null

    static propTypes = {
        data: PropTypes.array,
        positions: PropTypes.array,
        workHours: PropTypes.object,
        day: PropTypes.string,

        loading: PropTypes.bool,

        form: PropTypes.object,
        to: PropTypes.object,
        max: PropTypes.array,

        onSelect: PropTypes.func,
    }

    view = React.createRef()

    constructor ( props ) {
        super( props )

        this.state = {
            data: props.data ? format.generate.noPager( props.data ) : props.data,
            columns: [],
            width: helpers.width( props.from, props.to ),
        }

        this.set = format.generate.set( this )
    }

    componentDidMount () {
        this.set.columns( this.columns())
    }

    componentDidUpdate ( prevProps ) {
        const { data, from, to, max } = this.props,
              stateUpdate = {}

        if ( data !== prevProps.data || max !== prevProps.max ) {
            stateUpdate.data = format.generate.noPager( this.props.data )
            stateUpdate.columns = this.columns()
        }

        ( from !== prevProps.from || to !== prevProps.to ) &&
      ( stateUpdate.width = helpers.width( from, to ))

        if ( Object.keys( stateUpdate ).length > 0 ) {
            //console.time( 'save table', stateUpdate )
            this.set.state( stateUpdate, this.afterUpdate )
        }

    //;( window._kfc_resizeTimer ) && ( clearTimeout( window._kfc_resizeTimer ) )
    //window._kfc_resizeTimer = setTimeout( () => window.dispatchEvent( new Event( 'resize' ) ), 0 )
    }

    afterUpdate = () => {
    //console.timeEnd( 'save table' )
        const { workHours, day, from, to } = this.props,
              view = this.view.current,
              hours = [].slice.call( view.querySelectorAll( '.ant-table-scroll th' )),
              cday = logic.datetime.find( workHours?.days || [], day ),
              start = cday.timeStart,
              end = cday.timeEnd

        let started = false

        hours.forEach(( h ) => {
            h.innerText === start && ( started = true )
            h.style.fontWeight = started ? 'bold' : 'normal'
            h.innerText === end && ( started = false )
        })

        this.set.width( helpers.width( from, to ))
    }

    change = ( rec, key, val ) => {
        this.props.onSelect( val )
    }

    columns = () => {
        const { from, to, max } = this.props,
              def = format.copy.array( config.tables.schedule.top )

        return [ ...def, ...helpers.hourscols( from, to, true, max ) ]
    }

    render () {
        const { loading, positions } = this.props,
              { data, columns, width } = this.state

        return (
            <div
                className="staff-schedule-table report-table col-hoverable report-center"
                ref={this.view}
            >
                <ReportTable
                    data={data}
                    columns={columns}
                    select={{ positions: positions }}
                    scroll={{ x: width }}
                    rowKey="key"
                    loading={!this.props.data || loading}
                    onChange={this.change}
                />

                <div className="tabbed-footer">
                    <SyncScroll
                        connect="schedule"
                        apply=".staff-schedule-table"
                        height={20}
                    />
                </div>
            </div>
        )
    }
}

export default ScheduleTableView
