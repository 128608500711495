/* IMPORT */

import { turnoverActions, requestActions, settingsActions } from 'services'

/* EXPORT */

export const mapStateToProps = ( state ) => ({
    workHours: state.settings.workHours,
    average: state.turnover.average,
    request: state.request,
})

export const allActions = {
    ...requestActions,
    ...turnoverActions,
    ...settingsActions,
}
