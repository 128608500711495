import dayjs from 'dayjs'

import { format } from 'tools'
import config     from 'config'

export const get = ( res, date, shift, offset ) => {
    if ( dayjs( shift.shiftStart ).isSame( dayjs( shift.shiftEnd ), 'day' )) { return {} }

    const day = res.days.find(( d ) => dayjs( d.workStart ).isSame( date, 'day' )),
          another = day
              ? res.days.find(( d ) => d.dayOfWeek === day.dayOfWeek + offset )
              : null

    if ( !another ) { return {} }

    const position = another.positions.find(
        ( p ) => p.positionGuid === shift.positionGuid
    )

    let found = position
        ? position.shifts.find(( s ) =>
            s._temp_id
                ? s._temp_id === shift._temp_id
                : s.workScheduleId === shift.workScheduleId
        )
        : null

    if ( !found ) {
        if ( position ) {
            found = format.copy.object( shift )
            found._double = true

            position.shifts.push( found )
        }
    }

    return { position, found, day: another }
}

export const changeOriginal = ( double, date, res ) => {
    const { position, found } = get( res, date, double, -1 )

    if ( !found ) { return }

    if ( found ) {
        found.shiftStart = double.shiftStart.format( config.format.dateFull )
        found.shiftEnd = double.shiftEnd.format( config.format.dateFull )
    } else {
        if ( !position ) { return }

        const twin = format.copy.object( double )

        twin.shiftStart = double.shiftStart.format( config.format.dateFull )
        twin.shiftEnd = double.shiftEnd.format( config.format.dateFull )

        double._double = true
        twin._double = false
        position.shifts.push( twin )
    }
}

export const change = ( original, date, res ) => {
    const { position, found } = get( res, date, original, 1 )

    if ( found ) {
        found.shiftStart = original.shiftStart.format( config.format.dateFull )
        found.shiftEnd = original.shiftEnd.format( config.format.dateFull )
    } else {
        if ( !position ) { return }

        const twin = format.copy.object( original )

        twin.shiftStart = original.shiftStart.format( config.format.dateFull )
        twin.shiftEnd = original.shiftEnd.format( config.format.dateFull )

        original._double = false
        twin._double = true
        position.shifts.push( twin )
    }
}

export const remove = ( current, date, res ) => {
    const offset = dayjs( current.shiftStart ).isSame( date, 'day' ) ? 1 : -1,
          { position, found } = get( res, date, current, offset ),
          index = found ? position.shifts.indexOf( found ) : -1

    index > -1 && position.shifts.splice( index, 1 )
}
