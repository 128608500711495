import dayjs      from 'dayjs'
import { format } from 'tools'

export const deadline = ( event ) =>
    dayjs( event.extendedProps.deadline ).endOf( 'day' )

export const rightEvent = ( info ) => {
    if ( !info.event.extendedProps.events ) {
        return info.event
    }

    const _events = info.event.extendedProps.events,
          res = format.copy.include(
              info.event,
              [
                  'allDay',
                  'allow',
                  'backgroundColor',
                  'borderColor',
                  'classNames',
                  'constraint',
                  'durationEditable',
                  'end',
                  'groupId',
                  'id',
                  'overlap',
                  'rendering',
                  'source',
                  'start',
                  'startEditable',
                  'textColor',
                  'title',
                  'url',
              ],
              {
                  extendedProps: { ...info.event.extendedProps },
                  remove: () => {
                      info.event._calendar.dispatch({
                          type: 'REMOVE_EVENT_DEF',
                          defId: info.event._def.defId,
                      })
                  },
              }
          ),
          date = res.start,
          offset = timeOffset( info.event.extendedProps ),
          event = _events.find(
              ( e ) =>
                  dayjs( e.deadline ).endOf( 'day' ).isAfter( dayjs( date )) &&
        ( dayjs( e.deadline ).startOf( offset ).isBefore( dayjs( date )) ||
          dayjs( e.deadline ).startOf( offset ).isSame( dayjs( date )))
          ),
          taskId = event ? event.taskId : res.extendedProps.taskId,
          deadline = event ? event.deadline : res.extendedProps.deadline

    res.extendedProps.taskId = taskId
    res.extendedProps.deadline = deadline
    res.extendedProps._events = _events

    return res
}

export const timeOffset = ( event ) => {
    if ( event.groupName.indexOf( 'Daily' ) > -1 ) {
        return 'day'
    }

    if ( event.groupName.indexOf( 'Weekly' ) > -1 ) {
        return 'week'
    }

    if ( event.groupName.indexOf( 'Monthly' ) > -1 || event.groupName.indexOf( 'SIV' ) > -1 ) {
        return 'month'
    }

    if ( event.groupName.indexOf( 'Quarterly' ) > -1 ) {
        return 'quarter'
    }

    if ( event.groupName.indexOf( 'Year' ) > -1 ) {
        return 'year'
    }
}

export const restricts = ( event, view, date, deadline ) => ({
    cantDrop:
    view === 'month'
        ? date.isBefore( dayjs().startOf( 'day' ))
        : date.isBefore( dayjs()),
    anotherWeek:
    event.extendedProps.groupName.indexOf( 'Weekly' ) > -1
        ? dayjs( date ).endOf( 'week' ).isBefore( dayjs( deadline ).startOf( 'week' ))
        : false,
    anotherDay:
    event.extendedProps.groupName.indexOf( 'Daily' ) > -1
        ? !dayjs( date ).isSame( dayjs( deadline ), 'day' )
        : false,
})

export const classNames = ( event ) => {
    const res = []

    if ( dayjs( event.startedDate ).isBefore( dayjs().startOf( 'day' ))) {
        res.push( 'fc-e-past' )

        if ( !event.done ) {
            res.push( 'fc-e-missed' )
        }
    }

    if ( event.done ) {
        res.push( 'fc-e-done' )
    }

    return res
}

export const constraints = ( deadline ) => ( info ) => {
    if ( dayjs( info.createDate ).isBefore( dayjs())) { return false }
    if ( dayjs( info.createDate ).isAfter( dayjs( deadline ).add( 1, 'day' ).startOf( 'day' ))) { return false }
    return true
}

export const eventSumm = ( data ) => {
    let count = 0,
        duration = 0,
        missed = 0

    data.forEach(( event ) => {
        count++
        duration += event.duration
        !event.done && missed++
    })

    return { count, duration, missed }
}
