/* VENDOR */
import React, { Component }      from 'react'
import PropTypes                 from 'prop-types'
import { Card, Modal, Checkbox } from 'antd'
import dayjs                     from 'dayjs'

/* APPLICATION */
import {
    CalendarEventsList,
    AddEvent,
    EventInfo,
    EventModal,
} from 'components'
import { format } from 'tools'
import config     from 'config'

import FullCalendar from './FullCalendar'
import './calendar-screen.scss'
import * as helpers from './helpers'

class CalendarScreen extends Component {
    static propTypes = {
        view: PropTypes.string,
        date: PropTypes.object,
        selected: PropTypes.object,
        loading: PropTypes.bool,
        eventsLoading: PropTypes.bool,

        inbox: PropTypes.array,
        events: PropTypes.array,
        summary: PropTypes.object,
        factsNumbers: PropTypes.array,

        onAdd: PropTypes.func,
        onOpen: PropTypes.func,
        onSelect: PropTypes.func,
        setNeedReload: PropTypes.func,
        updateCalendar: PropTypes.func,
        extractRepeat: PropTypes.func,
        onDone: PropTypes.func,
        onRemove: PropTypes.func,
        onFilter: PropTypes.func,
    }

    /* REFS */
    calendar = React.createRef()
    getApi = () => this.calendar.current.calendarRef.current.getApi()

    /* REACT */

    constructor ( props ) {
        super( props )

        this.state = {
            working: false,
            drag: false,
            data: null,
            events: null,
            hours: null,

            popup: false,
            toAdd: null,
            addDate: null,

            temp: [],
        }

        this.set = format.generate.set( this )
    }

    componentDidMount () {
        const { events } = this.props

        !!events && this.parse( events )

        document.addEventListener( 'mouseup', this.dragEnd )
    }

    componentDidUpdate ( prevProps ) {
        const { events, view, date } = this.props,
              { temp } = this.state

        if ( date !== prevProps.date || events !== prevProps.events && !!events ) {
            this.set.events( null )
            this.parse( events )
        }

        if ( view !== prevProps.view ) {
            this.parse( prevProps.events )
        }

        if ( events !== prevProps.events ) {
            temp?.forEach(( info ) => info.event.remove())
            this.set.temp([])
        }
    }

    componentWillUnmount () {
        document.removeEventListener( 'mouseup', this.dragEnd )
    }

    /* DATA */

    parse = ( events ) => {
        setTimeout(() => this.delayParse( events ), 0 )
    }

    delayParse = ( events ) => {
        if ( !events ) {
            return
        }

        this.props.view === 'week'
            ? this.parseWeek( events )
            : this.parseMonth( events )
    }

    parseWeek = ( events ) => {
        const res = []

        events?.forEach(( event ) => res.push( this.makeEvent( event )))

        this.set.state({
            data: res,
            events: res,
        })
    }

    makeEvent = ( event ) => {
        const start = dayjs( event.startedDate ),
              end = dayjs( event.completeDate )

        event.start = start.toDate()
        event.end = end.subtract( 1, 'minute' ).toDate()
        event.classNames = helpers.get.classNames( event )
        event.constraint = { startTime: '00:00', endTime: '23:59' }
        event.allow = helpers.get.constraints( event.deadline )
        end.diff( start, 'minutes' ) < config.calendar.minimalDuration - 1 &&
      ( event.end = dayjs( start )
          .add( config.calendar.minimalDuration - 1, 'minutes' )
          .toDate())

        return { ...event }
    }

    parseMonth = ( events ) => {
        const tmp = {}

        events?.forEach(( event ) => {
            const date = dayjs( event.startedDate ).format( 'YYYY-MM-DD' )

            !Object.keys( tmp ).includes( date ) && ( tmp[ date ] = [])

            tmp[ date ].push( event )
        })

        helpers.prepare.monthDay( tmp )

        const res = Object.values( tmp )
            .map(( day ) => day.events )
            .reduce(( all, day ) => [ ...all, ...day ], [])

        this.set.state({
            events: res,
            data: res,
        })
    }

    /* EVENTS */

    assign = ( data ) => {
        let { addDate, toAdd } = this.state

        addDate = addDate.set( 'hours', data.timeStart.hour()).set( 'minutes', data.timeStart.minute())


        this.onMove({
            taskId: toAdd.extendedProps.taskId,
            description: toAdd.extendedProps.description,
            duration: toAdd.extendedProps._originalDuration,
            title: toAdd.title,
            date: addDate.format( config.format.dateFull + 'Z' ),
        })

        this.close()
    }

    done = () => {
        const taskId = this.state.event.extendedProps.taskId

        this.props.onDone( taskId )
        this.close()
    }

    edit = () =>
        this.set.state({
            popup: false,
            info: false,
            edit: true,
        })

    close = () => {
        this.set.state({
            popup: false,
            info: false,
            edit: false,
            single: true,
        })

        setTimeout(() => {
            this.set.state({
                event: null,
                remove: null,
            })
        }, 300 )
    }

    save = ( event ) => {
        this.close()
        this.onSave( event )
    }

    onSave = ( event ) => {
        const repeat = this.props.extractRepeat( event ),
              res = {
                  taskId: event.taskId,
                  title: event.title,
                  description: event.description,
                  date: dayjs( event.date )
                      .set( 'hours', event.timeStart.hour())
                      .set( 'minutes', event.timeStart.minute())
                      .format( config.format.dateFull + 'Z' ),
                  duration: event.timeEnd.diff( event.timeStart, 'minutes' ),
                  done: false,
                  repeat,
                  single: !event.single,
              }

        this.onDate( res )
        event.single && this.props.setNeedReload( true )
    }

    onDate = ( event ) => {
        this.props.updateCalendar( event )
    }
    onMove = ( event ) => this.onDate({ single: true, ...event })

    askRemove = ( event ) =>
        this.setState({
            edit: false,
            remove: event,
        })

    remove = () => {
        const { remove, single } = this.state

        this.props.onRemove({
            id: remove.taskId,
            single,
        })

        this.close()
    }

    toggleSingle = ( e ) =>
        this.setState({ single: !e.target.checked, })

    dragStart = ( info ) => {
        const { events, view, date } = this.props,
              { data } = this.state,
              event = info.event ? info.event.extendedProps : info,
              excluded = data.filter(( e ) => e.taskId !== event.taskId ),
              disabled = helpers.disabled.all( event, events, view, date )

        this.set.state({
            events: [ ...excluded, ...disabled ],
            drag: true,
            dragging: info,
        })
    }

    dragEnd = () => {
        const { edit, info, remove, data } = this.state

        if ( edit || info || remove ) { return }

        this.set.state({
            events: data,
            drag: false,
            dragging: null,
        })
    }

    drop = ( info ) => {
        const { view } = this.props,
              event = helpers.get.rightEvent( info ),
              date = dayjs( event.start ),
              deadline = helpers.get.deadline( event ),
              { cantDrop, anotherWeek, anotherDay } = helpers.get.restricts(
                  event,
                  view,
                  date,
                  deadline
              )

        if ( cantDrop || date.isAfter( deadline ) || anotherWeek || anotherDay ) {
            this.dragEnd()
            info.event.remove()
            return false
        }

        if ( view === 'week' ) {
            this.assignEvent( event, date )

            const { temp } = this.state,
                  remove = [ ...temp, info ]

            this.set.temp( remove )

            return false
        }

        this.set.state({
            popup: true,
            toAdd: event,
            addDate: date.set( 'hours', 11 ),
        })
    }

    assignEvent = ( event, date ) => {
        this.onMove({
            taskId: event.extendedProps.taskId,
            title: event.title,
            description: event.extendedProps.description,
            date: date.format( config.format.dateFull + 'Z' ),
            duration: event.extendedProps._originalDuration
                ? event.extendedProps._originalDuration
                : event.extendedProps.duration,
        })

        // const events = format.copy.array( this.state.events ),
        //       found = events.find(( e ) => e.taskId === event.extendedProps.taskId ),
        //       index = events.indexOf( found )

        // events.splice( index, 1 )

        // this.set.events( events )
    }

    select = ( info ) => {
        const half = config.ui.popupWidth / 2

        this.set.state({
            info: true,
            position: {
                left: Math.min(
                    info.jsEvent.pageX - half,
                    window.innerWidth - config.ui.popupWidth - 24
                ),
                top: Math.min( info.jsEvent.pageY - 100, window.innerHeight - 250 ),
            },
            editPosition: {
                left: Math.min(
                    info.jsEvent.pageX - half,
                    window.innerWidth - config.ui.popupWidth - 24
                ),
                top: Math.min( info.jsEvent.pageY - 100, window.innerHeight - 512 ),
            },
            event: info.event,
        })
    }

    render () {
        const { view, date, inbox, summary, selected, loading, restaurants, factsNumbers, onFilter, eventsLoading } = this.props,
              { drag, events, hours } = this.state,
              { popup, toAdd, addDate, remove, single } = this.state,
              { event, edit, info, position, editPosition } = this.state,
              cls = [ 'section-cards calendar-screen' ]

        drag && cls.push( 'dragging' )

        return (
            <div className={cls.join( ' ' )}>
                <aside className="section-cards-aside">
                    <Card bordered={false}>
                        <CalendarEventsList
                            disabled={!this.props.events && !inbox}
                            date={date}
                            view={view}
                            selected={selected ? dayjs( selected.date ) : null}
                            helpers={helpers}
                            summary={summary}
                            inbox={selected ? this.props.events : inbox}
                            onDrag={this.dragStart}
                            onOpen={this.props.onOpen}
                            onReturn={this.props.onSelect}
                            onFilter={onFilter}
                            restaurants={restaurants}
                            factsNumbers={factsNumbers}
                            loading={eventsLoading}
                        />
                    </Card>
                </aside>

                <FullCalendar
                    loading={loading}
                    ref={this.calendar}
                    view={view}
                    date={date}
                    events={events}
                    selected={selected ? hours : null}
                    onDrag={this.dragStart}
                    onDrop={this.drop}
                    onDateSelect={this.props.onSelect}
                    onEventSelect={this.select}
                />

                <AddEvent
                    visible={popup}
                    date={addDate}
                    event={toAdd}
                    onCancel={this.close}
                    onSubmit={this.assign}
                />

                <EventModal
                    title="Редактирование задачи"
                    okBtnText="Сохранить"
                    view={view}
                    deleteBtn={true}
                    visible={edit}
                    position={editPosition}
                    event={event ? helpers.prepare.eventInfo( event ) : event}
                    onClose={this.close}
                    onSubmit={this.save}
                    onRemove={this.askRemove}
                />

                <EventInfo
                    visible={info}
                    event={event}
                    position={position}
                    onClose={this.close}
                    onDone={this.done}
                    onEdit={this.edit}
                />

                <Modal
                    title="Удаление задачи"
                    className="kfc-popup"
                    centered={true}
                    open={!!remove}
                    onOk={this.remove}
                    okButtonProps={{ className: 'danger' }}
                    okText="Удалить"
                    onCancel={this.close}
                    cancelText="Отмена"
                >
                    <p>
            Восстановить удалённую задачу не получится, только создать заново
                    </p>
                    {remove && !!remove.repeat && (
                        <p>
                            <Checkbox checked={!single} onChange={this.toggleSingle}>
                Удалить также все последующие задачи
                            </Checkbox>
                        </p>
                    )}
                </Modal>
            </div>
        )
    }
}

export default CalendarScreen
