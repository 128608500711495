import { dropTypesColumns } from '../../GuestVoteTable/const'
import { DataGroupType }    from 'services/guestVote/statistic/type'

export const getStatisticsTableColumns = ( currentTypesFilter: DataGroupType ) => [
    {
        title: `${dropTypesColumns[ currentTypesFilter ]}`,
        dataIndex: 'name',
        key: 'name',
        sorter: ( a: { name: string; }, b: { name: any; }) => a.name.localeCompare( b.name ),
        width: 300,
    },
    {
        title: 'HI',
        dataIndex: 'factHappinessIndex',
        key: 'factHappinessIndex',
        sorter: ( a: { factHappinessIndex: number; }, b: { factHappinessIndex: number; }) => a.factHappinessIndex - b.factHappinessIndex,
    },
    {
        title: 'HI прошлый год',
        dataIndex: 'factHappinessIndexLastYear',
        key: 'factHappinessIndexLastYear',
        sorter: ( a: { factHappinessIndexLastYear: number; }, b: { factHappinessIndexLastYear: number; }) => a.factHappinessIndexLastYear - b.factHappinessIndexLastYear,
    },
    {
        title: 'Разница HI',
        dataIndex: 'deltaFactHappinessIndexLastYear',
        key: 'deltaFactHappinessIndexLastYear',
        className: ' numeric',
        'render_as': 'deltaCurrency',
        sorter: ( a: { deltaFactHappinessIndexLastYear: number; }, b: { deltaFactHappinessIndexLastYear: number; }) => a.deltaFactHappinessIndexLastYear - b.deltaFactHappinessIndexLastYear,
    },
    {
        title: 'OSAT',
        dataIndex: 'factOsat',
        key: 'factOsat',
        'render_as': 'percent',
        sorter: ( a: { factOsat: number; }, b: { factOsat: number; }) => a.factOsat - b.factOsat,
    },
    {
        title: 'OSAT прошлый год',
        dataIndex: 'factOsatLastYear',
        key: 'factOsatLastYear',
        'render_as': 'percent',
        sorter: ( a: { factOsatLastYear: number; }, b: { factOsatLastYear: number; }) => a.factOsatLastYear - b.factOsatLastYear,
    },
    {
        title: 'Разница OSAT',
        dataIndex: 'deltaFactOsatLastYear',
        key: 'deltaFactOsatLastYear',
        'render_as': 'percent',
        sorter: ( a: { deltaFactOsatLastYear: number; }, b: { deltaFactOsatLastYear: number; }) => a.deltaFactOsatLastYear - b.deltaFactOsatLastYear,
    },
    {
        title: 'Рейтинг',
        dataIndex: 'factFeedbacksRating',
        key: 'factFeedbacksRating',
        sorter: ( a: { factFeedbacksRating: number; }, b: { factFeedbacksRating: number; }) => a.factFeedbacksRating - b.factFeedbacksRating,
    },
    {
        title: 'Рейтинг прошлый год',
        dataIndex: 'factFeedbacksRatingLastYear',
        key: 'factFeedbacksRatingLastYear',
        sorter: ( a: { factFeedbacksRatingLastYear: number; }, b: { factFeedbacksRatingLastYear: number; }) => a.factFeedbacksRatingLastYear - b.factFeedbacksRatingLastYear,
    },
    {
        title: 'Обращения',
        dataIndex: 'factFeedbacks',
        key: 'factFeedbacks',
        sorter: ( a: { factFeedbacks: number; }, b: { factFeedbacks: number; }) => a.factFeedbacks - b.factFeedbacks,
    },
    {
        title: 'Транзакции',
        dataIndex: 'factTransactions',
        key: 'factTransactions',
        sorter: ( a: { factTransactions: number; }, b: { factTransactions: number; }) => a.factTransactions - b.factTransactions,
    },
    {
        title: 'Обращения на 10К транзакций',
        dataIndex: 'factFeedbacksPer10KTransactions',
        key: 'factFeedbacksPer10KTransactions',
        sorter: ( a: { factFeedbacksPer10KTransactions: number; }, b: { factFeedbacksPer10KTransactions: number; }) => a.factFeedbacksPer10KTransactions - b.factFeedbacksPer10KTransactions,
    },
]
