/* IMPORT */

import {
    staffActions,
    requestActions,
    userActions,
    settingsActions,
    restaurantActions,
} from 'services'

/* EXPORT */

export const mapStateToProps = ( state ) => ({
    user: state.user,
    request: state.request,
    staff: state.staff,
    fixed: state.staff.fixed,
    absence: state.staff.absence,
    roles: state.roles,
    companies: state.staff.companies,
    franchisee: state.restaurants.franchisee,
    transfer: state.staff.transfer,
    rates: state.staff.rates,
    ppr: state.staff.ppr
})

export const allActions = {
    ...settingsActions,
    ...staffActions,
    ...requestActions,
    ...userActions,
    ...restaurantActions,
}
