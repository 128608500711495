import React from 'react'

import { format } from 'tools'
import config     from 'config'
import column     from 'config/tables/helpers'

export const width = ( from, to, compact, inlineHours ) => {
    const hours = 24,
          cfg =
      compact && !inlineHours
          ? config.tables.schedule.mobile
          : config.tables.schedule.top,
          width = inlineHours
              ? 24
              : compact
                  ? config.ui.mobileHourWidth
                  : config.ui.hourWidth

    let res = 0

    res = cfg.reduce(( w, c ) => w + c.width, res )
    res += hours * width

    return res
}

export const hourscols = ( from, to, hoverable, max, inlineHours ) => {
    const res = []

    hours( hour( res, hoverable, max, inlineHours ), from, to )

    return res
}

export const hours = ( handler, from, to ) => {
    const _to = to.hour()
    let i = from.hour()

    while ( i <= _to ) {
        handler( i )
        i++
    }
}

const hour = ( res, hover, max, inlineHours ) => {
    return ( hour ) => {
        const cls = [ 'col-sep col-hour nopad' ],
              isMax = max ? max.indexOf( hour ) > -1 : false

        hover && cls.push( 'col-hover' )
        isMax && cls.push( 'col-max' )

        cls.push( 'numeric align-center' )

        res.push(
            column( inlineHours ? '' : format.strings.mktime( hour ), 'hour-' + hour, {
                as: 'cell',
                width: inlineHours ? 24 : config.ui.hourWidth,
                hideCurrency: true,
                placement: 'bottom',
                className: cls.join( ' ' ),
                popover: () => {
                    if ( isMax ) {
                        return (
                            <span>
                Максимальный
                                <br />
                товарооборот смены
                            </span>
                        )
                    }
                    return false
                },
            })
        )
    }
}
