import { guestVoteActions } from 'services'
import { IState }           from 'store'

export const allActions = { ...guestVoteActions }

export const mapStateToProps = ( state: IState ) => ({
    happinessIndexesSearch: state.guestVote.happinessIndexesSearch,
    happinessIndexesSummary: state.guestVote.happinessIndexesSummary,
    currentFilters: state.guestVote.currentFilters,
    categoriesFiltersOptions: state.guestVote.categoriesFiltersOptions,
    categoriesFiltersOptionsLoading: state.guestVote.categoriesFiltersOptions_loading,
    lastSelectedFilter: state.guestVote.lastSelectedFilter,
})

export type GuestVoteFiltersStoreProps = ReturnType<typeof mapStateToProps> & typeof allActions
