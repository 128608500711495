/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Alert }            from 'antd'

/* APPLICATION */
import { CardChart } from 'components'

import './turnover-chart.scss'

class TurnoverChart extends Component {
    static propTypes = {
        turnover: PropTypes.object,
        currency: PropTypes.string,
        low: PropTypes.bool,
        offset: PropTypes.string,
    }

    render () {
        return (
            <div className="turnover-chart">
                <CardChart
                    key="chart"
                    plan={this.props.turnover?.plan || this.props.turnover?.planAndFact?.plan}
                    fact={this.props.turnover?.fact || this.props.turnover?.planAndFact?.fact}
                    unit={this.props.currency}
                    offset={this.props.offset}
                />

                {this.props.low && (
                    <Alert
                        key="alert"
                        message="Цель на смену может быть не выполнена!"
                        type="error"
                    />
                )}
            </div>
        )
    }
}

export default TurnoverChart
