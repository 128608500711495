export const dropTypesMap = {
    RESTAURANTS: 'По ресторанам',
    FRANCHISEE_GROUPS: 'По партнерам',
    FACILITY_TYPES: 'По типам ресторанов',
    CITIES: 'По городам',
    REGION_COACHES: 'По region coaches',
    MARKET_COACHES: 'По market coaches',
    AREA_COACHES: 'По area coaches',
}

export const groupName = {
    RESTAURANTS: 'Ресторан',
    FRANCHISEE_GROUPS: 'Партнер',
    FACILITY_TYPES: 'Тип ресторана',
    CITIES: 'Город',
    REGION_COACHES: 'Region coach',
    MARKET_COACHES: 'Market coach',
    AREA_COACHES: 'Area coach',
}

export const allRowName = {
    RESTAURANTS: 'Все рестораны',
    FRANCHISEE_GROUPS: 'Все партнеры',
    FACILITY_TYPES: 'Все типы ресторанов',
    CITIES: 'Все города',
    REGION_COACHES: 'Все region coaches',
    MARKET_COACHES: 'Все market coaches',
    AREA_COACHES: 'Все area coaches',
}

export const averageName = {
    RESTAURANTS: 'В среднем по всем ресторанам',
    FRANCHISEE_GROUPS: 'В среднем по всем партнерам',
    FACILITY_TYPES: 'В среднем по всем типам ресторанов',
    CITIES: 'В среднем по всем городам',
    REGION_COACHES: 'В среднем по всем region coaches',
    MARKET_COACHES: 'В среднем по всем market coaches',
    AREA_COACHES: 'В среднем по всем area coaches',
}

export const dropTypesKeys = {
    RESTAURANTS: 'restaurantName',
    FRANCHISEE_GROUPS: 'franchiseeGroupName',
    FACILITY_TYPES: 'facilityTypeName',
    CITIES: 'city',
    REGION_COACHES: 'regionCoachName',
    MARKET_COACHES: 'marketCoachName',
    AREA_COACHES: 'areaCoachName',
}
