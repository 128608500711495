/* VENDOR */
import React, { Component }              from 'react'
import PropTypes                         from 'prop-types'
import { Table, Alert }                  from 'antd'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import HTML5Backend                      from 'react-dnd-html5-backend'

/* APPLICATION */
import { FilterInput, Pager } from 'components'
import { format }             from 'tools'
import config                 from 'config'

import renderAs from './renderAs'
import './report-table.scss'

const DragableBodyRow = ({
    index,
    moveRow,
    noDrag,
    className,
    // eslint-disable-next-line no-unused-vars
    style,
    ...restProps
}) => {
    const type = 'DragbleBodyRow',
          ref = React.useRef(),
          [ { isOver, dropClassName }, drop ] = useDrop({
              accept: type,
              collect: ( monitor ) => {
                  const { index: dragIndex } = monitor.getItem() || {}

                  if ( dragIndex === index ) {
                      return {}
                  }

                  return {
                      isOver: monitor.isOver(),
                      dropClassName:
                        dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
                  }
              },
              drop: ( item ) => moveRow( item.index, index ),
          }),
          [ { isDragging }, drag ] = useDrag({
              item: { type, index },
              collect: ( monitor ) => ({ isDragging: monitor.isDragging(), }),
          })

    !noDrag && drop( drag( ref ))

    return (
        <tr
            ref={ref}
            className={`${className}${
                isOver ? dropClassName : isDragging ? ' dragging' : ''
            }`}
            {...restProps}
        />
    )
}

/* TODO: REFACTOR */

class ReportTable extends Component {
    static propTypes = {
        data: PropTypes.object,
        columns: PropTypes.array,
        nested: PropTypes.object,
        select: PropTypes.object,
        scroll: PropTypes.object,

        rowKey: PropTypes.oneOfType([ PropTypes.string, PropTypes.func ]),
        searchKey: PropTypes.string,
        unit: PropTypes.string,
        placeholder: PropTypes.string,
        emptyText: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),

        filter: PropTypes.bool,
        hideHeader: PropTypes.bool,
        loading: PropTypes.bool,
        open: PropTypes.bool,
        isToday: PropTypes.bool,
        styleInactive: PropTypes.bool,

        prepare: PropTypes.func,
        onMore: PropTypes.func,
        onChange: PropTypes.func,
        onAdd: PropTypes.func,
        onRemove: PropTypes.func,
        onRestore: PropTypes.func,
        onCellFocus: PropTypes.func,
        onCellBlur: PropTypes.func,
        onCellClick: PropTypes.func,
        onSearch: PropTypes.func,
        dragSort: PropTypes.func,

        cellActions: PropTypes.object,
    }

    constructor ( props ) {
        super( props )
        this.state = {
            data: props.data ? props.data.content : [],
            open: [],
            needReFilter: false,
            isLoading: true,
        }
    }

    componentDidMount () {
        this.update( this.props.data )
        window.addEventListener( 'resize', this.force )
    }

    componentWillUnmount () {
        window.removeEventListener( 'resize', this.force )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        if ( nextProps.data !== this.props.data ) {
            this.setState({ isLoading: true })
            this.update( nextProps.data )
        }
    }

    force = () => this.forceUpdate()
    content = ( data ) => ( data ? data.content : null )

    expanded = () => ( this.props.nested ? this.expandedRowRender : null )

    update = ( data ) => {
        if ( data && ( Array.isArray( data ) || Array.isArray( data.content ))) {
            this.setState({ isLoading: false })
        }

        const modified = this.prepare( this.content( data ))

        this.filter( modified, true )
        this.open( modified )
    }

    filter = ( data, needReFilter ) => {
        this.setState({
            data: this.prepare( data ),
            needReFilter,
        })
        this.props.onSearch && this.props.onSearch( data )
    }

    rowClass = ( record ) => {
        let { styleInactive } = this.props,
            cls = record.rowClass ? [ record.rowClass ] : []

        record.factValue === null && cls.push( 'empty' );
        ( record._api_error ||
            record._error ||
            record._mc_error ||
            record._outstaff_error ) &&
        cls.push( 'row-error' )
        record._warning && cls.push( 'row-warning' )
        record._current && cls.push( 'row-current' )
        record.fixed && cls.push( 'row-fixed' );
        ( record.lowRest || record.muchWork ) && cls.push( 'row-overworked' )

        if ( styleInactive ) {
            !record.status &&
            !record.active &&
            !record.enabled &&
            cls.push( 'row-inactive' )
        } else {
            ( record.disabled || record.deleted ) && cls.push( 'row-inactive' )
        }

        return cls.join( ' ' ).trim()
    }

    rowAtts = ( record, rowIndex ) => {
        const atts = {
            title: record._api_error,
            index: rowIndex,
            onClick: ( e ) => {
                const deny = [
                    'ant-input-number-handler-up',
                    'ant-input-number-handler-up-inner',
                    'ant-input-number-handler-down',
                    'ant-input-number-handler-down-inner',
                    'ant-input-number-handler-wrap',
                ],
                      { onCellClick } = this.props

                let t = e.target

                t.tagName.toLowerCase() === 'svg' && ( t = t.parentNode )
                t.tagName.toLowerCase() === 'path' && ( t = t.parentNode.parentNode )

                if (
                    t &&
                    t.className &&
                    t.className
                        .split( ' ' )
                        .reduce(( den, cls ) => den || deny.indexOf( cls ) > -1, false )
                ) {
                    return
                }

                // eslint-disable-next-line camelcase
                record._api_error = null
                onCellClick && onCellClick( record )
                record.rowClick && record.rowClick()
            },
        }

        if ( this.props.dragSort ) {
            atts.moveRow = this.props.dragSort
            atts.noDrag = record.noDrag
        }

        return atts
    }

    prepareHelper = ( data, prepare, originalColumns ) => {
        if ( !data ) { return data }
        if ( !data.length ) { return data }
        if ( !prepare ) { return data }

        const columns = {}

        originalColumns.forEach(( col ) => {
            const key = col.dataIndex
            columns[ key ] = format.extract.key( data, key )
        })

        return data.map(( record, index ) => {
            const res = format.copy.object( record )
            return prepare( res, index, columns )
        })
    }

    prepare = ( data ) =>
        this.prepareHelper( data, this.props.prepare, this.props.columns )

    onFocus = ( record, key, parent, index, parser ) => {
        const handler = parser || (( val ) => val )
        return ( e ) =>
            this.props.onCellFocus &&
            this.props.onCellFocus(
                record,
                key,
                handler( e.target.value ),
                parent,
                index
            )
    }

    onBlur = ( record, key, parent, index, parser ) => {
        const handler = parser || (( val ) => val )
        return ( e ) => this.props.onCellBlur &&
            this.props.onCellBlur(
                record,
                key,
                handler( e.target.value ),
                parent,
                index
            )
    }

    onChange = ( record, key, value, parent, index ) =>
        this.props.onChange &&
        this.props.onChange( record, key, value, parent, index )

    onText = ( record, key, value, parent, index ) => {
        return ( e ) => this.onChange( record, key, e.target.value, parent, index )
    }

    onVal = ( record, key, value, parent, index ) => {
        return ( text ) => this.onChange( record, key, text, parent, index )
    }

    onCheck = ( record, key, parent, index ) => {

        return ( e ) => this.onChange( record, key, e.target.checked, parent, index )
    }

    onTimeChange = ( record, key, parent, index ) => {
        return ( e ) =>
            this.onChange( record, key, e.format( config.format.time ), parent, index )
    }

    onDateChange = ( record, key, parent, index ) => {
        return ( e ) =>
            this.onChange(
                record,
                key,
                e ? e.format( config.format.dayAPI ) : null,
                parent,
                index
            )
    }

    onSelect = ( record, key, parent, index ) => {
        return ( val ) => this.onChange( record, key, val, parent, index )
    }

    onRemove = ( record, parent, index ) => {
        return () =>
            this.props.onRemove && this.props.onRemove( record, parent, index )
    }

    onRestore = ( record, parent, index ) => {
        return () =>
            this.props.onRestore && this.props.onRestore( record, parent, index )
    }

    onAdd = ( record, parent, index ) => {
        return () => this.props.onAdd && this.props.onAdd( record, parent, index )
    }

    onAddNested = ( parent ) => {
        return ( e ) => {
            e.stopPropagation()
            this.props.nested &&
            this.props.nested.onAdd &&
            this.props.nested.onAdd( parent )
        }
    }

    empty = ( orig, parent ) => {
        if ( !orig ) {
            return this.props.emptyText ? this.props.emptyText : 'Ничего не найдено'
        }

        if ( typeof orig === 'string' ) { return orig }

        return orig( parent )
    }

    changeOpen = ( val, record ) => {
        const open = format.copy.array( this.state.open ),
              key = record[ this.props.rowKey ]

        val === true ? open.push( key ) : open.splice( open.indexOf( key ), 1 )

        this.setState({ open })
    }

    expandedPrepare = ( record, handler ) => {
        return ( sub, index, columns ) =>
            handler ? handler( record, sub, index, columns ) : sub


    }

    expandedRowRender = ( record ) => {
        const columns = this.columns( this.props.nested.columns, record ),
              data = this.prepareHelper(
                  record[ this.props.nested.data ],
                  this.expandedPrepare( record, this.props.nested.prepare ),
                  columns
              )

        return (
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={this.props.nested.rowKey || this.props.rowKey}
                locale={{
                    emptyText:
                        this.empty( this.props.nested.emptyText, record ) || this.empty(),
                }}
                showHeader={!this.props.topHeader}
            />
        )
    }

    open = ( data ) => {
        if ( !this.props.open ) { return null }

        this.setState({ open: data.map(( item ) => item[ this.props.rowKey ]), })
    }

    columns = ( cols, parent ) =>
        format.copy.array( cols ).map(
            renderAs({
                unitOfMeasure: this.props.unit,
                onAddNested: this.onAddNested,
                onText: this.onText,
                onVal: this.onVal,
                onCheck: this.onCheck,
                onTime: this.onTimeChange,
                onDate: this.onDateChange,
                onSelect: this.onSelect,
                onRemove: this.onRemove,
                onRestore: this.onRestore,
                onAdd: this.onAdd,
                onBlur: this.onBlur,
                onFocus: this.onFocus,
                items: this.props.select,
                actions: this.props.cellActions,
                isToday: this.props.isToday,
                totalCols: cols.length,
                parent,
            })
        )

    render () {
        const { searchKey, rowKey, placeholder, dragSort } = this.props,
              { topHeader, hideHeader, scroll, loading } = this.props,
              { data, filter, children, nested, columns } = this.props,
              last = {
                  number: data ? data.number : 0,
                  last: data
                      ? ( data && data.last ) ||
                    ( this.state.data && this.state.data.length < 1 )
                      : true,
              }

        const { isLoading } = this.state

        const expandableConfig = {
            expandedRowRender: this.expanded(),
            expandRowByClick: true,
            onExpand: this.changeOpen,
            expandedRowKeys: this.state.open,
        }

        return (
            <div className="report-wrap" ref={( node ) => ( this._container = node )}>
                <style
                    dangerouslySetInnerHTML={{
                        __html: `
                    .ant-table-body[style^="overflow-x"] {
                        margin-bottom: -${format.check.scrollbarWidth() - 1}px;
                    }
                `,
                    }}
                />

                {filter && (
                    <FilterInput
                        data={this.content( data )}
                        check={this.state.needReFilter}
                        update={this.filter}
                        rowKey={searchKey || rowKey}
                        placeholder={placeholder}
                    />
                )}

                {children}

                {topHeader && nested && (
                    <Table
                        columns={this.columns( nested.columns )}
                        className="only-header"
                    />
                )}

                <Pager onMore={this.props.onMore} check={last}>
                    {this.state.data &&
                    this.state.data.length < 1 ? (
                                <Alert message={this.empty()} />
                            ) : dragSort ? (
                                <DndProvider backend={HTML5Backend}>
                                    <Table
                                        showSorterTooltip={false}
                                        loading={loading || isLoading}
                                        showHeader={!hideHeader}
                                        pagination={false}
                                        rowClassName={this.rowClass}
                                        locale={{ emptyText: this.empty() }}
                                        rowKey={rowKey}
                                        onRow={this.rowAtts}
                                        scroll={scroll}
                                        dataSource={this.state.data}
                                        columns={this.columns( columns )}
                                        components={{ body: { row: DragableBodyRow } }}
                                        expandable={expandableConfig}
                                    />
                                </DndProvider>
                            ) : (
                                <Table
                                    showSorterTooltip={false}
                                    loading={loading || isLoading}
                                    showHeader={!hideHeader}
                                    pagination={false}
                                    rowClassName={this.rowClass}
                                    locale={{ emptyText: this.empty() }}
                                    rowKey={rowKey}
                                    onRow={this.rowAtts}
                                    scroll={scroll}
                                    dataSource={this.state.data}
                                    columns={this.columns( columns )}
                                    expandable={expandableConfig}
                                />
                            )}
                </Pager>
            </div>
        )
    }
}

export default ReportTable
