/* VENDOR */
import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { Layout }           from 'antd'
/* APPLICATION */
import { ViewTabs, Alert, Splash } from 'components'
import { uiActions }               from 'services'
import config                      from 'config'

import './global.scss'
import './global-print.scss'

const { Content, Sider } = Layout

let resizeInterval = null

class Global extends Component {
    constructor ( props ) {
        super( props )

        this.state = { dismissAuth: false, }
    }

    componentDidMount () {
        window.addEventListener( 'resize', () => {
            this.props.resize()
        })

        resizeInterval = setInterval(() => this.forceUpdate(), 60000 )
    }

    componentWillUnmount () {
        clearInterval( resizeInterval )
    }

    dismiss = () => this.setState({ dismissAuth: true })
    flushError = ( errNum ) => () => this.props.flushServerError( errNum )

    errors = () =>
        Object.keys( this.props.errors )
            .filter(( errNum ) => !!this.props.errors[ errNum ])
            .map(( errNum ) => {
                return (
                    <Alert
                        key={'err' + errNum}
                        button={true}
                        buttonText="Скрыть"
                        action={this.flushError( errNum )}
                        text={this.props.errors[ errNum ]}
                    />
                )
            })

    authAlert = () => {
        /* const time = format.extract.authRefresh(),
              till = dayjs( time ),
              now = dayjs(),
              minutes = till.diff( now, 'minutes' )

        if ( minutes < 15 ) {
            return (
                <Alert
                    button={true}
                    buttonText="Скрыть"
                    action={this.dismiss}
                    text="До завершения сессии осталось менее 15 минут."
                />
            )
        } */

        return null
    }

    render () {
        const { children, errors } = this.props,
              { dismissAuth } = this.state

        return (
            <main className="kfc-app">
                {/*<ConfigProvider locale={ruRU}>*/}
                <Splash />
                <Layout>
                    <Layout>
                        <Sider
                            theme="light"
                            width={config.ui.siderWidth}
                            className="global-sider"
                        >
                            <ViewTabs />
                        </Sider>
                        <Layout className="content-layout">
                            <Content style={{ flex: '0 0 100%' }}>
                                {children}

                                {errors && this.errors()}

                                {!dismissAuth && this.authAlert()}
                            </Content>
                        </Layout>
                    </Layout>
                </Layout>
                {/*</ConfigProvider>*/}
            </main>
        )
    }
}

const mapStateToProps = ( state ) => ({ errors: state.serverErrors, })

export default connect( mapStateToProps, uiActions )( Global )
