/* VENDOR */
import axios from 'axios'

/* APPLICATION */
import config     from 'config'
import { format } from 'tools'

const creds = config.test.creds

function resolveAfter2Seconds ( x ) {
    return new Promise(( resolve ) => {
        setTimeout(() => {
            resolve( x )
        }, 500 )
    })
}

export const getLoginStatus = () =>
    localStorage.getItem( config.keys.auth ) === 'true'
export const getCurrentRestaurant = () =>
    localStorage.getItem( config.keys.restaurant )
export const setCurrentRestaurant = ( id ) =>
    localStorage.setItem( config.keys.restaurant, id )

export const getRoles = () =>
    format.generate.get( 'staff', 'admin/users/job-roles' )

export const logout = () => {
    return new Promise(( resolve ) => {
        resolve()
        localStorage.removeItem( config.keys.auth )

        axios.get( `${process.env.REACT_APP_AUTH_API}saml/logout` ).then(( event ) => {
            var div = document.createElement( 'div' )
            div.innerHTML = event.data.match( /<form.*<\/form>/is )
            document.body.appendChild( div )
            // eslint-disable-next-line vars-on-top
            var form = div.querySelector( 'form' )
            form.submit()
        })
    })
}

export async function auth ( login, pwd ) {
    if ( login !== creds.login || pwd !== creds.pass ) {
        return await resolveAfter2Seconds( false )
    } else {
        return await resolveAfter2Seconds( true )
    }
}

/* ACCESS */

export const getAccessAreas = () =>
    axios.get( config.api.staff + 'rbac/grantedWebAreas', config.api.getOptions())

export const loadUserInfo = () => format.generate.get( 'staff', '/employees/me' )

/* AUTH ENDPOINTS */

export const getEndPoints = () => axios.get( config.api.staff + 'auth/endpoints' )
