import React          from 'react'
import { EventModal } from '../../index'
import dayjs          from 'dayjs'
import config         from 'config'

type AddEventModalProps = {
    view: string;
    extractRepeat: ( event: any ) => any
    setAdd: ( add: boolean ) => void,
    setAddDate: ( addDate: dayjs.Dayjs | null ) => void;
    setNeedReload: ( needReload: boolean ) => void;
    addHandler: ( event: any ) => void;
    add?: boolean;
    addDate?: dayjs.Dayjs;
    position?: {
        left?: number;
        top?: number;
    };
}

export const AddEventModal = ({ addDate, add, view, position, extractRepeat, setAdd, setAddDate, setNeedReload, addHandler }: AddEventModalProps ) => {

    const close = () => {
        // this.set.add( false )
        setAdd( false )
        setTimeout(() => setAddDate( null ), 300 )
    }

    const preAdd = ( event: any ) => {
        const repeat = extractRepeat( event )

        repeat && setNeedReload( true )

        close()

        addHandler({
            title: event.title,
            description: event.description,
            date: dayjs( event.date )
                .set( 'hours', event.timeStart.hour())
                .set( 'minutes', event.timeStart.minute())
                .format( config.format.dateFull + 'Z' ),
            duration: event.timeEnd.diff( event.timeStart, 'minutes' ),
            done: false,
            repeat,
        })
    }


    return (
        <EventModal
            title="Новая задача"
            view={view}
            visible={add}
            position={position}
            event={{ date: addDate, }}
            onClose={close}
            onSubmit={preAdd}
        />
    )
}
