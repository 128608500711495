/* VENDOR */
import React, { Component } from 'react'
import { connect }          from 'react-redux'
import {
    Layout,
    Card,
    Upload,
    Row,
    Col,
    Button,
    Alert,
    Select,
    Modal,
    Progress,
} from 'antd'
import axios                from 'axios'
import dayjs                from 'dayjs'
import { CalendarOutlined } from '@ant-design/icons'

/* APPLICATION */
import { AppHeader, InnerContent, Spinner, ViewTypeSwitch } from 'components'

import { format } from 'tools'
import config     from 'config'

import { allActions, mapStateToProps } from './connector'
import './check-upload.scss'
import { DatePicker }                  from 'antd-v5'

const { Content, Header } = Layout,
      { Dragger } = Upload,
      /* MiB */
      maxSize = 30000

let tmpFiles = []

class CheckUpload extends Component {
    constructor ( props ) {
        super( props )

        this.state = {
            view: 'upload',
            files: [],
            uploading: false,
            message: '',
            restSearch: '',
            restaurant: null,
            month: dayjs(),
            picker: false,
            removing: false,
            removed: false,
            percent: 0,
            minimalTimePassed: false,
            updated: false,
        }
    }

    componentDidMount () {
        this.props.fetchRestaurantsList( '' )
    }

    componentDidUpdate ( prevProps, prevState ) {
        const { checks } = this.props,
              { minimalTimePassed, updated, removing } = this.state

        if ( checks !== prevProps.checks ) {
            minimalTimePassed && clearInterval( this.removeInterval )

            this.setState({
                updated: true,
                removing: minimalTimePassed ? false : removing,
                removed: minimalTimePassed && checks.status < 300,
            })
        }

        if (
            minimalTimePassed !== prevState.minimalTimePassed &&
      minimalTimePassed
        ) {
            if ( updated ) {
                clearInterval( this.removeInterval )

                this.setState({
                    removing: false,
                    removed: checks.status < 300,
                })
            }
        }
    }

    onChange = ( info ) => {
        const { status } = info.file

        //;( status !== 'uploading' ) && ( console.log( info.file, info.fileList ) )

        if ( status === 'done' ) {
            console.log( `${info.file.name} file uploaded successfully.` )
        } else if ( status === 'error' ) {
            console.error( `${info.file.name} file upload failed.` )
        }
    }

    setFile = ( file ) => {
        tmpFiles.push( file )
        this.updateFiles()
        return false
    }

    removeFile = ( file ) => {
        const { files } = this.state,
              index = files.indexOf( file ),
              nf = [].slice.call( files )

        nf.splice( index, 1 )
        tmpFiles.splice( tmpFiles.indexOf( file ))

        this.setState({
            files: nf,
            message: '',
        })
    }

    updateFiles = () => {
        if ( this.updateTimeout ) {
            clearTimeout( this.updateTimeout )
        }

        this.updateTimeout = setTimeout(
            () =>
                this.setState(
                    { files: [ ...tmpFiles ] },
                    () => ( tmpFiles = [ ...this.state.files ])
                ),
            500
        )
    }

    submit = () => {
        const { files } = this.state,
              formData = new FormData()

        files.forEach(( file ) => formData.append( 'files', file ))

        this.setState({
            uploading: true,
            message: '',
        })

        axios
            .post( config.api.upload, formData, {
                headers: { 'content-type': 'multipart/form-data', },
                ...config.api.getOptions(),
            })
            .then(( res ) => {
                if ( !res || res.status >= 400 ) {
                    this.onError( res, `Error ${res?.status}. Ошибка загрузки` )
                }

                const { files } = this.state,
                      notUploaded = files.filter(
                          ( file ) => res.data[ file.name ] === 'not uploaded'
                      )

                if ( notUploaded.length > 0 ) {
                    this.setState({
                        uploading: false,
                        files: notUploaded,
                        message: 'Некоторые файлы не были загружены',
                    })
                } else {
                    this.setState({
                        files: [],
                        uploading: false,
                    })
                }
            })
            .catch(( res ) => {
                this.onError( res )
            })
    }

    onError = ( event, message ) => {
        console.log( 'error', event )

        this.setState({
            uploading: false,
            message: message || 'Ошибка загрузки',
        })
    }

    getSize = () => this.state.files.reduce(( size, file ) => size + file.size, 0 )

    count = () => this.state.files.length
    size = () => {
        let size = this.getSize(),
            word = format.strings.ending( size, [ 'байт', 'байта', 'байт' ])

        if ( size >= 1073741824 ) {
            size = size / 1073741824
            word = format.strings.ending( size, [ 'гигабайт', 'гигабайта', 'гигабайт' ])
        } else if ( size >= 1048576 ) {
            size = size / 1048576
            word = format.strings.ending( size, [ 'мегабайт', 'мегабайта', 'мегабайт' ])
        } else if ( size >= 1024 ) {
            size = size / 1024
            word = format.strings.ending( size, [ 'килобайт', 'килобайта', 'килобайт' ])
        }

        return format.strings.thousand( Math.round( size * 100 ) / 100 ) + ' ' + word
    }

    isTooBig = () => this.getSize() >= maxSize * 1024 * 1024

    setView = ( view ) => this.setState({ view })

    getContainer = ( trigger ) => {
        console.log( trigger, trigger.parentNode, trigger.parentNode.parentNode )
        const res = trigger.parentNode.parentNode

        res.style.position = 'relative'

        return res
    }

    option = ( opt ) => (
        <Select.Option value={opt.value} key={opt.value}>
            {opt.label}
        </Select.Option>
    )

    options = ( data, keys, vals ) =>
        ( data
            ? vals
                ? format.generate.options( data, keys, vals )
                : format.generate.keyvalrs( data )
            : []
        ).map( this.option )

    restSearch = ( search ) => {
        const { restSearch } = this.state

        if ( restSearch === search ) {
            return
        }

        this.setState({ restSearch: search })
        this.props.fetchRestaurantsList( search )
    }

    flushRestaurants = ( state ) => !state && this.restSearch( '' )

    close = () =>
        this.setState({
            removed: false,
            minimalTimePassed: false,
            updated: false,
        })

    submitRemove = () => {
        const { restaurant, month } = this.state

        this.setState({
            minimalTimePassed: false,
            removed: false,
            updated: false,
            removing: true,
            percent: 0,
            restaurant: null,
            month: dayjs(),
        })

        this.props.removeGuestChecks({
            restaurantId: restaurant,
            month: month.format( 'YYYY-MM-DD' ),
        })

        if ( this.removeInterval ) {
            clearInterval( this.removeInterval )
            clearTimeout( this.removeTimeout )
        }

        this.removeInterval = setInterval(() => {
            const { percent } = this.state

            this.setState({
                percent: Math.min(
                    99,
                    percent + ( percent < 40 ? 0.5 : percent < 70 ? 0.2 : 0.1 )
                ),
            })
        }, 30 )

        this.removeTimeout = setTimeout(() => {
            this.setState({ minimalTimePassed: true, })
        }, 500 )
    }

    content = () => {
        const { restaurants } = this.props,
              { view, files, uploading, message, restaurant, month, picker, removing } =
        this.state

        switch ( view ) {
            case 'remove':
                return (
                    <Card bordered={false}>
                        <Row gutter={24}>
                            <Col span={7}>
                                <Select
                                    showSearch
                                    value={!restaurant ? void 0 : restaurant}
                                    byKey={true}
                                    placeholder="Выберите ресторан"
                                    notFoundContent="Ничего не найдено"
                                    filterOption={() => true}
                                    onChange={( restaurant ) => this.setState({ restaurant })}
                                    onSearch={this.restSearch}
                                    onDropdownVisibleChange={this.flushRestaurants}
                                    getPopupContainer={( trigger ) => trigger.parentNode}
                                >
                                    {this.options(
                                        restaurants?.content,
                                        'factsNumber',
                                        'restaurantName'
                                    )}
                                </Select>
                            </Col>
                            <Col span={5}>
                                <span
                                    className="dates-fake"
                                    onClick={() => this.setState({ picker: !picker })}
                                >
                                    <CalendarOutlined />
                                    {format.strings.capitalize( month.format( 'MMMM YYYY' ))}
                                </span>
                                <DatePicker.MonthPicker
                                    value={month}
                                    open={picker}
                                    disabledDate={( current ) => current.isAfter( dayjs(), 'month' )}
                                    onChange={( month ) => this.setState({ month })}
                                    onBlur={() => this.setState({ picker: false })}
                                    onOpenChange={( picker ) => this.setState({ picker })}
                                    getCalendarContainer={this.getContainer}
                                />
                            </Col>
                            <Col span={5}>
                                <Button
                                    type="primary"
                                    size="large"
                                    className="ant-btn-danger"
                                    disabled={!restaurant || removing}
                                    style={{ width: '100%', }}
                                    onClick={this.submitRemove}
                                >
                  Удалить
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                )
            case 'upload':
            default:
                return (
                    <Card bordered={false}>
                        {message && <Alert type="error" message={message} />}
                        <Row gutter={24}>
                            <Col span={18}>
                                {uploading ? (
                                    <Spinner />
                                ) : (
                                    <Dragger
                                        accept="application/json"
                                        name="files"
                                        multiple={true}
                                        action={config.api.upload}
                                        onRemove={this.removeFile}
                                        onChange={this.onChange}
                                        beforeUpload={this.setFile}
                                        fileList={files}
                                    >
                                        <p>Перетащите файлы сюда или кликните, чтобы выбрать.</p>
                                    </Dragger>
                                )}
                            </Col>
                            <Col span={6}>
                                <Button
                                    type="primary"
                                    size="large"
                                    disabled={this.isTooBig() || files.length < 1 || uploading}
                                    onClick={this.submit}
                                    style={{
                                        marginTop: 8,
                                        width: '100%',
                                    }}
                                >
                  Загрузить
                                </Button>
                            </Col>
                        </Row>
                        {files.length > 0 && !uploading && (
                            <>
                                <hr />
                                <p>
                  Файлов: <b>{this.count()}</b>, размер:{' '}
                                    <b className={this.isTooBig() ? 'alarm' : ''}>
                                        {this.size()}
                                    </b>
                                </p>
                            </>
                        )}
                    </Card>
                )
        }
    }

    render () {
        const { removing, percent, removed } = this.state,
              tabs = {
                  _default: 'upload',
                  upload: 'Загрузка',
                  remove: 'Удаление',
              }

        return (
            <section className="kfc-check-upload kfc-tabbed-page scroll-container">
                <Layout>
                    <Header>
                        <AppHeader hideRestaurants />
                    </Header>
                    <Content>
                        <ViewTypeSwitch
                            update={this.setView}
                            tabs={tabs}
                            current={this.state.view}
                        />
                        <InnerContent>
                            <div className="tabbed-content">{this.content()}</div>

                            <Modal
                                title="Удаляем чеки"
                                className="kfc-popup kfc-remove-progress"
                                closable={false}
                                centered={true}
                                open={removing}
                                okButtonProps={{ style: { display: 'none' } }}
                                cancelButtonProps={{ style: { display: 'none' } }}
                            >
                                <Progress
                                    percent={percent}
                                    strokeColor="#8FC2FF"
                                    showInfo={false}
                                />
                                <small>{Math.round( percent ).toString()}%</small>
                            </Modal>

                            <Modal
                                title="Чеки удалены"
                                className="kfc-popup kfc-remove-done"
                                width={240}
                                centered={true}
                                open={removed}
                                okText="Закрыть"
                                okButtonProps={{ style: { margin: '0 auto' } }}
                                onOk={this.close}
                                onCancel={this.close}
                                cancelButtonProps={{ style: { display: 'none' } }}
                            />
                        </InnerContent>
                    </Content>
                </Layout>
            </section>
        )
    }
}

export default connect( mapStateToProps, allActions )( CheckUpload )
