import React, { Component, useEffect, useRef, useState } from 'react'
import classNames                                        from 'classnames'
import Portal                                            from './Portal/Portal'
import styles                                            from './photobrowser.module.scss'
import FullScreen                                        from './FullScreen/FullScreen'
import { throttle }                                      from './utils'
import { Spin }                                          from 'antd'
import { format }                                        from 'tools'

const closeIcon = { backgroundImage: `url(${require( './img/close.png' )})` }
const fullscreenOpenIcon = { backgroundImage: `url(${require( './img/fullscreen.png' )})` }
const fullscreenCloseIcon = { backgroundImage: `url(${require( './img/fullscreen_close.png' )})` }
const arrowIcon = { backgroundImage: `url(${require( './img/arrow.png' )})` }

const Image = ({ image, onClick, currentImage, index }) => {
    const [ loading, setLoading ] = useState( true )
    const imageRef = useRef()

    useEffect(() => {
        setLoading( true )
        fetch( image.src, { headers: { 'Authorization': `Bearer ${localStorage.getItem( 'access_token' )}` } })
            .then( res => res.blob())
            .then( blob => {
                if ( imageRef.current ) {
                    imageRef.current.src = URL.createObjectURL( blob )
                }
            }).catch(( error ) => {
                if ( error.status === 401 || error.status === 403 ) {
                    format.update.goToLogin()
                }
            }).finally(() => {
                setLoading( false )
            })

    }, [])

    return <Spin spinning={loading}>
        <img
            ref={imageRef}
            className={styles.image}
            alt={image.description}
            onClick={onClick}
            style={{ display: index === currentImage ? 'block' : 'none' }}
        />
    </Spin>
}

export default class PhotoBrowser extends Component {
    constructor ( props ) {
        super( props )
        this.state = {
            isOpen: false,
            currentImage: 0,
            fullscreen: false,
            showDescription: true,
            imagesSrc: []
        }
        this.throttleKeyDown = throttle( this.handleKeyDown, 200 )
        this.throttleMouseMove = throttle( this.handleMouseMove, 200 )
    }

    componentDidUpdate ( prevProps ) {
        if ( prevProps.isOpen !== this.props.isOpen ) {
            this.setState({ isOpen: this.props.isOpen }, () => {
                if ( this.state.isOpen ) {
                    document.body.style = 'overflow:hidden'
                    this.setShowDescription()
                    document.addEventListener( 'keydown', this.throttleKeyDown )
                    document.addEventListener( 'mousemove', this.throttleMouseMove )
                } else {
                    document.body.style = 'overflow:auto'
                    document.removeEventListener( 'keydown', this.throttleKeyDown )
                    document.removeEventListener( 'mousemove', this.throttleMouseMove )
                }
            })
        }
        if ( prevProps.currentImage !== this.props.currentImage ) {
            this.setState({ currentImage: this.props.currentImage })
        }
    }

    close = () => this.setState({ fullscreen: false }, () => this.props.onClose())

    closeBackdrop = () => {
        if ( this.props.closeByBackdrop ) {
            this.props.onClose()
        }
    }

    next = ( e ) => {
        if ( e ) { e.stopPropagation() }
        if ( this.state.currentImage < this.props.images.length - 1 ) {
            this.setState({ currentImage: this.state.currentImage + 1 })
        }
    }

    prev = ( e ) => {
        if ( e ) { e.stopPropagation() }
        if ( this.state.currentImage > 0 ) {
            this.setState({ currentImage: this.state.currentImage - 1 })
        }
    }

    fullscreen = ( fullscreen ) => this.setState({ fullscreen }, () => {
        if ( this.state.fullscreen ) { this.setShowDescription() }
    })

    handleKeyDown = ( e ) => {
        if ( e.which === 39 ) { this.next() }
        if ( e.which === 37 ) { this.prev() }
        if ( e.which === 27 ) { this.close() }
    }

    handleMouseMove = () => {
        this.setShowDescription()
    }

    setShowDescription = () => {
        clearTimeout( this.timerShow )
        this.setState({ showDescription: true }, () => {
            if ( this.state.fullscreen ) {
                this.timerShow = setTimeout(() => {
                    this.setState({ showDescription: false })
                }, 1500 )
            }
        })
    }

    getShow () {
        return this.state.showDescription ? styles.block : styles.hidden
    }

    arrow = ( direction ) => {
        const isLeft = direction === 'left'
        const isRight = direction === 'right'
        const isFirst = this.state.currentImage === 0
        const isLast = this.state.currentImage === this.props.images.length - 1

        if ( isLeft && isFirst ) { return null }
        if ( isRight && isLast ) { return null }

        return <div
            className={classNames(
                styles.arrow,
                styles[ direction ],
                this.getShow()
            )}
            onClick={direction === 'left' ? this.prev : this.next}
        >
            <div className={styles.icon} style={arrowIcon}></div>
        </div>
    }

    renderClose = () => {
        return <div
            className={classNames(
                styles.close,
                this.getShow()
            )}
            onClick={this.close}
            style={closeIcon}
        />
    }

    renderFullscreen = () => {
        const { fullscreen } = this.state
        return <div
            className={classNames(
                styles.fullscreen,
                this.getShow()
            )}
            onClick={() => this.fullscreen( !fullscreen )}
            style={fullscreen ? fullscreenCloseIcon : fullscreenOpenIcon}
        />
    }

    renderCount = () => {
        const { currentImage } = this.state
        const { betweenText, images, showCount } = this.props

        if ( !showCount ) { return null }

        if ( !images || images.length === 0 ) { return null }

        return <div className={`${styles.count} ${this.getShow()}`}>
            {`${currentImage + 1} ${betweenText} ${images.length}`}
        </div>
    }

    renderDescription = ( description ) => {
        if ( !description ) { return null }

        return <div className={classNames(
            styles.description,
            this.getShow()
        )}>
            {description}
        </div>
    }

    renderImage = ( image, index ) => {
        const { currentImage } = this.state
        return <React.Fragment key={`photo-borwser-${index}`}>
            <Image
                image={image}
                onClick={this.next}
                index={index}
                currentImage={currentImage}
            />
            {this.renderDescription( image.description )}
        </React.Fragment>
    }


    render () {
        const { isOpen, fullscreen } = this.state
        const { images } = this.props
        const containerStyle = { top: fullscreen ? 0 : window.pageYOffset, backgroundColor: fullscreen ? '#111' : 'rgba(0,0,0, 0.7)' }

        return (
            <Portal className={styles[ 'photo-browser' ]}>
                <FullScreen
                    enabled={fullscreen}
                    onChange={this.fullscreen}
                >
                    {isOpen ? <div
                        className={styles[ 'photo-browser-container' ]}
                        style={containerStyle}
                        onClick={this.closeBackdrop}
                    >
                        <div className={styles[ 'inner-container' ]}>
                            {images.map( this.renderImage )}
                            {this.arrow( 'left' )}
                            {this.arrow( 'right' )}
                            {this.renderCount()}
                            {this.renderClose()}
                            {this.renderFullscreen()}
                        </div>
                    </div>
                        :
                        null}
                </FullScreen>
            </Portal>
        )
    }
}

PhotoBrowser.defaultProps = {
    isOpen: false,
    images: [],
    onClose: () => {},
    onNext: () => {},
    onPrev: () => {},
    currentImage: 0,
    closeByBackdrop: false,
    betweenText: 'из',
    showCount: true,
    descriptionPositionX: 'left',
    descriptionPositionY: 'bottom',
    download: false
}
