import dayjs from 'dayjs'

import { format } from 'tools'
import config     from 'config'

import * as check      from './check'
import * as filtration from './filter'

export const separator = '|'

export const defaultGroup = ( event ) => ({
    groupName: event.groupName,
    groupNameUI: event.groupNameUI,
    named: {},
    items: [],
})

export const timeGroup = ( time ) => {
    const split = time.split( separator ),
          t = split[ 0 ],
          duration = +split[ 1 ],
          m = dayjs( t )

    return {
        groupName: time,
        groupNameUI: duration
            ? m.format( 'HH:mm' ) + ' — ' + m.add( duration, 'minutes' ).format( 'HH:mm' )
            : m.format( 'HH:mm' ),
        named: {},
        items: [],
    }
}

export const prepareEvent = ( event, classNames ) => ({
    ...event,
    constraint: { startTime: '00:00', endTime: '23:59' },
    allow: getConstraint( event.deadline ),
    duration: format.extract.duration(
        Math.max( config.calendar.minimalDuration - 1, event.duration - 1 )
    ),
    _originalDuration: event.duration,
    _duration: format.extract.duration( event.duration, true ),
    classNames,
})

export const packEvents = ( items ) => {
    const packed = { ...items[ 0 ], }

    packed.deadlines = {
        start: items.reduce(
            ( first, event ) =>
                dayjs( event.deadline ).isBefore( dayjs( first )) ? event.deadline : first,
            items[ 0 ].deadline
        ),
        end: items.reduce(
            ( latest, event ) =>
                dayjs( event.deadline ).isAfter( dayjs( latest ))
                    ? event.deadline
                    : latest,
            items[ 0 ].deadline
        ),
        all: items.map(( e ) => e.deadline ),
    }

    packed.events = items

    return packed
}

export const sortEvents = ( view, date ) => ( a, b ) => {
    const adate = dayjs( a.deadline ),
          bdate = dayjs( b.deadline )

    if ( view === 'week' ) {
        if ( adate.isSame( dayjs( date ), 'week' )) {
            return -1
        }

        if ( bdate.isSame( dayjs( date ), 'week' )) {
            return 1
        }
    }

    if ( adate.isBefore( bdate )) { return -1 }
    if ( bdate.isBefore( adate )) { return 1 }

    return 0
}

export const getConstraint = ( deadline ) => ( info ) => {
    if ( dayjs( info.start ).isBefore( dayjs())) { return false }
    if ( dayjs( info.start ).isAfter( dayjs( deadline ).add( 1, 'day' ).startOf( 'day' ))) { return false }
    return true
}

export const is = check
export const filter = filtration
