/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { connect }          from 'react-redux'

import { Card, Col, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

/* APPLICATION */
import { Center } from 'components'
import config     from 'config'

import './dashboard-card.scss'

const cardClass = 'dashboard-card'

class DashboardCard extends Component {
    static propTypes = {
        spans: PropTypes.array,

        info: PropTypes.string,
        className: PropTypes.string,

        underConstruction: PropTypes.bool,
    }

    size = ( vars ) =>
        this.props.ui.width < config.ui.breakpoint ? vars[ 0 ] : vars[ 1 ]

    cls = () =>
        this.props.className
            ? [ cardClass, this.props.className ].join( ' ' )
            : cardClass

    render () {
        const spans = this.props.spans || [ 24, 24 ],
              span = this.size( spans )

        return (
            <Col span={span}>
                <Card className={this.cls()} bordered={false}>
                    {this.props.children}

                    {this.props.info && (
                        <Tooltip
                            placement="topRight"
                            title={this.props.info}
                            overlayClassName="card-tooltip"
                        >
                            <div className="info-icon">
                                <InfoCircleOutlined />
                            </div>
                        </Tooltip>
                    )}

                    {this.props.underConstruction && (
                        <div className="under-construction">
                            <Center>
                                <span className="uc-message">В разработке...</span>
                            </Center>
                        </div>
                    )}
                </Card>
            </Col>
        )
    }
}

const mapStateToProps = ( state ) => ({ ui: state.ui, })

export default connect( mapStateToProps )( DashboardCard )
