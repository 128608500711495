/* IMPORT */

import { restaurantActions } from 'services'

/* EXPORT */

export const mapStateToProps = ( state ) => ({
    restaurants: state.restaurants.admin,
    checks: state.restaurants.admin.guestChecks,
})

export const allActions = { ...restaurantActions }
