/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import dayjs                from 'dayjs'

import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'

import { Col, Row } from 'antd'

/* APPLICATION */
import { FormField } from 'components'
import { format }    from 'tools'
import config        from 'config'

class AddEventForm extends Component {
    static propTypes = {
        okBtnText: PropTypes.string,
        deleteBtn: PropTypes.bool,

        view: PropTypes.string,
        event: PropTypes.object,

        onSubmit: PropTypes.func,
        onCancel: PropTypes.func,
        onRemove: PropTypes.func,
    }

    constructor ( props ) {
        super( props )

        this.form = React.createRef()

        this.state = {
            repeat: false,
            hasRepeat: props.event ? props.event.repeat : false,
        }
    }

    componentDidUpdate ( prevProps ) {
        const { event } = this.props

        if ( !event || !event.date ) {
            setTimeout(
                () =>
                    this.setState({
                        repeat: false,
                        hasRepeat: false,
                    }),
                300
            )
        }

        if (
            event &&
      ( !prevProps.event || event.taskId !== prevProps.event.taskId )
        ) {
            this.setState({ hasRepeat: !!event.repeat })
            setTimeout(() => {
                this.forceUpdate()
            }, 100 )
        }
    }

    getStart = () => {
        const event = this.props.event || {},
              date = dayjs( event.date ),
              today = dayjs()

        if ( today.isSame( date, 'day' )) {
            return today.startOf( 'hour' ).add( 1, 'hour' )
        }

        return dayjs( date ).set( 'hours', 11 ).set( 'minutes', 0 )
    }

    saveRepeat = () => this.setState({ repeat: false })

    cancelRepeat = () =>
        this.setState({
            repeat: false,
            hasRepeat: this.props.event ? this.props.event.repeat : false,
        })

    disabledHours = ( type ) => {
        return () => {
            const { form } = this.props,
                  anotherKey = type === 'start' ? 'timeEnd' : 'timeStart',
                  anotherVal = form.getFieldValue( anotherKey )

            if ( !anotherVal ) {
                return []
            }

            const anotherMinutes = anotherVal.minute(),
                  anotherHours =
          ( type === 'start' && anotherMinutes > 0 ) ||
          ( type === 'end' && anotherMinutes > 30 )
              ? anotherVal.hour() + 1
              : anotherVal.hour(),
                  today = dayjs()

            if ( dayjs( form.getFieldValue( 'date' )).isSame( today, 'day' )) {
                const rhours =
            anotherHours === 0 && type === 'start' ? 24 : anotherHours,
                      start = format.generate.range( 0, rhours ),
                      end = format.generate.range( rhours, 24 )

                if ( type === 'start' ) {
                    return today.minute() > 45
                        ? [ ...format.generate.range( 0, today.hour() + 1 ), ...end ]
                        : [ ...format.generate.range( 0, today.hour()), ...end ]
                }

                return today.minute() > 45
                    ? [
                            ...start,
                            ...format.generate.range(
                                0,
                                Math.max( anotherHours, today.hour()) + 1
                            ),
                        ]
                    : [
                            ...start,
                            ...format.generate.range(
                                0,
                                Math.max( anotherHours, today.hour())
                            ),
                        ]
            }

            return type === 'start'
                ? format.generate.range( anotherHours, 24 )
                : format.generate.range( 0, anotherHours )
        }
    }

    disabledMinutes = ( type ) => {
        return ( hour ) => {
            const { form } = this.props,
                  anotherKey = type === 'start' ? 'timeEnd' : 'timeStart',
                  anotherVal = form.getFieldValue( anotherKey )

            if ( !anotherVal ) {
                return []
            }

            const anotherHours = anotherVal.hour(),
                  today = dayjs()

            if ( dayjs( form.getFieldValue( 'date' )).isSame( today, 'day' )) {
                if ( hour === today.hour()) {
                    return format.generate.range( 0, today.minute() + 1 )
                }
            }

            if ( anotherHours === hour ) {
                return type === 'start'
                    ? format.generate.range( anotherVal.minute(), 60 )
                    : format.generate.range( 0, anotherVal.minute() + 1 )
            }

            return []
        }
    }

    fixDow = ( date ) => {
        const dow = date.format( 'dddd' )
        return [ 'среда', 'пятница', 'суббота' ].indexOf( dow ) > -1
            ? dow.split( '' ).slice( 0, -1 ).join( '' ) + 'у'
            : dow
    }

    getEveryWeekday = ( event ) => {
        const date = dayjs( event.date ),
              day = date.date(),
              dow = parseInt( date.format( 'd' ))

        if ( isNaN( dow )) {
            return ''
        }

        const downame = this.fixDow( date ),
              types = {
                  f: [ 3, 5, 6 ],
                  m: [ 1, 2, 4 ],
                  o: [ 0 ],
              },
              everys = {
                  f: 'Каждую',
                  m: 'Каждый',
                  o: 'Каждое',
              },
              numerics = {
                  f: [ 'первую', 'вторую', 'третью', 'чевертую' ],
                  m: [ 'первый', 'второй', 'третий', 'четвертый' ],
                  o: [ 'первое', 'второе', 'третье', 'четвертое' ],
              },
              last = {
                  f: 'последнюю',
                  m: 'последний',
                  o: 'последнее',
              },
              type = Object.keys( types ).find(( t ) => types[ t ].indexOf( dow ) > -1 ),
              every = everys[ type ],
              numeric = numerics[ type ],
              num =
        day > date.daysInMonth() - 7
            ? last[ type ]
            : numeric[ Math.floor(( day - 1 ) / 7 ) ]

        return `${every} ${num} ${downame} месяца`
    }

    getRepeatInfo = () => {
        let { form, event } = this.props,
            strategy =
        form.getFieldValue( 'completeStrategy' ) || event.completeStrategy,
            text = 'Повторяющаяся'

        strategy === 'date' &&
      ( text =
        'До ' +
        dayjs(
            form.getFieldValue( 'completeDate' ) || event.completeDate,
            'YYYY-MM-DD'
        ).format( 'D MMMM YYYY' ))
        strategy === 'count' &&
      ( text =
        'Повторить ' +
        format.strings.clearCount(
            form.getFieldValue( 'completeCount' ) || event.completeCount,
            [ 'раз', 'раза', 'раз' ]
        ))

        return <span className="repeat-sign">{text}</span>
    }

    isCurrentDow = ( raw ) => {
        const date = dayjs( this.props.form.getFieldValue( 'date' )),
              dow = date.day() === 0 ? 6 : date.day() - 1

        return parseInt( raw ) === parseInt( dow )
    }

    fixCompleteDate = ( newDate ) => {
        setTimeout(() => {
            const date = newDate || this.props.form.getFieldValue( 'date' ),
                  complete = this.props.form.getFieldValue( 'completeDate' ),
                  interval = this.props.form.getFieldValue( 'intervalIncrement' ),
                  intervalType = this.props.form.getFieldValue( 'intervalType' ),
                  minComplete = dayjs( date ).add( interval, intervalType )

            if ( complete.isBefore( minComplete )) {
                this.props.form.setFieldsValue({ completeDate: minComplete })
            }
        }, 0 )
    }

    fields = ( event, repeat ) => {
        const rawdate = dayjs( event.date ),
              date = dayjs( this.props.form.getFieldValue( 'date' )) || dayjs( rawdate ),
              interval =
        this.props.form.getFieldValue( 'intervalIncrement' ) ||
        event.intervalIncrement,
              intervalType =
        this.props.form.getFieldValue( 'intervalType' ) || event.intervalType,
              completeStrategy =
        this.props.form.getFieldValue( 'completeStrategy' ) ||
        event.completeStrategy

        return [
            {
                field: 'taskId',
                type: 'hidden',
                value: event.taskId,
            },
            {
                field: 'title',
                type: repeat ? 'hidden' : 'string',
                value: event.title,
                rules: [ config.rules.required ],
            },
            {
                field: 'date',
                label: 'Дата',
                type: repeat ? 'hidden' : 'date',
                format: 'D MMMM YYYY',
                value: rawdate,
                disabledDate: ( date ) =>
                    date.isBefore(
                        this.props.view === 'week' ? dayjs() : dayjs().startOf( 'day' )
                    ),
                rules: [ config.rules.required ],
                onChange: ( field, value ) => {
                    const lastVal = this.props.form.getFieldValue( 'date' ),
                          lastDow = lastVal.day() === 0 ? 7 : lastVal.day(),
                          dow = value.day() === 0 ? 7 : value.day(),
                          values = this.props.form.getFieldValue( 'weekDays' )

                    values.splice( values.indexOf( lastDow ), 1 )

                    this.props.form.setFieldsValue({ weekDays: [ ...values, dow ] })

                    this.fixCompleteDate()
                },
            },
            {
                field: 'description',
                label: 'Описание',
                type: repeat ? 'hidden' : 'text',
                value: event.description,
            },
            {
                field: 'time',
                type: 'row',
                hidden: repeat,
                items: [
                    {
                        field: 'timeStart',
                        label: 'Начало',
                        type: 'time',
                        minuteStep: 15,
                        disabledHours: this.disabledHours( 'start' ),
                        disabledMinutes: this.disabledMinutes( 'start' ),
                        onChange: ( field, value ) => {
                            this.props.form.setFieldsValue({ [ field ]: value })
                        },
                        value: event.date ? dayjs( event.date ) : this.getStart(),
                        span: 12,
                        rules: [ config.rules.required ],
                    },
                    {
                        field: 'timeEnd',
                        label: 'Завершение',
                        type: 'time',
                        minuteStep: 15,
                        disabledHours: this.disabledHours( 'end' ),
                        disabledMinutes: this.disabledMinutes( 'end' ),
                        onChange: ( field, value ) => {
                            this.props.form.setFieldsValue({ [ field ]: value })
                        },
                        value: event.end ?
                            event.end
                            : event.date
                                ? dayjs( event.date ).add( 1, 'hour' )
                                : this.getStart().add( 1, 'hour' ),
                        span: 12,
                        rules: [
                            config.rules.required,
                            {
                                validator: ( rule, val, cb ) => {
                                    if (
                                        !this.props.form.getFieldValue( 'timeStart' ).isBefore( val )
                                    ) {
                                        cb( 'Alarma' )
                                    }

                                    cb()
                                },
                                message: 'Время окончания должно быть позже',
                            },
                        ],
                    },
                ],
            },
            {
                field: 'repeatRow',
                type: 'row',
                hidden: repeat || ( event.taskId && !event.repeat ),
                items: [
                    {
                        field: 'repeatLink',
                        label: <span className="time-icon">Повторяющаяся задача</span>,
                        type: 'link',
                        handler: () =>
                            this.setState({
                                repeat: true,
                                hasRepeat: true,
                            }),
                        span: 14,
                    },
                    {
                        field: 'repeatInfo',
                        type: 'info',
                        span: 10,
                        render: () =>
                            this.props.event && this.state.hasRepeat
                                ? this.getRepeatInfo()
                                : '',
                    },
                ],
            },
            {
                field: 'single',
                type: 'checkbox',
                hidden: !event.taskId || repeat || ( event.taskId && !event.repeat ),
                label: 'Применить также ко всем последующим задачам',
            },
            {
                field: 'intervalRow',
                type: 'row',
                hidden: !repeat,
                items: [
                    {
                        field: 'intervalIncrement',
                        type: 'number',
                        label: 'Интервал',
                        value: event.intervalIncrement || 1,
                        min: 1,
                        span: 6,
                        onChange: () => this.fixCompleteDate(),
                    },
                    {
                        field: 'intervalType',
                        type: 'select',
                        value: event.intervalType || 'week',
                        className: 'fake-label',
                        byKey: true,
                        span: 8,
                        options: {
                            day: format.strings.ending( interval, [ 'день', 'дня', 'дней' ]),
                            week: format.strings.ending( interval, [
                                'неделя',
                                'недели',
                                'недель',
                            ]),
                            month: format.strings.ending( interval, [
                                'месяц',
                                'месяца',
                                'месяцев',
                            ]),
                            year: format.strings.ending( interval, [ 'год', 'года', 'лет' ]),
                        },
                        onChange: () => this.fixCompleteDate(),
                    },
                ],
            },
            {
                field: 'weekDays',
                type: 'checkgroup',
                value: event.weekDays || [ date.day() === 0 ? 7 : date.day() ],
                hidden: () => !repeat || intervalType !== 'week',
                options: [ 1, 2, 3, 4, 5, 6, 7 ].map(( dow ) => ({
                    value: dow,
                    label: dayjs().day( dow ).format( 'ddd' ),
                    disabled: this.isCurrentDow( dow - 1 ),
                })),
            },
            {
                field: 'monthDays',
                type: 'select',
                value: event.monthDays || 'everyDate',
                byKey: true,
                hidden: () => !repeat || intervalType !== 'month',
                options: {
                    everyDate: date.format( 'D' ) + ' число каждого месяца',
                    everyWeekday: this.getEveryWeekday({ date }),
                },
            },
            {
                field: 'completeStrategy',
                label: 'Завершение',
                type: 'radio',
                value: event.completeStrategy || 'date',
                hidden: !repeat,
                options: [
                    {
                        value: 'date',
                        label: 'по дате',
                    },
                    {
                        value: 'count',
                        label: 'по числу повторов',
                    },
                    {
                        value: 'none',
                        label: 'не завершать',
                    },
                ],
            },
            {
                field: 'completeDate',
                type: 'date',
                format: 'D MMMM YYYY',
                className: 'narrow',
                disabledDate: ( d ) =>
                    d.isBefore( dayjs()) ||
          d.isBefore(
              dayjs( date ).add(
                  this.props.form.getFieldValue( 'intervalIncrement' ),
                  this.props.form.getFieldValue( 'intervalType' )
              )
          ),
                value: event.completeDate
                    ? dayjs( event.completeDate )
                    : intervalType === 'year'
                        ? dayjs( date ).add( 5, 'year' )
                        : dayjs( date ).add( 1, 'year' ),
                hidden: () => !repeat || completeStrategy !== 'date',
            },
            {
                field: 'completeCount',
                type: 'number',
                min: 2,
                value: event.completeCount || 10,
                className: 'narrow',
                hidden: () => !repeat || completeStrategy !== 'count',
            },
            {
                field: 'actions',
                type: 'row',
                items: this.props.deleteBtn
                    ? [
                            {
                                field: 'submit',
                                text: repeat ? 'Готово' : this.props.okBtnText || 'Добавить',
                                type: 'button',
                                style: 'primary',
                                span: 9,
                                handler: repeat ? this.saveRepeat : this.preSubmit,
                            },
                            {
                                field: 'cancel',
                                text: 'Отменить',
                                type: 'button',
                                style: 'ghost',
                                span: 8,
                                handler: repeat ? this.cancelRepeat : this.cancel,
                            },
                            {
                                field: 'remove',
                                text: 'Удалить',
                                type: 'button',
                                style: 'remove',
                                span: 7,
                                hidden: repeat,
                                handler: this.remove,
                            },
                        ]
                    : [
                            {
                                field: 'submit',
                                text: repeat ? 'Готово' : this.props.okBtnText || 'Добавить',
                                type: 'button',
                                style: 'primary',
                                span: 10,
                                handler: repeat ? this.saveRepeat : this.preSubmit,
                            },
                            {
                                field: 'cancel',
                                text: 'Отменить',
                                type: 'button',
                                style: 'ghost',
                                span: 10,
                                handler: repeat ? this.cancelRepeat : this.cancel,
                            },
                        ],
            },
        ]
    }

    cancel = () => {
        setTimeout(() => {
            this.setState({ repeat: false, hasRepeat: false })
            this.props.form.resetFields()
        }, 300 )

        this.props.onCancel()
    }

    remove = () => {
        this.props.form.resetFields()
        this.props.onRemove( this.props.event )
    }

    row = ( cfg ) => {
        const res = []

        let hidden = false

        if ( cfg.hide ) {
            if ( typeof cfg.hide === 'function' ) {
                if ( cfg.hide()) {
                    return null
                }
            } else {
                return null
            }
        }

        if ( cfg.hidden ) {
            hidden = typeof cfg.hidden === 'function' ? cfg.hidden() : cfg.hidden
        }

        cfg.items.map(( item ) =>
            res.push(
                <Col span={item.span} key={item.field}>
                    {this.field( item )}
                </Col>
            )
        )

        return (
            <Row
                gutter={24}
                key={cfg.field}
                className={hidden ? `${cfg.field} fi-hidden` : cfg.field}
            >
                {res}
            </Row>
        )
    }

    field = ( cfg ) => {
        if ( cfg.type === 'row' ) {
            return this.row( cfg )
        }

        return <FormField {...cfg} key={cfg.field} form={this.props.form} />
    }

    preSubmit = () => {
        this.props.form.validateFieldsAndScroll(( err, values ) => {
            if ( !err ) {
                this.props.onSubmit({
                    ...values,
                    repeat: this.state.hasRepeat,
                })
                setTimeout(() => {
                    this.props.form.resetFields()
                    this.setState({
                        repeat: false,
                        hasRepeat: false,
                    })
                }, 300 )
            }
        })
    }

    render () {
        const { event, title } = this.props,
              { repeat } = this.state

        return (
            <Form
                {...config.ui.wideForm}
            >
                <h3>{repeat ? 'Повтор задачи' : title || 'Новая задача'}</h3>
                {this.fields( event || {}, repeat ).map( this.field )}
            </Form>
        )
    }
}

export default Form.create({ name: 'AddEventForm' })( AddEventForm )
