/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'

/* APPLICATION */
import { CompareTable, Spinner } from 'components'

class TimeSummary extends Component {
    static propTypes = {
        type: PropTypes.string,
        data: PropTypes.object,
    }

    constructor ( props ) {
        super( props )
        this.state = { data: [], }
    }

    componentDidMount () {
        this.setData( this.props.data )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        this.props.data !== nextProps.data && this.setData( nextProps.data, nextProps.type )
    }

    setData = ( data, type ) => {
        this.setState({ data: this.getTimeData( data, type ) })
    }

    getTimeData = ( data, type ) => {
        if ( !data ) { return [] }

        switch ( type ) {
            case 'fusion':
                return this.fusionData( data )
            case 'auto':
                return this.autoData( data )
            case 'hybrid':
            default:
                return this.hybridData( data )
        }
    }

    rowData = ( plan, fact, title, hit ) => ({
        title: title,
        type: 'relation',
        invert: true,
        plan: plan,
        fact: fact,
        hit: hit,
    })

    row = ( data, key, title ) => {
        if ( !data[ key ]) { return this.rowData( null, null, title ) }
        return this.rowData(
            data[ key ].plan,
            data[ key ].fact,
            title,
            data[ key ].hitPercentage
        )
    }

    extract = ( data, rows ) =>
        Object.keys( rows ).map(( key ) => this.row( data, key, rows[ key ]))

    fusionData = ( data ) => {
        if ( !data ) { return [] }

        return this.extract( data, {
            order: 'Приём заказа (без киосков)',
            payment: 'Оплата (без киосков)',
            handling: 'Сбор и выдача',
            assembly: 'Сбор',
            giveaway: 'Выдача',
        })
    }

    hybridData = ( data ) => {
        if ( !data ) { return [] }

        return this.extract( data, {
            totalKiosk: 'Общее время (киоск)',
            assemblyKiosk: 'Сбор (киоск)',
            giveawayKiosk: 'Выдача (киоск)',
            handling: 'Общее время (касса)',
            payment: 'Прием/Оплата (Касса)',
        })
    }

    autoData = ( data ) => {
        if ( !data ) { return [] }

        return this.extract( data, {
            total: 'Общее время',
            order: 'Приём заказа',
            payment: 'Оплата',
            handling: 'Сбор/выдача',
        })
    }

    render () {
        const { data } = this.state

        if ( !this.props.data ) { return <Spinner /> }

        return (
            <CompareTable noTitle useHit format="seconds" key="top" rows={data} />
        )
    }
}

export default TimeSummary
