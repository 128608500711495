import React, { useEffect }    from 'react'
import { PprTable }            from 'components'
import { Form, Input, Select } from 'antd'
import dayjs                   from 'dayjs'
import config                  from 'config'
import { format }              from 'tools'
import './ppr-area-info.scss'

const formatAPI = config.format.monthAPI

const { Option } = Select

const columns = {
    period: 'Месяц',
    region: 'Регион',
    acSurnameName: 'AC',
}

const columnsCard = {
    acPositionStatus: {
        name: 'Статус',
        type: 'select',
        placeholder: 'Выберите статус'
    },
    hrbpSurnameName: {
        name: 'HR BP',
        type: 'input',
        placeholder: 'Введите ФИО',
        maxLength: 128
    },
    hrtmSurnameName: {
        name: 'HRM',
        type: 'input',
        placeholder: 'Введите ФИО',
        maxLength: 128
    }
}

const PprRestaurantData = ({ acStatuses, form, card, data, isEditable }) => {
    useEffect(() => {
        form.setFieldsValue({ acPositionStatus: card?.acPositionStatus?.id })
        form.setFieldsValue({ hrbpSurnameName: card?.hrbpSurnameName })
        form.setFieldsValue({ hrtmSurnameName: card?.hrtmSurnameName })
        //TODO: установить форму на появление data
    }, [ card ])

    const td = ( key, isCard ) => {
        if ( isCard && isEditable ) {
            switch ( columnsCard[ key ].type ) {
                case 'select': {
                    return select( key )
                }
                default: {
                    return input( key )
                }
            }
        }

        if ( isCard && !isEditable ) {
            return <td>{card?.[ key ]?.name || card?.[ key ] || config.ui.nullSymbol}</td>
        }

        if ( key === 'period' ) {
            return <td>{data?.[ key ] ?
                format.strings.capitalize( dayjs( data?.[ key ], formatAPI ).format( 'MMMM' ))
                :
                config.ui.nullSymbol
            }</td>
        }

        return <td>{ data?.[ key ] || config.ui.nullSymbol}</td>
    }

    const select = ( key ) => <Form.Item>
        {form.getFieldDecorator(
            key,
            {
                rules: [ { required: true, message: columnsCard[ key ].placeholder } ],
                initialValue: card?.[ key ]?.id
            }
        )( <Select
            loading={!acStatuses}
        >
            {acStatuses?.map( item => <Option key={item.id} value={item.id}>{item.name}</Option> )}
        </Select> )}
    </Form.Item>

    const input = ( key ) => <Form.Item>
        {form.getFieldDecorator(
            key,
            {
                rules: [ { required: true, message: columnsCard[ key ].placeholder } ],
                initialValue: card?.[ key ]
            }
        )(
            <Input
                maxLength={columnsCard[ key ].maxLength}
            />
        )}
    </Form.Item>

    return (
        <PprTable
            horizontal
            className='ppr-area-info'
        >
            <tbody>
                {Object.keys( columns ).map(( key ) => <tr key={key}>
                    <th>{columns[ key ]}</th>
                    {td( key )}
                </tr> )}
                {Object.keys( columnsCard ).map(( key ) => <tr key={key}>
                    <th>{columnsCard[ key ].name}</th>
                    {td( key, true )}
                </tr> )}
            </tbody>
        </PprTable>

    )
}

export default PprRestaurantData
