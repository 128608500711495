/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import dayjs                from 'dayjs'

/* APPLICATION */
import { ReportTable } from 'components'
import { format }      from 'tools'
import config          from 'config'

import './task-table.scss'

class TaskTable extends Component {
    static propTypes = {
        group: PropTypes.object,
        hideDate: PropTypes.bool,
        timeKey: PropTypes.string,

        onDetails: PropTypes.func,
    }

    time = ( task, previous ) => {
        const { hideDate, timeKey } = this.props,
              key =
        timeKey && task[ timeKey ]
            ? timeKey
            : task.finishTime
                ? 'finishTime'
                : 'dueTime',
              f =
        hideDate ||
        ( previous && dayjs( previous[ key ]).isSame( dayjs( task[ key ]), 'day' ))
            ? 'HH:mm'
            : 'HH:mm, D MMM YYYY'

        return dayjs( task[ key ]).format( f )
    }

    content = ( task ) => (
        <div className="task-content">
            <p>{task.taskName}</p>
            <small>{task.description}</small>
            <small>{task.photoRequired ? 'Сфотографировать результат' : ''}</small>
        </div>
    )

    prepare = () => {
        const { group } = this.props,
              tasks = group.tasks,
              res = []

        let previous = dayjs()

        tasks.forEach(( task ) => {
            res.push({
                id: task.id,
                time: this.time( task, previous ),
                content: this.content( task ),
            })
        })

        return format.generate.noPager( res )
    }

    render () {
        const { onDetails } = this.props

        return (
            <div className="report-table task-table">
                <ReportTable
                    rowKey="id"
                    hideHeader={true}
                    data={this.prepare()}
                    columns={config.tables.tasks.group}
                    onCellClick={onDetails}
                />
            </div>
        )
    }
}

export default TaskTable
