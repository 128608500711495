/* IMPORT */

import { businessActions, restaurantActions, staffActions } from 'services'

/* EXPORT */

export const mapStateToProps = ( state ) => ({
    request: state.request,
    tasks: state.tasks.list,
    restaurants: state.restaurants.admin,
    restData: state.restaurants.data,
    groups: state.tasks.groups,
    staff: state.staff.search,
})

export const allActions = {
    ...businessActions,
    ...restaurantActions,
    ...staffActions,
}
