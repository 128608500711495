/* VENDOR */
import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { Link }             from 'react-router-dom'
import { Layout }           from 'antd'

/* APPLICATION */

import { DashboardCard, TimeSummary, ViewTypeSwitch } from 'components'

import { timeActions, requestActions } from 'services'
import config                          from 'config'
import { format }                      from 'tools'

const { Content } = Layout

let tabCache = {}

class TimeCard extends Component {
    constructor ( props ) {
        super( props )
        this.state = { tabs: {}, }
    }

    componentDidMount () {
        this.props.request.serviceType &&
      this.props.restaurants &&
      this.load( this.props )
        this.tabs( this.props )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { request, restaurants } = this.props
        if ( nextProps.summary !== this.props.summary || ( nextProps.summary && !this.props.summary )) {
            tabCache[ nextProps.request.serviceType ] = nextProps.summary
        }
        request.restaurantId !== nextProps.request.restaurantId &&
      this.tabs( nextProps )
        restaurants !== nextProps.restaurants && this.tabs( nextProps )

        if ( request.restaurantId !== nextProps.request.restaurantId ) {
            tabCache = {}
        }

        if (
            format.check.request( request, nextProps.request, [ 'unitOfMeasure', 'restaurantId' ], [ 'serviceType' ])
            || restaurants !== nextProps.restaurants
        ) {
            if (
                format.check.request( request, nextProps.request, [ 'unitOfMeasure', 'restaurantId' ])
                || ( restaurants !== nextProps.restaurants )
            ) {
                tabCache = {}
                this.props.flushServiceTime()
                this.load( nextProps )
            } else if ( !tabCache[ nextProps.request.serviceType ]) {
                this.props.flushServiceTime()
                this.load( nextProps )
            }
        }
    }

    load = ( props ) => {
        if ( !props.request.serviceType || !props.restaurants ) { return }
        props.fetchServiceTimeSummary( props.request )
    }

    tabs = ( props ) => {
        const { restaurants, request } = props

        if ( !restaurants ) { return }

        const rest = restaurants.find(
            ( r ) => r.factsNumber === parseInt( request.restaurantId )
        )

        if ( !rest ) { return }

        const available = Object.keys( rest.params ).filter(
            ( key ) => rest.params[ key ]
        ),
              tabs = {}

        available.forEach(( key ) => {
            if ( config.tabs.time[ key ]) {
                tabs[ key ] = config.tabs.time[ key ]
            }
        })

        this.setState({ tabs })
        this.change( available[ 0 ])
    }

    change = ( tab ) => this.props.setServiceType( tab )

    render () {
        const { request } = this.props,
              { tabs } = this.state

        return (
            <DashboardCard spans={[ 12, 7 ]}>
                <Layout>
                    <Content>
                        <div className="card-controls">
                            <h2>Время обслуживания</h2>
                            <div className="control-links">
                                <Link to="/dashboard/time">Отчёт</Link>
                            </div>
                        </div>

                        <ViewTypeSwitch
                            update={this.change}
                            tabs={tabs}
                            current={request.serviceType}
                        />

                        <TimeSummary type={request.serviceType} data={tabCache[ request.serviceType ]} />
                    </Content>
                </Layout>
            </DashboardCard>
        )
    }
}

const mapStateToProps = ( state ) => ({
    time: state.time,
    summary: state.time.summary,
    request: state.request,
    restaurants: state.restaurants.list,
}),

      allActions = {
          ...requestActions,
          ...timeActions,
      }

export default connect( mapStateToProps, allActions )( TimeCard )
