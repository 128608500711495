/* IMPORT */

import {
    performanceActions,
    ratingActions,
    requestActions,
    timeActions,
    turnoverActions,
    settingsActions,
} from 'services'
import { IState } from '../../store'

/* EXPORT */

export const allActions = {
    ...performanceActions,
    ...ratingActions,
    ...requestActions,
    ...timeActions,
    ...turnoverActions,
    ...settingsActions,
}

export const mapStateToProps = ( state: IState ) => ({
    workHours: state.settings.workHours,
    turnover: state.turnover,
    performance: state.performance,
    rating: state.rating,
    request: state.request,
    def: state.request.currentPredef,
    restaurants: state.restaurants.list,
    currentRestaurant: state.restaurants.current,
})

export type DashboardStoreProps = ReturnType<typeof mapStateToProps> & typeof allActions
