/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Crosshair }        from 'react-vis'

/* APPLICATION */

class ChartCross extends Component {
    static propTypes = {
        value: PropTypes.object,
        time: PropTypes.number,
        hide: PropTypes.bool,
    }

    value = () => ( this.props.time ? [ { x: this.props.time, y: 0 } ] : [])

    label = () => {
        if ( !this.props.value ) { return '' }

        let label = this.props.value.label

        label.substr( -2 ) === '00' && ( label = 'тек.' )

        return label
    }

    render () {
        if ( this.props.hide || !this.props.value ) { return null }

        return (
            <Crosshair {...this.props} values={this.value()}>
                <div className="cross-title">{this.label()}</div>
            </Crosshair>
        )
    }
}

export default ChartCross
