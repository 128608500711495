import { IState }         from 'store'
import { requestActions } from 'services'

export const mapStateToProps = ( state: IState ) => ({
    request: state.request,
    ui: state.ui,
})

export const allActions = { ...requestActions }

export type DateDropDropDownStoreProps = ReturnType<typeof mapStateToProps> & typeof allActions
