import dayjs from 'dayjs'

import { format } from 'tools'
import config     from 'config'

import * as get  from './get'
import * as find from './find'

export const time = ( shift, key, time, date, res ) => {
    const tstart =
      key === 'shiftStart' ? time : format.extract.time( shift.shiftStart ),
          tend = key === 'shiftEnd' ? time : format.extract.time( shift.shiftEnd ),
          start = format.extract.intTime( tstart ),
          end = format.extract.intTime( tend ),
          sh = format.extract.hours( tstart ),
          sm = format.extract.minutes( tstart ),
          eh = format.extract.hours( tend ),
          em = format.extract.minutes( tend ),
          original = format.copy.object( shift )

    if ( start >= end && ( end > 0 || ( end === 0 && em === 0 ))) {
        if ( shift._double ) {
            shift.shiftStart = dayjs( date )
                .subtract( 1, 'day' )
                .set( 'hour', sh ).set( 'minutes', sm )
            shift.shiftEnd = dayjs( date ).set( 'hour', eh ).set( 'minutes', em )
            changeOriginalTwin( shift, date, res )
        } else {
            shift.shiftStart = dayjs( date ).set( 'hour', sh ).set( 'minutes', sm )
            shift.shiftEnd = dayjs( date )
                .add( 1, 'day' )
                .set( 'hour', eh ).set( 'minutes', em )
            changeTwin( shift, date, res )
        }
    } else {
        shift.shiftStart = dayjs( date ).set( 'hour', sh ).set( 'minutes', sm )
        shift.shiftEnd = dayjs( date ).set( 'hour', eh ).set( 'minutes', em )

        if ( shift._double ) {
            const twin = format.copy.object( shift )

            twin.shiftStart = dayjs( date )
                .subtract( 1, 'day' )
                .set( 'hour', sh ).set( 'minutes', sm )
            twin.shiftEnd = dayjs( date )
                .subtract( 1, 'day' )
                .set( 'hour', eh ).set( 'minutes', em )

            changeOriginalTwin( twin, date, res )
        }

        removeTwins( original, date, res )
    }

    shift.shiftStart = shift.shiftStart.format( config.format.dateFull )
    shift.shiftEnd = shift.shiftEnd.format( config.format.dateFull )
}

const getTwin = ( res, date, shift, offset ) => {
    const day = res.days.find(( d ) => dayjs( d.workStart ).isSame( date, 'day' )),
          another = day
              ? res.days.find(( d ) => d.dayOfWeek === day.dayOfWeek + offset )
              : null

    if ( !another ) { return {} }

    const position = another.positions.find(
        ( p ) => p.positionGuid === shift.positionGuid
    )

    let found = position
        ? position.shifts.find(( s ) =>
            s._temp_id
                ? s._temp_id === shift._temp_id
                : s.workScheduleId === shift.workScheduleId
        )
        : null

    if ( !found ) {
        if ( position ) {
            found = format.copy.object( shift )
            found._double = true

            position.shifts.push( found )
        }
    }

    return { position, found, day: another }
},

      changeOriginalTwin = ( double, date, res ) => {
          const { position, found } = getTwin( res, date, double, -1 )

          if ( !found ) { return }

          if ( found ) {
              found.shiftStart = double.shiftStart.format( config.format.dateFull )
              found.shiftEnd = double.shiftEnd.format( config.format.dateFull )
          } else {
              if ( !position ) { return }

              const twin = format.copy.object( double )

              twin.shiftStart = double.shiftStart.format( config.format.dateFull )
              twin.shiftEnd = double.shiftEnd.format( config.format.dateFull )

              double._double = true
              twin._double = false
              position.shifts.push( twin )
          }
      },

      changeTwin = ( original, date, res ) => {
          const { position, found } = getTwin( res, date, original, 1 )

          if ( found ) {
              found.shiftStart = original.shiftStart.format( config.format.dateFull )
              found.shiftEnd = original.shiftEnd.format( config.format.dateFull )
          } else {
              if ( !position ) { return }

              const twin = format.copy.object( original )

              twin.shiftStart = original.shiftStart.format( config.format.dateFull )
              twin.shiftEnd = original.shiftEnd.format( config.format.dateFull )

              original._double = false
              twin._double = true
              position.shifts.push( twin )
          }
      }
export const removeTwins = ( current, date, res ) => {
    const offset = dayjs( current.shiftStart ).isSame( date, 'day' ) ? 1 : -1,
          { position, found } = getTwin( res, date, current, offset ),
          index = found ? position.shifts.indexOf( found ) : -1

    index > -1 && position.shifts.splice( index, 1 )
}

export const position = ( positions, day, pos, shift, id, date, res ) => {
    const tail = getTwin( res, date, shift, 1 ),
          target = get.position( id, day, positions ),
          tailTarget =
      tail.day && tail.position ? get.position( id, tail.day, positions ) : null

    changePos( day, pos, shift, target )
    tailTarget && changePos( tail.day, tail.position, tail.found, tailTarget )
}

const changePos = ( day, pos, shift, target ) => {
    pos.shifts.splice( pos.shifts.indexOf( shift ), 1 )
    pos.shifts.length < 1 && day.positions.splice( day.positions.indexOf( pos ), 1 )

    shift.positionGuid = target.positionGuid
    shift.positionGuid !== config.mcGuid && ( shift.shiftSupervisor = false )

    target.shifts.push( shift )
}

export const employee = ( employees, shift, id, date, res ) => {
    const tail = getTwin( res, date, shift, 1 ),
          name = find.employee( id, employees ).name

    shift.employeeId = id
    shift.employeeName = name

    if ( tail.found ) {
        tail.found.employeeId = id
        tail.found.employeeName = name
    }
}
