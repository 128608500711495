/* VENDOR */
import axios from 'axios'

/* APPLICATION */
import { format } from 'tools'
import config     from 'config'

const service = 'business',
      timeout = 300000

export const getObjectives = ( params ) =>
    format.generate.get( service, 'statistics/managers/shift-objectives', params )
export const getTasksReport = ( params ) =>
    format.generate.get( service, 'statistics/managers', params )
export const getTasksSummary = ( params ) =>
    format.generate.get( service, 'statistics/summary', params )
export const getTask = ( id ) =>
    format.generate.get( service, 'client/task/' + id, [])
export const getTaskDeviations = ( id ) =>
    format.generate.get( service, `client/task/action-plan/${id}/deviations`, [])
export const getAdminTasks = ( params ) =>
    format.generate.get( service, 'admin/tasks', params )
export const getGroupsList = ( search ) =>
    format.generate.get( service, 'admin/task-groups', { search })
export const getFullReport = ( params ) =>
    format.generate.get( service, 'statistics/restaurant/siv-results', params )

export const getConsolidatedPeriod = ( params ) =>
    format.generate.get(
        service,
        'statistics/consolidated/period',
        params,
        null,
        null,
        'consolidated'
    )

export const getConsolidatedDate = ( params ) =>
    format.generate.get(
        service,
        'statistics/consolidated/date',
        params,
        null,
        null,
        'consolidated'
    )

export const getManagerTasks = ( params ) =>
    format.generate.get(
        service,
        'statistics/tasks/period/' + params.userId,
        params,
        '',
        [ 'restaurantId', 'dateStart', 'dateEnd' ],
        'tasks-manager'
    )

export const getManagerShift = ( params ) =>
    format.generate.get(
        service,
        'statistics/tasks/date/' + params.userId,
        params,
        '',
        [ 'restaurantId', 'date' ],
        'tasks-manager'
    )

/* MANIPULATIONS */

export const createTask = ( body ) =>
    format.generate.post( 'business', 'client/task/create', body, '', '', '', '', { timeout })

export const updateTask = ( body ) =>
    format.generate.post( 'business', 'client/task/update', body, '', '', '', '', { timeout })

export const removeTask = ( taskId ) =>
    axios.delete(
        config.api.business + 'client/task/delete/' + taskId,
        config.api.getOptions()
    )

export const updateAdminTask = ( body ) =>
    axios.post(
        config.api.business + 'admin/tasks/' + body.id,
        body,
        config.api.getOptions()
    )
