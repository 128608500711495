/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Select }           from 'antd'
import dayjs                from 'dayjs'

/* APPLICATION */
import { CalendarSummary, Spinner, UserAvatar } from 'components'

import './sub-aside.scss'

const { Option } = Select

class SubAside extends Component {
    static propTypes = {
        current: PropTypes.number,

        restaurants: PropTypes.array,
        user: PropTypes.object,
        summary: PropTypes.object,

        onSelect: PropTypes.func,
    }

    constructor ( props ) {
        super( props )
        this.state = {}
    }

    componentDidMount () {}

    options = ( rests ) =>
        rests.map(( rest ) => (
            <Option value={rest.factsNumber} key={rest.factsNumber}>
                {rest.restaurantName}
            </Option>
        ))

    render () {
        const { summary, user, current, restaurants, onSelect, date } = this.props,
              future = dayjs().endOf( 'month' ).isBefore( date )

        if ( !user || !restaurants ) { return <Spinner /> }

        return (
            <div className="calendar-events-list">
                <header>
                    <Select
                        value={current}
                        onChange={onSelect}
                        style={{ width: '100%' }}
                        getPopupContainer={( trigger ) => trigger.parentNode}
                    >
                        {this.options( restaurants )}
                    </Select>
                    {user.employeeName && <div className="sub-user-info">
                        <UserAvatar name={user.employeeName}/>
                        <div className="sub-user-data">
                            <h3>{user.employeeName}</h3>
                            <span>
                                {!future &&
                                  user.datedTasks + ' из ' + user.totalTasks + ' запланировано'}
                            </span>
                        </div>
                    </div>}
                </header>
                <footer>
                    <CalendarSummary data={summary} />
                </footer>
            </div>
        )
    }
}

export default SubAside
