/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { connect }          from 'react-redux'
import { Scrollbars }       from 'react-custom-scrollbars'
import dayjs                from 'dayjs'

/* APPLICATION */
import { ReportTable, AddButton, Spinner } from 'components'
import { staffActions, settingsActions }   from 'services'
import { format }                          from 'tools'
import config                              from 'config'

import './staff-opp.scss'
import { Alert } from 'antd'

const defDay = 1

class StaffOpportunities extends Component {
    static propTypes = {
        data: PropTypes.array,
        outstaff: PropTypes.bool,
        draft: PropTypes.bool,
    }

    constructor ( props ) {
        super( props )
        this.state = {
            data: null,
            positionsList: [],
        }
    }

    componentDidMount () {
        this.props.fetchPositions( this.props.request )

        setTimeout(
            () =>
                this.setState({ data: this.prepare( this.props.data ), }),
            0
        )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        if ( this.props.data !== nextProps.data ) {
            this.setData( nextProps.data )
        }
        if ( this.props.request !== nextProps.request ) {
            this.props.fetchPositions( nextProps.request )
        }
    }

    componentDidUpdate ( prevProps, prevState ) {
        const { data } = this.state,
              { positions, outstaff } = this.props

        if (
            positions &&
      positions.length > 0 &&
      positions !== prevProps.positions
        ) {
            this.setPositions( positions )
        }

        if ( outstaff !== prevProps.outstaff ) {
            this.setPositions( positions )
        }

        if ( data && prevState.data && data !== prevState.data ) {
            if ( data.length > prevState.data.length ) {
                const found = data.find(
                    ( item ) =>
                        !prevState.data.find(( s ) => s.opportunityId === item.opportunityId )
                )

                if ( found ) {
                    const index = data.indexOf( found ),
                          height = this._table
                              ? this._table._container.offsetHeight / ( data.length + 1 )
                              : 0

                    this.scrolls.scrollTop( index * height )
                }
            }
        }
    }

    setPositions = ( poss ) =>
        this.setState({ positionsList: this.positions( poss ), })

    positions = ( poss ) => {
        if ( !poss ) { return [] }
        const { outstaff } = this.props,
              newPoss = []

        poss.forEach(( pos ) => {
            if ( !( outstaff && pos.positionGuid === config.mcGuid )) {
                newPoss.push( pos )
            }
        })
        return newPoss
    }

    setData = ( data ) =>
        this.setState({ data: this.prepare( data ), })

    prepare = ( data ) =>
        data
            ? data
                .filter(
                    ( item ) =>
                        data.find(( i ) => i.opportunityId === item.opportunityId ) === item
                )
                .sort( this.sort )
                .map(( item ) => {
                    item.fixed = item.type === 'fixed'
                    return item
                })
            : []

    sort = ( a, b ) => {
        if ( a.dayOfWeek === b.dayOfWeek ) {
            if ( a.type === b.type ) {
                if ( a.timed === b.timed ) { return 0 }
                if ( a.timed ) { return 1 }
                if ( b.timed ) { return -1 }
            }
            if ( a.type === 'fixed' ) { return 1 }
        }
        return a.dayOfWeek - b.dayOfWeek
    }

    update = ( opp ) => {
        const { request } = this.props,
              payload = format.copy.object( opp )

        delete payload.fixed

        this.props.updateOpportunity({
            data: payload,
            params: { employeeId: this.props.request.employeeId, },
        })

        setTimeout(() => this.props.fetchFixedShifts( request ), 200 )
    }

    remove = ( item ) =>
        this.props.removeOpportunity({
            employeeId: this.props.request.employeeId,
            opportunityId: item.opportunityId,
        })

    add = () => {
        const { data } = this.state,
              opp = format.copy.object( config.defs.opportunity ),
            //День без возможностей
              untimed = this.findLack( data.filter(( item ) => !item.timed ))

        opp._key = format.generate.guid()

        if ( untimed ) {
            opp.dayOfWeek = untimed
        } else {
            opp.timed = true

            //День без "временных" записей
            const next = this.findNextTimed( data )

            if ( next ) {
                opp.dayOfWeek = next
            } else {
                //День, где меньше всего "временных" со свдигом даты
                opp.dayOfWeek = this.findTimedLack( data )
                const last = this.findLastTimed( opp.dayOfWeek, data )

                opp.dateStart = dayjs( last.dateEnd, config.format.dayAPI )
                    .add( 1, 'day' )
                    .format( config.format.dayAPI )
                opp.dateEnd = opp.dateStart
            }
        }

        this.timed( opp )
        this.update( opp )
    }

    findLastTimed = ( dow, data ) => {
        const timed = data.filter(( i ) => i.dayOfWeek === dow && i.timed )

        if ( timed.length === 1 ) { return timed[ 0 ] }

        return timed
            .sort(( a, b ) => {
                const sa = dayjs( a.dateStart, config.format.dayAPI ),
                      sb = dayjs( b.dateStart, config.format.dayAPI )

                if ( sa.isBefore( sb )) { return -1 }
                if ( sb.isBefore( sa )) { return 1 }
                return 0
            })
            .pop()
    }

    findNextTimed = ( data ) => {
        const timed = data.filter(( item ) => item.timed )

        if ( timed.length < 1 ) { return defDay }

        return this.findLack( timed )
    }

    findLack = ( data ) => {
        if ( !data || data.length < 1 ) { return defDay }

        for ( let i = 1; i < 8; i++ ) {
            if ( !data.find(( item ) => item.dayOfWeek === i )) {
                return i
            }
        }

        return null
    }

    findTimedLack = ( data ) => {
        const counts = data.map(( item ) => ({
            dayOfWeek: item.dayOfWeek,
            count: data
                .filter(( i ) => i.timed )
                .reduce(( c, i ) => ( i.dayOfWeek === item.dayOfWeek ? c + 1 : c ), 0 ),
        })),
              min = counts.reduce(( m, i ) => ( i.count < m.count ? i : m ), counts[ 0 ])

        return min.dayOfWeek
    }

    change = ( item, key, val ) => {
        const { positionsList } = this.state,
              opp = format.copy.object( item )

        opp[ key ] = val

        if ( key === 'fixed' ) {
            opp.type = val ? 'fixed' : 'opportunity'

            if ( val && ( !opp.positionName || !opp.positionGuid )) {
                if ( !opp.positionName && !opp.positionGuid ) {
                    opp.positionGuid = positionsList[ 0 ].positionGuid
                    opp.positionName = positionsList[ 0 ].position
                } else {
                    const found = positionsList.find(
                        ( p ) =>
                            p.positionGuid === opp.positionGuid ||
              p.position === opp.positionName
                    )

                    if ( found ) {
                        opp.positionGuid = found.positionGuid
                        opp.positionName = found.position
                    } else {
                        opp.positionGuid = positionsList[ 0 ].positionGuid
                        opp.positionName = positionsList[ 0 ].position
                    }
                }
            }
        }

        if ( key === 'positionGuid' ) {
            const found = positionsList.find(( p ) => p.positionGuid === val )

            if ( found ) {
                opp.positionGuid = found.positionGuid
                opp.positionName = found.position
            }
        }

        this.timed( opp )

        format.update.clear( opp )

        this.update( opp )
    }

    timed = ( item ) =>
        item.timed ? format.dates.def( item ) : format.dates.flush( item )

    render () {
        const { data, draft } = this.props,
              { positionsList } = this.state

        return (
            <div className="staff-opp tabbed-container">
                <Scrollbars
                    {...config.ui.scrolls}
                    ref={( node ) => ( this.scrolls = node )}
                >
                    <div className="report-table">
                        {data ? (
                            draft ? (
                                <Alert
                                    message="Заполните и сохраните анкету сотрудника, чтобы добавить его возможности"
                                    type="warning"
                                />
                            ) : (
                                <ReportTable
                                    ref={( node ) => ( this._table = node )}
                                    data={format.generate.noPager( this.state.data )}
                                    columns={config.tables.opportunities}
                                    select={{
                                        positions: format.generate.options(
                                            positionsList,
                                            'positionGuid',
                                            'position'
                                        ),
                                    }}
                                    rowKey={( row ) => row.opportunityId || row._key}
                                    loading={false}
                                    onChange={this.change}
                                    onRemove={this.remove}
                                />
                            )
                        ) : (
                            <Spinner />
                        )}
                    </div>
                </Scrollbars>
                {!draft && (
                    <div className="tabbed-footer">
                        <AddButton disabled={this.props.isOpportunitiesLoading} text="Добавить" action={this.add} />
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = ( state ) => ({
    request: state.request,
    positions: state.settings.positions,
    isOpportunitiesLoading: state.staff.updateOppotunity_loading
}),

      allActions = {
          ...staffActions,
          ...settingsActions,
      }

export default connect( mapStateToProps, allActions )( StaffOpportunities )
