/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Tabs }             from 'antd'

/* APPLICATION */
import './view-type-switch.scss'

const defClass = 'view-type-switch'

class ViewTypeSwitch extends Component {
    static propTypes = {
        tabs: PropTypes.object.isRequired,
        current: PropTypes.string,
        update: PropTypes.func,
        onScroll: PropTypes.func,
    }

    constructor ( props ) {
        super( props )
        this.state = {
            items: [],
            cls: defClass,
        }
    }

    componentDidMount () {
        this.setClass( this.props.className )
        this.setItems( this.props.tabs )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { className, tabs } = this.props

        tabs !== nextProps.tabs && this.setItems( nextProps.tabs )
        className !== nextProps.className && this.setClass( nextProps.className )
    }

    setClass = ( name ) =>
        this.setState({ cls: [ defClass, name ].join( ' ' ).trim(), })

    setItems = ( tabs ) => {
        this.setState({ items: this.items( tabs ) })
    }

    items = ( tabs ) =>
        Object.keys( tabs )
            .filter(( key ) => key[ 0 ] !== '_' )
            .map(( key ) => ({ label: tabs[ key ], key: key }))

    render () {
        const { current, tabs, update, onScroll } = this.props,
              { cls, items } = this.state

        return (
            <div className={cls}>
                <Tabs
                    activeKey={current || tabs._default}
                    onChange={update}
                    animated={false}
                    onTabClick={onScroll}
                    items={items}
                    // onNextClick={onScroll}
                    // onPrevClick={onScroll}
                />
            </div>
        )
    }
}

export default ViewTypeSwitch
