import React, { useState, useEffect } from 'react'
import cn                             from 'classnames'
import './guest-vote-controls.scss'
import { Select }                     from 'antd'
import ruRU                           from 'antd/es/date-picker/locale/ru_RU'
// import ruLocale from 'dayjs/locale/ru';
import dayjs                                               from 'dayjs'
import { FilterParams }                                    from 'pages/GuestVote/types'
import { DaysOfWeek, PickerType, RangePicker, RangeValue } from './types'
import { DaysOfWeekType }                                  from 'services/guestVote/statistic/type'
// import ruRU from 'antd-V5/es/date-picker/locale/ru_RU'

const STORAGE_KEY = 'guestVoteControls'

type GuestVoteControlsProps = {
    className?: string;
    onFilterChange: ( filters: FilterParams ) => void;
};

const GuestVoteControls: React.FC<GuestVoteControlsProps> = ({ className, onFilterChange }) => {
    const currentDate = dayjs()
    const twoYearsAgo = currentDate.clone().subtract( 2, 'years' ).startOf( 'year' )

    const savedState = sessionStorage.getItem( STORAGE_KEY )
    const parsedState = savedState ? JSON.parse( savedState ) : null

    const defaultDateRange: RangeValue<dayjs.Dayjs> = [ currentDate.clone().startOf( 'month' ), currentDate.clone().endOf( 'month' ) ]
    const initialDateRange: RangeValue<dayjs.Dayjs> = parsedState?.dateRange ? parsedState.dateRange.map(( date: string | null ) => date ? dayjs( date ) : null ) as RangeValue<dayjs.Dayjs> : defaultDateRange
    const initialPicker: PickerType = parsedState?.currentPicker || 'month'
    const initialWeekDays: string[] = parsedState?.weekDays || []

    const [ currentPicker, setCurrentPicker ] = useState<PickerType>( initialPicker )
    const [ dateRange, setDateRange ] = useState<RangeValue<dayjs.Dayjs>>( initialDateRange )
    const [ weekDays, setWeekDays ] = useState<string[]>( initialWeekDays )
    const [ showWeekDaysFilter, setShowWeekDaysFilter ] = useState( false )

    const saveStateToStorage = ( picker: PickerType, dates: RangeValue<dayjs.Dayjs>, days: string[]) => {
        const state = {
            currentPicker: picker,
            dateRange: dates,
            weekDays: days
        }
        sessionStorage.setItem( STORAGE_KEY, JSON.stringify( state ))
    }

    /**
     * Обрабатывает изменения в компоненте выбора дат.
     * Обновляет состояние и session storage с новым типом выборщика и диапазоном дат.
     * Вызывает onFilterChange с обновленными параметрами фильтра.
     *
     * @param picker - Тип выборщика (date, month, year)
     * @param dates - Выбранный диапазон дат
     */
    const handlePickerChange = ( picker: PickerType, dates: RangeValue<dayjs.Dayjs> ): void => {
        setCurrentPicker( picker )
        saveStateToStorage( picker, dates, weekDays )


        if ( dates && dates[ 0 ] && dates[ 1 ]) {
            let dateStart = dates[ 0 ]
            let dateEnd = dates[ 1 ]

            if ( picker === 'month' ) {
                dateStart = dateStart.clone().startOf( 'month' )
                if ( dateEnd.isSame( currentDate, 'month' )) {
                    dateEnd = currentDate
                } else {
                    dateEnd = dateEnd.clone().endOf( 'month' )
                }
            } else if ( picker === 'year' ) {
                dateStart = dateStart.clone().startOf( 'year' )
                if ( dateEnd.isSame( currentDate, 'year' )) {
                    dateEnd = currentDate
                } else {
                    dateEnd = dateEnd.clone().endOf( 'year' )
                }
            }
            setDateRange([ dateStart, dateEnd ])

            const daysSelected = dateEnd.diff( dateStart, 'days' ) + 1
            if ( daysSelected < 7 ) {
                setShowWeekDaysFilter( false )
                setWeekDays([])
                onFilterChange({ dateStart, dateEnd, daysOfWeek: [], picker })
            } else {
                setShowWeekDaysFilter( true )
                onFilterChange({ dateStart, dateEnd, daysOfWeek: weekDays as DaysOfWeekType[], picker })
            }
        } else {
            setShowWeekDaysFilter( false )
            setWeekDays([])
            onFilterChange({ dateStart: null, dateEnd: null, daysOfWeek: [], picker: 'date' })
        }
    }


    useEffect(() => {
        handlePickerChange( currentPicker, dateRange )
    }, [])


    /**
     * Обрабатывает изменения в компоненте выбора дней недели.
     * Обновляет состояние и session storage с выбранными днями недели.
     * Вызывает onFilterChange с обновленными параметрами фильтра, если выбран диапазон дат.
     *
     * @param value - Выбранные дни недели в виде массива строк
     */
    const handleSelectChange = ( value: string[]): void => {
        setWeekDays( value )
        saveStateToStorage( currentPicker, dateRange, value )

        if ( dateRange && dateRange[ 0 ] && dateRange[ 1 ]) {
            onFilterChange({ dateStart: dateRange[ 0 ], dateEnd: dateRange[ 1 ], daysOfWeek: value as DaysOfWeekType[], picker: currentPicker })
        }
    }

    return (
        <div className={cn( 'guest-vote-controls', className )}>
            {[ 'date', 'month', 'year' ].map(( type ) => (
                <RangePicker
                    key={type}
                    bordered={false}
                    separator=':'
                    locale={ruRU}
                    allowClear={false}
                    getPopupContainer={( trigger ) => trigger}
                    format={type === 'date' ? 'DD MMM YYYY' : type === 'month' ? 'MMMM YYYY' : 'YYYY'}
                    className={cn( currentPicker === type ? 'active' : 'passive', type )}
                    onChange={( value ) => handlePickerChange( type as PickerType, value )}
                    value={currentPicker === type ? dateRange : undefined}
                    picker={type as 'date' | 'month' | 'year'}
                    placeholder={type === 'date' ? [ 'День', 'День' ] : type === 'month' ? [ 'Месяц', 'Месяц' ] : [ 'Год', 'Год' ]}
                    disabledDate={current => !current || current.isAfter( currentDate.endOf( 'day' )) || current.isBefore( twoYearsAgo )}

                />
            ))}
            {showWeekDaysFilter && (
                <Select
                    style={{ width: 220 }}
                    mode='multiple'
                    maxTagCount={1}
                    allowClear
                    className='guest-vote-controls__select'
                    placeholder='Дни недели'
                    value={weekDays}
                    onChange={handleSelectChange}
                >
                    {Object.entries( DaysOfWeek ).map(([ value, label ]) => (
                        <Select.Option key={value} value={value}>
                            {label}
                        </Select.Option>
                    ))}
                </Select>
            )}
        </div>
    )
}

export default GuestVoteControls
