/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'

/* APPLICATION */
import { CompareTable, Spinner } from 'components'

class PerformanceSummary extends Component {
    static propTypes = {
        showDetails: PropTypes.bool,

        data: PropTypes.object,
        goal: PropTypes.any,
    }

    status = ( plan, fact, value ) => {
        const perc = fact / plan
        if ( value ) {
            return 'black'
        }

        return perc >= 1 && perc <= 1.05 ? 'good' : 'bad'
    }

    goals = () => {
        const { goal } = this.props,
              label = 'Цель на месяц'


        return [
            {
                title: label,
                type: goal ? 'value' : 'spin',
                currency: '',
                value: goal,
                refValue: goal,
            },
        ]
    }

    getData = () => {
        const { data } = this.props

        if ( !data ) { return [] }

        return [
            {
                title: 'SPMH',
                type: 'relation',
                plan: data.planSpmh,
                fact: data.factSpmh,
                status: this.status,
            },
            {
                title: 'TCPH',
                type: 'relation',
                plan: data.planTcph,
                fact: data.factTcph,
                status: this.status,
            },
            {
                title: 'Items per Hour',
                type: 'value',
                value: data.factItemsPerHour,
                status: this.status,
            },
            {
                title: 'Количество блюд',
                type: 'value',
                value: data.allProductsInChecks,
                status: this.status,
            },
            {
                title: 'Товарооборот',
                type: 'relation',
                currency: '₽',
                plan: data.planTurnoverRgm,
                fact: data.factTurnover,
            },
            {
                title: 'Транзакции',
                type: 'relation',
                plan: data.planTransactions,
                fact: data.factTransactions,
            },
            {
                title: 'Отработано часов',
                type: 'relation',
                plan: data.planProductiveUnproductiveHours,
                fact: data.factProductiveUnproductiveHours,
            },
        ]
    }

    render () {
        const { data } = this.props

        if ( !data ) { return <Spinner /> }

        return (
            <React.Fragment>
                <CompareTable
                    key="goals"
                    className="compare-goals"
                    rows={this.goals()}
                />
                <CompareTable key="top" rows={this.getData()} noTitle />
            </React.Fragment>
        )
    }
}

export default PerformanceSummary
