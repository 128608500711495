/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'

import { Spin } from 'antd'
import  Icon    from '@ant-design/icons'

/* APPLICATION */
import './top-star.scss'

export class StarIcon extends Component {
    render () {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="none"
            >
                <path d="M13.9299 5.22558c-.1623-.48443-.5759-.8329-1.08-.90947l-3.19478-.48912L8.2255.779774c-.11393-.243777-.29497-.445362-.5244-.58444C7.49041.067195 7.24694 0 7.00034 0c-.24815 0-.49006.067195-.70076.195334-.22942.139078-.41047.340663-.5244.58444L4.34557 3.82699l-3.19478.48912c-.504112.07657-.917701.42504-1.0800157.90947-.1623142.48443-.0405785 1.01105.3152637 1.37672L2.73803 9.01663l-.55561 3.40667c-.06399.3938.04682.7922.30434 1.0954.25908.3047.63521.4797 1.03319.4797.22787 0 .45417-.0593.6555-.1703l2.82489-1.5627 2.82489 1.5643c.20137.1109.42767.1703.65547.1703.398 0 .7741-.175 1.0332-.4797.2575-.3032.3684-.7017.3044-1.0955l-.5556-3.40661 2.3519-2.41433c.3559-.36566.4761-.89385.3153-1.37828z" />
            </svg>
        )
    }
}

class TopStar extends Component {
    static propTypes = {
        item: PropTypes.object,
        text: PropTypes.string,
        position: PropTypes.string,
        onClick: PropTypes.func,
    }

    constructor ( props ) {
        super( props )

        this.state = { loading: false, top: this.props.item.top }
    }

    componentDidUpdate ( prevProps, prevState ) {
        if ( prevProps.item !== this.props.item ) {
            this.setState({ top: this.props?.item.top })
        }
    }

    toggle = ( e ) => {
        e.stopPropagation()

        this.setState({ loading: true })
        setTimeout(() => {
            this.setState({ loading: false })
        }, 300 )

        this.props.onClick( !this.props?.item.top )
        this.setState({ top: !this.props?.item.top })
    }

    status = () => ( this.state.top ? 'selected' : '' )

    icon = () =>
        this.state.loading ? (
            <Spin size="small" />
        ) : (
            <Icon
                component={StarIcon}
                className={this.status()}
                onClick={this.toggle}
            />
        )

    render () {
        return (
            <span className="top-star" onMouseLeave={this.flush}>
                {this.props.position !== 'right' && this.icon()}
                {this.props.text}
                {this.props.position === 'right' && this.icon()}
            </span>
        )
    }
}

export default TopStar
