import React from 'react'

import ViewTypeDrop     from 'components/layout/ViewTypeDrop/ViewTypeDrop'
import { dropTypesMap } from './const'
import './group-switch.scss'

const GroupSwitch = ({ onChange, value }) => {

    return (
        <div className='dropdown_wrapper'>
            <ViewTypeDrop
                current={value}
                placeholder={value}
                update={onChange}
                items={dropTypesMap}
            />
            <span className='support_text'>Выберите, в каком разрезе отобразить данные</span>
        </div>
    )
}

export default GroupSwitch
