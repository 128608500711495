/* IMPORT */

import { restaurantActions, chartActions } from 'services'

/* EXPORT */

export const mapStateToProps = ( state ) => ({
    types: state.restaurants?.types,
    typeError: state.restaurants?.typeError,
    facilityTypes: state.restaurants?.facilityTypes,
    facilityTypesError: state.restaurants?.facilityTypesError,
    restaurantInfo: state.planning?.restaurantInfo,
    restaurantId: state.request?.restaurantId,
})

export const allActions = { ...restaurantActions, ...chartActions }
