/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Modal }            from 'antd'
import dayjs                from 'dayjs'
import { Form }             from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'

/* APPLICATION */
import { FormField } from 'components'
import { format }    from 'tools'
import config        from 'config'

//import './assign-shift.scss'

class EmployeeTransfer extends Component {
    static propTypes = {
        employees: PropTypes.array,
        restaurants: PropTypes.array,

        onSubmit: PropTypes.func,
        onCancel: PropTypes.func,

        visible: PropTypes.bool,
    }

    cancel = () => {
        this.props.onCancel()
        setTimeout(() => {
            this.props.form.resetFields()
        }, 200 )
    }

    submit = () => {
        this.props.onSubmit( this.props.form.getFieldsValue())
        this.cancel()
    }

    fields = () => [
        {
            field: 'employee',
            label: 'Сотрудник',
            type: 'select',
            options: format.generate.optionsr(
                this.props.employees?.filter(
                    ( u ) => u.salary && u.rateDictionary && !u.transfer
                ),
                'id',
                'name'
            ),
            search: ( search, opt ) =>
                format.find.strInProp( opt.props, 'children', search ),
            rules: [ config.rules.required ],
        },
        {
            field: 'restaurant',
            label: 'Перевести в ресторан',
            type: 'select',
            rules: [ config.rules.required ],
            search: ( search, opt ) =>
                format.find.strInProp( opt.props, 'children', search ),
            empty: 'Ничего не найдено',
            options:
        this.props.restaurants && this.props.restaurants.length > 0
            ? format.generate.options(
                this.props.restaurants,
                'factsNumber',
                'restaurantName'
            )
            : { _none: 'Ничего не найдено', },
        },
        {
            field: 'date',
            label: 'До какого числа (Включительно)',
            type: 'date',
            format: 'D MMM YYYY',
            className: 'narrow',
            hideToday: true,
            disabledDate: ( d ) => d.isBefore( dayjs()),
        },
    ]

    field = ( cfg ) => (
        <FormField
            {...cfg}
            value={cfg.value}
            key={cfg.field}
            form={this.props.form}
        />
    )

    render () {
        const { visible, form } = this.props

        return (
            <Modal
                className="kfc-popup"
                centered={true}
                open={visible}
                title="Временный перевод сотрудника"
                okText="Перевести"
                onOk={this.submit}
                okButtonProps={{
                    disabled:
            !form.getFieldValue( 'employee' ) ||
            !form.getFieldValue( 'restaurant' ) ||
            !form.getFieldValue( 'date' ),
                }}
                cancelText="Отменить"
                onCancel={this.cancel}
            >
                <Form {...config.ui.wideForm}>{this.fields().map( this.field )}</Form>
            </Modal>
        )
    }
}

export default Form.create({ name: 'EmployeeTransfer' })( EmployeeTransfer )
