/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'

/* APPLICATION */
import { Center, CompareTable, Spinner } from 'components'

class RatingSummary extends Component {
    static propTypes = { data: PropTypes.array, }

    getData = () => {
        return this.props.data.map(( item ) => ({
            title: item.name,
            type: 'relation',
            currency: '%',
            plan: item.planValue,
            fact: item.factValue,
        }))
    }

    render () {
        if ( !this.props.data ) {
            return (
                <Center>
                    <Spinner />
                </Center>
            )
        }

        return <CompareTable key="top" noTitle rows={this.getData()} />
    }
}

export default RatingSummary
