/* VENDOR */
import React, { Component }       from 'react'
import PropTypes                  from 'prop-types'
import { Scrollbars }             from 'react-custom-scrollbars'
import { List, Checkbox, Select } from 'antd'
import Icon                       from '@ant-design/icons'

/* APPLICATION */
import { AddButton, Spinner, Icons } from 'components'

import config from 'config'

import './templates-list.scss'

const { Option } = Select

class TemplatesList extends Component {
    static propTypes = {
        activeOnly: PropTypes.bool,

        data: PropTypes.array,
        groups: PropTypes.array,
        current: PropTypes.object,
        selected: PropTypes.string,

        toggleActive: PropTypes.func,
        onSelect: PropTypes.func,
        onAdd: PropTypes.func,
        onGroup: PropTypes.func,
        onFirst: PropTypes.func,
    }

    constructor ( props ) {
        super( props )

        this.state = {
            data: props.data || [],
            drafts: true,
            preventScroll: false,
        }
    }

    componentDidMount () {
        this.props.groups &&
      !this.props.selected &&
      this.props.onGroup( this.getFirst( this.props.groups ))
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { data, groups, selected } = this.props

        nextProps.data !== data && this.filter( nextProps );
        ( nextProps.groups !== groups ) & !nextProps.selected &&
      this.props.onGroup( this.getFirst( nextProps.groups ));
        ( nextProps.groups !== groups || nextProps.selected !== selected ) &&
      this.filter( nextProps )
    }

    getFirst = ( groups ) =>
        groups ? groups.sort( this.sortGroups )[ 0 ].groupName : null

    getFirstItem = ( props ) =>
        props.groups.find(( g ) => g.groupName === props.selected )

    filter = ( props ) => {
        const data = props.data.filter(( i ) => i.groupName === props.selected )

        this.setState({ data })
        this.props.onFirst( data[ 0 ])
    }

    select = ( item ) => {
        return () => {
            this.setState({ preventScroll: true, })
            this.props.onSelect && this.props.onSelect( item.templateId )
        }
    }

    item = ( item ) => {
        const cls = []

        this.props.current &&
      item.templateId === this.props.current.templateId &&
      cls.push( 'current' )
        item.__new && cls.push( 'new' )
        !item.active && cls.push( 'inactive' )

        return (
            <List.Item className={cls.join( ' ' ).trim()} onClick={this.select( item )}>
                {item.__new && <Icon component={Icons.Edit.def} />}
                {item.templateName}
            </List.Item>
        )
    }

    option = ( group ) => (
        <Option value={group.groupName} key={group.groupName}>
            {group.categoryName}
        </Option>
    )

    sortGroups = ( a, b ) =>
        a.categoryName.localeCompare( b.categoryName, [ 'en', 'ru' ])

    groups = () =>
        this.props.groups
            ? [
                    ...this.props.groups
                        .sort( this.sortGroups )
                        .map( this.option ),
                ]
            : []

    render () {
        const { activeOnly, toggleActive, selected, onAdd, onGroup } = this.props,
              { data } = this.state

        return (
            <div className="templates-list">
                <div className="filter-container">
                    <Select
                        showSearch
                        onChange={onGroup}
                        value={selected}
                        byKey={true}
                        placeholder="Выберите группу"
                        notFoundContent="Ничего не найдено"
                        filterOption={( input, option ) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf( input.toLowerCase()) >= 0
                        }
                        getPopupContainer={( trigger ) => trigger.parentNode}
                    >
                        {this.groups()}
                    </Select>
                </div>

                <header className="list-header">
                    <Checkbox onChange={toggleActive} checked={activeOnly}>
            Только активные
                    </Checkbox>
                </header>

                {this.props.data && data ? (
                    <Scrollbars
                        {...config.ui.scrolls}
                        ref={( node ) => ( this.scrolls = node )}
                    >
                        <List
                            size="small"
                            dataSource={data}
                            renderItem={this.item}
                            locale={{ emptyText: 'Ничего не найдено' }}
                        />
                    </Scrollbars>
                ) : (
                    <Spinner />
                )}

                <div className="list-footer">
                    <AddButton text="Добавить шаблон" action={onAdd} />
                </div>
            </div>
        )
    }
}

export default TemplatesList
