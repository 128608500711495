/* VENDOR */
import axios from 'axios'

/* APPLICATION */
import { format } from 'tools'
import config     from 'config'

const service = 'dashboard'

export const getPerformanceSummary = ( params ) =>
    format.generate.get( service, 'performance/', params )

export const searchPerformance = ( body ) =>
    format.generate.post( service, 'performance/search', body )

export const getPerformancePlanAndFact = ( params ) =>
    format.generate.get( service, 'performance/plan-fact', params )

export const getPerformance = ( params ) =>
    format.generate.get( service, 'performance/report/', params )
export const getSPMHGoal = ( params ) =>
    format.generate.get( service, 'performance/spmh-goal/', params )

export const setSPMHGoal = ( body ) =>
    axios.post( config.api[ service ] + 'performance/spmh-goal', body, config.api.getOptions())
