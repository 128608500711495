/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import Icon                 from '@ant-design/icons'
import dayjs                from 'dayjs'

/* APPLICATION */
import {
    ReportsControls,
    SIVBlock,
    Spinner,
    TaskGroup,
    Icons,
    CreateActionPlan,
} from 'components'
import { format } from 'tools'
import config     from 'config'

import './tasks-full-report.scss'

const titles = {
    todo: 'Текущие задачи',
    ready: 'Ожидает проверки',
    confirmed: 'Подтверждено',
    declined: 'Отклонено',
}

class TasksFullReport extends Component {
    static propTypes = {
        restaurantId: PropTypes.string,

        date: PropTypes.object,
        data: PropTypes.object,

        assignees: PropTypes.object,
        executors: PropTypes.object,

        setDate: PropTypes.func,
        onCreateAP: PropTypes.func,
        onDetails: PropTypes.func,
    }

    constructor ( props ) {
        super( props )

        this.state = {
            current: 'people',
            currentType: 'restaurant',
            createAP: false,
        }

        this.selected = React.createRef()
    }

    componentDidMount () {
        window.addEventListener( 'resize', this.forced )
    }

    componentWillUnmount () {
        window.removeEventListener( 'resize', this.forced )
    }

    componentDidUpdate ( prevProps ) {
        if ( !this.props.savingAP && prevProps.savingAP !== this.props.savingAP ) {
            this.close()
        }
    }

    setCurrent = ( siv ) => {
        return ( type ) => {
            this.setState({
                current: siv,
                currentType: type,
            })

            setTimeout( this.forced, 0 )
        }
    }

    forced = () => this.forceUpdate()

    title = ( key ) => titles[ key ]

    group = ( key, data ) => ({
        groupKey: key,
        groupName: this.title( key ),
        tasks: this.waitSort( key, data ),
    })

    waitSort = ( key, data ) => {
        const res = data.map(( task ) => {
            task._modificators = [ `sub-${key}` ]

            switch ( key ) {
                case 'todo':
                    !task.finishTime &&
            dayjs().isAfter( dayjs( task.dueTime )) &&
            ( task._modificators = [ 'sub-outdated' ])
                    break
                case 'ready':
                    dayjs( task.finishTime ).isAfter( dayjs( task.dueTime )) &&
            task._modificators.push( 'later' )
                    break
                default:
                    break
            }

            return task
        })

        if ( key !== 'ready' && key !== 'todo' ) { return res }

        if ( key === 'todo' ) {
            return res.sort(( b, a ) => {
                if ( a.finishTime && !b.finishTime ) {
                    return 1
                }

                if ( !a.finishTime && b.finishTime ) {
                    return -1
                }

                if ( dayjs( a.dueTime ).isBefore( b.dueTime )) {
                    return -1
                }

                if ( dayjs( b.dueTime ).isBefore( a.dueTime )) {
                    return 1
                }

                return 0
            })
        }

        return res.sort(( a, b ) => {
            if ( a.finishTime && !b.finishTime ) {
                return 1
            }

            if ( !a.finishTime && b.finishTime ) {
                return -1
            }

            if ( dayjs( a.dueTime ).isBefore( b.dueTime )) {
                return -1
            }

            if ( dayjs( b.dueTime ).isBefore( a.dueTime )) {
                return 1
            }

            return 0
        })
    }

    taskGroup = ( tasks ) => {
        const confirm = [ 'confirmed', 'declined' ]

        return ( key ) => {
            if ( key === 'count' ) { return }

            return (
                <li key={key}>
                    <TaskGroup
                        detailsAny
                        hideLength
                        hidePhoto
                        hideTime
                        showName
                        showStub
                        group={this.group( key, tasks[ key ])}
                        showNameKey="responsibleScheduleName"
                        canCollapse={false}
                        separate={false}
                        scroll={false}
                        hideInfo={false}
                        preferFinish={confirm.indexOf( key ) < 0}
                        preferConfirm={confirm.indexOf( key ) > -1}
                        onDetails={this.props.onDetails({})}
                    />
                </li>
            )
        }
    }

    participants = ( stat ) => {
        if ( !stat ) { return 'Нет информации о визите' }

        const res = [
            'МС: ' +
        ( stat.shiftSupervisor ? stat.shiftSupervisor : config.ui.nullSymbol ),
            'RGM: ' + ( stat.rgm ? stat.rgm : config.ui.nullSymbol ),
            'ТУ: ' + ( stat.areaCoach ? stat.areaCoach : config.ui.nullSymbol ),
        ]

        return (
            <div className="visit-participants">
                {res.map(( str ) => (
                    <p key={str}>{str}</p>
                ))}
            </div>
        )
    }

    comment = ( stat ) => {
        if ( !stat || !stat.comment ) { return null }

        return (
            <React.Fragment>
                <h6>Комментарий</h6>
                <p>{stat.comment ?? '—'}</p>
            </React.Fragment>
        )
    }

    deviations = ( stat ) => {
        if ( !stat || !stat.deviationStatistics ) { return 'Нет отклонений' }

        const { deviations } = stat.deviationStatistics

        return deviations.map(( data ) => (
            <div className="deviation" key={data.id}>
                <p>{data.name}</p>
                <small>{data.comment}</small>
                {data.selectedStrategy === 2 && (
                    <span className="repeat-sign">Устранено на месте</span>
                )}
            </div>
        ))
    }

    openAPModal = () =>
        this.setState({ createAP: true, })

    close = () =>
        this.setState({ createAP: false, })

    createActionPlan = ( info ) => {
        const { data, restaurantId } = this.props,
              { current, currentType } = this.state

        this.props.onCreateAP(
            {
                ...info,
                restaurantId,
                actionPlanInfo: {
                    associatedVisitId:
            data.results[ current ][ currentType ].ratingVisitStatistics
                .associatedVisitId,
                    deviations: info.selection,
                },
            },
            true
        )
    }

    getDeviations = () => {
        const { data } = this.props,
              { current, currentType } = this.state,
              cur =
        data && data.results && data.results[ current ]
            ? data.results[ current ][ currentType ]
            : null

        if (
            !data ||
      !cur ||
      !cur.ratingVisitStatistics ||
      !cur.ratingVisitStatistics.deviationStatistics
        ) {
            return []
        }

        return cur.ratingVisitStatistics.deviationStatistics.deviations
    }

    userCanAP = () => {
        const { user, restaurantId } = this.props

        if ( !user ) { return false }
        if ( user.info.jobRole !== 'Area Coach' ) { return false }
        if ( user.restaurant.grantedRestaurants.indexOf( parseInt( restaurantId )) < 0 ) { return false }

        return true
    }

    footerWidth = () => {
        const block = document.querySelector( '.full-report-bottom' )

        if ( !block ) { return 0 }

        return block.clientWidth + 80
    }

    bottom = () => {
        const { data } = this.props,
              { current, currentType } = this.state,
              result = data.results[ current ]
                  ? data.results[ current ][ currentType ]
                  : null,
              stat =
        result && result.ratingVisitStatistics
            ? result.ratingVisitStatistics
            : null

        return (
            <div className="full-report-bottom">
                <div className="full-report-staff">
                    <h5>Участники</h5>
                    {this.participants( stat )}
                    {this.comment( stat )}
                </div>
                <div className="full-report-deviations">
                    <h5>Отклонения</h5>
                    {this.deviations( stat )}
                </div>
                <ul className="selected-tasks">{this.tasksGroups()}</ul>
                <footer className="result-footer" style={{ width: this.footerWidth() }}>
                    {result && stat && this.userCanAP() && (
                        <span className="link add-action-plan" onClick={this.openAPModal}>
                            <Icon component={Icons.Tablet.def} /> Сформировать AP
                        </span>
                    )}
                </footer>
            </div>
        )
    }

    tasksGroups = () => {
        const { data } = this.props,
              { current, currentType } = this.state,
              d = data.results[ current ] ? data.results[ current ][ currentType ] : null,
              tasks = d ? d.tasks : null

        if ( !d || ( !d.overall && d.overall !== 0 )) {
            return currentType === 'siv' ? (
                <span className="empty-siv">
          Здесь появятся задачи по результатам визита{' '}
                    {format.strings.capitalize( current )}
                </span>
            ) : (
                <span className="empty-siv">
          Здесь появятся задачи по результатам оценки ресторана
                </span>
            )
        }

        if (
            !Object.keys( tasks )
                .map(( t ) => t === 'count' || tasks[ t ].length === 0 )
                .includes( false )
        ) {
            return currentType === 'siv' ? (
                <span className="empty-siv">
          В рамках визита {format.strings.capitalize( current )} не было заведено
          задач
                </span>
            ) : (
                <span className="empty-siv">
          В рамках оценки работы ресторана не было заведено задач
                </span>
            )
        }

        return Object.keys( tasks ).map( this.taskGroup( tasks ))
    }

    placeArrow = () => {
        if ( !this.selected.current ) {
            setTimeout(() => this.forceUpdate(), 100 )
            return
        }

        const current = this.selected.current,
              box = current.getBoundingClientRect(),
              scroll = document.querySelector(
                  '.kfc-tasks .ant-layout-content'
              ).scrollTop,
              left =
        box.left +
        box.width / 2 -
        document.querySelector( '.global-sider' ).clientWidth -
        24,
              top =
        box.top +
        box.height -
        document.querySelector( '.app-header' ).clientHeight -
        16 +
        scroll

        return { left, top }
    }

    block = ( type ) => (
        <SIVBlock
            key={type}
            type={format.strings.capitalize( type )}
            data={this.props.data.results[ type ]}
            assign={this.state.current === type ? this.selected : null}
            current={this.state.current === type}
            currentType={this.state.currentType}
            onSelect={this.setCurrent( type )}
        />
    )

    arrow = ( key, handler, disabled ) => {
        const cls = [ 'pager-arrow', `arrow-${key}` ]

        !disabled && cls.push( 'active' )

        return (
            <span
                className={cls.join( ' ' )}
                onClick={() => this.props.setDate( handler())}
            ></span>
        )
    }

    render () {
        const { createAP } = this.state,
              { date, data, setDate, assignees, executors, savingAP } = this.props,
              hlCls = [ 'highlight' ],
              sort = [ 'people', 'place', 'promo', 'products' ]

        if ( !data ) {
            return (
                <div className="tasks-full-report">
                    <Spinner />
                </div>
            )
        }

        const blocksA = Object.keys( data.results )
            .filter(( r ) => !!data.results[ r ])
            .sort(( a, b ) => sort.indexOf( a ) - sort.indexOf( b )),
              blocksB = Object.keys( data.results )
                  .filter(( r ) => !data.results[ r ])
                  .sort(( a, b ) => sort.indexOf( a ) - sort.indexOf( b )),
              blocks = [ ...blocksA, ...blocksB ].map( this.block )

        data.info.rating >= 80 && hlCls.push( 'good' )
        data.info.rating < 80 && data.info.rating >= 50 && hlCls.push( 'mild' )
        data.info.rating < 50 && hlCls.push( 'bad' )

        return (
            <div className="tasks-full-report">
                <header className="tasks-full-report-header">
                    <section className="header-date controls-pager">
                        {this.arrow( 'prev', () => dayjs( date ).subtract( 1, 'month' ), false )}
                        <ReportsControls
                            type="month"
                            date={dayjs( date ).startOf( 'month' )}
                            onChange={setDate}
                        />
                        {this.arrow(
                            'next',
                            () => dayjs( date ).add( 1, 'month' ),
                            dayjs( date ).isSame( dayjs(), 'month' )
                        )}
                    </section>
                    <section className="header-info">
                        <ul className="full-report-states">
                            {( data.info.rating || data.info.rating === 0 ) && (
                                <li>
                  Оценка RGM
                                    <span className={hlCls.join( ' ' )}>{data.info.rating}%</span>
                                </li>
                            )}
                            {( data.info.routine || data.info.routine === 0 ) && (
                                <li>
                  Рутина
                                    <span>{data.info.routine}%</span>
                                </li>
                            )}
                            {( data.info.apbsc || data.info.apbsc === 0 ) && (
                                <li>
                  AP BSC
                                    <span>{data.info.apbsc}%</span>
                                </li>
                            )}
                            {( data.info.bsc || data.info.bsc === 0 ) && (
                                <li>
                  BSC
                                    <span>{data.info.bsc}</span>
                                </li>
                            )}
                        </ul>
                    </section>
                </header>
                <main className="siv-blocks">{blocks}</main>
                <span className="selected-arrow" style={this.placeArrow()}></span>

                {this.bottom()}

                <CreateActionPlan
                    visible={createAP}
                    saving={savingAP}
                    data={this.getDeviations()}
                    assignees={assignees}
                    executors={executors}
                    onCancel={this.close}
                    onSubmit={this.createActionPlan}
                />
            </div>
        )
    }
}

export default TasksFullReport
