/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import dayjs                from 'dayjs'

import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'

import { Modal, Col, Row } from 'antd'

/* APPLICATION */
import { FormField } from 'components'
import { format }    from 'tools'
import config        from 'config'

import './add-fixed-shift.scss'

class AddFixedShiftModal extends Component {
    static propTypes = {
        employees: PropTypes.array,
        positions: PropTypes.array,
        checkList: PropTypes.array,

        onSubmit: PropTypes.func,
        onCancel: PropTypes.func,

        visible: PropTypes.bool,
    }
    constructor ( props ) {
        super( props )
        this.state = { positions: {}, }

        this.formRef = React.createRef()
    }

    componentDidMount () {
        if ( this.props.positions ) {
            this.setPositions( this.props.positions )
        }
    }

    days = () =>
        config.defs.days.map(( val ) => ({
            value: val,
            label: dayjs().day( val ).format( 'ddd' ),
        }))

    checkEmployee = ( _, id ) => {
        let newPositions = {},
            check = false

        this.props.checkList.forEach(( staff ) => {
            if ( staff.id === id && staff.outstaff && !check ) {
                check = true
                for ( let key in this.props.positions ) {
                    if ( key !== config.mcGuid ) {
                        newPositions[ key ] = this.props.positions[ key ]
                    }
                }
                this.formRef.current.props.children[ 1 ].props.form.setFieldsValue({ position: null, })
            }
        })

        if ( !check ) {
            newPositions = this.props.positions
        }

        this.setPositions( newPositions )
    }

    checkTime = ( field, value ) => {
        this.props.form.setFieldsValue({ [ field ]: value })
    }

    setPositions = ( poss ) =>
        this.setState({ positions: poss, })

    fields = () => [
        {
            field: 'name',
            label: 'Сотрудник',
            type: 'select',
            options: this.props.employees,
            onChange: this.checkEmployee,
            search: ( s, opt ) => format.find.strInProp( opt.props, 'children', s ),
            rules: [ config.rules.required ],
        },
        {
            field: 'position',
            label: 'Позиция',
            type: 'select',
            options: this.state.positions,
            rules: [ config.rules.required ],
        },
        {
            field: 'days',
            label: 'День недели',
            type: 'checkgroup',
            options: this.days(),
            rules: [ config.rules.required ],
        },
        {
            field: 'restrict',
            type: 'row',
            items: [
                {
                    field: 'timeStart',
                    label: 'Начало работы',
                    type: 'time',
                    onChange: this.checkTime,
                    span: 12,
                },
                {
                    field: 'timeEnd',
                    label: 'Окончание работы',
                    onChange: this.checkTime,
                    type: 'time',
                    span: 12,
                },
            ],
        },
    ]

    row = ( cfg ) => {
        const res = []

        for ( let i = 0; i < cfg.items.length; i++ ) {
            res.push(
                <Col span={cfg.items[ i ].span} key={cfg.items[ i ].field}>
                    {this.field( cfg.items[ i ])}
                </Col>
            )
        }

        return (
            <Row gutter={24} key={cfg.field} className={cfg.field}>
                {res}
            </Row>
        )
    }

    field = ( cfg ) => {
        const val = config.defs.fixed ? config.defs.fixed[ cfg.field ] : null

        if ( cfg.type === 'row' ) {
            return this.row( cfg )
        }

        return (
            <FormField
                {...cfg}
                value={val}
                key={cfg.field}
                form={this.props.form}
                data={config.defs.fixed}
            />
        )
    }

    prepare = ( values ) => {
        return ( dow ) => {
            const res = format.copy.object( config.defs.fixed )

            res.fixedShiftId = format.generate.guid()
            res.dayOfWeek = dow

            res.employeeGuid = values.name
            res.positionGuid = values.position

            res.timeStart = values.timeStart.format( config.format.time )
            res.timeEnd = values.timeEnd.format( config.format.time )

            return res
        }
    }

    preSubmit = () => {
        this.props.form.validateFieldsAndScroll(( err, values ) => {
            if ( !err ) {
                const handler = this.prepare( values ),
                      days = values.days.map( handler )

                this.props.onSubmit( days )
                this.props.form.resetFields()
            }
        })
    }

    render () {
        return (
            <Modal
                title="Добавить смену сотрудника"
                className="kfc-popup add-fixed-shift"
                centered={true}
                open={this.props.visible}
                onOk={this.preSubmit}
                okButtonProps={{ className: 'wide-btn' }}
                okText="Добавить"
                onCancel={this.props.onCancel}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <Form
                    ref={this.formRef}
                    {...config.ui.wideForm}
                >
                    {this.fields().map( this.field )}
                </Form>
            </Modal>
        )
    }
}

export default Form.create({ name: 'AddFixedShiftModal' })( AddFixedShiftModal )
