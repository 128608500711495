import { Dayjs }           from 'dayjs'
import * as React          from 'react'
import { PickerTimeProps } from 'antd-v5/es/time-picker'
import { DatePicker }      from 'antd-v5'

export interface TimePickerProps extends Omit<PickerTimeProps<Dayjs>, 'picker'> {}

const TimePicker = React.forwardRef<any, TimePickerProps>(( props, ref ) => {
    return <DatePicker {...props} picker="time" ref={ref} />
})

TimePicker.displayName = 'TimePicker'

export default TimePicker
