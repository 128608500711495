/* VENDOR */
import dayjs from 'dayjs'

/* APPLICATION */
import { format } from 'tools'

import Template from './Template.js'
import './daily-template.scss'

const spoiler = ( pos, addHours ) => {
    const spoiler = {
        label: pos.position,
        props: { className: addHours ? 'spoiler with-hours' : 'spoiler', },
    }

    if ( addHours ) {
        addHours.forEach(( h ) => {
            spoiler[ 'hour' + h.hour ] = h.hour
        })
    }

    return spoiler
}
const shift = ( shift, from, length, date ) => {
    const fixed = shift.fixed ? 'Ф ' : '',
          mShiftStart = dayjs( shift.shiftStart ),
          mShiftEnd = dayjs( shift.shiftEnd ),
          res = {
              label: shift.employeeName,
              add: format.strings.interval(
                  shift.shiftStart.split( 'T' ).pop().replace( ':00', '' ),
                  shift.shiftEnd.split( 'T' ).pop().replace( ':00', '' )
              ),
              value: fixed + Math.abs( mShiftStart.diff( mShiftEnd, 'hours' )) + ' ч.',
              props: { className: 'shift', },
          }

    for ( let h = from; h < from + length; h++ ) {
        const hour = h > 23 ? h - 24 : h

        res[ 'hour' + hour ] = format.generate.greenLinePrint(
            hour,
            shift.shiftStart,
            shift.shiftEnd,
            dayjs( date ),
            h
        )
    }

    return res
}

const shifts = ( res, pos, from, length, date ) =>
    pos.shifts
        .sort(( a, b ) => {
            if ( dayjs( a.shiftStart ).isBefore( dayjs( b.shiftStart ))) {
                return -1
            }

            if ( dayjs( a.shiftStart ).isAfter( dayjs( b.shiftStart ))) {
                return 1
            }

            return 0
        })
        .map(( s ) => res.push( shift( s, from, length, date )))
const table = ( data ) => {
    const { planTurnover, planTransactions, planUnproductiveHours, planShiftSupervisorHours, planSpmh, planTcph } = data
    const { requiredHours, planHours, deltaHours } = data.productiveHours
    const res = [
        {
            key: 'planTurnover',
            label: 'План ТО Директора',
            add: null,
            value: format.strings.thousand( planTurnover, '', '' ),
        },
        {
            key: 'planTransactions',
            label: 'План транзакции',
            add: null,
            value: format.strings.thousand( planTransactions, '', '' ),
        },
        {
            key: 'planSpmh',
            label: 'План SPMH',
            add: null,
            value: format.strings.thousand( planSpmh, '', '' ),
        },
        {
            key: 'planTcph',
            label: 'План TCPH',
            add: null,
            value: planTcph,
        },
        {
            key: 'productiveHours.requiredHours',
            label: 'Требуется производ. часов',
            add: null,
            value: requiredHours,
        },
        {
            key: 'productiveHours.planHours',
            label: 'Запланировано производ. часов',
            add: null,
            value: planHours,
        },
        {
            key: 'productiveHours.deltaHours',
            label: 'Разница производ. часов',
            add: null,
            value: deltaHours <= 0 ? deltaHours : '+' + deltaHours,
        },
        {
            key: 'planUnproductiveHours',
            label: 'Запланировано непроизвод. часов',
            add: null,
            value: format.strings.thousand( planUnproductiveHours, '', '' ),
        },
        {
            key: 'planShiftSupervisorHours',
            label: 'Запланировано часов МС',
            add: null,
            value: format.strings.thousand( planShiftSupervisorHours, '', '' ),
            props: { className: 'pre-spoiler', },
        },
        {
            key: 'hour',
            label: 'Время',
            add: null,
            value: null,
            props: { className: 'pre-spoiler time', },
        }
    ]

    data.hours.forEach(( h ) => {
        res.forEach(( row ) => {
            // eslint-disable-next-line
            const val = eval("h." + row.key);
            row[ 'hour' + h.hour ] = ( val || row.key === 'hour' ) ? format.strings.thousand( val, ' ', ' ' ) : ' '
        })
    })

    data.positions.forEach(( pos, index ) => {
        res.push( spoiler( pos, index === 0 ? false : data.hours ))
        shifts( res, pos, data.hours[ 0 ].hour, data.hours.length, data.workStart )
    })

    res.forEach(( row, i ) => ( row.key = i + row.label + row.value + row.add ))

    const TCPHIndex = res.findIndex( item => item.label === 'План TCPH' )
    if ( TCPHIndex !== -1 ) {
        res[ TCPHIndex ].value = res[ TCPHIndex ].value !== null && res[ TCPHIndex ].value.toLocaleString()
    }

    return res
}
const columns = ( data ) => {
    const res = {
        label: ( row ) => row.label,
        add: ( row ) => row.add,
        value: ( row ) => row.value,
    }

    data.hours.forEach(( h ) => {
        const key = 'hour' + h.hour
        res[ key ] = ( row ) => row[ key ]
    })

    return res
}
const cols = ( data ) => {
    const length = [ 18, 11, 7 ],
          res = length.map(( l ) => l + '%' ),
          w = length.reduce(( s, l ) => s - l, 100 ) / data.hours.length

    data.hours.forEach(() => res.push( w + '%' ))

    return res
}

class DailyTemplate extends Template {
    constructor ( props ) {
        super( props )

        this.state = {
            data: table( props.data ),
            columns: columns( props.data ),
            className: 'daily-schedule-table',
            width: cols( props.data ),
        }
    }

    data = ( data ) => table( data )
    columns = ( data ) => columns( data )
}

export default DailyTemplate

