/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'

/* APPLICATION */
import { CompareTable, Spinner } from 'components'
import { format }                from 'tools'

import './turnover-summary.scss'

class TurnoverSummary extends Component {
    static propTypes = {
        data: PropTypes.object,
        showDetails: PropTypes.bool,
        currency: PropTypes.string,
    }

    goals = () => {
        const { data, currency, showDetails } = this.props,
              { goals, summary } = data,
              label = !showDetails
                  ? 'Цель на период'
                  : `Цель на ${format.dates.nrelative( summary.group )}`,
              predef =
        currency === '₽'
            ? [
                    {
                        title: label,
                        currency,
                        value: goals.planTurnover,
                    },
                    {
                        title: 'Прогресс',
                        type: 'delta',
                        currency,
                        value: goals.factTurnover,
                        fact: goals.deltaTurnover,
                    },
                ]
            : [
                    {
                        title: 'Прогресс',
                        type: 'value',
                        currency,
                        value: goals.factTurnover,
                        refValue: 100,
                    },
                ]

        return predef.map( format.generate.summary )
    }

    getData = ( data ) => {
        const { currency } = this.props,
              predef = [
                  {
                      title: 'Оборот',
                      currency,
                      plan: data.planTurnover,
                      fact: data.factTurnover,
                  },
                  {
                      title: 'Разница',
                      currency,
                      value: data.deltaTurnover,
                  },
                  {
                      title: 'Транзакции',
                      currency: format.check.onlyIf( currency, '%' ),
                      plan: data.planTransactions,
                      fact: data.factTransactions,
                  },
                  {
                      title: 'Средний чек',
                      currency,
                      plan: data.planAverageCheck,
                      fact: data.factAverageCheck,
                  },
              ]

        return predef.map( format.generate.summary )
    }

    label = ( data ) =>
        this.props.showDetails
            ? 'К ' + format.dates.relative( data.group ) + ' на ' + data.requestTime
            : 'На выбранный период'

    render () {
        const { currency, data, showDetails } = this.props,
              { summary, details, goals } = data,
              res = []

        if ( !summary || !details || !goals ) { return <Spinner /> }

        res.push(
            <CompareTable
                key="goals"
                className={
                    currency === '₽' ? 'compare-goals hide-goodbad' : 'compare-goals'
                }
                rows={this.goals()}
            />
        )
        showDetails &&
      res.push(
          <CompareTable
              key="top"
              title={this.label( details )}
              rows={this.getData( details )}
          />
      )

        res.push(
            <CompareTable
                key="bottom"
                title={this.label( summary )}
                rows={this.getData( summary )}
            />
        )

        return res
    }
}

export default TurnoverSummary
