/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'

/* APPLICATION */
import { CurrencySwitch, PerformanceChart, Report } from 'components'
import { format }                                   from 'tools'
import config                                       from 'config'
import { Select }                                   from 'antd'
import GroupSwitch                                  from '../GroupSwitch/GroupSwitch'
import { allRowName }                               from '../GroupSwitch/const'

class PerformanceRating extends Component {
    static propTypes = {
        request: PropTypes.object,

        data: PropTypes.object,
        selected: PropTypes.array,

        partner: PropTypes.any,
        partners: PropTypes.array,

        onSelect: PropTypes.func,
        onPartner: PropTypes.func,
        onControls: PropTypes.func,
    }

    constructor ( props ) {
        super( props )
        this.state = {
            mixed: {},
            selected: [],
        }
    }

    componentDidMount () {
        const { data } = this.props
        data.all && data.report && this.setData( this.props )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { data, selected } = this.props

        if (
            nextProps.data.all !== data.all ||
            nextProps.data.report !== data.report
        ) {
            this.setData( nextProps )
        }

        if ( nextProps.selected !== selected ) {
            this.setData( nextProps )
        }

        if ( this.props.ratingTableGroup !== 'RESTAURANTS' ) {
            this.props.selected.length = 0
            this.setData( nextProps )
        }

    }

    setData = ( props ) => {
        if ( !props.data || !props.data.report || !props.data.all ) {
            this.setState({ mixed: null, })
            return
        }

        const mixed = format.copy.object( props.data.report ),
              content = format.copy.array( mixed.content ),
              allRow = format.copy.object( props.data.all )

        content?.forEach(( row ) => {
            row._selected = props.selected.indexOf( row.id ) > -1
        })

        allRow.name = allRowName[ this.props.ratingTableGroup ]
        allRow._selected = props.selected.length < 1
        allRow._alwaysOnTop = true

        content?.unshift( allRow )

        mixed.content = content.filter(( row ) => Object.keys( row ).filter( i => row[ i ]).length )

        this.setState({ mixed })
    }

    applySelection = ( data, record, value ) => {
        const all = data.find(( r ) => !r.id )

        if ( value ) {
            if ( all.name === ( record.restaurantName || record.name )) {
                data.forEach(( r ) => ( r._selected = false ))
                all._selected = true
            } else {
                all._selected = false
            }
        } else {
            if ( !data.reduce(( res, r ) => res || r._selected, false )) {
                all._selected = true
            }
        }

        return all._selected
            ? []
            : data
                .filter(( r ) => r.id && r._selected )
                .map(( r ) => r.id )
    }

    onChange = ( record, key, value ) => {
        const mixed = format.copy.object( this.state.mixed ),
              nr = format.copy.object( record ),
              index = format.find.index( mixed.content, 'name', record.name )

        let selected = format.copy.array( this.props.selected )

        nr[ key ] = value
        mixed.content[ index ] = nr

        if ( key === '_selected' ) {
            selected = this.applySelection( mixed.content, record, value )
            this.props.onSelect( selected )
        }

        this.setState({
            mixed,
            selected,
        })
    }

    prepare = ( record, index, columns ) =>
        format.goodBad.iterate.goodBad( record, [ 'diff' ], columns )

    options = ( items ) =>
        items.map(( item ) => (
            <Select.Option value={item.value} key={item.value}>
                {item.label}
            </Select.Option>
        ))

    render () {
        const {
            request,
            data,
            partner,
            facilityType,
            city,
            regionCoach,
            marketCoach,
            factsNumbers,
            partners,
            areaCoach,
            facilityTypes,
            cities,
            regionCoaches,
            marketCoaches,
            areaCoaches,
            restaurants,
            ratingTableGroup,
            changeRatingTableGroup,
            onControls,
            onPartner,
            onFilter,
            onFocus,
        } = this.props,
              { mixed } = this.state


        return (
            <div className="rating-inner">
                <div className='rating-performance'>
                    <div className="rating-performance__filters">
                        <Select
                            placeholder="Партнеры"
                            mode='multiple'
                            maxTagCount={1}
                            value={partner}
                            onChange={onPartner}
                            onFocus={onFocus}
                            style={{ minWidth: 220 }}
                            getPopupContainer={( trigger ) => trigger.parentNode}
                            disabled={!partners.length}
                            filterOption={( input, option ) =>
                                option.props.children.toLowerCase().indexOf( input.toLowerCase()) >= 0
                            }
                            allowClear
                        >
                            {this.options([
                                ...( partners || []),
                            ])}
                        </Select>
                        <Select
                            placeholder="Типы ресторанов"
                            mode='multiple'
                            maxTagCount={1}
                            style={{ minWidth: 220 }}
                            value={ facilityType }
                            onFocus={ onFocus }
                            onChange={( value ) => onFilter({ value, name: 'facilityTypes' })}
                            getPopupContainer={( trigger ) => trigger.parentNode}
                            disabled={!facilityTypes.length}
                            filterOption={( input, option ) =>
                                option.props.children.toLowerCase().indexOf( input.toLowerCase()) >= 0
                            }
                            allowClear
                        >
                            {this.options([
                                ...( facilityTypes || []),
                            ])}
                        </Select>
                        <Select
                            placeholder="Города"
                            mode='multiple'
                            maxTagCount={1}
                            value={city}
                            style={{ minWidth: 220 }}
                            onChange={( value ) => onFilter({ value, name: 'cities' })}
                            onFocus={onFocus}
                            getPopupContainer={( trigger ) => trigger.parentNode}
                            disabled={!cities.length}
                            allowClear
                        >
                            {this.options([
                                ...( cities || []),
                            ])}
                        </Select>
                        <Select
                            placeholder="Region coaches"
                            mode='multiple'
                            maxTagCount={1}
                            style={{ minWidth: 220 }}
                            value={regionCoach}
                            onFocus={onFocus}
                            onChange={( value ) => onFilter({ value, name: 'regionCoaches' })}
                            getPopupContainer={( trigger ) => trigger.parentNode}
                            disabled={!regionCoaches.length}
                            filterOption={( input, option ) =>
                                option.props.children.toLowerCase().indexOf( input.toLowerCase()) >= 0
                            }
                            allowClear
                        >
                            {this.options([
                                ...( regionCoaches || []),
                            ])}
                        </Select>
                        <Select
                            placeholder="Market coaches"
                            mode='multiple'
                            maxTagCount={1}
                            style={{ minWidth: 220 }}
                            value={marketCoach}
                            onChange={( value ) => onFilter({ value, name: 'marketCoaches' })}
                            onFocus={onFocus}
                            getPopupContainer={( trigger ) => trigger.parentNode}
                            disabled={!marketCoaches.length}
                            filterOption={( input, option ) =>
                                option.props.children.toLowerCase().indexOf( input.toLowerCase()) >= 0
                            }
                            allowClear
                        >
                            {this.options([
                                ...( marketCoaches || []),
                            ])}
                        </Select>
                        <Select
                            placeholder="Area coaches"
                            mode='multiple'
                            maxTagCount={1}
                            style={{ minWidth: 220 }}
                            value={areaCoach}
                            onChange={( value ) => onFilter({ value, name: 'areaCoaches' })}
                            onFocus={onFocus}
                            getPopupContainer={( trigger ) => trigger.parentNode}
                            disabled={!areaCoaches.length}
                            filterOption={( input, option ) =>
                                option.props.children.toLowerCase().indexOf( input.toLowerCase()) >= 0
                            }
                            allowClear
                        >
                            {this.options([
                                ...( areaCoaches || []),
                            ])}
                        </Select>
                        <Select
                            placeholder="Рестораны"
                            maxTagCount={1}
                            mode='multiple'
                            style={{ minWidth: 220 }}
                            value={factsNumbers}
                            onChange={( value ) => onFilter({ value, name: 'factsNumbers' })}
                            onFocus={onFocus}
                            getPopupContainer={( trigger ) => trigger.parentNode}
                            disabled={!restaurants.length}
                            filterOption={( input, option ) =>
                                option.props.children.toLowerCase().indexOf( input.toLowerCase()) >= 0
                            }
                            allowClear
                        >
                            {this.options([
                                ...( restaurants || []),
                            ])}
                        </Select>
                    </div>
                    <CurrencySwitch
                        request={request}
                        update={onControls}
                        showGrowing={true}
                        hideCurrency={true}
                        before={'Показаны общие фактические и плановые значения SPMH выбранных ресторанов'}
                    />

                </div>
                <PerformanceChart plan={data.plan} fact={data.fact} />
                <GroupSwitch value={ratingTableGroup} onChange={changeRatingTableGroup}/>
                <div className="rating-height-limit-report-table">
                    <Report
                        rowKey="name"
                        data={mixed}
                        columns={config.tables.restaurants.performance( ratingTableGroup ).filter( Boolean )}
                        prepare={this.prepare}
                        onMore={this.props.onMore}
                        onChange={this.onChange}
                    />
                </div>
            </div>
        )
    }
}

export default PerformanceRating
