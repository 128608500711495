/* VENDOR */
import React from 'react'

/* APPLICATION */
import { format } from 'tools'

/* PRIVATE */

const colors = {
    good: '#07A72A',
    bad: '#A70000',
    none: '#B8B8B8',
    mix: '#575415',
},

      transition = ( arr, i, refarr, color ) => {
          const cur = format.strings.percent( refarr, i ),
                prev = format.strings.percent( refarr, i - 1 ),
                semi = ( prev + cur ) / 2 + '%',

                realcolor = color || colors.mix

          arr.push( getStop( 'transition-' + i, semi, realcolor ))
      },

      getStop = ( key, offset, color ) => (
          <stop key={key} offset={offset} stopColor={color} />
      ),

      getColor = ( fact, plan, compare ) => {
          if ( fact === plan ) { return colors.none }
          if ( fact === null ) { return colors.none }

          if ( compare ) {
              return compare( fact, plan ) ? colors.good : colors.bad
          }

          return fact > plan ? colors.good : colors.bad
      },

      getTransition = ({ stops, i, facts, color, previous }) => {
          if ( i > 0 ) {
              if (
                  color !== previous &&
      previous !== colors.none &&
      color !== colors.none
              ) {
                  transition( stops, i, facts )
              }

              if ( previous === colors.none || color === colors.none ) {
                  transition( stops, i, facts, colors.none )
              }
          }
      }

const getValue = ( point, src ) => {
    if ( point[ src + 'Spmh' ]) {

        return point[ src + 'Spmh' ]

    } else if ( point[ src + 'Turnover' ]) {

        return point[ src + 'Turnover' ]

    } else if ( point[ src + 'Value' ]) {

        return point[ src + 'Value' ]
    }

    return null
}


/* PUBLIC */

export const stops = ( fact, compare ) => {
    if ( !fact.length ) { return [] }

    const stops = []
    let previous

    fact.forEach(( point, i, facts ) => {
        const planValue = getValue( point, 'plan' )
        const factValue = getValue( point, 'fact' )

        const offset = format.strings.percByKey( facts, i, 'x' ) + '%',
              color = getColor( factValue, planValue, compare ),
              item = getStop( 'color-' + i, offset, color )

        point.color = color

        getTransition({ stops, i, facts, color, previous })
        stops.push( item )

        previous = color
    })

    return stops
}

export const hideCross = ( dates ) =>
    !format.check.now( dates.dateStart, dates.dateEnd )
