/* VENDOR */
import React, { Component }                            from 'react'
import PropTypes                                       from 'prop-types'
import { Modal, Checkbox, Spin, Input, Divider, List } from 'antd'
import { Scrollbars }                                  from 'react-custom-scrollbars'

/* APPLICATION */
import { Spinner } from 'components'
import { format }  from 'tools'
import config      from 'config'

import './linked-users.scss'

const { Search } = Input,
      itemCls = 'restaurant-item'

class LinkedUsers extends Component {
    static propTypes = {
        data: PropTypes.object,
        selected: PropTypes.array,

        onSubmit: PropTypes.func,
        onCancel: PropTypes.func,
        onSearch: PropTypes.func,
        onMore: PropTypes.func,
        onLinked: PropTypes.func,

        visible: PropTypes.bool,
    }

    constructor ( props ) {
        super( props )

        this.state = {
            users: [],
            selection: format.copy.array( props.selected ),

            search: '',

            fetching: false,
            onlyLinked: false,
        }
    }

    componentWillUnmount () {
        this._watcher && clearInterval( this._watcher )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { data, visible } = this.props

        if ( nextProps.visible !== visible ) {
            if ( nextProps.visible ) {
                this.setState({
                    users: nextProps.data ? nextProps.data.content : null,
                    selection: format.copy.array( nextProps.selected ),
                })
                this._watcher = setInterval( this.watch, 100 )
            } else {
                this.setState({
                    selection: format.copy.array( nextProps.selected ),
                    search: '',
                })
                this._watcher && clearInterval( this._watcher )
            }
        }

        if ( nextProps.data !== data ) {
            this.setState({
                users: nextProps.data ? nextProps.data.content : null,
                fetching: false,
            })
            this._watcher = setInterval( this.watch, 100 )
        }
    }

    watch = () => {
        const { fetching, search, onlyLinked } = this.state,
              { data, visible } = this.props,
              container = document.querySelector( '.Linked-list > div > div' )

        if ( !container || !visible || fetching || data.last || onlyLinked ) { return }

        if (
            container.scrollHeight - container.clientHeight - container.scrollTop <
      200
        ) {
            this.props.onMore( search )
            this.setState({ fetching: true, })
        }
    }

    search = ( e ) => {
        const str = e.target.value

        if ( str.length >= 2 ) {
            this.props.onSearch( str )
            this.scrolls && this.scrolls.scrollToTop()
        } else if ( this.state.search.length > 1 ) {
            this.props.onSearch( '' )
        }

        this.setState({ search: str })
    }

    toggle = ( item ) => {
        return () => {
            const { selection } = this.state,
                  found = this.getAdded( item )

            found
                ? selection.splice( selection.indexOf( found ), 1 )
                : selection.push( item )

            this.setState({ selection })
        }
    }

    getAdded = ( item ) =>
        this.state.selection.find(( d ) => d.userId === item.userId )

    item = ( item ) => {
        const cls = [ itemCls ]

        return (
            <List.Item className={cls.join( ' ' ).trim()}>
                <Checkbox checked={!!this.getAdded( item )} onChange={this.toggle( item )}>
                    <span className="item-title">{item.fullName}</span>
                </Checkbox>
            </List.Item>
        )
    }

    submit = () => {
        const { selection } = this.state

        this.setState({ onlyLinked: false, })

        this.props.onSubmit( selection )
    }

    added = () =>
        this.state.selection.filter(
            ( item ) => !this.props.selected.find(( i ) => i.userId === item.userId )
        ).length
    removed = () =>
        this.props.selected.filter(
            ( item ) => !this.state.selection.find(( i ) => i.userId === item.userId )
        ).length

    addText = () => {
        const added = this.added(),
              removed = this.removed()

        if ( added === 0 && removed === 0 ) { return 'Нечего добавить' }

        if ( removed > 0 && added === 0 ) {
            return (
                'Убрать ' +
        format.strings.clearCount( removed, [
            'пользователя',
            'пользователей',
            'пользователей',
        ])
            )
        }

        if ( added > 0 && removed === 0 ) {
            return (
                'Добавить ' +
        format.strings.clearCount( added, [
            'пользователя',
            'пользователей',
            'пользователей',
        ])
            )
        }

        return (
            'Убрать ' +
      format.strings.clearCount( removed, [
          'пользователя',
          'пользователей',
          'пользователей',
      ]) +
      ', добавить ' +
      added
        )
    }

    toggleOnlyLinked = () =>
        this.setState(
            { onlyLinked: !this.state.onlyLinked, },
            () => this.props.onLinked( this.state.onlyLinked, this.state.selection )
        )

    onCancel = () => {
        this.setState({ onlyLinked: false, })
        this.scrolls && this.scrolls.scrollToTop()

        this.props.onCancel()
    }

    render () {
        const { search, selection, users, fetching, onlyLinked } = this.state,
              { data, visible } = this.props,
              summ = users ? [ ...users, ...selection ] : users,
              raw = summ
                  ? onlyLinked
                      ? summ.filter(
                          ( r ) =>
                              format.find.strInProp( r, 'fullName', search ) &&
                selection.find(( rr ) => r.userId === rr.userId )
                      )
                      : summ
                          .filter(( r ) => !!r.userId )
                          .filter(( r ) => format.find.strInProp( r, 'fullName', search ))
                  : summ,
              linked = raw
                  ? raw.filter(
                      ( r, i ) =>
                          raw.indexOf( raw.find(( rr ) => rr.userUuid === r.userUuid )) === i
                  )
                  : raw,
              sorted = linked
                  ? linked.sort(( a, b ) => a.fullName.localeCompare( b.fullName, 'en' ))
                  : linked

        return (
            <Modal
                className="kfc-popup kfc-linked-users"
                centered={true}
                open={visible}
                title="Связанные пользователи"
                okText={this.addText()}
                onOk={this.submit}
                okButtonProps={{ disabled: this.added() < 1 && this.removed() < 1 }}
                onCancel={this.onCancel}
                cancelText="Отмена"
            >
                <div className="filter-container">
                    <Search
                        placeholder="Начните вводить название"
                        value={search}
                        onChange={this.search}
                    />
                </div>

                <Divider />

                {data && data.content ? (
                    <div className="linked-list">
                        <Scrollbars
                            {...config.ui.scrolls}
                            ref={( node ) => ( this.scrolls = node )}
                        >
                            <List
                                size="small"
                                dataSource={sorted}
                                renderItem={this.item}
                                locale={{ emptyText: 'Ничего не найдено' }}
                            />
                        </Scrollbars>

                        <Divider />

                        <div className="list-overspin">
                            {selection.length > 0 && (
                                <Checkbox checked={onlyLinked} onClick={this.toggleOnlyLinked}>
                  Только связанные
                                </Checkbox>
                            )}
                            {fetching && <Spin size="small" />}
                        </div>
                    </div>
                ) : (
                    <Spinner />
                )}
            </Modal>
        )
    }
}

export default LinkedUsers
