/* VENDOR */
import React, { Component } from 'react'
import { Layout }           from 'antd'

/* APPLICATION */
import {
    AppHeader,
    InnerContent,
    ViewTypeSwitch,
    Manning,
    Duration,
    Breaks,
} from 'components'

const { Content, Header } = Layout

class Chart extends Component {
    constructor ( props ) {
        super( props )
        this.state = { view: 'manning', }
    }

    setView = ( view ) => this.setState({ view })

    content = () => {
        switch ( this.state.view ) {
            case 'breaks':
                return <Breaks />
            case 'duration':
                return <Duration />
            case 'manning':
            default:
                return <Manning />
        }
    }

    render () {
        const tabs = {
            manning: 'Manning chart',
            duration: 'Продолжительность',
            breaks: 'Перерывы',
        }

        return (
            <section className="kfc-manning kfc-tabbed-page scroll-container">
                <Layout>
                    <Header>
                        <AppHeader />
                    </Header>
                    <Content>
                        <ViewTypeSwitch
                            update={this.setView}
                            tabs={tabs}
                            current={this.state.view}
                        />
                        <InnerContent>
                            <div className="tabbed-content">{this.content()}</div>
                        </InnerContent>
                    </Content>
                </Layout>
            </section>
        )
    }
}

export default Chart
