/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import dayjs                from 'dayjs'

/* APPLICATION */
import { format } from 'tools'
import './chart-time.scss'
import TimePicker from '../TimePicker/TimePicker'

class ChartTime extends Component {
    static propTypes = {
        minuteStep: PropTypes.number,
        value: PropTypes.object,
        format: PropTypes.string,
        disabled: PropTypes.bool,
        bordered: PropTypes.bool,
        disabledHours: PropTypes.array,

        disabledMinutes: PropTypes.object,
        onChange: PropTypes.func,
        getPopupContainer: PropTypes.func,
    }

    constructor ( props ) {
        super( props )
        this.uniq = props.uniq || format.generate.guid()
    }

    watch = ( val ) => {
        setTimeout(() => {
            if ( val ) {
                [].slice
                    .call( document.querySelectorAll( '.chart-time-focused' ))
                    .forEach(( node ) => node.classList.remove( 'chart-time-focused' ))
                document
                    .querySelector( `.chart-time-${this.uniq}` )
                    .classList.add( 'chart-time-focused' )
            }
        })
        val ? this.startWatch() : this.stopWatch()
    }

    startWatch = () => ( this._watch = setInterval( this.watchTimePicker, 100 ))

    stopWatch = () => clearInterval( this._watch )

    daytime = () => {
        const { value } = this.props

        if ( !value ) { return '' }

        const time = parseInt( value.format( 'HHmm' ))

        if ( time >= 0 && time < 600 ) { return 'night' }
        if ( time >= 600 && time < 1200 ) { return 'morning' }
        if ( time >= 1200 && time < 1800 ) { return 'day' }
        if ( time >= 1800 && time < 2400 ) { return 'evening' }
    }

    cls = () => `chart-time chart-time-${this.uniq} ${this.daytime()}`
    dishours = () => this.props.disabledHours

    watchTimePicker = () => {
        const picker = document.querySelector(
            `.pick-${this.uniq} .ant-time-picker-panel-input`
        )

        if ( picker ) {
            if ( picker !== this._picker ) {
                picker.addEventListener( 'input', this.timeWatcher )
                picker.addEventListener( 'keyup', this.checkKeys )
            }
        } else {
            if ( this._picker ) {
                this._picker.removeEventListener( 'input', this.timeWatcher )
                this._picker.removeEventListener( 'keyup', this.checkKeys )
            }
        }

        this._picker = picker
    }

    checkKeys = ( e ) => {
        const { value } = this.props,
              { val, formated } = this.getVal( e )

        switch ( e.key ) {
            case 'Enter':
                e.stopPropagation()
                document.dispatchEvent( new Event( 'mousedown' ))
                e.target.value = formated

                if ( value.format( 'HH:mm' ) === formated ) { return }
                this._apply && clearTimeout( this._apply )

                this._apply = setTimeout(() => this.apply( val ), 100 )
                break

            default:
      //Do nothing
        }
    }

    timeWatcher = ( e ) => {
        if ([ 'deleteContentBackward', 'deleteContentForward' ].includes( e.inputType )) { return }

        const { formated } = this.getVal( e )

        e.target.parentNode.className = 'ant-time-picker-panel-input-wrap'

        e.target.value = formated
    }

    getVal = ( e ) => {
        const val = e.target.value.replace( /:/g, '' ).replace( /[^0-9]/g, '' ),
              check = parseInt( val.substr( 0, 2 )) > 23,
              hours = check ? val.substr( 0, 1 ) : val.substr( 0, 2 ),
              minutes = check ? val.substr( 1, 2 ) : val.substr( 2, 2 ),
              formated =
        val.length > 2 || check
            ? minutes.length > 0
                ? `${hours}:${minutes}`
                : `${hours}:`
            : hours

        return { val, check, hours, minutes, formated }
    }

    apply = ( val ) => {
        const { disabledHours, disabledMinutes, value } = this.props,
              hours = Math.min( 23, val.substr( 0, 2 )),
              minutes = Math.min( 45, Math.round( val.substr( 2, 2 ) / 15 ) * 15 ),
              m = dayjs( `${hours}:${minutes}`, 'HH:mm' ),
              dismin = disabledMinutes( hours )
                  ? disabledMinutes( hours ).includes( minutes )
                  : false

        if (( disabledHours || []).includes( hours ) || dismin ) {
            this._picker.value = value.format( 'HH:mm' )
            this._picker.parentNode.className =
        'ant-time-picker-panel-input-wrap has-error'
            return
        }

        this.checkMinutes( m )
    }

    checkMinutes = ( val ) => {
        if ( !val ) { return }

        const dis = this.props.disabledMinutes( val.hour()) || [],
              allow = [ 0, 15, 30, 45 ].filter(( n ) => dis.indexOf( n ) < 0 )

        if ( allow ) {
            val = val.set( 'minutes', Math.max( allow[ 0 ], val.minute()))
        }

        this.props.onChange( val )
    }

    render () {
        const {
            minuteStep,
            value,
            format,
            disabled,
            bordered,
            disabledMinutes,
            getPopupContainer,
        } = this.props


        return (
            <span className={this.cls()}>
                <TimePicker
                    ref={( node ) => ( this._ref = node )}
                    key="single-chart-time"
                    minuteStep={minuteStep}
                    value={value}
                    format={format}
                    disabled={disabled}
                    bordered={bordered ?? false}
                    suffixIcon={null}
                    allowClear={false}
                    showNow={false}
                    disabledTime={() => ({
                        disabledHours: this.dishours,
                        disabledMinutes,
                    })}
                    onChange={this.checkMinutes}
                    onOpenChange={this.watch}
                    popupClassName={`pick-${this.uniq}`}
                    getPopupContainer={getPopupContainer}
                    onCalendarChange={this.checkMinutes}
                />
            </span>
        )
    }
}

export default ChartTime
