import column                 from './helpers'
import { dropTypesColumns }   from '../../components/guestVote/GuestVoteTable/const'
import { Tooltip }            from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import React                  from 'react'
import { DataGroupType }      from 'services/guestVote/statistic/type'
import config                 from '../index'

const getStatisticsTable = ( currentDataGroup: DataGroupType ) => {
    const columns = [
        currentDataGroup === 'RESTAURANTS' ? column( 'Код', 'id', {
            sorter: 'bynull',
            width: 93,
        }) : null,
        column( `${dropTypesColumns[ currentDataGroup ]}`, 'name', {
            width: 300,
            sorter: 'locale'
        }),
        column( 'HI', 'factHappinessIndex', {
            as: 'currency',
            sorter: 'bynull',
            numeric: true,
            width: 60,
        }),
        column( <div className="delta-turnover">
            <span>HI <br/> прошлый год</span>
        </div>, 'factHappinessIndexLastYear', {
            as: 'currency',
            sorter: 'bynull',
            numeric: true
        }),
        column( <div className="delta-turnover">
            <span>Разница HI</span>
            <Tooltip title='Разница между «HI» и «HI прошлый год»'>
                <InfoCircleOutlined onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}/>
            </Tooltip>
        </div>, 'deltaFactHappinessIndexLastYear', {
            as: 'deltaCurrency',
            sorter: 'bynull',
            numeric: true
        }),
        column( 'OSAT', 'factOsat', {
            as: 'deltaPercentage',
            sorter: 'bynull',
            numeric: true
        }),
        column( <div className="delta-turnover">
            <span>OSAT <br/> прошлый год</span>
        </div>, 'factOsatLastYear', {
            as: 'deltaPercentage',
            sorter: 'bynull',
            numeric: true
        }),
        column( <div className="delta-turnover">
            <span>Разница OSAT</span>
        </div>, 'deltaFactOsatLastYear', {
            as: 'deltaPercentageColor',
            sorter: 'bynull',
            numeric: true
        }),
        column( 'Рейтинг', 'factFeedbacksRating', {
            as: 'currency',
            sorter: 'bynull',
            numeric: true
        }),
        column( <div className="delta-turnover">
            <span>Рейтинг <br/> прошлый год</span>
        </div>, 'factFeedbacksRatingLastYear', {
            as: 'currency',
            sorter: 'bynull',
            numeric: true
        }),
        column( 'Обращения', 'factFeedbacks', {
            as: 'currency',
            sorter: 'bynull',
            numeric: true
        }),
        column( <div className="delta-turnover">
            <span>Транзакции</span>
            <Tooltip
                title='Важно! Транзакции за текущий и предыдущий дни могут быть загружены не полностью'>
                <InfoCircleOutlined onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}/>
            </Tooltip>
        </div>, 'factTransactions', {
            as: 'currency',
            sorter: 'bynull',
            numeric: true
        }),
        column( <div className="delta-turnover">
            <span>Обращения на<br/> 10К транзакций</span>
        </div>, 'factFeedbacksPer10KTransactions', {
            as: 'currency',
            sorter: 'bynull',
            numeric: true
        }),
    ]


    return columns.filter( col => col !== null )
}

const getConsolidatedStatisticsTable = ( currentDataGroup: DataGroupType ) => {
    const groupColumnKeys: Partial<Record<DataGroupType, string>> = {
        'RESTAURANTS': 'restaurantName',
        'FRANCHISEE_GROUPS': 'franchiseeGroupName',
        'FACILITY_TYPES': 'facilityTypeName',
        'CITIES': 'city',
        'REGION_COACHES': 'regionCoachName',
        'MARKET_COACHES': 'marketCoachName',
        'AREA_COACHES': 'areaCoachName',
    }

    const columns = [
        column( 'ID обращения', 'id', {
            sorter: ( a: any, b: any ) => {
                const aValue = Number( a.id.split( '-' )[ 1 ])
                const bValue = Number( b.id.split( '-' )[ 1 ])
                return aValue - bValue
            },
            width: 120,
            numeric: true,
        }),
        column( 'Создано', 'createDate', {
            sorter: 'locale',
            width: 120
        }),
        column( 'Источник', 'feedbackSource', {
            sorter: 'locale',
            width: 150
        }),
        column( 'Ресторан', 'restaurantName', {
            width: 250,
            sorter: 'locale',
            render: ( text: string | null ) =>
                <Tooltip title={text}>
                    <span>{( text && text.length > 28 ) ? `${text.slice( 0, 28 )}...` : text}</span>
                </Tooltip>
        }),
        currentDataGroup === 'RESTAURANTS' ? column( 'Код ресторана', 'factsNumber', {
            sorter: 'bynull',
            numeric: true,
            width: 150
        }) : null,
        column( `${dropTypesColumns[ currentDataGroup ]}`, groupColumnKeys[ currentDataGroup ], {
            width: 250,
            sorter: 'locale',
            render: ( text: string | null ) =>
                <Tooltip title={text}>
                    <span>{text
                        ? text.length > 28 ? `${text.slice( 0, 28 )}...` : text
                        : config.ui.nullSymbol}</span>
                </Tooltip>
        }),
        column( 'Исполнитель', 'feedbackExecutor', {
            sorter: 'locale',
            width: 250,
            render: ( text: string | null ) =>
                <Tooltip title={text}>
                    <span>{( text && text.length > 28 ) ? `${text.slice( 0, 28 )}...` : text}</span>
                </Tooltip>
        }),
        column( 'Руководитель', 'headOfEmployee', {
            sorter: 'locale',
            width: 250
        }),
        column( 'Категория', 'feedbackCategory', {
            sorter: 'locale',
            width: 250
        }),
        column( 'Содержание', 'feedbackFullText', {
            sorter: 'locale',
            width: 250,
            className: 'full-text',
            render: ( text:string | null ) =>
                <Tooltip getPopupContainer={( el ): HTMLElement => el?.parentElement?.parentElement || document.body } title={text}>
                    <span>{( text && text.length > 40 ) ? `${text.slice( 0, 30 )}...` : text}</span>
                </Tooltip>
        }),
        column( 'Подкатегория', 'subcategory', {
            sorter: 'locale',
            width: 150
        }),
        column( 'Тип обращения', 'feedbackType', {
            sorter: 'locale',
            width: 150
        }),
        column( 'Уровень влияния', 'influenceLevel', {
            sorter: 'locale',
            width: 150
        }),
        column( 'Канал продаж', 'saleChannelName', {
            sorter: 'locale',
            width: 150
        }),
    ]


    return columns.filter( col => col !== null )
}

export default { guestVote: { getStatisticsTable, getConsolidatedStatisticsTable } }
