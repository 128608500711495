/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { connect }          from 'react-redux'

/* APPLICATION */
import { userActions, requestActions, restaurantActions } from 'services'

import { Spinner, ViewTypeDrop } from 'components'
import { format }                from 'tools'

import './restaurant-title.scss'
import { getGroup } from '../../../tools/format/dates.js'
import config       from '../../../config/index.js'

class RestaurantTitle extends Component {
    static propTypes = { allowAllRestaurants: PropTypes.bool, }

    constructor ( props ) {
        super( props )
        this.state = {
            title: '...',
            search: '',

            current: null,
            restaurants: null,
            filtered: null,
        }
    }

    componentDidMount () {
        const { restaurants, request } = this.props

        request.restaurantId &&
      this.setCurrent( request.restaurantId, restaurants, 'rid' )
        restaurants && this.setRestaurants( restaurants )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { restaurant, restaurants, request } = this.props

        if ( request.restaurantId !== nextProps.request.restaurantId ) {
            this.setCurrent( nextProps.request.restaurantId, nextProps.restaurants )
        }

        if ( restaurant !== nextProps.restaurant ) {
            this.props.loadRestaurants( nextProps.restaurant.grantedRestaurants )
        }

        if ( restaurants !== nextProps.restaurants ) {
            this.setCurrent( nextProps.request.factsNumber ?? nextProps.request.restaurantId, nextProps.restaurants )
            this.setRestaurants( nextProps.restaurants )
        }
    }

    hasIds = ( data ) =>
        data && data.grantedRestaurants && data.grantedRestaurants.length > 0

    setCurrent = ( id, list ) => {
        this.setState({
            current: id,
            title: this.getTitle( id, list ),
        })

        userActions.setCurrentRestaurant( id )
    }

    setRestaurants = ( raw ) => {
        const { allowAllRestaurants } = this.props,
              { search } = this.state,
              all = { all: 'Все рестораны', },
              rests = format.generate.keyval(
                  format.generate.options( raw, 'factsNumber', 'restaurantName' )
              ),
              restaurants = allowAllRestaurants ? { ...all, ...rests } : rests

        this.setState({
            restaurants,
            filtered: this.filter( restaurants, search ),
        })
    }

    getTitle = ( id, list ) => {
        if ( !list || id === '-1' ) { return '?' }
        const found = list.find(( r ) => parseInt( r.factsNumber ) === parseInt( id ))
        return found ? found.restaurantName : 'KFC #' + id
    }

    onChange = ( id ) => {
        this.props.setCurrentRestaurant( id )
        this.props.setRestaurant( id )
    }

    onFilter = ( e ) =>
        this.setState({
            search: e.target.value,
            filtered: this.filter( this.state.restaurants, e.target.value ),
        })

    filter = ( data, search ) => {
        const res = {}

        Object.keys( data )
            .filter(( id ) => data[ id ].toLowerCase().indexOf( search.toLowerCase()) > -1 )
            .map(( id ) => ( res[ id ] = data[ id ]))

        return res
    }

    render () {
        const { loading, timeZoneName } = this.props,
              { restaurants, filtered, current, title, search } = this.state,
              searchOffset = search ? 48 : 0,
              datesInStorage = JSON.parse( sessionStorage.getItem( 'filtersData' )),
              { end, start } = getGroup( 'shift', datesInStorage ? datesInStorage.workHours : null, null, timeZoneName )

        if ( datesInStorage && datesInStorage.predef === 'shift' ) {
            datesInStorage.start = start.format( config.format.date )
            datesInStorage.end = end.format( config.format.date )

            sessionStorage.setItem( 'filtersData', JSON.stringify( datesInStorage ))
        }

        if ( loading || !restaurants ) {
            return <Spinner />
        }

        if ( Object.keys( restaurants ).length === 1 ) {
            return <h2 className="restaurant-title">{title}</h2>
        }

        const placeholder = restaurants[ current ] || '...',
              height =
        Object.keys( filtered ).length > 0
            ? Math.min( 290, Object.keys( filtered ).length * 40 + searchOffset )
            : 100

        return (
            <ViewTypeDrop
                className="restaurants-drop"
                placeholder={placeholder}
                items={filtered}
                current={current}
                height={height}
                search={search}
                filter={Object.keys( restaurants ).length > 10}
                filterPlaceholder="Фильтр по названию"
                onFilter={this.onFilter}
                update={this.onChange}
            />
        )
    }
}

const mapStateToProps = ( state ) => ({
    loading: state.user.loading,
    restaurant: state.user.restaurant,
    restaurants: state.restaurants.list,
    request: state.request,
}),

      allActions = {
          ...userActions,
          ...requestActions,
          ...restaurantActions,
      }

export default connect( mapStateToProps, allActions )( RestaurantTitle )
