import { Col }      from 'antd'
import React        from 'react'
import dayjs        from 'dayjs'
import config       from 'config'
import { PprTable } from 'components'
import { format }   from 'tools'
import './ppr-restaurant-data.scss'

const formatAPI = config.format.monthAPI

const columns = {
    period: 'Месяц',
    restaurantName: 'Ресторан',
    planTurnover: 'ТО',
    spmh: 'Производительность',
    hours: 'Загрузка по часам'
}

const PprRestaurantData = ({ data, loading, error, onRetry }) => {
    const getField = ( key ) => {
        if ( !data?.[ key ]) {
            return config.ui.nullSymbol
        }
        switch ( key ) {
            case 'period':
                return format.strings.capitalize( dayjs( data?.[ key ], formatAPI ).format( 'MMMM' ))
            default:
                return data?.[ key ]
        }
    }

    return (
        <Col span={12}>
            <PprTable
                horizontal
                className='ppr-restaurant-data'
                loading={loading}
                error={error}
                onRetry={() => onRetry( 'restaurantData' )}
            >
                <tbody>
                    {Object.keys( columns ).map(( key ) => <tr key={key}>
                        <th>{columns[ key ]}</th>
                        <td>{getField( key )}</td>
                    </tr> )}
                </tbody>
            </PprTable>
        </Col>
    )
}

export default PprRestaurantData
