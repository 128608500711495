/* VENDOR */
import axios from 'axios'

/* APPLICATION */
import config     from 'config'
import { format } from 'tools'

const service = 'dashboard',
      date = [ 'date' ]

export const getOverview = ( page, params ) =>
    axios.post( config.api[ service ] + `goals/search?page=${page}`, params, config.api.getOptions())

// export const getPlanning = ( params ) =>
//     format.generate.get(
//         service,
//         'goals/search/' + params.restaurantId,
//         params,
//         '',
//         date
//     )

export const getPlanning = ( params ) =>
    axios.post( config.api[ service ] + 'goals/search', params, config.api.getOptions())

export const updateTargets = ( params ) =>
    axios.post( config.api[ service ] + 'goals', params.body, {
        ...format.generate.params( params.query ),
        ...config.api.getOptions(),
    })

export const updateRestaurantTargets = ( params ) =>
    axios.patch(
        config.api[ service ] + 'goals/' + params.restaurantId,
        params.body,
        {
            ...format.generate.params( params.query ),
            ...config.api.getOptions(),
        }
    ).catch(( error ) => {
        if ( error.status === 401 || error.status === 403 ) {
            format.update.goToLogin()
        }
    })

export const getUnknownLastYear = ( params ) =>
    axios.post( `${config.api[ service ]}goals/search`, params, config.api.getOptions())
