/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { connect }          from 'react-redux'

/* APPLICATION */
import { LoadMoreButton } from 'components'
import { requestActions } from 'services'
import { format }         from 'tools'

class Pager extends Component {
    static propTypes = {
        onMore: PropTypes.func,
        check: PropTypes.any,
    }

    constructor ( props ) {
        super( props )
        this.state = { loading: false, }
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        if ( !format.compare.basic( nextProps.check, this.props.check )) {
            this.setState({ loading: false, })
        }
    }

    last = () => ( this.props.check ? this.props.check.last : true )

    next = () => {
        this.props.onMore(
            format.generate.pager( this.props.request, this.props.check.number + 1 )
        )

        this.setState({ loading: true, })
    }

    render () {
        return (
            <div className="pager-wrap">
                {this.props.children}

                {!this.last() && !!this.props.onMore && (
                    <LoadMoreButton onClick={this.next} loading={this.state.loading} />
                )}
            </div>
        )
    }
}

const mapStateToProps = ( state ) => ({ request: state.request, })

export default connect( mapStateToProps, requestActions )( Pager )
