/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'

/* APPLICATION */
import { ReportTable, SyncScroll } from 'components'

import { format } from 'tools'
import config     from 'config'

import './schedule-table.scss'

class ScheduleTableView extends Component {
    static propTypes = {
        data: PropTypes.array,
        positions: PropTypes.array,
        workHours: PropTypes.object,
        day: PropTypes.string,

        loading: PropTypes.bool,

        form: PropTypes.object,
        to: PropTypes.object,
        max: PropTypes.array,

        onSelect: PropTypes.func,
    }

    view = React.createRef()

    constructor ( props ) {
        super( props )
        this.state = {
            data: props.data,
            columns: [],
        }
    }

    componentDidMount () {
        this.init()
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        if ( nextProps.data !== this.props.data ) {
            this.update( nextProps.data )
            this.init()
        }
    }

    componentDidUpdate () {
        window.dispatchEvent( new Event( 'resize' ))
    }

    init = () =>
        this.setState({ columns: this.columns(), })

    update = ( data ) =>
        this.setState({ data }, () => {
            const { workHours, day } = this.props,
                  view = this.view.current,
                  hours = [].slice.call( view.querySelectorAll( '.ant-table-scroll th' )),
                  cday = workHours.days.find(( d ) => d.dayOfWeek === parseInt( day )),
                  start = cday.timeStart,
                  end = cday.timeEnd

            let started = false

            hours.forEach(( h ) => {
                h.innerText === start && ( started = true )
                h.style.fontWeight = started ? 'bold' : 'normal'
                h.innerText === end && ( started = false )
            })
        })
    change = ( rec, key, val ) => this.props.onSelect( val )

    columns = () => {
        const { helpers, from, to, max } = this.props,
              def = format.copy.array( config.tables.schedule.top )

        return [ ...def, ...helpers.hourscols( from, to, true, max ) ]
    }

    render () {
        const { helpers, loading, from, to, positions } = this.props,
              { data, columns } = this.state

        return (
            <div
                className="staff-schedule-table report-table col-hoverable report-center"
                ref={this.view}
            >
                <ReportTable
                    data={format.generate.noPager( data )}
                    columns={columns}
                    select={{ positions: positions }}
                    scroll={{ x: helpers.width( from, to ) }}
                    rowKey="key"
                    loading={!this.props.data || loading}
                    onChange={this.change}
                />

                <div className="tabbed-footer">
                    <SyncScroll
                        connect="schedule"
                        apply=".staff-schedule-table"
                        height={20}
                    />
                </div>
            </div>
        )
    }
}

export default ScheduleTableView
