/* VENDOR */
import axios from 'axios'

/* APPLICATION */
import config     from 'config'
import { format } from 'tools'

const { storage } = format.generate,
      gen = ( type ) => {
          return ( data ) => {
              const name = type === 'daily-schedule' ? 'chart/schedules' : 'reports/' + type

              storage[ name ] && storage[ name ]()
              if ( type === 'daily-schedule' || name === 'reports/ready' ) {
                  data.params.factsNumber = data.params.factsNumber ? data.params.factsNumber : data.params.restaurantId
                  delete data.params.restaurantId
              }

              return axios.get( config.api.planning + name, {
                  ...data,
                  ...config.api.getOptions(),
                  ...format.generate.cancel( name ),
              })
          }
      }

export const getDailyScheduleReport = gen( 'daily-schedule' )
export const getScheduleReport = gen( 'schedule' )
export const getWorktimeReport = gen( 'work-time' )
export const getFactworktimeReport = gen( 'fact-work-time' )
export const getReadyReport = gen( 'ready' )
export const getStatisticsReport = gen( 'statistics' )
export const getWorkedtimeReport = gen( 'worked-time' )

export const getStationsReport = ( data ) => {
    const name = 'reports/stations'

    storage[ name ] && storage[ name ]()

    return axios.get( config.api.dashboard + name, {
        ...data,
        ...config.api.getOptions(),
        ...format.generate.cancel( name ),
    })
}

export const getIByTimeReport = ( params ) =>
    params.restaurantId
        ? format.generate.get(
            'planning',
            'reports/interactive/time/' + params.restaurantId,
            params,
            null,
            null,
            'IByTime'
        )
        : format.generate.get(
            'planning',
            'reports/interactive/time',
            params,
            null,
            null,
            'IByTime'
        )
