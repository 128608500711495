/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Checkbox, Spin }   from 'antd'

/* APPLICATION */
import './spin-check.scss'

class SpinCheck extends Component {
    static propTypes = {
        checked: PropTypes.bool,
        disabled: PropTypes.bool,
        wait: PropTypes.bool,
        onChange: PropTypes.func,
    }

    constructor ( props ) {
        super( props )
        this.state = { loading: false, }
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { checked, disabled } = this.props

        if ( checked !== nextProps.checked || disabled !== nextProps.disabled ) {
            this.setState({ loading: false })
        }
    }

    onChange = ( e ) => {
        this.props.wait && this.setState({ loading: true })
        this.props.onChange( e )
    }

    render () {
        if ( this.state.loading ) { return <Spin size="small" className="spin-check" /> }

        return <Checkbox {...this.props} onChange={this.onChange} />
    }
}

export default SpinCheck
