/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Card, Tabs }       from 'antd'
import dayjs                from 'dayjs'

/* APPLICATION */
import {
    InteractiveControls,
    ReportTable,
    Spinner,
    BackTitle,
} from 'components'
import { format } from 'tools'
import config     from 'config'

import './interactive-reports.scss'

class InteractiveReports extends Component {
    static propTypes = {
        data: PropTypes.object,
        companies: PropTypes.array,

        flush: PropTypes.func,
        fetch: PropTypes.func,
    }

    constructor ( props ) {
        super( props )
        this.state = {
            tab: 'bytime',
            deep: null,
            loading: !props.data,

            outstaff: 'all',
            company: 'all',
            byService: false,
            byPosition: false,

            dates: [ dayjs().startOf( 'week' ), dayjs().endOf( 'week' ) ],
        }
    }

    componentDidMount () {
        this.load()
    }

    componentWillUnmount () {
        this.props.flush()
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { data } = this.props

        data !== nextProps.data && this.setState({ loading: false })
    }

    load = () => this.props.fetch( this.params())

    params = () => {
        const { outstaff, company, byService, byPosition, dates, deep } =
        this.state,
              params = {
                  employeeType: outstaff,
                  byService,
                  byPosition,
                  dateStart: dates[ 0 ].format( config.format.date ),
                  dateEnd: dates[ 1 ].format( config.format.date ),
              }

        outstaff === 'outstaff' &&
      company !== 'all' &&
      ( params.companyUuid = company )
        deep && ( params.restaurantId = deep.factsNumber )

        return params
    }

    onLoad = ( state ) =>
        this.setState(
            {
                ...state,
                loading: true,
            },
            this.load
        )

    setDates = ( dates ) => this.onLoad({ dates })
    setOutstaff = ( outstaff ) => this.onLoad({ outstaff })
    setCompany = ( company ) => this.onLoad({ company })
    setByService = ( byService ) => this.onLoad({ byService })
    setByPosition = ( byPosition ) => this.onLoad({ byPosition })

    data = () => {
        const { data } = this.props,
              { deep, byService, byPosition } = this.state,
              res =
        data && data.time
            ? deep
                ? deep && !byPosition
                    ? data.time.sort(( a, b ) =>
                        a.employeeName.localeCompare( b.employeeName )
                    )
                    : data.time
                : [
                        {
                            restaurantName: 'Итого',
                            factsNumber: '',
                            sumPlanSchedule: data.time.reduce(
                                ( s, r ) => s + ( r.sumPlanSchedule || 0 ),
                                0
                            ),
                            sumPlanContract: data.time.reduce(
                                ( s, r ) => s + ( r.sumPlanContract || 0 ),
                                0
                            ),
                            fact: data.time.reduce(( s, r ) => s + ( r.fact || 0 ), 0 ),
                            sumDelta: data.time.reduce(
                                ( s, r ) => s + ( r.sumDelta || 0 ),
                                0
                            ),
                        },
                        ...data.time.map(( row ) => {
                            row.rowClick = this.deep( row )
                            row._popover = byService

                            return row
                        }),
                    ]
            : null

        return format.generate.noPager( res )
    }

    columns = () =>
        this.state.byService
            ? config.tables.reports.time
            : config.tables.reports.time.slice( 0, -1 )

    deep = ( deep ) => () => {
        if ( this.state.byService ) { return }
        this.onLoad({ deep })
    }

    undeep = () => this.onLoad({ deep: null })

    deepColumns = () => {
        const { byPosition } = this.state,
              res = format.copy.array( config.tables.reports.time )

        res[ 0 ] = byPosition
            ? {
                    title: 'Позиция',
                    dataIndex: 'positionName',
                }
            : {
                    title: 'Сотрудник',
                    dataIndex: 'employeeName',
                    render: ( text, r ) =>
                        r.outstaff ? <span className="outstaff">{text}</span> : text,
                }

        delete res[ 1 ]
        res.pop()

        res.forEach(( col ) => {
            switch ( col.dataIndex ) {
                case 'sumPlanSchedule':
                    col.dataIndex = 'plan'
                    break

                case 'sumPlanContract':
                    col.dataIndex = 'planContract'
                    break

                case 'sumDelta':
                    col.dataIndex = 'delta'
                    break

                default:
        //Do nothing
            }
        })

        return res
    }

    download = () => {
        const { deep } = this.state

        let url = deep
            ? process.env.REACT_APP_PLANNING_API +
        'reports/interactive/time/' +
        deep.factsNumber +
        '/excel'
            : process.env.REACT_APP_PLANNING_API + 'reports/interactive/time/excel'

        url += format.generate.query( this.params())

        window.open( url )
    }

    render () {
        const { loading, deep } = this.state,
              { dates, outstaff, company, byService, byPosition } = this.state,
              { companies } = this.props,
              controls = (
                  <InteractiveControls
                      dates={dates}
                      outstaff={outstaff}
                      company={company}
                      checked={deep ? byPosition : byService}
                      companies={companies}
                      check={deep ? 'По позициям' : 'Разбить по станциям'}
                      onDates={this.setDates}
                      onOutstaff={this.setOutstaff}
                      onCompany={this.setCompany}
                      onCheck={deep ? this.setByPosition : this.setByService}
                      onDownload={this.download}
                  />
              )

        const tabs = [
            {
                key: 'bytime',
                label: 'Сводный по времени',
                children: (
                    <React.Fragment>
                        {controls}
                        <div className="report-table">
                            {loading ? (
                                <Spinner />
                            ) : (
                                <ReportTable
                                    rowKey={( r ) =>
                                        '' + r.factsNumber + r.positionCategoryName
                                    }
                                    styleInactive={false}
                                    data={this.data()}
                                    columns={this.columns()}
                                />
                            )}
                        </div>
                    </React.Fragment>
                )
            }
        ]

        return (
            <div className="interactive-reports-card">
                <Card bordered={false}>
                    {deep ? (
                        <div className="interactive-deep">
                            <BackTitle text={deep.restaurantName} action={this.undeep} />
                            {controls}
                            <div className="report-table">
                                {loading ? (
                                    <Spinner />
                                ) : (
                                    <ReportTable
                                        rowKey={( r ) => '' + r.employeeName + r.positionName}
                                        styleInactive={false}
                                        data={this.data()}
                                        columns={this.deepColumns()}
                                    />
                                )}
                            </div>
                        </div>
                    ) : (
                        <Tabs
                            animated={false}
                            onChange={this.setView}
                            activeKey={this.state.tab}
                            items={tabs}
                        >
                        </Tabs>
                    )}
                </Card>
            </div>
        )
    }
}

export default InteractiveReports
