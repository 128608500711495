/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Table, Spin }      from 'antd'

/* APPLICATION */
import { TimeTag, Relation, Value } from 'components'

import { format } from 'tools'

import './compare-table.scss'

const columns = [
    {
        dataIndex: 'title',
        key: 'title',
        width: '70%',
    },
    {
        dataIndex: 'data',
        key: 'data',
        width: '30%',
    },
]

class CompareTable extends Component {
    static propTypes = {
        rows: PropTypes.array,
        title: PropTypes.string,

        noTitle: PropTypes.bool,
        useHit: PropTypes.bool,

        format: PropTypes.PropTypes.oneOfType([ PropTypes.func, PropTypes.string ]),
    }

    constructor ( props ) {
        super( props )

        this.state = { data: [], }
    }

    componentDidMount () {
        this.buildRows( this.props )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        nextProps.rows !== this.props.rows && this.buildRows( nextProps )
    }

    format = {
        get: ( item ) => {
            if ( this.format.none( item )) { return format.strings.thousand }

            return item.format
                ? this.format.func( item.format )
                : this.format.func( this.props.format )
        },
        none: ( item ) => !item.format && !this.props.format,
        func: ( func ) => ( typeof func === 'string' ? format.strings[ func ] : func ),
    }

    element = ( item ) => {
        const formatter = this.format.get( item )

        switch ( item.type ) {
            case 'spin':
                return <Spin size="small" />

            case 'delta':
                return (
                    <Relation
                        fact={item.fact}
                        value={item.value}
                        addon={item.currency}
                        format={formatter}
                        hit={null}
                        useHit={false}
                        status={item.status}
                    />
                )

            case 'relation':
                return (
                    <Relation
                        fact={item.fact}
                        plan={item.plan}
                        addon={item.currency}
                        format={formatter}
                        invert={item.invert}
                        hit={item.hit}
                        useHit={this.props.useHit}
                        status={item.status}
                    />
                )

            case 'value':
                return (
                    <Value
                        value={item.value}
                        refValue={item.refValue}
                        addon={item.currency}
                        format={formatter}
                    />
                )

            default:
                return (
                    <Value
                        value={item.value}
                        refValue={item.refValue}
                        addon={item.currency}
                        format={formatter}
                    />
                )
        }
    }

    buildRows = ( props ) => {
        const res = []

        if ( !props.rows ) { return }

        props.rows.map(( item, index ) =>
            res.push({
                key: index,
                title: item.title,
                data: this.element( item ),
            })
        )

        this.setState({ data: res, })
    }

    render () {
        const { noTitle, title, className } = this.props,
              { data } = this.state,
              cls = [ 'compare-table' ]

        className && cls.push( className )

        return (
            <div className={cls.join( ' ' )}>
                {!noTitle && <TimeTag text={title} />}
                <Table
                    dataSource={data}
                    columns={columns}
                    showHeader={false}
                    pagination={false}
                    locale={{ emptyText: 'Информация отсутствует' }}
                />
            </div>
        )
    }
}

export default CompareTable
