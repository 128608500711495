/* IMPORT */
import {
    planningActions,
    requestActions,
    performanceActions,
    restaurantActions,
} from 'services'

/* EXPORT */

export const mapStateToProps = ( state ) => ({
    request: state.request,
    user: state.user,
    overview: state.planning.overview,
    error: state.planning.error,
    errors: state.serverErrors,
    origins: state.restaurants.origins,
    updated: state.planning.updated,
    lastYear: state.planning.lastYear,
})

export const allActions = {
    ...planningActions,
    ...requestActions,
    ...performanceActions,
    ...restaurantActions,
}
