/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Input }            from 'antd'

/* APPLICATION */
import './classic-pager.scss'

class ClassicPager extends Component {
    static propTypes = {
        page: PropTypes.number,
        total: PropTypes.number,

        onChange: PropTypes.func,
    }
    state = { page: 1 }

    prev = () => {
        this.props.onChange( this.props.page - 1 )
        this.setState({ page: this.props.page })
    }
    next = () => {
        this.props.onChange( this.props.page + 1 )
        this.setState({ page: this.props.page + 2 })
    }
    change = () => {
        // const rpage = this.props.page
        const rtotal = this.props.total
        let page = Math.abs( this.state.page )
        if ( page === 0 ) {
            page = 1
        }
        if ( page > rtotal ) {
            page = rtotal
        }

        this.props.onChange(
            Math.max( 0, Math.min( this.props.total - 1, parseInt( page ) - 1 ))
        )

        this.setState({ page })
    }

    render () {
        const { page, total } = this.props,
              prevCls = [ 'clpg-prev' ],
              nextCls = [ 'clpg-next' ]

        let rpage = page,
            rtotal = total

        if ( total < 2 ) {
            rpage = 0
            rtotal = 1
        }

        rpage === 0 && prevCls.push( 'disabled' )
        rpage === rtotal - 1 && nextCls.push( 'disabled' )

        return (
            <div className="classic-pager">
                <div className={prevCls.join( ' ' )} onClick={this.prev}></div>
                <div className="clpg-label">Страница</div>
                <div className="clpg-page">
                    <Input
                        value={this.state.page}
                        onKeyDown={( e ) => {
                            if ( e.key === 'Enter' ) {
                                this.change()
                            }
                        }}
                        type="number"
                        min={1}
                        max={rtotal + 1}
                        onChange={( e ) => this.setState({ page: e.target.value })}
                    />
                </div>
                <div className="clpg-from">из</div>
                <div className="clpg-total">{rtotal}</div>
                <div className={nextCls.join( ' ' )} onClick={this.next}></div>
            </div>
        )
    }
}

export default ClassicPager
