/* VENDOR */
import axios from 'axios'

/* APPLICATION */
import config     from 'config'
import { format } from 'tools'

const rid = [ 'restaurantId' ]
const service = 'planning'

export const getSchedules = ( params ) =>
    format.generate.get( service, 'chart/schedules/actual-dates', params, '', rid )
export const getSchedule = ( params ) =>
    format.generate.get( service, 'chart/schedules/', params )

export const getReportsSchedulesSummary = ({ dayOne, dayTwo }) =>
    axios.all([
        format.generate.get( 'facade', 'reports/schedules/summary', dayTwo, 'currentWeek' ),
    ])

export const getAssignment = ( params ) =>
    format.generate.get( service, 'chart/schedules/assignment', params )

export const getSideDays = ({ dayOne, dayTwo }) =>
    axios.all([
        format.generate.get( 'dashboard', 'turnover-report', dayOne, 'prev' ),
        format.generate.get( 'dashboard', 'turnover-report', dayTwo, 'next' ),
    ])

export const setScheduleDay = ( data ) =>
    axios.post( config.api.planning + 'chart/schedules/', data.body, {
        ...format.generate.params( data.params ),
        ...config.api.getOptions(),
    })

export const newSchedule = ( data ) =>
    axios.post( config.api.planning + 'chart/schedules/new', data.body, {
        ...config.api.getOptions(),
        ...format.generate.params( data.params ),
    })

export const setTurnover = ( data ) =>
    format.generate.post(
        'dashboard',
        'goals/set-turnoverRgm/',
        data.body,
        data.restaurantId,
        ''
    )

export const setHiddenEmployees = ( data ) =>
    axios.post( config.api.planning + 'schedules/employees-hided', data, config.api.getOptions())

export const deleteHiddenEmployees = ( uuid ) =>
    axios.delete( config.api.planning + 'schedules/employees-hided/' + uuid, config.api.getOptions())
