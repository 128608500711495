import dayjs               from 'dayjs'
import React, { useState } from 'react'
import { Form }            from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import classNames          from 'classnames'
import { ReportsControls } from 'components'
import config              from 'config'
import {
    Button,
    Select,
    Spin
} from 'antd'
import './ppr-partner-header.scss'

const { Option } = Select

const formatView = config.format.monthView,
      formatAPI = config.format.monthAPI

const PprPartnerHeader = ({ ...props }) => {
    const [ loadingPartners, setLoadingPartners ] = useState( false )
    const [ form ] = Form.useForm()

    const { getFieldDecorator } = props.form,
          date = props.date ? dayjs( props.date, formatView ) : null

    const arrow = ( key, handler, disabled ) => {
        return <span
            className={classNames([ 'pager-arrow', `arrow-${key}`, !disabled && 'active' ])}
            onClick={() => !disabled && props.onChange( handler())}
        ></span>
    }

    const changePartner = ( value ) => {
        //TODO: изменение селекта партнера
    }

    const option = ({ userId, scheduleName, fullName }) => (
        <Option key={userId} value={userId}>{scheduleName || fullName}</Option>
    )

    const handleSubmit = e => {
        e.preventDefault()
        props.form.validateFields(( err, values ) => {
            if ( !err ) {
                //TODO: обновление данных
            }
        })
    }

    return (
        <header className="ppr-partner-header">
            <Spin spinning={!props.periods}>
                <div className="ppr-partner-header-inner">
                    <div className="header-title">{props.periods && dayjs( date ).format( formatView )}</div>
                    <div className="header-picker controls-pager">
                        {arrow(
                            'prev',
                            () => dayjs( date ).subtract( 1, 'month' ),
                            !props.periods?.find( period => dayjs( date ).subtract( 1, 'month' ).isSame( period, 'month' ))
                        )}
                        <ReportsControls
                            type="month"
                            date={dayjs( date ).startOf( 'month' )}
                            onChange={props.onChange}
                            showDate={false}
                            disabledDate={( date ) => !props.periods?.some( period => dayjs( period, formatAPI ).isSame( date ))}
                        />
                        {arrow(
                            'next',
                            () => dayjs( date ).add( 1, 'month' ),
                            !props.periods?.find( period => dayjs( date ).add( 1, 'month' ).isSame( period, 'month' ))
                        )}
                    </div>
                    <div className='vertical-line' />
                    <Form
                        name='PprPartnerHeader'
                        form={form}
                        onFinish={handleSubmit}
                        className='partner-controls'
                    >
                        <Form.Item>
                            <div className="ac-col">
                                <label htmlFor="partnerSelect">Партнер</label>
                                {getFieldDecorator( 'userId', { rules: [ { required: true, message: 'Выберите партнера' } ], })(
                                    <Select
                                        id='partnerSelect'
                                        dropdownClassName='ppr-area-manager-select'
                                        placeholder={'Выберите из списка'}
                                        style={{ width: 200 }}
                                        filterOption={false}
                                        onChange={changePartner}
                                        loading={loadingPartners}
                                        defaultActiveFirstOption={false}
                                    >
                                    </Select>
                                )}
                            </div>
                        </Form.Item>
                        <Button id='partnerUpdate' htmlType="submit" type='primary'>Обновить</Button>
                    </Form>

                </div>
            </Spin>

        </header>
    )
}

export default PprPartnerHeader
