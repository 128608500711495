/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'

/* APPLICATION */
import { CardChart } from 'components'
import { format }    from 'tools'

class PerformanceChart extends Component {
    static propTypes = {
        plan: PropTypes.array,
        fact: PropTypes.array,
        height: PropTypes.number,
        offset: PropTypes.string,
    }

    plan = () =>
        this.props.plan
            ? format.extract.graph( this.props.plan, 'time', 'planSpmh' )
            : null

    fact = () =>
        this.props.fact
            ? format.extract.graph( this.props.fact, 'time', 'factSpmh' )
            : null

    compare = ( fact, plan ) => fact >= plan && fact / plan <= 1.05

    render () {
        const { height } = this.props

        return (
            <CardChart
                height={height}
                unit="₽"
                plan={this.props.plan}
                fact={this.props.fact}
                offset={this.props.offset}
                compare={this.compare}
            />
        )
    }
}

export default PerformanceChart
