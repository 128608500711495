/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Card }             from 'antd'

/* APPLICATION */
import { ReportTable, Link } from 'components'
import { format }            from 'tools'
import config                from 'config'

class PositionsOld extends Component {
    static propTypes = {
        data: PropTypes.array,
        onChange: PropTypes.func,
        parent: PropTypes.object,
    }

    constructor ( props ) {
        super( props )

        this.state = { sent: false, }

        this.set = format.generate.set( this )
    }

    componentDidMount () {
        !!this.props.data && this.checkMC()
    }

    componentDidUpdate ( prevProps ) {
        const { data } = this.props

        if ( data !== prevProps.data && data ) {
            this.checkMC()
        }
    }

    checkMC = () => {
        if ( this.state.sent ) { return }

        const { data } = this.props,
              mc = data.find(
                  ( row ) => row.positionGuid === '44081e01-c6d1-4e00-b51c-0a86f7ac69b6'
              )

        if ( mc && !mc.chart ) {
            this.props.onChange( mc, 'chart', true )
            this.set.sent( true )
        }
    }

    render () {
        return (
            <Card bordered={false}>
                <div className="report-table left-off">
                    <div className="aside-container">
                        <div style={{ maxWidth: 600 }}>
                            <ReportTable
                                data={format.generate.noPager( this.props.data )}
                                columns={config.tables.settings.positionsOld}
                                rowKey="positionGuid"
                                filter={false}
                                onChange={this.props.onChange}
                            />
                        </div>
                        <aside>
                            <span>
                Отмеченные позиции учитываются{' '}
                                <Link onClick={() => this.props.parent.setView( 'manning' )}>
                  в чарте
                                </Link>.<br/>
                                Позиции, вошедшие в чарт, являются производительными (за исключением МС)
                            </span>
                        </aside>
                    </div>
                </div>
            </Card>
        )
    }
}

export default PositionsOld
