import React, { useState, useEffect } from 'react'
import { Modal }                      from 'antd'
import { Form }                       from '@ant-design/compatible'
import dayjs                          from 'dayjs'

import { FormField } from 'components'

import config from 'config'

const StopSyncModal = ({ visible, editable, data, form, onSave, onClose }) => {
    const [ changed, $changed ] = useState( data || {}),
          fieldsList = () => [
              {
                  field: 'comment',
                  label: 'Комментарий',
                  type: 'autoheight',
                  value: changed.comment,
                  rules: [
                      config.rules.required,
                      config.rules.length(
                          0,
                          100,
                          'Текст не может быть длиннее 100 символов',
                          false
                      ),
                  ],
              },
              {
                  field: 'stopSyncUntil',
                  type: 'date',
                  format: 'D MMM YYYY',
                  value: changed.stopSyncUntil,
                  hideToday: true,
                  disabledDate: ( d ) => d.isBefore( dayjs()),
              },
          ],
          fieldItem = ( cfg ) => <FormField {...cfg} key={cfg.field} form={form} />,
          preSubmit = () => {
              form.validateFieldsAndScroll(( err, values ) => {
                  if ( !err ) {
                      onSave( values )
                      form.resetFields()
                  }
              })
          }

    useEffect(() => {
        $changed( data || {})
    }, [ data ])

    useEffect(() => {
        form.resetFields()
    }, [ visible ])

    return (
        <Modal
            className="kfc-popup kfc-stopsync-comment"
            centered={true}
            open={visible}
            title="Отключение от синхронизации"
            okText="Сохранить"
            onOk={preSubmit}
            okButtonProps={editable ? {} : { style: { display: 'none' } }}
            onCancel={onClose}
            cancelText="Закрыть"
        >
            {editable ? (
                <Form {...config.ui.wideForm}>{fieldsList().map( fieldItem )}</Form>
            ) : (
                <>
                    <p>
            Отключил: <b>{data?.userId}</b>
                    </p>
                    <p>{data?.comment}</p>
                </>
            )}
        </Modal>
    )
}

export default Form.create({ name: 'AddEvent' })( StopSyncModal )
