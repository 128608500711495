/* VENDOR */
import React                                       from 'react'
import { Tooltip }                                 from 'antd'
import { CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'
import dayjs                                       from 'dayjs'

/* APPLICATION */
import column from './helpers'

export default {
    overview: [
        column( 'Название', 'restaurantName', {
            sorter: 'locale',
            action: true,
            render: ( text, rec ) =>
                rec.isGrossAccounting ?
                        <Tooltip title='Ресторан использует gross показатели для отображения данных по фактическим продажам'>
                            <span className="gross-rest">{text}</span>
                        </Tooltip>
                    : text,
        }),
        column( 'Код', 'factsNumber', { sorter: 'bynull', }),
        column( 'Город', 'city', {
            sorter: 'locale',
            render: ( text ) => <span title={text}>{text}</span>,
        }),
        column( 'План SPMH', 'planSpmh', {
            numeric: true,
            editable: true,
            as: 'number',
            sorter: 'bynull',
            format: 'thousand',
        }),
        column( 'План ТО ТУ', 'planTurnoverAreaCoach', {
            numeric: true,
            useValue: true,
            editable: true,
            nullable: true,
            as: 'currency',
            sorter: 'bynull',
        }),
        column( 'План ТО Директора', 'planTurnoverRgm', {
            numeric: true,
            useValue: true,
            as: 'currency',
            sorter: 'bynull',
        }),
        column(
            <div className="delta-turnover">
                <span>Разница План ТО</span>
                <Tooltip title='Разница между "План ТО ТУ" и "План ТО Директора"'>
                    <InfoCircleOutlined />
                </Tooltip>
            </div>,
            'deltaPlanTurnover',
            {
                numeric: true,
                as: 'deltaCurrency',
                cell: [ 'cellclass' ],
                sorter: 'bynull',
                isRedPositive: true,
            }
        ),
        column( 'Факт ТО', 'factTurnover', {
            numeric: true,
            as: 'currency',
            format: 'thousand',
            sorter: 'bynull',
        }),
        column(
            <div className="delta-turnover">
                <span>Разница</span>
                <Tooltip title='Разница между "Факт ТО" и "План ТО Директора"'>
                    <InfoCircleOutlined />
                </Tooltip>
            </div>,
            'deltaTurnover',
            {
                numeric: true,
                as: 'currency',
                format: 'thousand',
                cell: [ 'cellclass' ],
                sorter: 'bynull',
            }
        ),
        column( 'Прошлый год', 'factTurnoverLastYear', {
            numeric: true,
            as: 'currency',
            format: 'thousand',
            sorter: 'bynull',
        }),
        column(
            <div className="delta-turnover">
                <span>Разница прошлый год</span>
                <Tooltip title='Разница между  "Факт ТО" и "Прошлый год"'>
                    <InfoCircleOutlined />
                </Tooltip>
            </div>,
            'deltaTurnoverLastYear',
            {
                numeric: true,
                as: 'currency',
                format: 'thousand',
                cell: [ 'numeric' ],
                sorter: 'bynull',
            }
        ),
    ],
    planning: [
        column( 'Дата', 'date', {
            format: 'dowDate',
            sorter: 'bymoment',
        }),
        column( 'План ТО ТУ', 'planTurnoverAreaCoach', {
            numeric: true,
            as: 'currency',
            format: 'thousand',
            sorter: 'bynull',
        }),
        column( 'План ТО Директора', 'planTurnoverRgm', {
            editable: true,
            useValue: true,
            numeric: true,
            as: 'currency',
            format: 'thousand',
            sorter: 'bynull',
            width: '10%',
            disabled: ( record ) => record.date.isBefore( dayjs()),
        }),
        column( '', 'planTurnoverRgmChanged', {
            width: '5%',
            render: ( value ) => value === true && (
                <span>
                    <CloseCircleOutlined />
                </span>
            )
        }),
        column( 'Дата прогноза', 'predictDate', {
            editable: true,
            allowClear: true,
            disabled: ( record ) => record.date.isBefore( dayjs()),
        }),
        column(
            <div className="delta-turnover">
                <span>Дата План ТО ТУ</span>
                <Tooltip title='Дата добавления/изменения поля "План ТО ТУ"'>
                    <InfoCircleOutlined />
                </Tooltip>
            </div>,
            'createdAt',
            {
                numeric: true,
                as: 'date',
                cell: [ 'cellclass' ],
                sorter: 'bynull',
            }),
        column(
            <div className="delta-turnover">
                <span>Дата План ТО Директора</span>
                <Tooltip title='Дата добавления/изменения поля "План ТО Директора"'>
                    <InfoCircleOutlined />
                </Tooltip>
            </div>,
            'modifiedAt',
            {
                numeric: true,
                as: 'date',
                cell: [ 'cellclass' ],
                sorter: 'bynull',
            }),
        column(
            <div className="delta-turnover">
                <span>Разница План ТО</span>
                <Tooltip title='Разница между "План ТО ТУ" и "План ТО Директора"'>
                    <InfoCircleOutlined />
                </Tooltip>
            </div>,
            'deltaPlanTurnover',
            {
                numeric: true,
                as: 'deltaCurrency',
                cell: [ 'cellclass' ],
                sorter: 'bynull',
                isRedPositive: true,
            }),
        column( 'Факт ТО', 'factTurnover', {
            numeric: true,
            as: 'currency',
            format: 'thousand',
            sorter: 'bynull',
        }),
        column(
            <div className="delta-turnover">
                <span>Разница</span>
                <Tooltip title='Разница между "Факт ТО" и "План ТО Директора"'>
                    <InfoCircleOutlined />
                </Tooltip>
            </div>,
            'deltaTurnover',
            {
                numeric: true,
                as: 'deltaCurrency',
                sorter: 'bynull',
                isRedPositive: false,
            }
        ),
        column( 'Прошлый год', 'factTurnoverLastYear', {
            numeric: true,
            as: 'currency',
            format: 'thousand',
            sorter: 'bynull',
        }),
        column(
            <div className="delta-turnover">
                <span>Разница прошлый год</span>
                <Tooltip title='Разница между  "Факт ТО" и "Прошлый год"'>
                    <InfoCircleOutlined />
                </Tooltip>
            </div>,
            'deltaTurnoverLastYear',
            {
                numeric: true,
                as: 'currency',
                format: 'thousand',
                cell: [ 'cellclass' ],
                sorter: 'bynull',
            }
        ),
        column( 'План СЧ', 'planAverageCheck', {
            numeric: true,
            useValue: true,
            as: 'currency',
            format: 'thousand',
            sorter: 'bynull',
        }),
        column( 'Факт СЧ', 'factAverageCheck', {
            numeric: true,
            as: 'currency',
            format: 'thousand',
            sorter: 'bynull',
        }),
        column( 'Разница СЧ', 'deltaAverageCheck', {
            numeric: true,
            as: 'currency',
            format: 'thousand',
            cell: [ 'cellclass' ],
            sorter: 'bynull',
        }),
    ],
}
