import { IState }           from 'store'
import { guestVoteActions } from 'services'

export const mapStateToProps = ( state: IState ) => ({
    happinessIndexesSearchIsLoading: state.guestVote.happinessIndexesSearch_loading,
    happinessIndexesSummaryIsLoading: state.guestVote.happinessIndexesSummary_loading,
    happinessIndexesSearchAppendIsLoading: state.guestVote.happinessIndexesSearchAppend_loading
})

export const allActions = { ...guestVoteActions }

export type GuestVoteFiltersStoreProps = ReturnType<typeof mapStateToProps> & typeof allActions
