/* VENDOR */
import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { Card, Layout }     from 'antd'

/* APPLICATION */
import { ReportTable, AddButton } from 'components'

import { format } from 'tools'
import config     from 'config'

import { mapStateToProps, allActions } from './connector'
import './positions-editor.scss'

const { Content, Footer } = Layout

class PositionEditor extends Component {
    constructor ( props ) {
        super( props )
        this.state = { positions: props.positions, }
    }

    componentDidMount () {
        this.load()
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { positions } = this.props

        positions !== nextProps.positions && this.setPositions( nextProps.positions )
    }

    setPositions = ( positions ) =>
        this.setState({
            positions: positions
                .sort(( a, b ) => {
                    if (( a.deleted && b.deleted ) || ( !a.deleted && !b.deleted )) {
                        return a.order - b.order
                    } else {
                        return a.deleted ? 1 : -1
                    }
                })
                .map(( p ) => {
                    p.noDrag = p.deleted
                    return p
                }),
        })

    load = () => {
        this.props.fetchAdminPositionsCategories()
        this.props.fetchAdminPositions()
    }

    // TODO: BSR-4609 Отрефачить после выката Несовершеннолетних BSR-4090
    change = ( original, key, value ) => {
        const positions = format.copy.array( this.state.positions ),
              found = positions.find(( t ) => t.positionUuid === original.positionUuid ),
              index = positions.indexOf( found )

        if ( found ) {
            const rval =
        key === 'positionStationUuid' ? ( value === null ? null : value ) : value

            key === 'positionStationUuid' && rval === null && ( found.positionStationName = null )

            found[ key ] = rval
            positions[ index ] = found

            delete found._api_error
            delete found.categoryUuid
            delete found.categoryName
            delete found.name
            delete found.position

            this.setPositions( positions )
            this.props.updateAdminPosition( found )
        }
    }

    add = () => {
        const newkey = 'НОВ ',
              total = Math.max( ...this.state.positions.filter(
                  ( p ) => p.position.indexOf( newkey ) === 0
              ).map( item => +( item.position.replace( /\D/g, '' ) ?? 0 )))

        const needPosition = this.state.positions.find(( p ) => p.positionStationName === 'Другое' )

        this.props.createAdminPosition({
            positionStationUuid: needPosition.positionStationUuid,
            positionStationName: needPosition.positionStationName,
            positionFullName: 'Новая позиция',
            positionName: `${newkey}${total + 1}`,
            order: this.state.positions.length + 1,
        })

        setTimeout(
            () =>
                document
                    .querySelector( '.row-inactive' )
                    .scrollIntoView({ block: 'center', behavior: 'smooth' }),
            500
        )
    }

    remove = ( record ) => this.props.removeAdminPosition( record )
    restore = ( record ) => this.props.restoreAdminPosition( record )

    move = ( dragIndex, hoverIndex ) => {
        let positions = format.copy.array( this.state.positions )
        const cut = positions[ dragIndex ]

        positions = positions.filter(( _, index ) => index !== dragIndex )

        const npos = [
            ...positions.slice( 0, hoverIndex ),
            cut,
            ...positions.slice( hoverIndex ),
        ]

        npos.forEach(( p, i ) => ( p.order = i + 1 ))
        this.setPositions( npos )
        this.props.moveAdminPosition( npos[ hoverIndex ])
    }

    render () {
        const { categories } = this.props,
              { positions } = this.state,
              catops = format.generate.options(
                  categories,
                  'positionCategoryUuid',
                  'positionCategoryName'
              )

        return (
            <Card bordered={false} className="positions-editor">
                <Layout>
                    <Content>
                        <div className="report-table">
                            <ReportTable
                                rowKey="positionUuid"
                                styleInactive={false}
                                dragSort={this.move}
                                data={format.generate.noPager( positions )}
                                columns={config.tables.admin.positions}
                                select={{ catops }}
                                onChange={this.change}
                                onRemove={this.remove}
                                onRestore={this.restore}
                            />
                        </div>
                    </Content>
                    <Footer>
                        <AddButton text="Добавить позицию" action={this.add} />
                    </Footer>
                </Layout>
            </Card>
        )
    }
}

export default connect( mapStateToProps, allActions )( PositionEditor )
