import React, { useEffect, useState } from 'react'
import { Col, Row }                   from 'antd'
import dayjs                          from 'dayjs'
import { connect }                    from 'react-redux'
import config                         from 'config'
import {
    PprEmployeeData,
    PprHrData,
    PprLineStaffStructure,
    PprManagerBench,
    PprManagerData,
    PprManagerStructure,
    PprRestaurantData,
    PprTableError,
    PprTeamStructure,
    Spinner
} from 'components'

import { allActions, mapStateToProps } from './connector'
import './ppr-director.scss'

const formatView = config.format.monthView
const formatAPI = config.format.monthAPI

const PprDirector = ({ director, period, restaurantId, ppr, ...rest }) => {
    const [ loading, setLoading ] = useState( false )

    useEffect(() => {
        getPeriods()
    }, [])

    useEffect(() => {
        if ( Array.isArray( ppr?.periods ) && period ) {
            update()
        }
    }, [ ppr, period, restaurantId ])

    const getPeriods = () => {
        setLoading( true )
        rest.fetchStaffPprAvailablePeriods().finally(() => setLoading( false ))
    }

    const update = () => {
        rest.flushPprDirectorData()
        const pprParams = { factsNumber: restaurantId }
        pprParams.period = dayjs( period, formatView ).format( formatAPI )
        Promise.all([
            rest.fetchPprHr( pprParams ),
            rest.fetchPprManagersStructure( pprParams ),
            rest.fetchPprEmployeesCount( pprParams ),
            rest.fetchPprRestaurant( pprParams ),
            rest.fetchPprData( pprParams ),
            rest.fetchPprManagers( pprParams ),
            rest.fetchPprEmployees( pprParams ),
            rest.fetchPprManagersBench( pprParams ),
        ])
    }

    const onRetry = ( key ) => {
        rest.flushPprDirectorItem( key )
        const pprParams = { factsNumber: restaurantId }
        pprParams.period = dayjs( period, formatView ).format( formatAPI )

        switch ( key ) {
            case 'hr': {
                rest.fetchPprHr( pprParams )
                break
            }
            case 'managersStructure': {
                rest.fetchPprManagersStructure( pprParams )
                break
            }
            case 'managersBench': {
                rest.fetchPprManagersBench( pprParams )
                break
            }
            case 'managers': {
                rest.fetchPprManagers( pprParams )
                break
            }
            case 'employees': {
                rest.fetchPprEmployees( pprParams )
                break
            }
            case 'teamStructure': {
                rest.fetchPprRestaurant( pprParams )
                break
            }
            case 'restaurantData': {
                rest.fetchPprRestaurant( pprParams )
                break
            }
        }
    }

    return (
        <div className="ppr-director">
            {
                loading ? <Spinner />
                    :
                        <React.Fragment>
                            <Row gutter={16}>
                                <PprLineStaffStructure
                                    data={director?.employees}
                                    loading={director?.employees_loading}
                                    error={director?.employees_error}
                                    onRetry={onRetry}
                                />
                                <Col xs={17} sm={17} md={17} lg={17} xl={18} xxl={18}>
                                    <Row style={{ marginBottom: 16 }}>
                                        <PprRestaurantData
                                            data={director?.restaurant}
                                            loading={director?.restaurant_loading}
                                            error={director?.restaurant_error}
                                            onRetry={onRetry}
                                        />
                                        <PprHrData
                                            data={director?.hr}
                                            loading={director?.hr_loading}
                                            error={director?.hr_error}
                                            onRetry={onRetry}
                                        />
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={16}>
                                            <Row>
                                                <PprManagerStructure
                                                    data={director?.managersStructure}
                                                    loading={director?.managers_structure_loading}
                                                    error={director?.managers_structure_error}
                                                    onRetry={onRetry}
                                                />
                                                <PprManagerBench
                                                    data={director?.managersBench}
                                                    loading={director?.managers_bench_loading}
                                                    error={director?.managers_bench_error}
                                                    onRetry={onRetry}
                                                />
                                            </Row>
                                        </Col>
                                        <PprTeamStructure
                                            data={director?.restaurant}
                                            loading={director?.restaurant_loading}
                                            error={director?.restaurant_error}
                                            onRetry={onRetry}
                                        />
                                    </Row>
                                </Col>
                            </Row>
                            <div style={{ marginTop: 56 }}>
                                <PprManagerData
                                    data={director?.managers || []}
                                    managersStructure={director?.managersStructure}
                                    restaurant={director?.restaurant}
                                    loading={director?.managers_loading}
                                    error={director?.managers_error}
                                    onRetry={onRetry}
                                />
                                <PprEmployeeData
                                    data={director?.employees?.employees}
                                    loading={director?.employees_loading}
                                    error={director?.employees_error}
                                    onRetry={onRetry}
                                />
                            </div>
                        </React.Fragment>
            }
            {ppr?.periods_error && <PprTableError
                error={ppr?.periods_error}
                onRetry={getPeriods}
                centered={false}
            />}
        </div>
    )
}

export default connect( mapStateToProps, allActions )( PprDirector )
