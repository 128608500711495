/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'

import { Modal, Input } from 'antd'
import Icon             from '@ant-design/icons'

/* APPLICATION */
import { Icons, Spinner, DishList } from 'components'

import './category-card.scss'

class CategoryCard extends Component {
    static propTypes = {
        category: PropTypes.object,

        onChange: PropTypes.func,
        onRemove: PropTypes.func,
        onDishAdd: PropTypes.func,
        onDishRemove: PropTypes.func,
    }

    titleRef = React.createRef()

    constructor ( props ) {
        super( props )
        this.state = {
            category: null,
            name: '',
            editable: false,
            confirm: false,
        }
    }

    componentDidMount () {
        this.props.category && this.setCategory( this.props.category )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { category } = this.props
        nextProps.category !== category && this.setCategory( nextProps.category )
    }

    setCategory = ( category ) =>
        this.setState({
            category,
            name: category && category.categoryName,
        })

    startEdit = () => {
        this.setState({ editable: true })
        setTimeout(() => this.titleRef.current.focus(), 100 )
    }

    stopEdit = () => this.setState({ editable: false })
    setName = ( e ) => this.setState({ name: e.target.value })
    checkSaveOnEnter = ( e ) => e.keyCode === 13 && this.saveName()
    saveName = () => {
        this.props.onChange( 'categoryName', this.state.name )
        this.stopEdit()
    }

    askRemove = () => this.setState({ confirm: true })
    hidePopup = () => this.setState({ confirm: false })

    removeCategory = () => {
        this.props.onRemove( this.props.category )
        this.hidePopup()
    }

    render () {
        const { category, editable, name } = this.state

        if ( !category ) { return <Spinner /> }

        return (
            <div className="category-card view-type-switch fixed">
                {category ? (
                    <h2 className="category-name">
                        {editable ? (
                            <Input
                                ref={this.titleRef}
                                value={name}
                                onChange={this.setName}
                                onKeyUp={this.checkSaveOnEnter}
                                onBlur={this.saveName}
                            />
                        ) : (
                            <span>{category.categoryName}</span>
                        )}
                        <Icon component={Icons.Edit.active} onClick={this.startEdit} />
                        <Icon
                            className="remove-icon"
                            component={Icons.Remove.active}
                            onClick={this.askRemove}
                        />
                    </h2>
                ) : (
                    <h2>...</h2>
                )}

                <DishList
                    data={category.dishes}
                    onAdd={this.props.onDishAdd}
                    onRemove={this.props.onDishRemove}
                />

                <Modal
                    title="Вы действительно хотите удалить категорию? Это действие нельзя отменить"
                    className="kfc-popup"
                    centered={true}
                    open={this.state.confirm}
                    onOk={this.removeCategory}
                    okText={'Да, удалить'}
                    okButtonProps={{ className: 'wide-btn' }}
                    onCancel={this.hidePopup}
                    cancelButtonProps={{ style: { display: 'none' } }}
                />
            </div>
        )
    }
}

export default CategoryCard
