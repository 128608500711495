/* VENDOR */
import dayjs from 'dayjs'

/* APPLICATION */
import * as extract from './extract'
import * as dates   from './dates'

const requestKeys = [
    'dateStart',
    'dateEnd',
    'dateGroup',
    'unitOfMeasure',
    'currentPredef',
    'restaurantId',
]

export const onlyIf = ( what, val ) => ( what === val ? val : null )
export const hasNull = ( obj ) =>
    Object.values( obj ).filter(( v ) => v === null ).length > 0
export const realNull = ( num ) => ( num === null ? 0 : num )
export const empty = ( obj ) => Object.keys( obj ).length === 0
export const anyEmpty = ( obj, keys ) =>
    keys.map(( key ) => !!obj[ key ]).includes( false )
export const equal = ( time, check ) =>
    extract.hours( time ) === extract.hours( check )

export const now = ( start, end ) =>
    !dayjs( end ).isBefore( dayjs()) && dayjs( start ).isBefore( dayjs())

export const request = ( oldReq, newReq, exc, inc ) =>
    requestKeys
        .concat( inc || [])
        .filter(( key ) => !exc || exc.indexOf( key ) < 0 )
        .map(( key ) => oldReq[ key ] !== newReq[ key ])
        .includes( true )

export const interval = ( time, start, end ) => {
    const rend =
    parseInt( start.replace( /:/g, '' )) < parseInt( end.replace( /:/g, '' ))
        ? end
        : parseInt( end.split( ':' )[ 0 ]) + 24 + ':' + end.split( ':' ).slice( 1 )

    return (
        extract.hours( time ) >= extract.hours( start ) &&
    extract.hours( time ) < extract.hours( rend ) + 1
    )
}

let __scrollbar = 0

const getScrollbars = () => {
    const outer = document.createElement( 'div' ),
          inner = document.createElement( 'div' )

    let widthNoScroll, widthWithScroll

    outer.style.visibility = 'hidden'
    outer.style.width = '100px'
    outer.style.msOverflowStyle = 'scrollbar'

    document.body.appendChild( outer )

    widthNoScroll = outer.offsetWidth
    outer.style.overflow = 'scroll'
    inner.style.width = '100%'

    outer.appendChild( inner )

    widthWithScroll = inner.offsetWidth
    outer.parentNode.removeChild( outer )

    __scrollbar = widthNoScroll - widthWithScroll
}

getScrollbars()
window.addEventListener( 'resize', getScrollbars )

export const scrollbarWidth = () => __scrollbar

export const recordDiff = ( record, label ) => {
    const year = 'YYYY',
          start = dayjs( record.startInterval ),
          end = dayjs( record.endInterval ),
          diff = Math.abs( start.diff( end, 'days' )),
          hours = Math.abs( start.diff( end, 'days' ))

    if ( start.format( year ) === end.format( year )) {
        return label
    }

    if ( diff < 32 && diff > 28 ) {
        return dates.withYear( start )
    }

    if ( diff > 1 ) {
        return dates.withYear( start, true ) + ' – ' + dates.withYear( end, true )
    }

    if ( hours > 1 ) {
        return dates.withYear( start, true )
    }

    return label
}

export const globalDiff = ( record, label, request ) => {
    if ( request.dateGroup === 'week' ) {
        return recordDiff( record, label )
    }

    const year = 'YYYY',
          start = dayjs( request.dateStart ),
          end = dayjs( request.dateEnd ),
          diff = Math.abs( start.diff( end, 'days' )),
          rstart = dayjs( record.startInterval )

    if ( start.format( year ) === end.format( year )) {
        return label
    }

    if ( diff < 32 && diff > 28 ) {
        return dates.withYear( rstart )
    }

    return dates.withYear( rstart, true )
}

export const diffYears = ( record, label, request ) => {
    if ( !request ) {
        return recordDiff( record, label )
    }
    return globalDiff( record, label, request )
}

export const changes = () => {}
/*console.error(
        Object.keys( n )
            .concat( Object.keys( old ) )
                .filter( ( key, i, arr ) => arr.indexOf( key ) === i )
                .filter( key => n[key] !== old[key] )
                .map(
                    key => '[' + key + '] ' + old[key] + ' > ' + n[key]
                )
    )*/

export const enough = ( props, ignoreWorkHours ) =>
    !!( props.workHours || ignoreWorkHours ) &&
  !!props.request.currentPredef &&
  !!props.request.dateStart &&
  !!props.request.dateEnd

export const form = ( err, values ) => {
    if ( !err ) {
        return true
    }
    return !Object.keys( err )
        .map(( key ) => !!values[ key ])
        .includes( false )
}

export const allContainsNullOrZero = ( array, fields ) => array?.every( item => {
    return Object.entries( item ).filter(
        ([ key ]) => fields.find( k => k === key )
    )?.every(([ _, value ]) => value === 0 || value === null )
})
