/* IMPORT */

import { settingsActions, chartActions, restaurantActions } from 'services'

/* EXPORT */

export const mapStateToProps = ( state ) => ({
    request: state.request,
    chartByType: state.planning.chartByType,
    chartByTypeError: state.planning.chartByTypeError,
    positionsChartedByType: state.planning.positionsChartedByType,
    facilityTypesError: state.restaurants.facilityTypesError,
    typeError: state.restaurants.typeError
})

export const allActions = {
    ...settingsActions,
    ...chartActions,
    ...restaurantActions
}
