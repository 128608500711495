import { ReloadOutlined }  from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import React               from 'react'
import classNames          from 'classnames'
import './ppr-table-error.scss'

const PprTableError = ({ error, onRetry, centered = true }) => {
    const errorText = typeof error === 'string' ? error : 'Произошла ошибка при загрузке данных'
    return (
        <div className={classNames( 'ppr-table-error', centered && 'centered' )}>
            <div className='title'>Ошибка</div>
            <Tooltip title={errorText}>
                <div className='subtitle'>{errorText}</div>
            </Tooltip>
            {onRetry && <Button
                type='primary'
                icon={<ReloadOutlined />}
                onClick={onRetry}
            >Повторить</Button>}
        </div>
    )
}

export default PprTableError
