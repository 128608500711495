/* eslint-disable no-inline-comments */
/* VENDOR */
import React from 'react'

/* APPLICATION */
import { format } from 'tools'
import config     from 'config'

import Template from './Template.js'
import './managers-report.scss'

const totals = [ 'hours', 'paid', 'shifts' ],
      total = ( row, days ) => {
          return ( dow ) => {
              const day = days ? days.find(( d ) => parseInt( d.dayOfWeek ) === dow ) : null
              row[ 'day' + dow ] =
        day && day.total ? format.strings.float( day.total ) : '–'
          }
      },
      rows = ( data ) => {
          const res = [],
                last = {
                    employee: format.strings.clearCount(
                        data.summary ? data.summary.employees : 0,
                        [ 'человек', 'человека', 'человек' ]
                    ),
                }

          data.employees.forEach(( e ) => {
              const row = { employee: e.name, }

              config.defs.days.forEach( total( row, e.days ))
              e.summary
                  ? format.extract.selection( e.summary, row, totals, format.strings.float )
                  : ( row.paid = <span className="float-center">Нет в расписании</span> )

              row.minmax = e.hours
              row.rotation = e.positions.join( ' / ' )

              res.push( row )
          })

          config.defs.days.forEach( total( last, data.summary ? data.summary.days : 0 ))
          format.extract.selection(
              data.summary ? data.summary.total : 0,
              last,
              totals,
              format.strings.float
          )

          res.push( last )

          return res
      },
      heads = () => [
          {
              employee: 'Сотрудник',
              day1: <span>Дневные часы</span>,
              day2: <span>&nbsp;</span>,
              day3: <span>&nbsp;</span>,
              day4: <span>&nbsp;</span>,
              day5: <span>&nbsp;</span>,
              day6: <span>&nbsp;</span>,
              day7: <span>&nbsp;</span>,
              hours: <span>Всего</span>,
              paid: <span>&nbsp;</span>,
              shifts: <span>&nbsp;</span>,
              minmax: <React.Fragment>Ставка ч.</React.Fragment>,
              rotation: 'Ротация',
          },
          {
              employee: <span>&nbsp;</span>,
              day1: 'ПН',
              day2: 'ВТ',
              day3: 'СР',
              day4: 'ЧТ',
              day5: 'ПТ',
              day6: 'СБ',
              day7: 'ВС',
              hours: 'Часов',
              paid: 'Опл.',
              shifts: 'Смен',
          },
      ],
      width = () => [
          '15%',
          'auto', //day1
          'auto', //day2
          'auto', //day3
          'auto', //day4
          'auto', //day5
          'auto', //day6
          'auto', //day7
          '4%', //separator
          'auto', //hours
          'auto', //paid
          'auto', //shifts
          '4%', //separator
          '8%', //minmax
          '15%', //position
      ],
      columns = () =>
          format.generate.rowReader([
              'employee',
              'day1',
              'day2',
              'day3',
              'day4',
              'day5',
              'day6',
              'day7',
              'space',
              'hours',
              'paid',
              'shifts',
              'space2',
              'minmax',
              'rotation',
          ])

class ManagersReport extends Template {
    constructor ( props ) {
        super( props )

        this.state = {
            data: rows( props.data ),
            heads: heads(),
            width: width( props.data ),
            columns: columns( props.data ),
            className: 'managers-report',
        }
    }

    data = ( data ) => rows( data )
    columns = ( data ) => columns( data )
}

export default ManagersReport
