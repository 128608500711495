/* eslint-disable camelcase */
/* VENDOR */
import axios           from 'axios'
import { asyncUpdate } from 'reduxigen'
import dayjs           from 'dayjs'
import { store }       from 'store'

/* APPLICATION */
import * as copy     from './copy'
import * as find     from './find'
import * as extract  from './extract'
import * as strings  from './strings'
import * as generate from './generate'

import config from 'config'

export const array = ( data, state, key, sub, extra ) => {
    if ( data.length > 0 ) {
        const ndata = copy.array( state[ key ]),
              handler = extra ? extra : ( d ) => d,
              res = {}

        data.forEach(( item ) => {
            const index = find.index( ndata, sub, handler( item[ sub ]))
            ndata[ index ] = item
        })

        res[ key ] = ndata

        return res
    }
}

export const dow = ( data, state, key ) =>
    array( data, state, key, 'dayOfWeek', ( d ) => ( d === 0 ? 7 : d ))

export const check = ( event, state, original, helper, obj, keys, sub ) => {
    const { status, data, arr, index, message } = extract.update(
        event,
        original[ obj ][ sub ],
        keys
    )

    arr[ index ] = status === 200 ? data : data.original

    if ( message && status !== 200 ) {
        arr[ index ]._api_error = message
    }

    const res = { ...original[ obj ] }
    res[ sub ] = arr

    return helper( obj, res, state )
}

export const object = ( obj, pathStr, data ) => {
    if ( pathStr.indexOf( '.' ) < 0 ) {
        return {
            ...obj,
            [ pathStr ]: data
        }
    }

    const path = pathStr.split( '.' ),
          key = path.splice( 0, 1 ),
          subPath = path.join( '.' )

    return {
        ...obj,
        [ key ]: object( obj[ key ], subPath, data )
    }
}

export const clear = ( item ) => {
    delete item._api_error
}

export const getRestname = ( rid, state ) => {
    let rests = []

    state.restaurants.list && ( rests = [ ...state.restaurants.list ])
    state.restaurants.admin &&
    state.restaurants.admin.content &&
    ( rests = [ ...rests, ...state.restaurants.admin.content ])

    const found = rests.find(( r ) => parseInt( r.factsNumber ) === parseInt( rid ))

    return found ? found.restaurantName : rid
}

export const getRestData = ( event, state, rname, rid ) => {
    if ( rname && rid && rid !== 'неизвестно' ) {
        return rname + ' (' + rid + ')'
    }

    const ids =
        event.errorData && event.errorData.data && event.errorData.data.factsNumbers
            ? event.errorData.data.factsNumbers
            : null

    if ( !ids || ids.length === 0 ) {
        return 'неизвестного ресторана'
    }

    if ( ids.length > 2 ) {
        return ids.length + ' ресторанов (' + ids.join( ', ' ) + ')'
    }

    if ( ids.length === 2 ) {
        const r1 = getRestname( ids[ 0 ], state ),
              r2 = getRestname( ids[ 1 ], state )

        return r1 + ' и ' + r2
    }

    return getRestname( ids[ 0 ], state ) + ' (' + ids[ 0 ] + ')'
}

const getOverlapInfo = ( event, state ) => {
    if (
        !event ||
        !event.errorData ||
        !event.errorData.data ||
        !event.errorData.data.overlapped
    ) {
        return {}
    }

    const found = state.staff.list.find(
        ( s ) => s.id === event.errorData.data.overlapped.employeeId
    ),
          name = found ? found.name || found.fullName : config.ui.nullSymbol

    return {
        name,
        date: dayjs( event.errorData.data.overlapped.timeStart ).format(
            config.format.onlyDayView
        )
    }
}

export const getErrorMessage = ( event, state ) => {
    if ( event.errorData?.msg || event.data?.msg ) {
        return event.errorData?.msg || event.data?.msg
    }
    const code = event.errorData ? event.errorData.code : event.data?.code,
          status = event.status,
          cfg = event.config,
          request = cfg && cfg.data ? JSON.parse( cfg.data ) : {},
          params = cfg && cfg.params ? cfg.params : {},
          match =
            event.errorData &&
            event.errorData?.msg &&
            event.errorData?.msg.match( /'.+'/ ),
          rid =
            request.restaurantId ||
            params.restaurantId ||
            ( match ? parseInt( match[ 0 ].replace( /'/g, '' )) : 'неизвестно' ),
          rname = getRestname( rid, state ),
          sname = match ? match[ 0 ] : '\'Неизвестно\'',
          granted =
            state.user.restaurant &&
            state.user.restaurant.grantedRestaurants &&
            state.user.restaurant.grantedRestaurants.length > 0
                ? 'с рестораном ' + rname + ' (' + rid + ')'
                : 'ни с одним рестораном.',
          overlap = getOverlapInfo( event, state ),
          messages = {
              400001: 'В рейтинге не может быть более 5 блюд одновременно',
              400003:
                'Нельзя установить план за прошедшую дату' +
                ( event.errorData && event.errorData.data
                    ? ' (' +
                    dayjs( event.errorData.data ).format( config.format.dayView ) +
                    ').'
                    : '.' ),
              400004: `Не удалось сохранить расписание, ${overlap.name} имеет пересечение смен ${overlap.date}`,
              400005:
                'Нельзя изменять плановый товарооборот при импорте данных (разница ' +
                strings.delta( event.errorData ? event.errorData.data : 0, 'руб.' ) +
                ')',
              401000: 'Не удалось войти в систему. Обратитесь в service desk.',
              401001:
                'Не удалось войти в систему. Ваш аккаунт деактивирован. Обратитесь в service desk.',
              401002:
                'Не удалось войти в систему. Доступ для вашего аккаунта ограничен. Обратитесь в service desk.',
              403000:
                'Ваш аккаунт не связан ' + granted + '. Обратитесь в service desk.',
              403001: 'Не хватает прав доступа. Обратитесь в service desk.',
              404000:
                'Ресторан ' +
                rname +
                ' (' +
                rid +
                ') не подключен к системе DSR. Обратитесь в service desk.',
              404001: 'Неопределенная ошибка 404001',
              404002:
                'Недостаточно данных для построения плана для ' +
                getRestData( event, state, rname, rid ) +
                '.',
              404003:
                rname +
                ' (' +
                rid +
                ') в разделе "Сотрудники" не заполнена анкета директора. Если анкета заполнена, то создайте обращение в GSD.',
              404004: 'Категория не найдена',
              404005:
                'Внештатный сотрудник не может быть установлен старшим смены. У него нет YUM ID для входа в DSR',
              408000: 'Превышено время ожидания, попробуйте обновить страницу',
              409000: 'Категория с именем ' + sname + ' уже существует',
              409: event?.errorData?.msg || event?.data?.msg
          }

    if ( checkPprErrors( event )) {
        return null
    }

    if ( !messages[ code ] && status !== 500 ) {
        if ( event.data && typeof event.data === 'string' ) {
            return event.data
        }

        if ( event.event?.data?.code && event.event?.data?.message ) {
            return event.event.data.message
        }

        if ( event.data?.msg || event.errorData?.msg ) {
            const
                msg = event.data?.msg || event.errorData?.msg

            if ( msg.indexOf( 'planValue должно быть больше или равно' ) > -1 ||
                msg.indexOf( 'turnoverPlan должно быть больше или равно' ) > -1 ) {
                return `Меньше ${msg.split( ' ' ).pop()} рублей план ТО выставить нельзя`
            }

            if ( cfg?.url?.match( /(restaurants\/)\d{5,10}(\/ppr)/g )) {
                return 'Данные для данного периода отсутствуют. Внесите данные и сохраните форму'
            }

            if ( msg.toLowerCase().indexOf( 'ppr' ) > -1 ) {
                return ''
            }
        }

        return 'Неизвестная ошибка. Обратитесь в service desk.'
    } else {
        if ( event.errorData?.message ) {
            if ( event.errorData.message.indexOf( 'cannot be assigned as shift supervisorUser' ) > -1 ) {
                return 'Внештатный сотрудник не может быть установлен старшим смены. У него нет YUM ID для входа в DSR'
            }
        } else if ( !event.errorData ) {
            return 'Неизвестная ошибка. Обратитесь в service desk.'
        }
    }

    return messages[ code ]
}

const checkErrors = ( event, state, errors ) => {
    const code = event.errorData ? event.errorData.code : event.data?.code

    try {
        getErrorMessage( event, state )
    } catch ( e ) {
        console.error( 'Error in messages', e )
    }

    return event.status !== 200 && event.status !== 204 && !checkPprErrors( event )
        ? {
                ...errors,
                [ code || event.status ]:
            errors[ event.status ] || getErrorMessage( event, state )
            }
        : errors
}

const checkErrorsUp = ( event, errors, minErr ) =>
    event.status < minErr || checkPprErrors( event )
        ? errors
        : {
                ...errors,
                [ event.status ]:
                'Внутренняя ошибка сервера. Попробуйте обновить страницу или обратитесь к администратору.'
            }

export const checkServerErrors = ( event, state ) => {
    let errors = state.serverErrors

    if ( event.__force ) {
        return {
            ...state,
            serverErrors: errors
        }
    }

    if ( event.status !== 400 && event.status !== 500 && event.kfcError ) {
        event.errorData = event.data
        delete event.data
    }

    event.status === 408 &&
    ( event.errorData = Array.isArray( event.data ) ? event.data[ 0 ] : event.data )

    errors = checkErrorsUp( event, errors, 500 )
    errors = checkErrors( event, state, errors )

    return {
        ...state,
        serverErrors: errors
    }
}

export const merge = ( event ) => {
    return Array.isArray( event )
        ? event.reduce(
            ( s, e ) => ({
                statusText: [ ...s.statusText, e.statusText ],
                status: Math.max( s.status, parseInt( e.status )),

                data: [ ...s.data, e.data ],
                config: [ ...s.config, e.config ],
                headers: [ ...s.headers, e.headers ],
                request: [ ...s.request, e.request ]
            }),
            {
                statusText: [],
                status: 0,
                data: [],
                config: [],
                headers: [],
                request: []
            }
        )
        : event

}

export function queryWithRefresh ( requester ) {
    return ( params ) =>
        new Promise(( resolve ) => {
            requester( params )
                .then(( event ) => {
                    if ( event?.status === 401 || event?.status === 403 ) {
                        return Promise.reject( event )
                    }
                    const err = typeof event.data === 'string' && event.data?.indexOf( '<html' ) > -1
                    const mergeRes = merge( event )

                    resolve({
                        ...mergeRes,
                        kfcError:
                            err ||
                            !!mergeRes.data?.error ||
                            mergeRes.data?.httpResultType === 'ERROR'
                    })
                })
                .catch(
                    async ( event ) => {
                        if (( !axios.isCancel( event ) || event.status === 408 ) && ( event.status === 401 || event.status === 403 || !event.status )) {
                            const refreshResult = await goToLogin( store.getState().user.endpoints )
                            if ( refreshResult?.status === 200 ) {
                                const originalRequestResult = await requester( params )
                                if ( originalRequestResult?.status.toString()[ 0 ] === '2' ) {
                                    const err = typeof originalRequestResult.data === 'string' && originalRequestResult.data?.indexOf( '<html' ) > -1
                                    const mergeRes = merge( originalRequestResult )
                                    resolve({
                                        ...mergeRes,
                                        kfcError:
                                            err ||
                                            !!mergeRes.data?.error ||
                                            mergeRes.data?.httpResultType === 'ERROR'
                                    })
                                }
                            }
                        }
                        return ( !axios.isCancel( event ) || event.status === 408 ) &&
                            resolve({
                                ...event,
                                event: event,
                                errorData: event.data,
                                data: event.status === 408 ? event.data : null,
                                kfcError: true
                            })
                    })
        })
}

export const asyncu = ( name, requester, parser ) => {
    return asyncUpdate(
        name,
        queryWithRefresh( requester ),
        ( event, state ) => {
            const ns = checkServerErrors( event, state )
            return parser( event, ns )
        }
    )
}

const checkPprErrors = ( event ) => {
    if ( !window.location.pathname.includes( 'staff' )) {
        return false
    }

    const pprDirectorUrls = [
        /ppr\/restaurant/g,
        /(restaurants\/)\d{5,10}(\/ppr\/hr)/g,
        /employees\/ppr\/manager-structures/g,
        /employees\/ppr\/employees-count/g,
        /employees\/ppr\/managers/g,
        /employees\/ppr\/employees/g,
        /employees\/ppr\/manager-benches/g,
        /(restaurants\/)\d{5,10}(\/ppr)/g,
        /employees\/ppr\/periods/g,
        /dictionaries\/ppr\/ac-statuses/g
    ]

    return pprDirectorUrls.find( dirUrl => event.config?.url?.match( dirUrl )?.length > 0 )
}

export const getPprError = ( event ) => {
    if ( event.status >= 200 && event.status <= 299 ) {
        return ''
    }

    if ( event.errorData?.msg ) {
        return event.errorData?.msg
    }

    if ( typeof event.data === 'string' ) {
        return event.data
    }

    if ( event.error && event.message ) {
        return [ event.error, event.message ].join( '. ' )
    }

    if ( event?.event?.data?.message ) {
        return event?.event?.data?.message
    }

    return 'Внутренняя ошибка сервера. Попробуйте обновить страницу или обратитесь к администратору.'
}

export const getRedirect = ( endpoints, isOrigin ) => {
    const isLocalhost = window.location.href.includes( 'localhost' )

    if ( isOrigin ) {
        return isLocalhost ? window.location.origin : endpoints.redirectUri
    }

    return isLocalhost ? window.location.href : endpoints.redirectUri + window.location.pathname
}

export const goToLogin = async ( endpoints ) => {
    //Получаем данные для авторизации
    if ( !endpoints ) {
        const { data } = await axios.get( config.api.staff + 'auth/endpoints' )
        endpoints = data
    }

    if ( localStorage.getItem( 'refresh_token' )) {
        return await postRefreshAccessToken( endpoints )
    }

    const redirectUri = getRedirect( endpoints )

    //редирект на страницу авторизации
    window.location = `${endpoints.authorizationEndpoint}${generate.query({
        response_type: endpoints.responseType,
        redirect_uri: strings.removeGetParams( redirectUri ),
        client_id: endpoints.clientId,
        scope: endpoints.scope
    })}`
}

export const postAccessToken = async ( endpoints ) => {
    const tokenKeys = [ 'access_token', 'refresh_token', 'id_token' ]
    //code есть в гет параметрах
    const code = generate.parseQuery( window.location.search ).code
    if ( code ) {
        //Получаем дданныеату для авторизации
        if ( !endpoints ) {
            const { data } = await axios.get( config.api.staff + 'auth/endpoints' )
            endpoints = data
        }

        try {
            const redirectUri = getRedirect( endpoints )
            //отправляем запрос на получаение access/refresh токенов
            const response = await axios.post( endpoints.tokenEndpoint, getFormUrlEncoded({
                client_id: endpoints.clientId,
                client_secret: endpoints.clientSecret,
                redirect_uri: strings.removeGetParams( redirectUri ),
                grant_type: 'authorization_code',
                code
            }), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })

            tokenKeys.forEach( field => {
                localStorage.setItem( field, response.data[ field ])
            })
            localStorage.setItem( config.keys.auth, 'true' )
            //localStorage.setItem( config.keys.splash, 'yes' )
            //убираем параметры из url
            window.location = strings.removeGetParams( redirectUri )
        } catch ( e ) {
            [ ...tokenKeys, config.keys.auth ].forEach( field => {
                localStorage.removeItem( field )
            })
            //убираем параметры из url
            window.location = getRedirect( endpoints )
        }

    }
}

// let isRefreshQueryPending = false
let refreshQueryPromise
export const postRefreshAccessToken = async ( endpoints ) => {
    const tokenKeys = [ 'access_token', 'refresh_token', 'id_token' ]
    //Получаем данные для авторизации
    if ( !endpoints ) {
        const { data } = await axios.get( config.api.staff + 'auth/endpoints' )
        endpoints = data
    }
    const currentPromiseState = await promiseState( refreshQueryPromise )

    if ( currentPromiseState === 'pending' ) {
        return refreshQueryPromise
    }

    refreshQueryPromise = axios.post( endpoints.tokenEndpoint, getFormUrlEncoded({
        client_id: endpoints.clientId,
        client_secret: endpoints.clientSecret,
        grant_type: 'refresh_token',
        scope: endpoints.scope,
        refresh_token: localStorage.getItem( 'refresh_token' )
    }), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
        .then( response => {
            tokenKeys.forEach( field => {
                localStorage.setItem( field, response.data[ field ])
            })
            localStorage.setItem( config.keys.auth, 'true' )
            return response
        }).catch( e => {
            [ ...tokenKeys, config.keys.auth ].forEach( field => {
                localStorage.removeItem( field )
            })
            window.location = getRedirect( endpoints )
        })

    return refreshQueryPromise
}

export const getLogout = async ( endpoints ) => {
    //Получаем данные для авторизации
    if ( !endpoints ) {
        const { data } = await axios.get( config.api.staff + 'auth/endpoints' )
        endpoints = data
    }

    const redirectUri = getRedirect( endpoints, true )
    const id_token_hint = localStorage.getItem( 'id_token' )

    localStorage.clear()
    sessionStorage.clear()

    window.location = `${endpoints.endSessionEndpoint}${generate.query({
        id_token_hint,
        post_logout_redirect_uri: strings.removeGetParams( redirectUri )
    })}`
}

export const getFormUrlEncoded = ( obj ) => {
    let formBody = []
    for ( let property in obj ) {
        let encodedKey = encodeURIComponent( property )
        let encodedValue = encodeURIComponent( obj[ property ])
        formBody.push( encodedKey + '=' + encodedValue )
    }
    return formBody.join( '&' )
}

function promiseState ( p ) {
    const t = {}
    return Promise.race([ p, t ])
        .then( v => ( v === t ) ? 'pending' : 'fulfilled', () => 'rejected' )
}
