/* IMPORT */

import { ratingActions } from 'services'
/* EXPORT */

export const mapStateToProps = ( state ) => ({
    request: state.request,
    rating: state.rating,
    restaurants: state.restaurants.list,
})

export const allActions = ratingActions
