/* VENDOR */
import { set, asyncUpdate } from 'reduxigen'
import config               from 'config'

/* APPLICATION */
import * as api                               from './api'
import { format }                             from 'tools'
import { store }                              from 'store'
import { goToLogin, merge, queryWithRefresh } from '../../tools/format/update.js'
import axios                                  from 'axios'

const asyncu = format.update.asyncu,
      helper = format.helper.deep( 'user' )

export const setAuthProgress = set( 'user.progress' )
export const isLoggedIn = () => api.getLoginStatus()
export const setCurrentRestaurant = ( id ) => api.setCurrentRestaurant( id )

export const loadUser = asyncUpdate(
    'user',
    ( params ) =>
        new Promise(( resolve ) =>
            api
                .loadUserInfo( params )
                .then(( event ) => {
                    if ( event?.data?.msg?.indexOf( 'authentication' ) > -1 || event.status === 401 ) {
                        return Promise.reject( event )
                    }
                    if ( event.status === 403 ) {
                        sessionStorage.setItem( config.keys.countReloadError, true )
                    } else {
                        sessionStorage.removeItem( config.keys.countReloadError )
                    }
                    const err = typeof event.data === 'string' && event.data.indexOf( '<html' ) > -1
                    resolve({ ...event, kfcError: err })
                })
                .catch( async ( event ) => {
                    if (( !axios.isCancel( event ) || event.status === 408 ) && ( event.status === 401 || event.status === 403 || !event.status )) {
                        const refreshResult = await goToLogin( store.getState().user.endpoints )

                        if ( refreshResult?.status === 200 ) {
                            const originalRequestResult = await api.loadUserInfo( params )

                            if ( originalRequestResult.status === 403 ) {
                                sessionStorage.setItem( config.keys.countReloadError, true )
                            } else {
                                sessionStorage.removeItem( config.keys.countReloadError )
                            }

                            if ( originalRequestResult?.status.toString()[ 0 ] === '2' ) {
                                const err = typeof originalRequestResult.data === 'string' && originalRequestResult.data?.indexOf( '<html' ) > -1
                                const mergeRes = merge( originalRequestResult )
                                resolve({
                                    ...mergeRes,
                                    kfcError:
                            err ||
                            !!mergeRes.data?.error ||
                            mergeRes.data?.httpResultType === 'ERROR',
                                })
                            }
                        }
                    }

                    resolve({ ...event, kfcError: true })
                })
        ),
    ( event, state ) => {
        if ( event.kfcError || event.status === 401 || event.status === 403 ) {
            return { ...state }
        }

        const user = format.copy.object( state.user )

        let defRest = parseInt( api.getCurrentRestaurant()),
            allow = event.data.grantedRestaurants.map(( key ) => parseInt( key )),
            rid = state.request.restaurantId

        if (
            defRest !== parseInt( event.data.factsNumber ) &&
      allow.indexOf( defRest ) < 0
        ) {
            defRest = event.data.factsNumber || event.data.grantedRestaurants[ 0 ]
        }

        user.info = event.data
        user.name = event.data.fullName
        user.restaurant = {
            grantedRestaurants: [
                typeof defRest === 'string' ? parseInt( defRest ) : defRest,
            ],
        }
        user.rid = defRest

        event.data.grantedRestaurants.forEach(( key ) => {
            const gid = parseInt( key )
            user.restaurant.grantedRestaurants.indexOf( gid ) < 0 &&
        user.restaurant.grantedRestaurants.push( gid )
        })

        rid = defRest ? defRest.toString() : defRest

        return {
            ...state,
            user,
            request: {
                ...state.request,
                restaurantId: rid,
                factsNumber: rid,
            },
            calendar: { factsNumbers: [ String( defRest ) ] }
        }
    }
)

export const loadUserInfo = asyncUpdate(
    'user.auth.info',
    () => api.loadUserInfo(),
    ( event, state ) => ({
        ...state,
        user: {
            ...state.user,
            info: event.data,
        },
    })
)

export const auth = asyncUpdate(
    'user.auth',
    ({ login, pwd }) => api.auth( login, pwd ),
    ( event, state ) =>
        helper(
            {
                progress: false,
                auth: event,
                error: !event,
            },
            state
        )
)

export const logOut = asyncUpdate(
    'user.logout',
    ( onload ) => api.logout( onload ),
    () => store.initialState
)

export const getAccessAreas = asyncu(
    'user.areas',
    () => api.getAccessAreas(),
    ( event, state ) => {
        if ( event.data.error === 'invalid_token' ) {
            api.logout(() => ( window.location.href = '/' ))
            return state
        }

        return helper({ areas: event.data.webAreas }, state )
    }
)

export const fetchRoles = asyncu(
    'user.roles',
    () => api.getRoles(),
    ( event, state ) => ({
        ...state,
        roles: format.generate.keyvalr( event.data || []),
    })
)

export const fetchAuthEndpoints = asyncUpdate(
    'user.endpoints',
    () => api.getEndPoints(),
    ( event, state ) => ({
        ...state,
        user: {
            ...state.user,
            endpoints: event.data
        }
    })
)
