/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Scrollbars }       from 'react-custom-scrollbars'
import dayjs                from 'dayjs'

/* APPLICATION */
import { Spoiler } from 'components'
import { format }  from 'tools'
import config      from 'config'

import './task-group.scss'

class TaskGroup extends Component {
    static propTypes = {
        group: PropTypes.object,
        showNameKey: PropTypes.string,

        canCollapse: PropTypes.bool,
        separate: PropTypes.bool,
        noHead: PropTypes.bool,
        scroll: PropTypes.bool,

        hideInfo: PropTypes.bool,
        hideLength: PropTypes.bool,
        hidePhoto: PropTypes.bool,
        showName: PropTypes.bool,
        detailsAny: PropTypes.bool,

        preferFinish: PropTypes.bool,
        preferConfirm: PropTypes.bool,

        onDetails: PropTypes.func,
    }

    title = ( item ) => item.taskName

    due = ( date ) => dayjs( date ).format( 'DD MMM, HH:mm' )

    info = ( item ) => {
        if ( !item.dueTime && !item.assigneeName && item.photoRequired === null ) { return null }

        const { showName, showNameKey, hidePhoto, preferFinish, preferConfirm } =
        this.props,
              nameKey = showNameKey || 'fullName',
              info =
        preferConfirm && item.confirmationTime
            ? [
                    <span className="task-date" key="confirmation">
                        {this.due( item.confirmationTime.replace( /\.[0-9]+/g, '' ))}
                    </span>,
                ]
            : [
                    <span className="task-date" key="finish">
                        {preferFinish && item.finishTime
                            ? this.due( item.finishTime.replace( /\.[0-9]+/g, '' ))
                            : this.due( item.dueTime )}
                    </span>,
                ]

        showName && info.push( item[ nameKey ])
        !hidePhoto &&
      info.push( item.photoRequired ? 'Фото требуется' : 'Фото не требуется' )

        return (
            <span className="task-info">
                {info
                    .filter(( d ) => d )
                    .reduce(( res, nfo ) => (
                        <React.Fragment>
                            {res} ・ {nfo}
                        </React.Fragment>
                    ))}
            </span>
        )
    }

    startTime = ( item ) => {
        if ( item.dueTime && !this.props.hideInfo ) { return null }
        if ( !item.startTime ) { return null }

        return (
            <span className="task-time">
                {Array.isArray( item.startTime )
                    ? item.startTime.map( this.time ).join( ', ' )
                    : this.time( item.startTime )}
            </span>
        )
    }

    closeTime = ( item ) => (
        <span className="task-time">{this.time( item.finishTime )}</span>
    )

    taskTime = ( item ) =>
        item.finishTime ? this.closeTime( item ) : this.startTime( item )

    time = ( str ) => dayjs( str ).format( config.format.time )

    task = ( item ) => {
        const mods = item._modificators || [],
              cls = [ 'manager-task' ],
              { separate, hideTime, detailsAny } = this.props

        mods.map(( key ) => cls.push( 'mod-' + key ))

        return (
            <div
                key={item.taskName + item.description + item.startTime + item.dueTime}
                className={cls.join( ' ' )}
                onClick={
                    mods.includes( 'done' ) || mods.includes( 'outdated' ) || detailsAny
                        ? this.showDetails( item )
                        : null
                }
            >
                <h5 className="task-title">
                    {this.title( item )} {!separate && item.description}
                </h5>

                {separate && <div className="task-description">{item.description}</div>}

                {!hideTime && this.taskTime( item )}
                {!this.props.hideInfo && this.info( item )}
            </div>
        )
    }

    showDetails = ( item ) => {
        return () => this.props.onDetails( item )
    }

    length = ( group ) =>
        group.tasks.reduce(
            ( total, task ) =>
                Array.isArray( task.startTime )
                    ? total + task.startTime.length
                    : total + 1,
            0
        )

    header = ( group ) => (
        <React.Fragment>
            <b>{group.groupTitle || group.groupName}</b>
            {!this.props.hideLength && (
                <span className="task-group-count">{this.length( group )}</span>
            )}
        </React.Fragment>
    )

    content = ( group ) => {
        const content =
      group.tasks && group.tasks.length > 0 ? (
          group.tasks.map( this.task )
      ) : this.props.showStub ? (
          <span className="empty-group">Нет задач</span>
      ) : null

        return this.props.scroll ? (
            <section className="group-tasks">
                <Scrollbars
                    {...config.ui.scrolls}
                    ref={( node ) => ( this.scrolls = node )}
                >
                    {content}
                </Scrollbars>
            </section>
        ) : (
            <section className="group-tasks">{content}</section>
        )
    }

    render () {
        const { group, noHead } = this.props,
              cls = [
                  'task-group',
                  format.strings.join([ 'group', group.groupKey || group.groupName ]),
              ]

        return (
            <div className={cls.join( ' ' )}>
                {this.props.canCollapse ? (
                    <Spoiler header={this.header( group )} open={false}>
                        {this.content( group )}
                    </Spoiler>
                ) : (
                    <React.Fragment>
                        {!noHead && <h4>{this.header( group )}</h4>}
                        {this.content( group )}
                    </React.Fragment>
                )}
            </div>
        )
    }
}

export default TaskGroup
