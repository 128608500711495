/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Select, Checkbox } from 'antd'

/* APPLICATION */
import { ReportsControls } from 'components'

import './interactive-controls.scss'

const { Option } = Select

class InteractiveControls extends Component {
    static propTypes = {
        dates: PropTypes.arrayOf( PropTypes.object ),
        outstaff: PropTypes.string,
        company: PropTypes.string,
        checked: PropTypes.bool,

        companies: PropTypes.array,
        check: PropTypes.string,

        onDates: PropTypes.func,
        onOutstaff: PropTypes.func,
        onCompany: PropTypes.func,
        onCheck: PropTypes.func,
        onDownload: PropTypes.func,
    }

    options = ( data ) =>
        [
            {
                companyUuid: 'all',
                companyName: 'Любая компания',
            },
            ...data,
        ].map(( item ) => (
            <Option key={item.companyUuid} value={item.companyUuid}>
                {item.companyName}
            </Option>
        ))

    onCheck = ( cb ) => ( e ) => cb( e.target.checked )

    render () {
        const { dates, outstaff, company, checked } = this.props,
              { companies, check } = this.props,
              { onDates, onOutstaff, onCompany, onCheck } = this.props

        return (
            <div className="interactive-controls">
                <div className="pc-row">
                    <ReportsControls type="range" date={dates} onChange={onDates} />
                </div>
                <div className="pc-row">
                    <Select value={outstaff} onChange={onOutstaff}>
                        <Option key="all" value="all">
              Все
                        </Option>
                        <Option key="staff" value="staff">
              Кроме внештатных
                        </Option>
                        <Option key="oustaff" value="outstaff">
              Только внештатные
                        </Option>
                    </Select>
                </div>
                {outstaff === 'outstaff' && (
                    <div className="pc-row">
                        <Select value={company} onChange={onCompany}>
                            {this.options( companies || [])}
                        </Select>
                    </div>
                )}
                <div className="pc-row">
                    <Checkbox checked={checked} onChange={this.onCheck( onCheck )}>
                        {check}
                    </Checkbox>
                </div>
            </div>
        )
    }
}

export default InteractiveControls
