import React        from 'react'
import { PprTable } from 'components'
import config       from 'config'
import './ppr-area-manager-structure.scss'

const rowPrefixes = [ 'rgm', 'argm', 'sm' ],
      colsStructures = {
          Optimal: 'Менедж. структура',
          BenchNextLevel: 'BENCH след. уровень',
          TrainingNextLevel: 'На тренин. след. уров.',
          OnPosition: 'На позиции',
          OnTrainingOnPosition: 'На тренин. и на позиции',
          AreaVacancie: 'Вакансии'
      },
      colsBench = {
          BenchCertified: 'Сертифицировано',
          BenchOnTraining: 'На тренинге'
      },
      colSpanStructure = Object.keys( colsStructures ).length,
      colSpanBench = Object.keys( colsBench ).length

const PprAreaManagerStructure = ({
    areaManagersStructureLong,
    areaBenchStructureLong
}) => {
    return (
        <PprTable vertical className='ppr-area-manager-structure'>
            <thead>
                <tr>
                    <th rowSpan={2}>Позиция</th>
                    <th colSpan={colSpanStructure}>Структура менеджеров</th>
                    <th colSpan={colSpanBench}>BENCH менеджеров</th>
                </tr>
                <tr>
                    {Object.values( colsStructures ).map( text => <th key={text}>{text}</th> )}
                    {Object.values( colsBench ).map( text => <th key={text}>{text}</th> )}
                </tr>
            </thead>
            <tbody>
                {rowPrefixes.map( prefix => <tr key={`manager-structure_${prefix}`}>
                    <td>{prefix.toUpperCase()}</td>
                    {Object.keys( colsStructures ).map( field => <td key={`${prefix}${field}`}>
                        {areaManagersStructureLong?.[ `${prefix}${field}` ] || config.ui.nullSymbol}
                    </td> )}
                    {Object.keys( colsBench ).map( field => <td key={`${prefix}${field}`}>
                        {areaBenchStructureLong?.[ `${prefix}${field}` ] || config.ui.nullSymbol}
                    </td> )}
                </tr> )}
            </tbody>
        </PprTable>
    )
}

export default PprAreaManagerStructure
