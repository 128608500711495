import React       from 'react'
import { Popover } from 'antd'

import column from './helpers'

export default {
    reports: {
        time: [
            column( 'Ресторан', 'restaurantName', {
                action: true,
                render: ( text, row ) => {
                    if ( !row._popover ) { return text }

                    return (
                        <Popover
                            content={
                                <React.Fragment>
                  Для того чтобы перейти к отчету по ресторану,
                                    <br />
                  отключите представление ресторанов по типу услуг
                                </React.Fragment>
                            }
                            placement="topLeft"
                            mouseLeaveDelay={0}
                            mouseEnterDelay={0}
                            overlayStyle={{ pointerEvents: 'none' }}
                        >
                            {text}
                        </Popover>
                    )
                },
            }),
            column( 'Код', 'factsNumber' ),
            column( 'План расписание, ч', 'sumPlanSchedule', {
                format: 'thousand',
                numeric: true,
            }),
            column( 'План контракт, ч', 'sumPlanContract', {
                format: 'thousand',
                numeric: true,
            }),
            column( 'Факт, ч', 'fact', {
                format: 'thousand',
                numeric: true,
            }),
            column( 'Недоработка, ч', 'sumDelta', {
                format: 'thousand',
                numeric: true,
            }),
            column( 'Станция', 'positionCategoryName', {
                render: ( text, row ) => {
                    if ( !row.factsNumber ) { return '' }
                    return text || 'Без категории'
                },
            }),
        ],
    },
}
