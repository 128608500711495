/* IMPORT */
import { timeActions, requestActions, settingsActions } from 'services'

/* EXPORT */

export const mapStateToProps = ( state ) => ({
    time: state.time.report,
    workHours: state.settings.workHours,
    request: state.request,
    restaurants: state.restaurants.list,
})

export const allActions = {
    ...requestActions,
    ...timeActions,
    ...settingsActions,
}
