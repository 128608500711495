/* eslint-disable camelcase */
/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import {  Popover }         from 'antd'

/* APPLICATION */
import { ReportTable, Spinner, OverallModal } from 'components'
import { DatePicker }                         from 'antd-v5'
import column                                 from 'config/tables/helpers'
import { format }                             from 'tools'

import config from 'config'

import './overall-rating.scss'
import GroupSwitch from '../GroupSwitch/GroupSwitch'

import dayjs from 'dayjs'

const { MonthPicker } = DatePicker,
      defMonth = () => ({
          total: null,
          people: null,
          product: null,
          promo: null,
          place: null,
          routine: null,
          apbsc: null,
          bsc: null,
          restaurant: {
              total: null,
              visits: [],
          },
      })

class OverallRating extends Component {
    static propTypes = {
        data: PropTypes.array,

        fetchData: PropTypes.func,
        update: PropTypes.func,
    }

    constructor ( props ) {
        super( props )

        const date = dayjs().startOf( 'quarter' ),
              data = this.prepare( props.data ),
              month = dayjs().diff( date, 'month' )

        this.state = {
            data,
            date,
            month,
            columns: this.columns( date, 0, data ),
            selected: null,
            popup: false,
        }
    }

    componentDidMount () {
        this.load()
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { data } = this.props

        data !== nextProps.data && this.setData( nextProps.data )
    }

    setData = ( raw ) => {
        const { date } = this.state,
              data = this.prepare( raw ),
              month = dayjs().isSame( date, 'quarter' )
                  ? dayjs().diff( date, 'month' )
                  : 0

        this.setState({
            data,
            month,
            columns: this.columns( date, month, data ),
        })
    }

    setDate = ( date ) =>
        this.setState(
            { date, },
            this.load
        )

    select = ( month ) => {
        return () =>
            this.setState({
                month,
                columns: this.columns( this.state.date, month, this.state.data ),
            })
    }

    showPopup = ( record ) => {
        return () => {
            if ( record.restaurantId === 'all' ) { return }
            if ( record.total === null ) { return }

            this.setState({
                selected: record,
                popup: true,
            })
        }
    }

    hidePopup = () => {
        this.setState({ popup: false, })

        setTimeout(() => this.setState({ selected: null }), 200 )
    }

    empty = ( data ) => {
        const res = format.copy.array( data )

        res.unshift({
            _no_data: true,
            _no_edit: true,
            _alwaysOnTop: true,
            restaurantId: 'all',
            restaurantName: 'Все рестораны',
            total: null,
            months: [
                {
                    message: (
                        <span className="no-b">Нет данных за выбранный квартал</span>
                    ),
                    visits: [],
                },
                {
                    message: (
                        <span className="no-b">Нет данных за выбранный квартал</span>
                    ),
                    visits: [],
                },
                {
                    message: (
                        <span className="no-b">Нет данных за выбранный квартал</span>
                    ),
                    visits: [],
                },
            ],
        })

        res.forEach(( row ) => {
            row.months.forEach(( month, index ) => {
                month
                    ? this.prepareMonth( row, month, index )
                    : this.prepareMonth( row, defMonth( index ), index )

                row[ `month-${index}-restaurant` ] = ''
                row._no_edit = true
            })
        })

        return res
    }

    prepare = ( data ) => {
        if ( !data ) { return null }
        if ( data.filter(( d ) => !!d.total ).length < 1 ) {
            return this.empty( data )
        }

        const res = format.copy.array( data )

        res.unshift({
            _no_edit: true,
            _alwaysOnTop: true,
            _month_0_empty: true,
            _month_1_empty: true,
            _month_2_empty: true,
            restaurantId: 'all',
            restaurantName: 'Все рестораны',
            total: Math.round( this.reduce( data, 'total' )),
            months: [
                {
                    total: Math.round( this.reduce( data, 'months', 0, 'total' )),
                    visits: [],
                },
                {
                    total: Math.round( this.reduce( data, 'months', 1, 'total' )),
                    visits: [],
                },
                {
                    total: Math.round( this.reduce( data, 'months', 2, 'total' )),
                    visits: [],
                },
            ],
        })

        res.forEach(( row ) => {
            row.months.forEach(( month, index ) => {
                month
                    ? this.prepareMonth( row, month, index )
                    : this.prepareMonth( row, defMonth( index ), index )

                row[ `month-${index}-restaurant` ] =
          month && month.restaurant ? month.restaurant.total : null

                res[ 0 ][ `_month_${index}_empty` ] =
          res[ 0 ][ `_month_${index}_empty` ] && ( !month || !month.restaurant )
            })
        })

        return res
    }

    prepareMonth = ( row, month, index ) =>
        Object.keys( month ).forEach(( key ) => {
            row[ `month-${index}-${key}` ] = month[ key ]
        })

    reduce = ( data, key, index, sub ) => {
        const filtered =
      index !== undefined
          ? data.filter(
              ( r ) => r[ key ][ index ] !== null && r[ key ][ index ][ sub ] !== null
          )
          : data.filter(( r ) => r[ key ] !== null )

        if ( index !== undefined ) {
            const res =
        filtered.reduce(
            ( summ, row ) =>
                summ +
            ( row[ key ][ index ] && row[ key ][ index ][ sub ]
                ? row[ key ][ index ][ sub ]
                : 0 ),
            0
        ) / filtered.length

            return isNaN( res ) ? null : res
        }

        if ( filtered.filter(( d ) => !!d ).length === 0 ) { return config.ui.nullSymbol }

        return (
            filtered.reduce(( summ, row ) => summ + row[ key ], 0 ) / filtered.length ||
      null
        )
    }

    load = () => {
        const { date } = this.state,
              { fetchData } = this.props

        fetchData( date.format( config.format.dayAPI ))
    }

    quarters = ( month ) => (
        <span className="nowrap">
            {month.format( 'MMM' ) +
        ' – ' +
        dayjs( month ).add( 2, 'month' ).format( 'MMM' )}
        </span>
    )

    list = ( visits, key, record ) => {
        const all = visits.reduce(( list, row ) => {
            row && list.indexOf( row[ key ]) < 0 && list.push( row[ key ])
            return list
        }, [])

        if ( all.length === 0 ) { return '—' }

        return all.length > 1 ? (
            <React.Fragment>
                {all[ 0 ]}{' '}
                <span className="link" onClick={this.showPopup( record )}>
          +{all.length - 1}
                </span>
            </React.Fragment>
        ) : (
            all[ 0 ]
        )
    }

    monthRender = ( index ) => {
        return ( text, record ) => {
            if ( !record ) { return text }
            if ( !record.months[ index ]) { return text }
            if ( !record.months[ index ].restaurant ) { return text }
            if (
                !record.months[ index ].restaurant.visits ||
        record.months[ index ].restaurant.visits.length < 1
            ) { return text }

            const visits = record.months[ index ].restaurant.visits,
                  info = (
                      <React.Fragment>
                          {visits.map(( visit ) =>
                              visit ? (
                                  <p key={visit.date + visit.result}>
                                      {visit.result +
                    '% – ' +
                    dayjs( visit.date ).format( config.format.onlyDayViewShort )}
                                  </p>
                              ) : null
                          )}

                          <div className="popin-offset">
                              <p key="rgm">RGM: {this.list( visits, 'rgm', record )}</p>
                              <p key="area">ТУ: {this.list( visits, 'areaCoach', record )}</p>
                              <p key="mc">МС: {this.list( visits, 'shiftSupervisor', record )}</p>
                          </div>

                          <p key="more">
                              <span className="link" onClick={this.showPopup( record )}>
                Подробнее
                              </span>
                          </p>
                      </React.Fragment>
                  )

            return (
                <Popover content={info} overlayClassName="table-cell-popover">
                    <span className="link" onClick={this.showPopup( record )}>
                        {text}
                    </span>
                </Popover>
            )
        }
    }

    columns = ( date, month, data ) => {
        const res = format.copy.array( config.tables.restaurants.overall )

        let i, m, col

        res[ 0 ].title = (
            <MonthPicker
                value={date}
                onChange={this.setDate}
                format="Q квартал YYYY"
                disabledDate={( date ) =>
                    date.isBefore( dayjs( '01-01-2020' )) ||
          date.isAfter( dayjs().endOf( 'year' ))
                }
                getCalendarContainer={( trigger ) => trigger.parentNode}
                monthCellContentRender={this.quarters}
            />
        )

        for ( i = 0; i < 3; i++ ) {
            m = dayjs( date ).add( i, 'month' ).locale( 'ru' )

            if ( i === month ) {
                col = column(
                    format.strings.capitalize( m.format( 'MMMM' )),
                    `month-${i}-message`,
                    { width: '100%', }
                )

                if ( data && !data[ 0 ]._no_data && !data[ 0 ][ `_month_${month}_empty` ]) {
                    col.children = format.copy.array( config.tables.restaurants.month( i ))
                    col.children[ 1 ].render = this.monthRender( i )
                }

                data &&
          data[ 0 ][ `_month_${month}_empty` ] &&
          ( data[ 0 ][ `month-${i}-message` ] = (
              <span className="no-b">Нет данных за выбранный месяц</span>
          ))
            } else {
                col = column(
                    <span className="link" onClick={this.select( i )}>
                        {format.strings.capitalize( m.format( 'MMMM' ))} ›
                    </span>,
                    `month-${i}-total`,
                    {
                        rowSpan: 2,
                        as: 'currency',
                        format: 'checkNull',
                        className: 'collapsed',
                    }
                )
            }

            res.push( col )
        }

        return res
    }

    change = ( record, rawKey, value ) => {
        const { date, month } = this.state,
              key = rawKey.split( '-' ).pop(),
              req = {
                  restaurantId: record.restaurantId,
                  date: dayjs( date ).add( month, 'month' ).format( config.format.dayAPI ),
                  [ key ]: value,
              }

        this.props.update( req )
    }

    recalc = ( record, rawKey, value ) => {
        const { month } = this.state,
              copy = format.copy.object( record ),
              data = format.copy.array( this.state.data ),
              found = data.find(( row ) => row.restaurantId === copy.restaurantId ),
              index = data.indexOf( found )

        copy[ rawKey ] = value
        data[ index ] = copy

        //Calculate Month RGM
        const keys = [
            'people',
            'restaurant',
            'product',
            'promo',
            'place',
            'routine',
            'apbsc',
        ],
              fkeys = keys.filter(( k ) => copy[ `month-${month}-${k}` ] !== null ),
              mtotal = Math.round(
                  fkeys.reduce(( summ, k ) => summ + copy[ `month-${month}-${k}` ], 0 ) /
          fkeys.length
              )

        copy[ `month-${month}-total` ] = mtotal

        //Calculate total
        const fmonths = [ 0, 1, 2 ].filter(( m ) => copy[ `month-${m}-total` ] !== null ),
              total = Math.round(
                  fmonths.reduce(( summ, m ) => summ + copy[ `month-${m}-total` ], 0 ) /
          fmonths.length
              )

        copy.total = total

        //Calculate month summary
        const filtered = data.filter(
            ( r, i ) => i > 0 && r[ `month-${month}-total` ] !== null
        )

        data[ 0 ][ `month-${month}-total` ] = Math.round(
            filtered.reduce(( s, r ) => s + r[ `month-${month}-total` ], 0 ) /
        filtered.length
        )

        //Calculate overall summary
        const erests = data.filter(( r, i ) => i > 0 && r.total !== null )

        data[ 0 ].total = Math.round(
            erests.reduce(( s, r ) => s + r.total, 0 ) / erests.length
        )

        this.setState({ data, })
    }

    render () {
        const { ratingTableGroup, changeRatingTableGroup } = this.props
        const { columns, data, date, month, selected, popup } = this.state

        return (
            <div className="rating-inner overall-rating">
                <GroupSwitch value={ratingTableGroup} onChange={changeRatingTableGroup}/>
                {data ? (
                    <div className="report-table">
                        <ReportTable
                            rowKey="restaurantId"
                            unit="percent"
                            data={format.generate.noPager( data )}
                            columns={columns}
                            onCellBlur={this.change}
                            onChange={this.recalc}
                        />
                    </div>
                ) : (
                    <Spinner />
                )}
                <OverallModal
                    visible={popup}
                    date={dayjs( date ).add( month, 'month' )}
                    data={selected}
                    month={month}
                    onClose={this.hidePopup}
                />
            </div>
        )
    }
}

export default OverallRating
