import React, { ReactNode }          from 'react'
import { GuestVoteGauge }            from '../charts'
import styles                        from './happiness-gauges.module.scss'
import { HappinessGaugesStoreProps } from './connector'
import { connect }                   from 'react-redux'
import { mapStateToProps }           from './connector'
import { Spinner }                   from 'components'
import { MetricsType }               from 'services/guestVote/statistic/type'

const HappinessGauges = ( props: HappinessGaugesStoreProps ) => {
    const { summaryHappinessIndex, summaryHappinessIndexIsLoading, happinessIndexesByHour, happinessIndexesByHourIsLoading, metricsType } = props

    const speedometers: Record<MetricsType, ReactNode> = {
        'HI': <React.Fragment>
            {summaryHappinessIndex.factHappinessIndex && <GuestVoteGauge title="HI" percent={summaryHappinessIndex.factHappinessIndex / 100 }/>}
            {happinessIndexesByHour.map(({ factHappinessIndex, name }) =>
                factHappinessIndex && <GuestVoteGauge key={name} percent={factHappinessIndex / 100} title={name}/>
            )}
        </React.Fragment>,
        'OSAT': <React.Fragment>
            {summaryHappinessIndex.factOsat && <GuestVoteGauge title="OSAT" postfix="%" percent={summaryHappinessIndex.factOsat / 100 }/>}
            {happinessIndexesByHour.map(({ factOsat, name }) =>
                factOsat && <GuestVoteGauge key={name} postfix="%" percent={factOsat / 100} title={name}/>
            )}
        </React.Fragment>,
        'RATING': <React.Fragment>
            {summaryHappinessIndex.factFeedbacksRating && <GuestVoteGauge multiplier={5} title="Рейтинг" percent={summaryHappinessIndex.factFeedbacksRating / 5 }/>}
            {happinessIndexesByHour.map(({ factFeedbacksRating, name }) =>
                factFeedbacksRating && <GuestVoteGauge multiplier={5} key={name} percent={factFeedbacksRating / 5} title={name}/>
            )}
        </React.Fragment>
    }

    return (
        <div className={styles.happiness_gauges}>
            {summaryHappinessIndexIsLoading || happinessIndexesByHourIsLoading
                ? <Spinner/>
                : speedometers[ metricsType ]
            }
        </div>
    )
}

export default connect( mapStateToProps, {})( HappinessGauges )
