/* eslint-disable camelcase */
import { format } from 'tools'

export const changed = ( res, props, state ) =>
    !state.changeTimer &&
  ( state.error === 'tomorrow_mc_error' ||
    ( state.data &&
      state.data !== props.schedule &&
      state.changed.includes( true )))
        ? res
        : ''

export const errorMessage = ( error, errorDate, returnedOutstaff, message ) => {
    if ( error === 'underage_error' && message ) { return message }

    const errors = {
        mc_error: 'В смене должен быть главный менеджер',
        tomorrow_mc_error: 'Назначьте главного по смене на завтра, чтобы в приложении появились чек-листы.',
        outstaff_error: returnedOutstaff
            ? returnedOutstaff.length > 1
                ? `Сотрудники ${format.strings.joinAnd(
                    returnedOutstaff,
                    'employeeName'
                )} не могут быть установлены в расписании. Они переведены в другой ресторан`
                : 'Сотрудник не может быть установлен в расписании. Он переведен в другой ресторан'
            : 'Внештатный сотрудник не может быть установлен старшим смены. У него нет YUM ID для входа в DSR',
    }

    return errors[ error ]
        ? errors[ error ]
        : errorDate
            ? `Графики работников пересекаются ${errorDate}. Изменения не сохранятся.`
            : 'Графики работников пересекаются. Изменения не сохранятся.'
}
