/* VENDOR */
import React from 'react'
import dayjs from 'dayjs'

/* APPLICATION */
import { format } from 'tools'
import column     from './helpers'

export default {
    label: ( request ) => {
        const isTimeText = request?.currentPredef === 'shift' || request?.currentPredef === 'day'
        return column( isTimeText ? 'Время' : 'Дата', 'label', {
            sorter: 'bydate',
            render: ( text, record ) => format.check.diffYears( record, text, request ),
        })
    },

    fact: column( 'Факт', 'factValue', {
        numeric: true,
        as: 'currency',
        sorter: 'bynull',
    }),

    plan: column( '' +
        'Цель', 'planValue', {
        numeric: true,
        as: 'currency',
        sorter: 'bynull',
    }),

    delta: column( 'Разница', 'delta', {
        numeric: true,
        as: 'currency',
        sorter: 'bynull',
        cell: [ 'cellclass' ],
    }),

    prevYear: column( 'Прош. год', 'factTurnoverLastYear', {
        numeric: true,
        as: 'currency',
        sorter: 'bynull',
    }),

    deltaYear: column( 'Разница', 'deltaTurnoverLastYear', {
        numeric: true,
        as: 'currency',
        sorter: 'bynull',
        cell: [ 'cellclass' ],
    }),

    managers: column( 'Менеджер(ы) смены', 'manager', {
        sorter: ( a, b ) => format.sort.objectArray( a, b, 'manager', 'managerName' ),
        render: ( text ) => text.map(( m ) => m.managerName ).join( ', ' ),
    }),

    turnover: column( 'Товарооборот', 'turnover', {
        numeric: true,
        as: 'currency',
        sorter: 'bynull',
        cell: [ 'cellclass' ],
    }),

    fullness: column( 'Наполненность', 'fullness', {
        numeric: true,
        sorter: 'bynull',
    }),

    averageCheck: column( 'Средний чек', 'averageCheck', {
        numeric: true,
        as: 'currency',
        sorter: 'bynull',
    }),

    percFact: column( 'Доля', 'factValue', {
        width: 60,
        numeric: true,
        sorter: 'bynull',
        format: 'percentage',
    }),

    percPlan: column( 'Цель', 'planValue', {
        editable: true,
        as: 'number',
        addOn: '%',
        width: 60,
        sorter: 'bynull',
        format: 'percentage',
    }),

    percDelta: column( 'Разница', 'deltaValue', {
        width: 60,
        numeric: true,
        sorter: 'bynull',
        cell: [ 'cellclass' ],
        format: 'percentage',
        min: 0,
        max: 100,
    }),

    days: ( key, ftype, variants ) =>
        column( 'День недели', 'dayOfWeek', {
            as: 'spoiler',
            className: 'capitalize',
            width: '100%',
            render: ( text, input ) => {
                const count = input[ key ] ? input[ key ].length : 0,
                      str = format.strings.count( count, variants )

                return (
                    <span className="collapse-header">
                        {dayjs().day( text ).format( 'dddd' )}
                        <span className="text-flush">{str}</span>
                    </span>
                )
            },
        }),

    daySelect: column( 'День', 'dayOfWeek', {
        editable: true,
        as: 'dayOfWeekSelect',
        className: 'capitalize',
        width: 130,
    }),

    employee: column( 'Сотрудник', 'name', {
        editable: true,
        as: 'select',
        items: 'employees',
        focusOnCreate: true,
        width: 260,
        search: true,
    }),

    dateStart: column( 'Дата начала', 'dateStart', {
        editable: true,
        as: 'date',
        width: 150,
    }),

    dateEnd: column( 'Дата окончания', 'dateEnd', {
        editable: true,
        as: 'date',
        width: 150,
    }),

    remove: column( '', null, {
        width: 60,
        editable: true,
        as: 'remove',
        className: 'align-center',
        small: true,
    }),

    add: column( '', null, {
        as: 'add',
        className: 'align-center',
    }),
}
