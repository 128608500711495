export function throttle ( callback, limit ) {
    let waiting = false
    return () => {
        if ( !waiting ) {
            callback.apply( this, arguments )
            waiting = true
            setTimeout(() => {
                waiting = false
            }, limit )
        }
    }
}

export const getRandomArbitrary = ( min, max ) => {
    return Math.floor( Math.random() * ( max - min ) + min )
}
