/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Input }            from 'antd'

/* APPLICATION */
import { format } from 'tools'

const Search = Input.Search

class FilterInput extends Component {
    static propTypes = {
        data: PropTypes.array,
        check: PropTypes.any,
        placeholder: PropTypes.string,
        rowKey: PropTypes.oneOfType([ PropTypes.string, PropTypes.array ]),
        update: PropTypes.func.isRequired,
    }

    static defaultProps = { rowKey: 'label', }

    constructor ( props ) {
        super( props )

        this.state = { search: '', }
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { data, check } = this.props

        if ( nextProps.data ) {
            if ( nextProps.data !== data || nextProps.check !== check ) {
                this.update({ target: { value: this.state.search } }, nextProps )
            }
        }
    }

    placeholder = () => this.props.placeholder || 'Введите название'
    testEnter = ( e ) => e.key === 'Enter' && this.update( e )

    update = ( e, rprops ) => {
        const props = rprops || this.props,
              search = e.target.value,
              update =
        e.target.value.length > 1
            ? this.filter( search, props )
            : this.filter( '', props )

        this.setState({ search })
        this.props.update( update )
    }

    filter = ( str, props ) => format.find.withStr( str, props.data, props.rowKey )

    render () {
        return (
            <div className="filter-container">
                <Search
                    placeholder={this.placeholder()}
                    value={this.state.search}
                    onChange={this.update}
                    onKeyPress={this.testEnter}
                />
            </div>
        )
    }
}

export default FilterInput
