/* VENDOR */
import React, { Component } from 'react'
import { connect }          from 'react-redux'
import PropTypes            from 'prop-types'
import { Scrollbars }       from 'react-custom-scrollbars'
import { Tabs }             from 'antd'
import dayjs                from 'dayjs'

/* APPLICATION */
import { TaskGroup, TaskTable, UserAvatar } from 'components'

import { businessActions } from 'services'
import { format }          from 'tools'
import config              from 'config'

import './manager-report.scss'

const titles = {
    tasks: 'Текущие',
    outdated: 'Просроченные',
    intime: 'Вовремя',
    late: 'Позже',
    cancelled: 'Отменено',
}

class ManagerReport extends Component {
    static propTypes = {
        data: PropTypes.object,
        onDetails: PropTypes.func,
    }

    groups = ( data, noDate, timeKeys ) => {
        const { onDetails } = this.props

        return Object.keys( data ).map(( key ) => {
            const group = {
                groupName: key,
                groupTitle: ' ',
                tasks: data[ key ],
            },
                  items = (
                      <TaskTable
                          group={group}
                          hideDate={noDate}
                          timeKey={timeKeys ? timeKeys[ key ] : null}
                          onDetails={onDetails( group )}
                      />
                  )

            return key === 'tasks' || data[ key ].length > 0 ? (
                <div className="self-tasks-group" key={key}>
                    <h3>{this.title( key )}</h3>
                    {data[ key ].length > 0 ? items : <span>Нет задач</span>}
                </div>
            ) : null
        })
    }

    title = ( key ) => ( titles[ key ] ? titles[ key ] : key )

    closed = ( data ) => {
        if (
            data.intime.length < 1 &&
      data.late.length < 1 &&
      ( !data.cancelled || data.cancelled.length < 1 )
        ) {
            return 'Нет закрытых задач'
        }

        const req = {
            intime: data.intime.map(( item ) => {
                item._modificators = [ 'intime' ]
                return item
            }),
            late: data.late.map(( item ) => {
                item._modificators = [ 'late' ]
                return item
            }),
            cancelled: ( data.cancelled || []).map(( item ) => {
                item._modificators = [ 'canceled' ]
                return item
            }),
        }

        return this.groups( req, true, { cancelled: 'deleteTime' })
    }

    missed = ( data ) => {
        const { onDetails } = this.props,
              done = <h4>Все обходы выполнены</h4>

        if ( data.length < 1 ) {
            return done
        }

        const missed = {
            groupName: 'missed',
            groupTitle: '',
            tasks: data,
        }

        if ( missed.tasks.length < 1 ) {
            return done
        }

        return (
            <TaskGroup
                group={this.merge( missed, 'startTime' )}
                canCollapse={false}
                hideInfo={true}
                noHead={true}
                onDetails={onDetails( missed )}
            />
        )
    }

    merge = ( group, key, needSort ) => {
        const sort = needSort === false ? false : true,
              ng = format.copy.object( group )

        let tasks = []

        ng.tasks.forEach(( task ) => {
            const inArray = tasks.find(( t ) => t.taskName === task.taskName )

            if ( inArray ) {
                inArray[ key ].push( task[ key ])
            } else {
                const nt = format.copy.object( task )

                nt[ key ] = [ task[ key ] ]
                tasks.push( nt )
            }
        })

        tasks.forEach(
            ( task ) =>
                ( task[ key ] = task[ key ].sort(( a, b ) =>
                    dayjs( a ).isBefore( dayjs( b )) ? -1 : 1
                ))
        )

        if ( sort ) {
            tasks = tasks.sort(( a, b ) => {
                let index = 0

                while (
                    a[ key ][ index ] === b[ key ][ index ] &&
          index < Math.min( a.length, b.length )
                ) {
                    index++
                }

                return dayjs( a[ key ][ index ]).isBefore( dayjs( b[ key ][ index ])) ? -1 : 1
            })
        }

        ng.tasks = tasks

        return ng
    }

    targets = ( objectives ) => {
        const { onDetails } = this.props,
              res = objectives.map(( item, indx ) => {
                  const group = {
                      groupName: item.title,
                      groupTitle: item.title,
                      tasks: [
                          {
                              ...item,
                              taskName: item.content,
                              description: null,
                          },
                      ],
                  }

                  return (
                      <div className="self-tasks-group" key={item.title + indx}>
                          <TaskGroup
                              group={group}
                              canCollapse={false}
                              hideInfo={true}
                              hideLength={true}
                              onDetails={onDetails( group )}
                          />
                      </div>
                  )
              })

        return res.length > 0 ? res : 'На данную смену цели не заданы'
    }

    count = ( data ) => {
        const exclude = [ 'cancelled' ],
              res = Array.isArray( data )
                  ? data.length
                  : Object.keys( data ).reduce(
                      ( summ, key ) =>
                          exclude.indexOf( key ) > -1 ? summ : summ + this.count( data[ key ]),
                      0
                  )

        return !Array.isArray( data ) && parseInt( res ) === 0 ? '' : res
    }

    render () {
        const { data } = this.props,
              { objectives, active, closed, missed } = data

        const doneTabTitle = (
            <span>
                  Закрытые
                <sup>{this.count( closed )}</sup>
            </span> )

        const missedTabTitle = (
            <span>
                  Пропущенные обходы
                <sup>
                    {this.count( missed ) || <span className="all-done"></span>}
                </sup>
            </span> )

        const tabs = [
            {
                label: 'Задачи', key: 'tasks', children: ( <Scrollbars
                    {...config.ui.scrolls}
                    ref={( node ) => ( this.scrolls = node )}
                >
                    {this.groups( active )}
                </Scrollbars> )
            },
            {
                label: doneTabTitle, key: 'done', children: ( <Scrollbars
                    {...config.ui.scrolls}
                    ref={( node ) => ( this.scrolls = node )}
                >
                    {this.closed( closed )}
                </Scrollbars> )
            },
            {
                label: missedTabTitle, key: 'missed', children: ( <Scrollbars
                    {...config.ui.scrolls}
                    ref={( node ) => ( this.scrolls = node )}
                >
                    {this.missed( missed )}
                </Scrollbars> )
            },
        ]

        if ( objectives && objectives.length > 0 ) {
            tabs.unshift({
                label: 'Цели', key: 'targets', children: ( <Scrollbars
                    {...config.ui.scrolls}
                    ref={( node ) => ( this.scrolls = node )}
                >
                    {this.targets( objectives )}
                </Scrollbars>
                )
            })
        }

        return (
            <div className="manager-report" onClick={this.updateScrolls}>
                <header className="manager-report-header">
                    <aside>
                        <UserAvatar
                            name={data.name}
                            userId={data.userId}
                            image={data.userImage}
                        />
                    </aside>
                    <section className="manager-info">
                        <h3>{data.name.replace( ' ', '|' ).split( '|' ).join( '\n' )}</h3>
                    </section>
                </header>
                <section className="manager-tasks">
                    <Tabs animated={false} items={tabs}></Tabs>
                </section>
            </div>
        )
    }
}

const mapStateToProps = ( state ) => ({ request: state.request }),

      allActions = { ...businessActions }

export default connect( mapStateToProps, allActions )( ManagerReport )
