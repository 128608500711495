import dayjs                                  from 'dayjs'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Select, Spin }               from 'antd'
import classNames                             from 'classnames'
import { ReportsControls }                    from 'components'
import config                                 from 'config'
import { connect }                            from 'react-redux'
import { allActions, mapStateToProps }        from './connector'
import './ppr-area-header.scss'

const { Option } = Select

const formatView = config.format.monthView,
      formatAPI = config.format.monthAPI,
      restaurantRole = 'Restaurant Role 5',
      coachRoles = [ 'Area Coach', 'Area Coach Trainee' ]

const PprAreaHeader = ( props ) => {
    const [ search, setSearch ] = useState( '' )
    const [ period, setPeriod ] = useState( '' )
    const [ userId, setUserId ] = useState( '' )
    const [ loadingUsers, setLoadingUsers ] = useState( false )
    const searchTimeout = useRef( null )

    const isRestaurantRole = props.user.jobRole.toLowerCase() === restaurantRole.toLowerCase(),
          isCoachRole = !!coachRoles.find( elem => elem.toLowerCase() === props.user.jobRole.toLowerCase()),
          isSearching = search.length > 2,
          notFoundContent = isSearching ? 'ТУ с такими фамилией и именем не найден' : 'Начните вводить фамилию имя управляющего'

    const isChanged = () => {
        if ( props.areaManager !== userId && userId ) { return true }
        if ( !dayjs( period ).isSame( dayjs( props.date, formatView ), 'month' )) { return true }
    }

    useEffect(() => {
        if ( isCoachRole ) {
            setUserId( props.user.userId )
        }
    }, [])

    useEffect(() => {
        props.onChanged( isChanged())
    }, [ isChanged() ])

    useEffect(() => {
        if ( props.date ) {
            setPeriod( dayjs( props.date, formatView ))
        }
    }, [ props.date ])

    useEffect(() => {
        //вызов только с ролью Restaurant role 5
        if ( isCoachRole ) {
            return
        }

        clearTimeout( searchTimeout.current )

        if ( !isSearching ) {
            setLoadingUsers( false )
            return
        }

        setLoadingUsers( true )
        searchTimeout.current = setTimeout(() => {
            props.fetchAreaPprUsers({
                search,
                jobRoles: [ 'Area Coach', 'Area Coach Trainee' ],
                status: true,
                restaurantIds: props.user.grantedRestaurants
            }).finally(() => {
                //props.onChangeAreaManager( undefined )
                setLoadingUsers( false )
            })
        }, 500 )
    }, [ search ])

    const arrow = ( key, handler, disabled ) => {
        return <span
            className={classNames([ 'pager-arrow', `arrow-${key}`, !disabled && 'active' ])}
            onClick={() => !disabled && setPeriod( handler())}
        ></span>
    }

    const onSearch = ( val ) => {
        setSearch( val )
    }

    const changeAreaManager = ( value ) => {
        setUserId( value )
    }

    const option = ({ userId, scheduleName, fullName }) => (
        <Option key={userId} value={userId}>{scheduleName || fullName}</Option>
    )

    const handleUpdate = () => {
        props.onChange({
            periodPprArea: dayjs( period ).format( formatView ),
            areaManager: userId
        })
    }

    const onUsersFocus = () => {
        if ( !userId ) {
            props.flushAreaPprUsers()
        }
    }

    return (
        <header className="ppr-area-header">
            <Spin spinning={!props.periods && !props.periodsError}>
                <div className="ppr-area-header-inner">
                    <div className="header-title">{props.periods && dayjs( period ).format( formatView )}</div>
                    <div className="header-picker controls-pager">
                        {arrow(
                            'prev',
                            () => dayjs( period ).subtract( 1, 'month' ),
                            !props.periods?.find( date => dayjs( period ).subtract( 1, 'month' ).isSame( date, 'month' ))
                        )}
                        <ReportsControls
                            type="month"
                            date={period ? dayjs( period ).startOf( 'month' ) : dayjs().startOf( 'month' )}
                            onChange={setPeriod}
                            showDate={false}
                            disabledDate={( date ) => !props.periods?.some( period => dayjs( period, formatAPI ).isSame( date ))}
                        />
                        {arrow(
                            'next',
                            () => dayjs( period ).add( 1, 'month' ),
                            !props.periods?.find( date => dayjs( period ).add( 1, 'month' ).isSame( date, 'month' ))
                        )}
                    </div>
                    <div className='vertical-line' />
                    <div className='area-controls'>
                        <div className="ac-col">
                            <label htmlFor="areaManager">Территориальный управляющий</label>
                            <Select
                                id='areaManager'
                                dropdownClassName='ppr-area-manager-select'
                                placeholder={'Выберите из списка'}
                                style={{ width: 200 }}
                                filterOption={false}
                                disabled={isCoachRole}
                                onChange={changeAreaManager}
                                showSearch
                                onSearch={onSearch}
                                onFocus={onUsersFocus}
                                loading={loadingUsers}
                                value={userId}
                                notFoundContent={loadingUsers ? <Spin size='small' /> : notFoundContent}
                                defaultActiveFirstOption={false}
                            >
                                {isCoachRole && [ props.user ].map( option )}
                                {isRestaurantRole && props.users?.content?.map( option )}
                            </Select>
                        </div>

                        <div className="ac-col">
                            <Button disabled={!isChanged() || props.periodsError} id='areaUpdate' type='primary' onClick={handleUpdate}>Обновить</Button>
                        </div>
                    </div>
                </div>
            </Spin>

        </header>
    )
}

export default connect( mapStateToProps, allActions )( PprAreaHeader )
