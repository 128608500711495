export function convertToRoman ( num ) {
    const roman = {
        M: 1000,
        CM: 900,
        D: 500,
        CD: 400,
        C: 100,
        XC: 90,
        L: 50,
        XL: 40,
        X: 10,
        IX: 9,
        V: 5,
        IV: 4,
        I: 1
    }
    let str = ''

    for ( let i of Object.keys( roman )) {
        let q = Math.floor( num / roman[ i ])
        num -= q * roman[ i ]
        str += i.repeat( q )
    }

    return str
}

/**
 * Преобразует строку с currency в число.
 * Аналог parseInt, parseFlat.
 *
 * @param {string} input - Входная строка для разбора.
 * @returns {number} - Разобранное число или NaN, если входные данные некорректны.
 */
export const parseCustomNumber = ( input ) => {
    try {
        let sanitizedInput = `${input}`.replace( /[^\d.,-]/g, '' )
        if ( sanitizedInput.includes( ',' )) {
            sanitizedInput = sanitizedInput.replace( ',', '.' )
        }
        let number = parseFloat( sanitizedInput )
        return number
    } catch {
        return NaN
    }
}

