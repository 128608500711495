/* VENDOR */
import axios from 'axios'

/* APPLICATION */
import config     from 'config'
import { format } from 'tools'

const rid = [ 'factsNumber', 'inactive' ],
      sid = [ 'restaurantId', 'search' ],
      eid = { employeeId: 'employeeUuid' },
      service = 'staff'

export const getStaffList = ( params ) => {
    return format.generate.get( service, 'employees/employee-list', params, '', rid )
}
export const getExecutorList = ( rid ) =>
    format.generate.get(
        service,
        'employees/' + rid + '/task-data?employeeType=all'
    )
export const getAssignList = ( rid ) =>
    format.generate.get(
        service,
        'employees/' + rid + '/task-data?employeeType=assignee'
    )
export const getCompanies = () => format.generate.get( service, 'companies' )
export const getEmployee = ( params ) =>
    format.generate.get(
        service,
        'employees/employee-card',
        params,
        '',
        eid,
        'erating'
    )
export const getFixedShifts = ( params ) =>
    format.generate.get( service, 'employees/fixed-shifts', params, '', sid )
export const getAbsence = ( params ) =>
    format.generate.get( service, 'employees/employee-absence', params, '', sid )

export const getRating = ( eid ) =>
    format.generate.get( 'business', 'employees/' + eid + '/rating' )

export const getRatingList = ( params ) =>
    format.generate.get(
        'business',
        'employees/' + params.id + '/rating/page',
        params,
        null,
        null,
        'erating'
    )

export const getAdminUsers = ( params ) =>
    format.generate.get( service, 'admin/users', params )
export const getRestaurantStaff = ( params ) =>
    format.generate.get( service, 'admin/users/task-data', params, sid )
export const getCanBeLinked = ( params ) =>
    format.generate.get( service, 'admin/users/linked-data', params )

/* MAIN */

export const getRates = ( request ) =>
    axios.post(
        config.api.staff + 'dictionaries/rates',
        request,
        config.api.getOptions()
    )

export const addEmployee = ( request ) =>
    axios.post(
        config.api.staff + 'employees/' + request.params.employeeId,
        request.data,
        config.api.getOptions()
    )

export const saveEmployee = ( request ) =>
    axios.patch(
        config.api.staff + 'employees/' + request.params.employeeId,
        request.data,
        config.api.getOptions()
    )

export const removeEmployee = ( employeeId ) =>
    axios.delete( config.api.staff + 'employees/' + employeeId, { ...config.api.getOptions(), })

export const updateAdminUser = ( body ) =>
    axios.post(
        config.api.staff + 'admin/users/' + body.userId,
        body,
        config.api.getOptions()
    )

/* OPPORTUNITIES */

export const setOpportunity = ( request ) =>
    axios.post(
        config.api.staff +
      'employees/' +
      request.params.employeeId +
      '/opportunities',
        request.data,
        config.api.getOptions()
    )

export const removeOpportunity = ( request ) =>
    axios.delete(
        config.api.staff +
      'employees/' +
      request.employeeId +
      '/opportunities/' +
      request.opportunityId,
        {
            data: request,
            ...config.api.getOptions(),
        }
    )

/* POSITIONS */

export const setPosition = ( request ) =>
    axios.post(
        config.api.staff + 'employees/' + request.params.employeeId + '/positions',
        request.data,
        config.api.getOptions()
    )

export const removePosition = ( request ) =>
    axios.delete(
        config.api.staff +
      'employees/' +
      request.employeeId +
      '/positions/' +
      request.guid,
        {
            data: request,
            ...config.api.getOptions(),
        }
    )

/* FXIED SHIFTS */

export const setFixedShift = ( request ) =>
    axios.post(
        config.api.staff +
      'employees/' +
      request.params.employeeGuid +
      '/fixed-shifts',
        request.data,
        config.api.getOptions()
    )

export const removeFixedShift = ( request ) =>
    axios.delete(
        config.api.staff +
      'employees/' +
      request.employeeGuid +
      '/fixed-shifts/' +
      request.fixedShiftId,
        {
            data: request,
            ...config.api.getOptions(),
        }
    )

/* ABSENCE */

export const setAbsence = ( request ) =>
    axios.post(
        config.api.staff +
        'employees/' +
        request.params.employeeUuid +
        '/absence',
        request.data,
        config.api.getOptions()
    )

export const removeAbsence = ( request ) =>
    axios.delete(
        config.api.staff +
        'employees/' +
        request.employeeGuid +
        '/absence/' +
        request.absenceId,
        {
            data: request,
            ...config.api.getOptions(),
        }
    )


/* TRANSFER */

export const transferEmployee = ({ userId, ...params }) =>
    axios.post(
        `${config.api.staff}employees/${userId}/transfer`,
        {},
        {
            ...config.api.getOptions(),
            params,
        }
    )

export const cancelTransferEmployee = ( userId ) =>
    axios.delete(
        `${config.api.staff}employees/${userId}/transfer`,
        config.api.getOptions()
    )

/* PPR DIRECTOR */

export const getPprRestaurant = ( params ) =>
    format.generate.get( 'dashboard', 'ppr/restaurant', params )

export const getPprHr = ({ factsNumber, ...params }) =>
    format.generate.get( 'restaurant', `restaurants/${factsNumber}/ppr/hr`, params )

export const getPprManagersStructure = ( params ) =>
    format.generate.get( 'staff', 'employees/ppr/manager-structures', params )

export const getPprEmployeesCount = ( params ) =>
    format.generate.get( 'staff', 'employees/ppr/employees-count', params )

export const getPprData = ({ factsNumber }) =>
    format.generate.get( 'restaurant', `restaurants/${factsNumber}/ppr` )

export const getPprManagers = ( params ) =>
    format.generate.get( 'staff', 'employees/ppr/managers', params )

export const getPprEmployees = ( params ) =>
    format.generate.get( 'staff', 'employees/ppr/employees', params )

export const getPprManagersBench = ( params ) =>
    format.generate.get( 'staff', 'employees/ppr/manager-benches', params )

/* PPR */

export const getStaffPprPositions = () => format.generate.get( 'staff', 'dictionaries/ppr/positions' )

export const getStaffPprRcl = () => format.generate.get( 'staff', 'dictionaries/ppr/rcl-codes' )

export const getStaffPprPositionStatuses = () => format.generate.get( 'staff', 'dictionaries/ppr/position-statuses' )

export const getPprAvailablePeriods = ( params ) => format.generate.get( service, 'employees/ppr/periods', params )

export const getEmployeePpr = ({ employeeUuid, ...params }) =>
    format.generate.get( service, `employees/${employeeUuid}/ppr`, params )

export const createEmployeePpr = ({ id, data }) => format.generate.post( 'staff', `employees/${id}/ppr`, data, '', '', '', '' )

export const updateEmployeePpr = ({ id, pprId, data }) =>
    format.generate.put( 'staff', `employees/${id}/ppr/${pprId}`, data, '', '', '' )

/* PPR AREA */

export const getAreaPprUsers = ( params, getParams = '' ) =>
    format.generate.post( 'staff', 'employees/search', params, format.generate.query( getParams ), '', '' )

export const getPprArea = ( params ) =>
    format.generate.get( 'staff', 'employees/ppr/area', params )

export const getAreaCard = ( params ) =>
    format.generate.get( 'staff', 'employees/ppr/area/card', params )

export const createAreaCard = ( params ) =>
    format.generate.post( 'staff', 'employees/ppr/area/card', params, '', '', '', '' )

export const updateAreaCard = ({ areaCardUuid, ...params }) =>
    format.generate.put( 'staff', `employees/ppr/area/card/${areaCardUuid}`, params, '', '', '' )

export const getPprAcStatuses = () => format.generate.get( 'staff', 'dictionaries/ppr/ac-statuses' )
