/* VENDOR */
import React from 'react'

/* APPLICATION */
import { format } from 'tools'

import Template from './Template.js'
import './ready-report.scss'

const header = ( day ) => ({
    key: day.date + 'Header',
    time: 'Доступное время',
    employee: 'Сотрудник',
    specified: 'Определенные смены',
    hours: 'Часы',
    shifts: 'Смены',
    rate: 'Ставка',
    isUnderage: 'Несовершеннолетний',
    phone: 'Телефон',
    props: { className: 'row-header' },
}),
      spoiler = ( day ) => ({
          key: day.date,
          time: format.strings.dowDateY( day.date ),
          props: { className: 'row-day' },
      }),
      isNull = ( data ) => ( data === null ? '–' : data ),
      employee = ( date, data, idx ) => ({
          key: date + data.employeeName + idx,
          time:
      data.timeStart && data.timeEnd
          ? format.strings.interval( data.timeStart, data.timeEnd )
          : '–',
          employee: data.employeeName,
          specified:
      data.specifiedShifts && data.specifiedShifts.length > 0
          ? data.specifiedShifts.map(( shift ) => (
              <div key={shift.position + shift.timeStart}>
                  {format.strings.interval( shift.timeStart, shift.timeEnd )}
            &nbsp;
                  {shift.position}
              </div>
          ))
          : '–',
          hours: isNull( data.hours.min ) + ' / ' + isNull(( +data.hours.max ).toFixed( 1 )),
          shifts: isNull( data.shifts.min ) + ' / ' + isNull( data.shifts.max ),
          rate: format.strings.float( data.rate ),
          isUnderage: data.isUnderage ? 'Да' : 'Нет',
          phone: data.phone,
      }),
      heads = ( day ) => [ spoiler( day ), header( day ) ],
      rows = ( day ) => {
          const res = []

          day.data
              .filter(( d ) => d.employeeName )
              .forEach(( d, idx ) => res.push( employee( day.date, d, idx )))

          return res
      },
      columns = () =>
          format.generate.rowReader([
              'time',
              'employee',
              'specified',
              'hours',
              'shifts',
              'rate',
              'isUnderage',
              'phone',
          ])

class ReadyReport extends Template {
    constructor ( props ) {
        super( props )

        this.state = {
            data: rows( props.data ),
            heads: heads( props.data ),
            columns: columns( props.data ),
            className: 'ready-report',
        }
    }

    data = ( data ) => rows( data )
    columns = ( data ) => columns( data )
}

export default ReadyReport
