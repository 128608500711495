import { guestVoteActions } from 'services'
import { IState }           from 'store'

export const allActions = { ...guestVoteActions }

export const mapStateToProps = ( state: IState ) => ({
    metricsType: state.guestVote.metricsType,
    //other props
})

export type GuestVoteRadioButtonStoreProps = ReturnType<typeof mapStateToProps> & typeof allActions
