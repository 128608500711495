/* VENDOR */
import React, { Component }  from 'react'
import PropTypes             from 'prop-types'
import { Card, Tabs, Alert } from 'antd'

/* APPLICATION */
import { ManningTableOld, ManningChartOld, Link } from 'components'
import { format }                                 from 'tools'
import config                                     from 'config'

class ManningOld extends Component {
    static propTypes = {
        manning: PropTypes.object,
        positions: PropTypes.array,

        onRemove: PropTypes.func,
        onUpdate: PropTypes.func,
    }

    constructor ( props ) {
        super( props )
        this.state = {
            tab: 'table',
            manning: null,
            positions: [],
            count: [],
            spmh: [],
        }
    }

    componentDidMount () {
        this.setManning( this.props )
        this.setPositions( this.props.positions )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { manning, positions } = this.props

        if ( positions !== nextProps.positions ) {
            this.setPositions( nextProps.positions )
            manning && this.setManning( nextProps )
        }

        manning !== nextProps.manning && this.setManning( nextProps )
    }

    setManning = ( props ) => {
        if ( !props.positions ) { return }

        const allowed = this.allowed( props.positions ).map(( i ) => i.positionGuid ),
              manning = props.manning
                  ? props.manning.data
                      .map(( item ) => {
                          item.positions = item.positions.filter(
                              ( pos ) => allowed.indexOf( pos.positionGuid ) > -1
                          )
                          return item
                      })
                      .sort(( a, b ) => a.turnover - b.turnover )
                  : null

        this.setState({
            manning: manning,
            chart: this.generateChart( props.manning ),
        })
    }

    setPositions = ( positions ) =>
        this.setState({ positions: positions ? this.allowed( positions ) : [], })

    allowed = ( positions ) =>
        positions ? positions.filter(( pos ) => pos.chart ) : []

    generateChart = ( prop ) => {
        if ( !prop || !prop.data ) { return [] }
        return prop.data
            .map(( item, index ) => ({
                x: item.total,
                y: item.spmh,
                label: format.strings.thousand( item.spmh, index === 0 ? ' ₽' : '' ),
                yOffset: -10,
            }))
            .sort(( a, b ) => b.x - a.x )
    }

    positions = ( rec, id, count ) => {
        const found = format.find.byKey( rec.positions, 'positionGuid', id )

        found
            ? ( found.positionCount = count )
            : rec.positions.push({
                positionGuid: id,
                positionCount: count,
            })
    }

    change = ( record, key, val ) => {
        const rec = format.copy.object( record ),
              allow = [ 'turnoverChartId', 'turnover', 'total', 'spmh', 'positions' ]

        allow.indexOf( key ) < 0
            ? this.positions( rec, key, val )
            : ( rec[ key ] = parseInt( val ))

        this.props.onUpdate( format.extract.include( rec, allow ))
    }

    add = () => {
        const max = format.extract.max( this.state.manning.map(( r ) => r.turnover )),
              rec = format.copy.object( config.defs.manning ),
              manning = format.copy.array( this.state.manning )

        rec.turnover = max + 1
        rec.turnoverChartId = format.generate.guid()
        rec.__unsaved = true

        manning.push( rec )

        this.setState({ manning, })
    }

    removeUnsaved = ( record ) => {
        const manning = format.copy.array( this.state.manning )

        manning.splice( manning.indexOf( record ), 1 )
        this.setState({ manning })
    }

    removeSaved = ( record ) => this.props.onRemove( record )

    remove = ( record ) =>
        record.__unsaved ? this.removeUnsaved( record ) : this.removeSaved( record )

    wupdate = ( tab ) => {
        this.setState({ tab })
        setTimeout(() => window.dispatchEvent( new Event( 'resize' )), 100 )
    }

    render () {
        if ( this.state.positions.length < 1 ) {
            return (
                <div className="manning-card">
                    <Card bordered={false}>
                        <Alert
                            message={
                                <span>
                  Чтобы сформировать чарт, отметьте хотя бы одну{' '}
                                    <Link onClick={() => this.props.parent.setView( 'positions' )}>
                    позицию
                                    </Link>
                                </span>
                            }
                            type="warning"
                        />
                    </Card>
                </div>
            )
        }

        const tabs = [
            {
                label: 'Таблица',
                key: 'table',
                children: (
                    <ManningTableOld
                        data={this.state.manning}
                        positions={this.state.positions}
                        onAdd={this.add}
                        onChange={this.change}
                        onRemove={this.remove}
                    />
                ),
            },
            {
                label: 'График',
                key: 'chart',
                children: (
                    <ManningChartOld
                        data={this.state.chart}
                        onAdd={() => {
                            this.wupdate( 'table' )
                            this.add()
                        }}
                    />
                ),
            },
        ]

        return (
            <div className="manning-card">
                <Card bordered={false}>
                    <Tabs
                        animated={false}
                        onChange={this.wupdate}
                        activeKey={this.state.tab}
                        items={tabs}
                    ></Tabs>
                </Card>
            </div>
        )
    }
}

export default ManningOld
