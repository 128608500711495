/*eslint-disable one-var*/
import React, { useCallback, useEffect } from 'react'
import { Col, Form, Select }             from 'antd'
import classNames                        from 'classnames'
import { PprTable }                      from 'components'
import config                            from 'config'
import './ppr-manager-bench.scss'

const { Option } = Select

const prefixes = [ 'rgm', 'argm', 'sm' ]

const options = [ ...Array( 101 ).keys() ].map(( key ) => ({
    value: key,
    label: key,
}))

const PprManagerBench = ({ loading, error, onRetry, form, isArea, data, isEditable }) => {
    useEffect(() => {
        if ( form && isArea ) {
            [ 'rgmAreaBenchPlan', 'argmAreaBenchPlan', 'smAreaBenchPlan' ].forEach( key => {
                form.setFieldsValue({ key: data[ key ] })
            })
        }
        //TODO: установить форму на появление data
    }, [])

    const isRedColor = ( content, isNumber ) => {
        return ( isNumber && content < 100 ) || ( !isNumber && content < 0 )
    }

    const td = useCallback(({
        content,
        isNumber = false,
        isBold = false,
        isTotal = false,
        isDelta = false,
        editable = false,
        key,
        prefix
    }) => {
        if ( editable ) {
            return <Form.Item>
                {form.getFieldDecorator(
                    key,
                    {
                        rules: [ { type: 'number', required: true, message: `Выберите ${prefix.toUpperCase()} план` } ],
                        initialValue: content
                    }
                )(
                    <Select>
                        {options.map( item => <Option
                            key={`${key}-${item.value}`}
                            value={item.value}
                        >{item.label}</Option> )}
                    </Select>
                )}
            </Form.Item>

        }

        return <td className={
            classNames(
                isDelta && content > 0 && 'color-blue',
                isRedColor( content, isNumber ) && ( isTotal ? 'bg-color-red' : 'color-red' ),
                isBold && 'bold'
            )
        }>{content ?? config.ui.nullSymbol}{isNumber && typeof content === 'number' && '%'}</td>
    }, [ data ])

    const tr = useCallback(( prefix ) => <tr key={prefix}>
        {td({
            content: data?.[ getKey( prefix, 'Plan' ) ],
            editable: !!form && isEditable,
            key: getKey( prefix, 'Plan' ),
            prefix
        })}
        {td({ content: data?.[ getKey( prefix, 'Fact' ) ] })}
        {td({ content: data?.[ getKey( prefix, 'Delta' ) ], isDelta: true })}
    </tr>, [ data ])

    const getKey = ( prefix, postfix ) => {
        return `${prefix}${isArea ? 'Area' : ''}Bench${postfix}`
    }

    return (
        <Col span={12}>
            <PprTable
                loading={loading}
                error={error}
                onRetry={() => onRetry( 'managersBench' )}
                vertical
                className='ppr-manager-bench'
            >
                <thead>
                    <tr>
                        <th colSpan={3}>BENCH менеджеров</th>
                    </tr>
                    <tr>
                        <th>План</th>
                        <th>Факт</th>
                        <th>+/-</th>
                    </tr>
                </thead>
                <tbody>
                    {prefixes.map( tr )}
                    <tr className="result-row">
                        {td({ content: 'Итого', isBold: true })}
                        {td({ content: data?.[ getKey( 'total', 'Fact' ) ], isBold: true, isNumber: true, isTotal: true })}
                        {td({ content: data?.[ getKey( 'total', 'Delta' ) ], isBold: true, isDelta: true })}
                    </tr>
                </tbody>
            </PprTable>
        </Col>
    )
}

PprManagerBench.defaultProps = { onRetry: () => {} }

export default PprManagerBench
