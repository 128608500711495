/* VENDOR */
import React, { Component } from 'react'
import { withRouter }       from 'react-router-dom'

/* APPLICATION */
import config from 'config'
import './page-title.scss'

const hidden = {
    '/': config.left.dashboard.title,
    '/template-editor': 'Шаблоны задач',
    '/check-upload': 'Загрузка чеков',
}

class PageTitle extends Component {
    constructor ( props ) {
        super( props )
        this.state = { title: '...', }
    }

    componentDidMount () {
        const location = this.props.location.pathname,
              noSlashLocation = this.props.location.pathname.replace( /\/$/, '' ),
              key = location.split( '/' )[ 1 ],
              found = Object.values( config.left ).find(( v ) => v.route === '/' + key ),
              keys = Object.keys( hidden )

        let title = found ? found.title : null

        if ( !title ) {
            if ( keys.includes( location ) || keys.includes( noSlashLocation )) {
                title = hidden[ location ] || hidden[ noSlashLocation ]
            } else {
                title = '404'
            }
        }

        this.setState({ title })
    }

    render () {
        return <h1 className="page-title">{this.state.title}</h1>
    }
}

export default withRouter( PageTitle )
