/* VENDOR */
import React       from 'react'
import { Popover } from 'antd'
import axios       from 'axios'
import dayjs       from 'dayjs'
import qs          from 'qs'
import Icon        from '@ant-design/icons'

/* APPLICATION */
import * as Icons from 'components/layout/icons'
import config     from 'config'

import * as extract from './extract'
import * as check   from './check'
import * as copy    from './copy'
import * as strings from './strings'

const { CancelToken } = axios

export const storage = {}

export const uniq = () => '_' + Math.random().toString( 36 ).substr( 2, 9 )
export const params = ( obj, ext ) => ({ params: extract.include( obj, ext ) })
export const query = ( obj ) =>
    '?' +
  Object.keys( obj )
      .map(( key ) => key + '=' + obj[ key ])
      .join( '&' )

export const parseQuery = ( queryString ) => {
    let query = {}
    if ( queryString ) {
        let pairs = ( queryString[ 0 ] === '?' ? queryString.substr( 1 ) : queryString ).split( '&' )
        for ( let i = 0; i < pairs.length; i++ ) {
            let pair = pairs[ i ].split( '=' )
            query[ decodeURIComponent( pair[ 0 ]) ] = decodeURIComponent( pair[ 1 ] || '' )
        }
    }

    return query
}

export const get = ( api, endpoint, prm = '', append = '', inc = '', replace = '' ) => {
    const add = append || '',
          key = replace ? replace : endpoint + add,
          timeout = setTimeout(() => {
              storage[ key ] && storage[ key ]( '_timeout' )
          }, 5 * 60 * 1000 )

    storage[ key ] && storage[ key ]()

    return new Promise(( resolve, reject ) => {
        axios
            .get( config.api[ api ] + endpoint, {
                ...params( prm, inc ),
                ...config.api.getOptions(),
                ...cancel( key ),
                paramsSerializer: params => {
                    return qs.stringify( params, { arrayFormat: 'comma' })
                }
            })
            .then(( result ) => {
                clearTimeout( timeout )
                resolve( result )
            })
            .catch(( err ) => {
                if ( err.message === '_timeout' || err.code === 'ECONNABORTED' ) {
                    reject({
                        status: 408,
                        config: { url: config.api[ api ] + endpoint },
                        data: {
                            code: 408000,
                            message: 'Превышено время ожидания',
                        },
                    })
                } else {
                    reject( err )
                }
            })
    })
}

export const post = ( api, endpoint, body, prm = '', append = '', inc = '', replace = '', opts = {}) => {
    const add = append || '',
          key = replace ? replace : endpoint + add,
          timeout = setTimeout(() => {
              storage[ key ] && storage[ key ]( '_timeout' )
          }, 5 * 60 * 1000 )

    storage[ key ] && storage[ key ]()

    return new Promise(( resolve, reject ) => {
        axios
            .post( config.api[ api ] + endpoint + prm, body, {
                ...config.api.getOptions(),
                ...cancel( key ),
                ...opts
            })
            .then(( result ) => {
                clearTimeout( timeout )
                resolve( result )
            })
            .catch(( err ) => {
                if ( err.message === '_timeout' || err.code === 'ECONNABORTED' ) {
                    reject({
                        status: 408,
                        config: { url: config.api[ api ] + endpoint },
                        data: {
                            code: 408000,
                            message: 'Превышено время ожидания',
                        },
                    })
                } else {
                    reject( err )
                }
            })
    })
}

export const put = ( api, endpoint, body, prm = '', append = '', replace = '', opts = {}) => {
    const add = append || '',
          key = replace ? replace : endpoint + add,
          timeout = setTimeout(() => {
              storage[ key ] && storage[ key ]( '_timeout' )
          }, 5 * 60 * 1000 )

    storage[ key ] && storage[ key ]()

    return new Promise(( resolve, reject ) => {
        axios
            .put( config.api[ api ] + endpoint + prm, body, {
                timeout: 300000,
                ...config.api.getOptions(),
                ...cancel( key ),
                ...opts
            })
            .then(( result ) => {
                clearTimeout( timeout )
                resolve( result )
            })
            .catch(( err ) => {
                if ( err.message === '_timeout' || err.code === 'ECONNABORTED' ) {
                    reject({
                        status: 408,
                        config: { url: config.api[ api ] + endpoint },
                        data: {
                            code: 408000,
                            message: 'Превышено время ожидания',
                        },
                    })
                } else {
                    reject( err )
                }
            })
    })
}

export const deleteApi = ( api, endpoint, prm = '', append = '', replace = '', opts = {}) => {
    const add = append || '',
          key = replace ? replace : endpoint + add,
          timeout = setTimeout(() => {
              storage[ key ] && storage[ key ]( '_timeout' )
          }, 5 * 60 * 1000 )

    storage[ key ] && storage[ key ]()

    return new Promise(( resolve, reject ) => {
        axios
            .delete( config.api[ api ] + endpoint + prm, {
                timeout: 300000,
                ...config.api.getOptions(),
                ...cancel( key ),
                ...opts
            })
            .then(( result ) => {
                clearTimeout( timeout )
                resolve( result )
            })
            .catch(( err ) => {
                if ( err.message === '_timeout' || err.code === 'ECONNABORTED' ) {
                    reject({
                        status: 408,
                        data: {
                            code: 408000,
                            message: 'Превышено время ожидания',
                        },
                    })
                } else {
                    reject( err )
                }
            })
    })
}

export const cancel = ( name ) => ({
    cancelToken: new CancelToken( function executor ( c ) {
        storage[ name ] = c
    }),
})

export const range = ( start, end ) => {
    const res = []

    for ( let i = start; i < end; i++ ) {
        res.push( i )
    }

    return res
}

export const exrange = ( start, end, from, to ) => {
    let res = range( start, end ),
        count = to - from + 1

    res.splice( res.indexOf( from ), count )

    return res
}

export const guid = () =>
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace( /[xy]/g, ( c, r ) =>
        ( 'x' === c ? ( r = ( Math.random() * 16 ) | 0 ) : ( r & 0x3 ) | 0x8 ).toString( 16 )
    )

export const simplePassword = ( len ) => {
    const length = len || 8,
          chars =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!$#%'

    let res = ''

    for ( let i = 0, n = chars.length; i < length; ++i ) {
        res += chars.charAt( Math.floor( Math.random() * n ))
    }

    return res
}

const validatePass = async ( pass, rules ) =>
    rules.reduce( async ( result, rule ) => {
        try {
            await rule.validator( rule, pass )

            return result
        } catch ( e ) {
            return false
        }
    }, true )

export const password = async ( len, rules ) => {
    let pass = simplePassword( len )

    if ( !rules || rules.length < 1 ) {
        return pass
    }

    let res = await validatePass( pass, rules )

    while ( !res ) {
        pass = simplePassword( len )
        res = await validatePass( pass, rules )
    }

    return pass
}

export const noPager = ( data ) => ({
    content: data,
    last: true,
})

export const dowRequest = ( item ) => {
    const req = copy.object( item )

    if ( item.data.dayOfWeek === 0 ) {
        req.data.dayOfWeek = 7
    }

    return req
}

export const report = ( cfg ) => {
    const keys = cfg.reduce(( k, c ) => [ ...k, ...Object.keys( c ) ], []),
          res = extract.keys( cfg, keys )

    res.tabs = extract.key( cfg, 'title' )

    return res
}

export const relation = ( title, currency, fact, plan ) => ({
    title,
    type: 'relation',
    currency,
    plan,
    fact,
})

export const value = ( title, currency, value, refValue ) => ({
    title,
    currency,
    value,
    refValue,
})

export const summary = ( args ) => {
    const { title, currency, fact, plan, type } = args,
          val = args.value

    if ( type ) {
        return args
    }

    if ( val !== undefined ) {
        return value( title, currency, val )
    }

    return currency === '%'
        ? value( title, currency, fact, 100 )
        : relation( title, currency, fact, plan )
}

export const pager = ( orig, page ) => {
    const req = copy.object( orig )
    req.page = page
    return req
}

export const optionsr = ( arr, idKey, nameKey ) =>
    keyval( options( arr, idKey, nameKey ))

export const options = ( arr, idKey, nameKey ) => {
    if ( !arr ) {
        return []
    }

    return arr.map(( item ) => ({
        value: typeof idKey === 'function' ? idKey( item ) : item[ idKey ],
        label: typeof nameKey === 'function' ? nameKey( item ) : item[ nameKey ],
    }))
}
export const optionsStr = ( arr ) => {
    if ( !arr ) {
        return []
    }

    return arr.map(( item ) => ({
        value: item,
        label: item,
    }))
}

export const keyval = ( arr ) => {
    if ( !arr ) {
        return {}
    }

    const res = {}

    arr.map(( item ) => ( res[ item.value ] = item.label ))

    return res
}

export const keyvalr = ( arr ) => {
    if ( !arr ) {
        return []
    }

    const res = {}

    arr.map(( item ) => ( res[ item ] = item ))

    return res
}

export const keyvalrs = ( obj ) =>
    Object.keys( obj ).map(( key ) => ({
        value: key,
        label: obj[ key ],
    }))

export const parser = ( addOn, separator ) => {
    return ( value ) => {
        const strval = typeof value === 'string' ? value : value.toString(),
              val = strval
                  .replace( new RegExp( ' ', 'g' ), '' )
                  .replace( new RegExp( separator, 'g' ), '.' )
                  .replace( /^0+/g, '' )
                  .replace( /[^0-9.-]+/g, '' )

        return val.indexOf( '.' ) > -1 ? val : parseFloat( val ) || 0
    }
}

export const rowReader = ( keys ) => {
    const res = {}

    keys.forEach(( key ) => ( res[ key ] = ( row ) => row[ key ]))

    return res
}

const lineLength = ( time, prefix ) =>
    ( prefix || 'time' ) + '-' + extract.minutes( time )

export const greenLinePrint = ( index, start, end, date, real ) => {
    const rstart = extract.time( start ),
          rend = extract.time( end )

    if ( index === real && date.isSame( dayjs( start ), 'day' )) {
        if ( rend === '00:00' ) {
            return greenLine( index, rstart, '24:00' )
        }

        return greenLine( index, rstart, rend )
    }

    if ( parseInt( rend ) < parseInt( rstart )) {
        if ( date.isSame( dayjs( start ), 'day' )) {
            return greenLine( index, '-02:00', rend )
        }

        if ( date.isSame( dayjs( end ), 'day' ) && index === real ) {
            return greenLine( index, '-02:00', rend )
        }
    }

    if ( index !== real && !date.isSame( dayjs( end ), 'day' )) {
        return greenLine( index, rstart, '25:00' )
    }
}

export const greenLineExt = (
    index,
    start,
    end,
    fixed,
    date,
    lowRest,
    muchWork
) => {
    const much = 'Перевыполнена норма на неделю',
          low = 'Не прошло 12 часов после предыдущей смены'

    let rstart = extract.time( start ),
        rend = extract.time( end ),
        tooltip = null

    !date.isSame( dayjs( start ), 'day' ) &&
    date.isSame( dayjs( end ), 'day' ) &&
    ( rstart = '-01:00' )
    date.isSame( dayjs( start ), 'day' ) &&
    !date.isSame( dayjs( end ), 'day' ) &&
    rend !== '00:00' &&
    ( rend = '25:00' )
    date.isSame( dayjs( start ), 'day' ) &&
    !date.isSame( dayjs( end ), 'day' ) &&
    rend === '00:00' &&
    ( rend = '24:00' )

    if ( lowRest && muchWork ) {
        tooltip = `${much} и ${low.toLocaleLowerCase()}`
    } else if ( lowRest || muchWork ) {
        tooltip = lowRest ? low : much
    }

    return greenLine( index, rstart, rend, fixed, lowRest || muchWork, tooltip )
}

export const greenLine = ( index, start, end, fixed, overwork, tooltip ) => {
    const time = strings.mktime( index ),
          prev = strings.mktime( parseInt( end ) - 1 ),
          prevv = strings.mktime( parseInt( end ) - 2 ),
          isNextDay = end === '25:00',
          isPrevDay = start === '-01:00'

    let cls = [ 'schedule-cell' ],
        icon = null

    if ( !check.interval( time, start, end, true )) {
        return null
    }

    if ( check.equal( time, start )) {
        cls.push( 'start' )
        cls.push( lineLength( start ))
        icon = fixed ? (
            <Icon component={Icons.Pin.active} className="fixed-icon" />
        ) : overwork ? (
            <Icon component={Icons.Sad.active} className="fixed-icon" />
        ) : null
    }

    if ( check.equal( time, end )) {
        cls.push( 'end' )
        cls.push( lineLength( end ))
    }

    if ( check.equal( time, prev )) {
        cls.push( 'pre' )
        cls.indexOf( 'start' ) < 0
            ? cls.push( lineLength( end ))
            : cls.push( lineLength( end, 'time-end' ))
    }

    if ( check.equal( time, prevv ) && isNextDay ) {
        cls.push( 'next-day' )
    }

    time === '00:00' && isPrevDay && cls.push( 'prev-day' )

    const span = <span className={cls.join( ' ' )}>{icon}</span>

    return tooltip ? <Popover content={tooltip}>{span}</Popover> : span
}

export const set = ( cmp ) => {
    const res = {},
          keys = Object.keys( cmp.state )

    keys.forEach(( key ) => {
        res[ key ] = ( val, cb ) =>
            cmp.setState({ [ key ]: val }, typeof cb === 'function' ? cb : void 0 )
    })

    res.state = ( data, cb ) =>
        cmp.setState( data, typeof cb === 'function' ? cb : void 0 )

    return res
}
