import * as find from './find'

export const day = ( day, data ) =>
    data.days.find(( d ) => d.dayOfWeek === parseInt( day ))

export const firstDay = ( days, day ) => {
    const found = days.find(( d ) => d.dayOfWeek === parseInt( day ))

    if ( found ) { return found.dayOfWeek }

    return parseInt( day ) === 0 ? 7 : day
}

export const empty = ( positions, id ) => {
    const pdata = positions.find(( p ) => p.positionGuid === id ),
          hours = []

    for ( let i = 0; i < 24; i++ ) {
        hours.push({
            hour: i,
            plan: 0,
            fact: 0,
            delta: 0,
        })
    }

    return {
        hours: hours,
        summaryHours: {
            plan: null,
            fact: null,
            delta: null,
        },
        position: pdata.position,
        positionGuid: pdata.positionGuid,
        positionName: pdata.name,
        shifts: [],
    }
}

export const position = ( id, day, positions ) => {
    let pos = find.position( id, day )

    if ( !pos ) {
        pos = empty( positions, id )
        day.positions.push( pos )
    }

    return pos
}
