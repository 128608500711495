import React, { Component, ChangeEvent } from 'react'
import { Select }                        from 'antd'
import styles                            from './multi-select-dropdown.module.scss'
import { BaseOptionType }                from 'antd/lib/select'

const defText = 'Выберите рестораны'

interface MultiSelectDropdownProps {
    placeholder?: string;
    filterPlaceholder?: string;
    items: Record<string, string>;
    current?: string[];
    height?: any;
    filter?: boolean;
    onFilter?: ( e: ChangeEvent<HTMLInputElement> ) => void;
    update?: ( values: string[]) => void;
    container?: () => void;
    className?: string;
}

interface MultiSelectDropdownState {
    open: boolean;
    filteredItems: BaseOptionType[];
}

class MultiSelectDropdown extends Component<MultiSelectDropdownProps, MultiSelectDropdownState> {
    static defaultProps = {
        placeholder: defText,
        current: [],
    }

    container = React.createRef<HTMLDivElement>()

    constructor ( props: MultiSelectDropdownProps ) {
        super( props )
        this.state = {
            open: false,
            filteredItems: [],
        }
    }

    componentDidMount () {
        this.generateMenu( this.props )
    }

    componentDidUpdate ( prevProps: MultiSelectDropdownProps ) {
        const { items } = this.props

        if ( items !== prevProps.items ) {
            this.generateMenu( this.props )
        }
    }

    generateMenu ( props: MultiSelectDropdownProps ) {
        const filteredItems = Object.entries( props.items ).map(([ value, label ]) => ({ value, label }))

        this.setState({ filteredItems })
    }

    handleSelectChange = ( values: string[]) => {
        this.props.update?.( values )
    }

    handleFilter = ( input: string, option?: BaseOptionType ) =>
        ( option?.label ?? '' ).toLowerCase().includes( input.toLowerCase()) || ( option?.value ?? '' ).toLowerCase().includes( input.toLowerCase())

    render () {
        const { filteredItems } = this.state

        return (
            <div className={styles[ 'view-type-drop' ]} ref={this.container}>
                <Select
                    mode="multiple"
                    className={styles.select}
                    placeholder={defText}
                    filterOption={this.handleFilter}
                    value={this.props.current}
                    onChange={this.handleSelectChange}
                    maxTagCount={1}
                    options={filteredItems}
                />
            </div>
        )
    }
}

export default MultiSelectDropdown
