/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Scrollbars }       from 'react-custom-scrollbars'
import { List, Input }      from 'antd'
import Icon                 from '@ant-design/icons'


/* APPLICATION */
import { AddButton, Spinner, Icons } from 'components'

import config from 'config'

const { Search } = Input

class CategoriesList extends Component {
    static propTypes = {
        data: PropTypes.array,

        current: PropTypes.object,

        onSelect: PropTypes.func,
        onSearch: PropTypes.func,
        onAdd: PropTypes.func,
    }

    constructor ( props ) {
        super( props )

        this.state = { search: '', }
    }

    componentDidMount () {
        !this.props.current && this.selectFirst( this.props )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { data } = this.props
        nextProps.data !== data &&
      !nextProps.current &&
      this.selectFirst( nextProps )
    }

    setSearch = ( e ) => {
        const search = e.target.value

        this.setState({ search })

        if ( search.length !== 1 ) {
            this.searchTimeout && clearTimeout( this.searchTimeout )
            this.searchTimeout = setTimeout(() => this.search( search ), 200 )
        }
    }

    search = ( str ) => this.props.onSearch( str )
    selectFirst = ( props ) =>
        props.data && props.data.length > 0 && props.onSelect( props.data[ 0 ])

    select = ( item ) => () => this.props.onSelect( item )

    item = ( item ) => {
        const { current } = this.props,
              cls = []

        current && item.categoryId === current.categoryId && cls.push( 'current' )
        item.__new && cls.push( 'new' )

        return (
            <List.Item className={cls.join( ' ' ).trim()} onClick={this.select( item )}>
                {item.__new && <Icon component={Icons.Edit.def} />}
                {item.categoryName}
            </List.Item>
        )
    }

    render () {
        const { data } = this.props,
              { search } = this.state

        return (
            <div className="templates-list">
                <div className="filter-container">
                    <Search
                        placeholder="Название категории"
                        value={search}
                        onChange={this.setSearch}
                    />
                </div>

                {data ? (
                    <Scrollbars
                        {...config.ui.scrolls}
                        ref={( node ) => ( this.scrolls = node )}
                    >
                        <List
                            size="small"
                            dataSource={data}
                            renderItem={this.item}
                            locale={{ emptyText: 'Ничего не найдено' }}
                        />
                    </Scrollbars>
                ) : (
                    <Spinner />
                )}

                <div className="list-footer">
                    <AddButton text="Добавить категорию" action={this.props.onAdd} />
                </div>
            </div>
        )
    }
}

export default CategoriesList
