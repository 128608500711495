/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Alert, Spin }      from 'antd'

/* APPLICATION */
import { AddButton, Link, ReportTable, SyncScroll, Spinner } from 'components'

import { format } from 'tools'
import column     from 'config/tables/helpers'
import config     from 'config'

import './manning-table.scss'

const cellWidth = 100

class ManningTable extends Component {
    static propTypes = {
        data: PropTypes.array,
        positions: PropTypes.array,
        editable: PropTypes.bool,

        onRemove: PropTypes.func,
        onAdd: PropTypes.func,
        onChange: PropTypes.func,
        loading: PropTypes.bool
    }

    constructor ( props ) {
        super( props )
        this.state = {
            data: null,
            columns: [],
            width: 1000,
        }
    }

    componentDidMount () {
        this.setData( this.props.data )
        this.setColumns( this.props.positions )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { data, positions } = this.props

        nextProps.data !== data && this.setData( nextProps.data )
        nextProps.positions !== positions && this.setColumns( nextProps.positions )
    }

    componentDidUpdate () {
        window.dispatchEvent( new Event( 'resize' ))
    }

    setColumns = ( positions ) =>
        this.setState({
            columns: this.columns( positions ),
            width: this.width( positions ),
        })

    setData = ( data ) =>
        this.setState({ data: this.prepare( data ), })

    preprow = ( row ) => {
        row.positions.map(( pos ) => ( row[ pos.positionUuid ] = pos.positionCount ))
        return row
    }

    position = ( pos ) =>
        column( pos.position, pos.positionUuid, {
            editable: this.props.editable,
            as: 'number',
            width: cellWidth,
            disabled: ( row ) => row.__unsaved
        })

    prepare = ( data ) => ( data ? format.copy.array( data ).map( this.preprow ) : null )

    width = ( positions ) =>
        this.columns( positions ).reduce(( w, p ) => w + p.width, 0 )


    columns = ( original ) => {
        const positions = original
            ? original
                .filter(( pos ) => pos.positionUuid !== config.mcGuid )
                .filter( pos => pos.uuidChartPosition )
                .map( this.position )
            : []

        return [
            column( 'Кол-во транзакций в час', 'transactions', {
                width: 200,
                editable: this.props.editable,
                as: 'number',
                focusOnCreate: true,
                fixed: 'left',
            }),

            ...positions,

            column( 'Всего', 'total', {
                width: cellWidth,
                fixed: 'right',
            }),

            column( 'TCPH', 'tcph', {
                width: 130,
                as: 'number',
                fixed: 'right',
            }),

            this.props.editable && column( '', '', {
                editable: true,
                as: 'remove',
                className: 'align-center',
                fixed: 'right',
                wait: true,
                width: 150,
            }),
        ].filter( item => item )
    }

    render () {
        const { data, columns, width } = this.state,
              { loading, onAdd } = this.props

        if ( !data ) { return <Spinner /> }

        return (
            <div className="staff-manning-table scroll-container">
                <Spin spinning={!!loading}>
                    <div className="report-table manning-table">
                        {data?.length < 1 && (
                            <Alert
                                message={
                                    <span>
                                        Чтобы сформировать чарт,{' '}
                                        {onAdd ? <Link onClick={onAdd}>добавьте цель</Link> : 'добавьте цель'} по
                                        кол-ву транзакций в час
                                    </span>
                                }
                                type="warning"
                            />
                        )}
                        <ReportTable
                            data={format.generate.noPager( data )}
                            columns={columns}
                            emptyText=" "
                            rowKey="plannedTransactionNumberUuid"
                            scroll={{ x: width }}
                            loading={false}
                            onRemove={this.props.onRemove}
                            onCellBlur={this.props.onChange}
                        />
                    </div>
                    <div className="tabbed-footer left sticky">
                        {this.props.editable && <div className="middle-footer">
                            <AddButton text="Добавить" action={this.props.onAdd} />
                        </div>}
                        <SyncScroll connect="manning" apply=".manning-table" height={20} hideTracksWhenNotNeeded={false} />
                    </div>
                </Spin>


            </div>
        )
    }
}

export default ManningTable
