/* IMPORT */

import {
    staffActions,
    restaurantActions,
    userActions,
    uiActions,
} from 'services'

/* EXPORT */

export const mapStateToProps = ( state ) => ({
    request: state.request,
    user: state.user,

    restaurants: state.restaurants.admin,
    restUsers: state.restaurants.adminAreaCoach,
    restFZ: state.restaurants.adminFZ,
    linked: state.staff.linked,
    granted: state.restaurants.granted,
    restData: state.restaurants.data,

    users: state.users.list,
    roles: state.roles,
    errors: state.serverErrors,
    userUpdateIsLoading: state.staff?.admin?.update_loading
})

export const allActions = {
    ...staffActions,
    ...restaurantActions,
    ...userActions,
    ...uiActions,
}
