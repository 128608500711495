export const permissions = {
    top: [
        {
            key: 'standard',
            title: 'Стандартные',
        },
        {
            key: 'marketManager',
            title: 'Маркет менеджер',
        },
        {
            key: 'supervisor',
            title: 'Супервизор',
        },
        {
            key: 'admin',
            title: 'Админ и планирование',
        },
    ],
    sub: [
        {
            key: 'templates',
            title: 'Шаблоны задач',
        },
        {
            key: 'categories',
            title: 'Категории блюд',
        },
        {
            key: 'plans',
            title: 'Планы',
        },
        {
            key: 'users',
            title: 'Пользователи',
        },
        {
            key: 'tasks',
            title: 'Задачи',
        },
        {
            key: 'partners',
            title: 'Партнеры',
        },
        {
            key: 'refs',
            title: 'Справочники',
        },
        {
            key: 'banners',
            title: 'Баннеры',
        },
    ],
}

export const tabs = {
    basic: {
        _default: 'shift',
        shift: 'Смена',
        day: 'День',
        week: 'Неделя',
        month: 'Месяц',
    //quarter: "Квартал"
    },
    time: {
        fusion: 'Fusion',
        hybrid: 'Hybrid',
        auto: 'Auto',
    },
    rating: {
        _default: 'categories',
        categories: 'Категории',
        dishes: 'Блюда',
        modifiers: 'Допы',
    },
}
