import { guestVoteActions } from 'services'
import { IState }           from 'store'

export const allActions = { ...guestVoteActions }

export const mapStateToProps = ( state: IState ) => ({
    currentFilters: state.guestVote.currentFilters,
    happinessIndexesSearch: state.guestVote.happinessIndexesSearch,
    happinessIndexesSearchIsLoading: state.guestVote.happinessIndexesSearch_loading,
    happinessIndexesSearchAppendIsLoading: state.guestVote.happinessIndexesSearchAppend_loading,
    happinessIndexesSummary: state.guestVote.happinessIndexesSummary,
    happinessIndexesSummaryIsLoading: state.guestVote.happinessIndexesSummary_loading,
    happinessIndexes: state.guestVote.happinessIndexes,
    happinessIndexesIsLoading: state.guestVote.happinessIndexes_loading,
    metricsType: state.guestVote.metricsType,
})


export type StatisticsTabStoreProps = ReturnType<typeof mapStateToProps> & typeof allActions
