/* VENDOR */
import { update } from 'reduxigen'

/* APPLICATION */
import * as api   from './api'
import { format } from 'tools'

const asyncUpdate = format.update.asyncu,
      helper = format.helper.basic( 'planning' )

export const flushPlanningOverview = update(
    'planning.flush.overview',
    ( data, state ) => ({
        ...state,
        planning: {
            ...state.planning,
            overview: null,
        },
    })
)

export const flushPlanningReport = update(
    'planning.flush.report',
    ( data, state ) => ({
        ...state,
        planning: {
            ...state.planning,
            report: null,
        },
    })
)

export const flushPlanningError = update(
    'planning.flush.error',
    ( _, state ) => ({
        ...state,
        planning: {
            ...state.planning,
            error: null,
        },
    })
)

export const setPlanningError = update(
    'planning.error.set',
    ( error, state ) => ({
        ...state,
        planning: {
            ...state.planning,
            error,
        },
    })
)

export const setDeep = update( 'overview.deep', ( deep, state ) => ({
    ...state,
    planning: {
        ...state.planning,
        deep,
    },
}))

export const setLength = update( 'overview.length', ( length, state ) => ({
    ...state,
    planning: {
        ...state.planning,
        length,
    },
}))

export const fetchOverview = asyncUpdate(
    'overview.report',
    ({ page, ...params }) => api.getOverview( page, params ),
    ( event, state ) => helper( 'overview', event.data, state )
)

export const appendOverview = asyncUpdate(
    'overview.report.append',
    ({ page, ...params }) => api.getOverview( page, params ),
    ( event, state ) => {
        if ( event.status !== 200 ) {
            return state
        }
        let content = event.data.content

        const number = event.data.number,
              last = event.data.last

        state.planning.overview.content &&
        ( content = [ ...state.planning.overview.content, ...content ])

        return {
            ...state,
            planning: {
                ...state.planning,
                overview: {
                    content,
                    number,
                    last
                }
            }
        }
    },
)

export const fetchPlanning = asyncUpdate(
    'planning.report',
    ( params ) => api.getPlanning( params ),
    ( event, state ) => helper( 'report', event.data.content, state )
)

export const updateTargets = asyncUpdate(
    'planning.update',
    ( params ) => api.updateTargets( params ),
    ( event, state ) => ({
        ...state,
        planning: {
            ...state.planning,
            updated: event,
        },
    })
)

export const updateRestaurantTargets = asyncUpdate(
    'planning.update',
    ( params ) => api.updateRestaurantTargets( params ),
    ( event, state ) => {
        const report = format.copy.array( state.planning.report )
        if ( event.status === 401 ) {
            format.update.goToLogin( state.user.endpoints )
        }

        if ( event.status !== 200 ) {
            return {
                ...state,
                planning: {
                    ...state.planning,
                    report,
                    error: event.errorData?.msg
                },
            }
        }

        if ( event.status === 200 ) {
            return {
                ...state,
                planning: {
                    ...state.planning,
                    report: event.data,
                },
            }
        }

    }
)

export const getUnknownLastYear = asyncUpdate(
    'planning.lastYearGet',
    ( params ) => api.getUnknownLastYear( params ),
    ( event, state ) => {
        if ( event.status !== 200 ) {
            return {
                ...state,
                planning: {
                    ...state.planning,
                    lastYear: { data: null },
                },
            }
        }

        return {
            ...state,
            planning: {
                ...state.planning,
                lastYear: {
                    data: event.data?.content[ 0 ].factTurnoverLastYear,
                    ident: event.data?.content[ 0 ].restaurantIdent,
                },
            },
        }
    }
)
