/* IMPORT */
import {
    chartActions,
    schedulesActions,
    settingsActions,
    staffActions,
    uiActions,
} from 'services'

/* EXPORT */

export const mapStateToProps = ( state ) => ({
    request: state.request,
    schedules: state.schedules.list,
    schedule: state.schedules.current,
    dataSummaryReport: state.schedules.dataSummaryReport,
    dataSummaryReportSunToSun: state.schedules.dataSummaryReportSunToSun,
    generated: state.schedules.generated,
    list: state.staff.list,
    positions: state.planning.positionsChartedByType,
    hours: state.settings.workHours,
    duration: state.chart.duration,
    sideDays: state.schedules.sideDays,
    weekTurnover: state.schedules.weekTurnover,
    saveTurnover: state.schedules.saveTurnover,
    errorTurnover: state.schedules.weekTurnover_error,
    errorSetTurnover: state.schedules.errorSetTurnover,
    user: state.user,
    saved: state.schedules.saved,
    saveCount: state.schedules.daysToSave,
    genError: state.schedules.generateError,
    restaurants: state.restaurants.list
})

export const allActions = {
    ...schedulesActions,
    ...staffActions,
    ...settingsActions,
    ...chartActions,
    ...uiActions
}
