/* VENDOR */
import React, { Component, useEffect, useRef, useState } from 'react'
import PropTypes                                         from 'prop-types'
import { Modal, Divider, Button, Row, Col, List }        from 'antd'
import { Scrollbars }                                    from 'react-custom-scrollbars'
import dayjs                                             from 'dayjs'

/* APPLICATION */
import { Spinner, BackTitle, PhotoBrowser } from 'components'
import config                               from 'config'

import './task-details.scss'

const Image = ({ image, onClick }) => {
    const [ loading, setLoading ] = useState( true )
    const imageRef = useRef()

    useEffect(() => {
        setLoading( true )
        fetch( image.src, { headers: { 'Authorization': `Bearer ${localStorage.getItem( 'access_token' )}` } })
            .then( res => res.blob())
            .then( blob => {
                if ( imageRef.current ) {
                    imageRef.current.style.backgroundImage = `url(${URL.createObjectURL( blob )})`
                }
            }).finally(() => {
                setLoading( false )
            })

    }, [])

    return <div
        className='photo-wrapper'
        onClick={onClick}
    >
        <div
            ref={imageRef}
            className='photo'
        />
    </div>
}

class TaskDetails extends Component {
    static propTypes = {
        task: PropTypes.object,
        group: PropTypes.object,
        deviations: PropTypes.array,

        visible: PropTypes.bool,
        canEdit: PropTypes.bool,
        canRemove: PropTypes.bool,

        onEdit: PropTypes.func,
        onRemove: PropTypes.func,
        onClose: PropTypes.func,
        getDeviations: PropTypes.func,
        flushDeviations: PropTypes.func,
    }

    constructor ( props ) {
        super( props )

        this.state = {
            showDeviations: false,
            showPhotos: false,
            currentPhoto: 0,
            photos: []
        }
    }

    componentDidUpdate ( prevProps ) {
        const { visible } = this.props

        !visible &&
      visible !== prevProps.visible &&
      setTimeout( this.hideDeviations, 300 )
    }

    showDeviations = ( task ) => {
        this.props.getDeviations( task.id )

        this.setState({ showDeviations: true, })
    }

    hideDeviations = () => {
        this.props.flushDeviations()

        this.setState({ showDeviations: false, })
    }

    deviation = ( item ) => {
        const cls = [ 'deviation-item' ]

        return (
            <List.Item className={cls.join( ' ' ).trim()}>
                <span className="item-title">{item.name}</span>
                <span className="item-description">{item.comment}</span>
            </List.Item>
        )
    }

    details = ( task, photos ) =>
        this.state.showDeviations ? (
            <div className="task-details-container">
                <BackTitle text="Связанные отклонения" action={this.hideDeviations} />
                <div className="task-details">
                    <div className="deviations-list">
                        {!this.props.deviations ? (
                            <Spinner />
                        ) : (
                            <Scrollbars
                                {...config.ui.scrolls}
                                ref={( node ) => ( this.scrolls = node )}
                            >
                                <List
                                    size="small"
                                    dataSource={this.props.deviations}
                                    renderItem={this.deviation}
                                    locale={{ emptyText: 'Ничего не найдено' }}
                                />
                            </Scrollbars>
                        )}
                    </div>
                </div>
            </div>
        ) : (
            <div className="task-details-container">
                <h2>{task.title}</h2>
                <div className="task-details">
                    {task.deviationCount > 0 && (
                        <span
                            className="link list-icon"
                            onClick={() => this.showDeviations( task )}
                        >
              Связанные отклонения
                        </span>
                    )}
                    {task.description && (
                        <div className="task-detail-row">
                            <label>Комментарий</label>
                            <p>{task.description}</p>
                        </div>
                    )}
                    <div className="task-detail-row">
                        <label>Выполнить до</label>
                        <p>{dayjs( task.completeDate ).format( config.format.dateView )}</p>
                    </div>
                    <div className="task-detail-row">
                        <label>Ответственный</label>
                        <p>{task.assignee.fullName}</p>
                    </div>
                    {task.executor && (
                        <div className="task-detail-row">
                            <label>Исполнитель</label>
                            <p>{task.executor}</p>
                        </div>
                    )}
                    {photos?.length > 0 && <div className="task-detail-row">
                        <label>Фото ({photos.length})</label>
                        <div className='photos-row'>
                            {photos.map(( photo, index ) => <Image
                                key={`photo-${index}`}
                                onClick={() => this.openPhotos( index )}
                                image={photo}
                            /> )}
                        </div>

                    </div>}
                    {this.status( task )}
                </div>
                <div className="task-details-footer">
                    <Row>
                        <Col span={8}>
                            <Button
                                type="primary"
                                style={{ width: '100%' }}
                                onClick={this.props.onClose}
                            >
                Закрыть
                            </Button>
                        </Col>
                        {this.props.canEdit && (
                            <React.Fragment>
                                <Col span={10}>
                                    {task.status === 'TODO' && (
                                        <Button type="ghost" onClick={this.edit}>
                      Редактировать
                                        </Button>
                                    )}
                                </Col>
                                <Col span={6}>
                                    {this.props.canRemove && (
                                        <Button type="remove" onClick={this.remove}>
                      Удалить
                                        </Button>
                                    )}
                                </Col>
                            </React.Fragment>
                        )}
                    </Row>
                </div>
            </div>
        )

    slide = ( url, key ) => (
        <img key={key} src={process.env.REACT_APP_BASE + url} alt="" />
    )

    status = ( task ) => {
        const { group } = this.props,
              cls = [ 'task-status-icon' ]

        let text = null

        switch ( task.status ) {
            case 'COMPLETED':
                if ( dayjs( task.finishedDate ).isAfter( task.completeDate )) {
                    text = 'Сделано с опозданием'
                    cls.push( 'late' )
                } else {
                    text = 'Сделано'
                    cls.push( 'success' )
                }
                break
            case 'CANCELED':
                if ( group.groupName === 'outdated' ) {
                    text = 'Просрочено'
                    cls.push( 'fail' )
                } else {
                    text = 'Отменено'
                    cls.push( 'canceled' )
                }
                break
            case 'OUTDATED':
                text = 'Просрочено'
                cls.push( 'fail' )
                break
            default:
                break
        }

        if ( !text ) { return }

        return (
            <div className="task-detail-row">
                <Divider />
                <div className="task-status">
                    <em className={cls.join( ' ' )}></em>
                    <div>
                        <p>{text}</p>
                        <label>
                            {dayjs(
                                task.deleteDate || task.finishedDate || task.completeDate
                            ).format( config.format.dateView )}
                        </label>
                    </div>
                </div>
            </div>
        )
    }

    photo = ( task ) => {
        if ( task ) {
            return [
                ...task.components.reduce(
                    ( arr, c ) => [
                        ...arr,
                        ...c.data.fileData.reduce(( carr, f ) => [ ...carr, { src: config.api.base + f.fileUrl } ], arr ),
                    ],
                    []
                ),
                ...task.photos.map( item => ({ src: config.api.base + item.fileUrl }))
            ]
        }

        return null
    }

    edit = () => this.props.onEdit( this.props.task )
    remove = () => this.props.onRemove( this.props.task )

    closePhotos = () => this.setState({ showPhotos: false })
    openPhotos = ( index ) => this.setState({ currentPhoto: index, showPhotos: true })

    render () {
        const { task, visible, onClose } = this.props,
              { showPhotos, currentPhoto } = this.state,
              photos = this.photo( task ),
              cls = [ 'kfc-popup', 'kfc-task-details' ]

        return (
            <Modal
                className={cls.join( ' ' )}
                centered={true}
                open={visible}
                okText="Закрыть"
                okButtonProps={{ style: { display: 'none' } }}
                onOk={onClose}
                cancelButtonProps={{ style: { display: 'none' } }}
                onCancel={onClose}
            >
                {task ? this.details( task, photos ) : <Spinner />}
                {photos && photos.length > 0 && <PhotoBrowser
                    isOpen={showPhotos}
                    onClose={this.closePhotos}
                    images={photos}
                    currentImage={currentPhoto}
                />}
            </Modal>
        )
    }
}

export default TaskDetails
