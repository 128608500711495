/* eslint-disable camelcase */
/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import dayjs                from 'dayjs'
import { Modal }            from 'antd'
import Icon                 from '@ant-design/icons'

/* APPLICATION */
import { AddButton, SyncScroll, AddEmployeeShift } from 'components'

import { StarIcon } from 'components/layout/TopStar/TopStar'

import { format } from 'tools'
import config     from 'config'

import ScheduleChartTable from './ScheduleChartTable'

import * as helpers from '../helpers'
import './schedule-chart.scss'

class ScheduleChartView extends Component {
    static propTypes = {
        data: PropTypes.array,
        positions: PropTypes.array,
        employees: PropTypes.array,
        workHours: PropTypes.object,

        loading: PropTypes.bool,
        compact: PropTypes.bool,
        fixedHead: PropTypes.bool,
        inlineHours: PropTypes.bool,

        day: PropTypes.string,
        date: PropTypes.object,
        from: PropTypes.object,
        to: PropTypes.object,

        onAdd: PropTypes.func,
        onChange: PropTypes.func,
        onRemove: PropTypes.func,
        onTop: PropTypes.func,
        changeSelected: PropTypes.func,
    }

    view = React.createRef()
    sync = React.createRef()

    constructor ( props ) {
        super( props )

        this.state = {
            data: props.data,
            width: 0,

            popup: false,
            unassign: false,
            toUnassign: null,

            columns: [],
            employees: [],
            positions: [],
        }

        this.set = format.generate.set( this )
    }

    componentDidMount () {
        const { from, to, compact } = this.props

        this.updateColumns()
        this.updateEmployees()
        this.updatePositions()

        this.set.width( helpers.width( from, to, compact ))
    }

    shouldComponentUpdate ( nextProps, nextState ) {
        if ( this.state !== nextState ) {
            return true
        }

        return this.updated( nextProps )
    }

    componentDidUpdate ( prevProps, prevState ) {
        const { data, date, employees, positions, from, to, compact, inlineHours } =
        this.props,
              { popup } = this.state,
              useScroll = !data && date.isSame( prevProps.date )

        if ( prevProps.data !== data ) {
            setTimeout(() => {
                this.update( data, useScroll )
                this.updateColumns()
            }, 0 )
        }

        inlineHours !== prevProps.inlineHours && this.updateColumns()
        prevProps.employees !== employees && this.updateEmployees()
        prevProps.positions !== positions && this.updatePositions();
        ( from !== prevProps.from || to !== prevProps.to ) &&
      this.set.width( helpers.width( from, to, compact ))
        popup !== prevState.popup && ( window._schedule_popup = popup )
    }

    updated = ( diffProps ) => {
        const { data, employees, positions, from, to, date, inlineHours } =
      this.props

        return (
            inlineHours !== diffProps.inlineHours ||
      data !== diffProps.data ||
      employees !== diffProps.employees ||
      positions !== diffProps.positions ||
      from !== diffProps.from ||
      to !== diffProps.to ||
      !date?.isSame( diffProps.date )
        )
    }

    updateColumns = () => this.set.columns( this.columns())

    updateEmployees = () => {
        const employees = this.employees()

        this.set.state({
            employees,
            kvEmployees: format.generate.keyval( employees ),
        })
    }

    updatePositions = () => {
        const positions = this.positions()

        this.set.state({
            positions,
            kvPositions: format.generate.keyval( positions ),
        })
    }

    update = ( data, useScroll ) =>
        this.set.data( data, () => this.afterUpdate( useScroll ))

    afterUpdate = ( useScroll ) => {
        const { workHours, day } = this.props,
              view = this.view.current,
              scroll = view.querySelector( '.ant-table-body' ),
              hours = [].slice.call( view.querySelectorAll( '.ant-table-scroll th' )),
              cday = workHours?.days?.find(( d ) => d.dayOfWeek === parseInt( day )),
              start = cday?.timeStart,
              end = cday?.timeEnd,
              found = hours.reduce(
                  ( found, h ) => ( h.innerText === start ? h : found ),
                  hours[ 0 ]
              )

        let started = false,
            previous = 0

        hours.forEach(( h ) => {
            h.innerText === start && ( started = true )
            h.style.fontWeight = started ? 'bold' : 'normal'
            h.innerText === end && ( started = false )
        })

        if ( useScroll ) {
            if ( found ) {
                found.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'center',
                })
            }

            const sync = () => {
                const current = scroll?.scrollLeft

                if ( current !== previous ) {
                    previous = current
                    setTimeout( sync, 100 )
                } else {
                    this.sync.current &&
            this.sync.current.scroll({ target: scroll, })
                }
            }

            setTimeout( sync, 500 )
        }
    }

    showPopup = () => {
        this.props.changeSelected( false )
        this.set.popup( true )
    }

    showUnassign = ( row ) =>
        this.set.state({
            unassign: true,
            toUnassign: row,
        })

    hidePopup = () => {
        this.props.changeSelected( true )

        this.set.state({
            popup: false,
            unassign: false,
        })
    }

    defCols = ( compact ) =>
        compact ? config.tables.schedule.mobile : config.tables.schedule.chart

    canEdit = ( date ) =>
        dayjs( date ).isAfter( dayjs().startOf( 'day' )) ||
    dayjs( date ).isSame( dayjs().startOf( 'day' ))

    columns = () => {
        const { from, to, compact, date, inlineHours } = this.props,
              def = format.copy.array( this.defCols( compact )),
              start = def.find(( c ) => c.dataIndex === 'shiftStart' ),
              end = def.find(( c ) => c.dataIndex === 'shiftEnd' )

        start.min = end.min = from.hour()
        start.max = end.max = to.hour()

        if ( !compact ) {
            const editable = this.canEdit( date )

            def.forEach(( c ) => ( c.editable = editable ))

            def[ 0 ].className = 'first-star'
            def[ 0 ].render = ( text, record ) => {
                if ( !record.employeeId ) { return null }
                if ( record.positionGuid !== config.mcGuid ) { return null }

                if (
                    ( !def[ 0 ].editable || record.disabled === true ) &&
          record.shiftSupervisor
                ) { return <Icon component={StarIcon} className={'active'} /> }

                if ( def[ 0 ].editable && !record.disabled ) {
                    if (
                        dayjs( date ).isAfter( dayjs()) ||
            dayjs( dayjs( record.shiftStart )).isAfter( dayjs())
                    ) {
                        return (
                            <Icon
                                component={StarIcon}
                                className={record.shiftSupervisor ? 'active' : ''}
                                onClick={this.setSupervisor( record )}
                            />
                        )
                    }

                    return record.shiftSupervisor ? (
                        <Icon component={StarIcon} className={'active'} />
                    ) : null
                }

                return null
            }
        }

        return [ ...def, ...helpers.hourscols( from, to, false, [], inlineHours ) ]
    }

    setSupervisor = ( row ) => {
        return ( e ) => {
            e.preventDefault()
            row.shiftSupervisor && row._was_supervisor
                ? this.showUnassign( row )
                : this.props.onChange( row, 'shiftSupervisor', !row.shiftSupervisor )
        }
    }

    unassign = () => {
        this.hidePopup()
        setTimeout(
            () =>
                this.props.onChange( this.state.toUnassign, 'shiftSupervisor', false ),
            0
        )
    }

    positions = ( data ) =>
        format.generate.options(
            data || this.props.positions,
            'positionGuid',
            'position'
        )
    employees = ( data ) =>
        format.generate.options( data || this.props.employees, 'id', 'name' )

    add = ( data ) => {
        this.hidePopup()
        setTimeout(() => this.props.onAdd( data ), 0 )
    }

    scroll = () => {
        const table = document.querySelector( '.staff-schedule-chart .report-wrap' ),
              { fixedHead } = this.props,
              { width } = this.state,
              res = { x: width }

        if ( fixedHead && table ) {
            res.y = table.offsetHeight
        }

        return res
    }

    cellSearch = () => ( search, opt ) =>
        format.find.strInProp( opt.props, 'children', search )

    render () {
        const { date, day, from, to, compact, inlineHours } = this.props,
              { data, columns, popup, employees, positions, unassign } = this.state,
              { kvEmployees, kvPositions } = this.state,
              cls = [ 'staff-schedule-chart report-table' ]

        inlineHours && cls.push( 'inline-hours' )

        return (
            <div className={cls.join( ' ' )} ref={this.view}>
                <ScheduleChartTable
                    data={data}
                    columns={columns}
                    employees={employees}
                    positions={positions}
                    from={from}
                    to={to}
                    compact={compact}
                    inlineHours={inlineHours}
                    day={day}
                    isToday={date.isSame( dayjs(), 'day' )}
                    onDone={this.props.onDone}
                    onChange={this.props.onChange}
                    onRemove={this.props.onRemove}
                    onAssign={this.props.onAssign}
                />

                <div className="tabbed-footer left sticky">
                    {this.props.data &&
            this.props.onAdd &&
            this.canEdit( this.props.date ) && (
                        <div className="middle-footer">
                            <AddButton text="Добавить смену" action={this.showPopup} />

                            <AddEmployeeShift
                                fullEmployees={this.props.employees}
                                employees={kvEmployees}
                                positions={kvPositions}
                                visible={popup}
                                from={this.props.from}
                                to={this.props.to}
                                onCancel={this.hidePopup}
                                onSubmit={this.add}
                            />
                        </div>
                    )}

                    <SyncScroll
                        ref={this.sync}
                        connect="schedule"
                        apply=".staff-schedule-chart"
                        height={20}
                    />

                    <Modal
                        className="kfc-popup"
                        centered={true}
                        title="Вы уверены?"
                        open={unassign}
                        onOk={this.unassign}
                        onCancel={this.hidePopup}
                        okText="Переназначить"
                        cancelText="Оставить старшим"
                    >
            Задачи будут переназначены на другого менеджера.
                    </Modal>
                </div>
            </div>
        )
    }
}

export default ScheduleChartView
