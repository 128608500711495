/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import dayjs                from 'dayjs'

/* APPLICATION */
import { ViewTypeSwitch } from 'components'
import config             from 'config'
import { Button }         from 'antd'

class Days extends Component {
    static propTypes = {
        visible: PropTypes.bool,
        format: PropTypes.string,

        start: PropTypes.object,
        current: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),

        titleRender: PropTypes.func,
        onDay: PropTypes.func,
    }

    days = () => {
        const { visible, start } = this.props,
              res = {}

        if ( !visible ) {
            return res
        }
        [ 1, 2, 3, 4, 5, 6, 7 ].forEach(( day ) => {
            const key = day.toString(),
                  title = this.title( start, day )

            res[ key ] = title
        })

        return res
    }

    title = ( str, offset ) => {
        if ( this.props.titleRender ) {
            return this.props.titleRender( str, offset )
        }

        const frmt = this.props.format || config.format.onlyDayView,
              dateStart = this.props.start,
              start = dayjs( str, config.format.dayAPI ),
              dow = dayjs().day( offset ).format( 'ddd' ),
              date = start.add( offset - 1, 'days' ).format( frmt ),
              isToday = dayjs( dateStart )
                  .startOf( 'day' )
                  .add( offset - 1, 'days' )
                  .isSame( dayjs().startOf( 'day' )),
              otext = date + ', ' + dow.toUpperCase(),
              text = isToday ? <b>{otext}</b> : otext

        return text
    }

    onChange = ( day ) => setTimeout(() => this.props.onChange( day ), 0 )

    render () {
        const { visible, current } = this.props

        if ( !visible ) {
            return null
        }

        return (
            <ViewTypeSwitch
                className="capitalize wide"
                tabs={this.days()}
                current={current ? current.toString() : '1'}
                update={this.onChange}
                onScroll={this.tooltipOnOff}
            />
        )
    }
}

export default Days
