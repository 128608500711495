/* VENDOR */
import { createStore, applyMiddleware, compose } from 'redux'
import thunk                                     from 'redux-thunk'
// @ts-ignore
import { rootReducer } from 'reduxigen'
import { IState }      from './type'

const pageableTemplate = {
    content: [],
    number: 0,
    totalPages: 0,
    totalElements: 0,
    pageable: {
        offset: 0,
        pageNumber: 0,
        pageSize: 0,
        paged: false,
        sort: {
            sorted: false,
            unsorted: false,
            empty: false,
        },
        unpaged: false,
    },
    empty: true,
    last: true,
    size: 0,
    sort: {
        sorted: false,
        unsorted: false,
        empty: false,
    },
    first: true,
    numberOfElements: 0,
}


const zoneOffset = -( new Date().getTimezoneOffset() / 60 ),
      zoneId = zoneOffset >= 0 ? `UTC+${zoneOffset}` : `UTC${zoneOffset}`

export const initialState: IState = {
    request: {
        dateStart: null,
        dateEnd: null,
        dateGroup: 'hour',
        unitOfMeasure: 'currency',
        currentPredef: 'shift',
        serviceType: '',
        inactive: false,
        growing: false,
        restaurantId: null,
        factsNumber: null,
        employeeId: null,
        zoneId,
    },

    assignment: [],
    banners: {},
    calendar: {},
    categories: {},
    chart: {},
    managers: {},
    performance: {},
    planning: {},
    rating: {},
    reports: {},
    restaurants: { admin: {} },
    restaurantsRating: {},
    roles: {},
    schedules: {},
    settings: {},
    staff: {},
    tasks: {},
    templates: {},
    time: {},
    top: {},
    turnover: {},
    user: {},
    users: {},
    partners: {},
    guestVote: {
        currentFilters: {},
        dataGroup: 'RESTAURANTS',
        metricsType: 'RATING',
        categoriesFiltersOptions: { searchFilter: '', searchFilterOptions: [] },
        happinessIndexes: [],
        happinessIndexesByHour: [],
        happinessIndexesSummary: {},
        happinessIndexesSearch: pageableTemplate,
        feedbacksSearch: pageableTemplate,
    },

    serverErrors: {},

    ui: {
        title: 'Показатели',
        width: window.innerWidth,
        height: window.innerHeight,
    },
}

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default createStore( rootReducer( initialState ), composeEnhancers( applyMiddleware( thunk )))
