/* VENDOR */
import axios from 'axios'

/* APPLICATION */
import { format } from 'tools'
import config     from 'config'

const service = 'restaurant'

/* TOP */

export const setTop = ( body ) =>
    axios.post( config.api.restaurant + body.endpoint, body, config.api.getOptions())

export const unsetTop = ( body ) =>
    axios.delete( config.api.restaurant + body.endpoint, {
        data: body,
        ...config.api.getOptions(),
    })

export const setDishPlan = ( body ) =>
    axios.post(
        config.api.restaurant + 'target/product/',
        body,
        config.api.getOptions()
    )

export const setModPlan = ( body ) =>
    axios.post(
        config.api.restaurant + 'target/modifiers/',
        body,
        config.api.getOptions()
    )

export const setCategoryPlan = ( body ) =>
    axios.post(
        config.api.restaurant + 'target/category/',
        body,
        config.api.getOptions()
    )

/* RESTAURANTS */
const listTimeout = 300000

export const getRestaurants = ({ numbers }) =>
    format.generate.post(
        'restaurant',
        'restaurants/by_numbers',
        Array.isArray( numbers ) ? numbers : numbers.split( ',' ),
        '', '', '', ''
    )

export const getRestaurantsList = ({ page, ...body }) =>
    format.generate.post( 'restaurant', 'admin/restaurants/search', body, '', '', '', '', {
        timeout: listTimeout,
        params: { page },
    })

export const getRestaurantsUsersList = ({ page, ...body }) =>
    format.generate.post( 'staff', 'admin/users/search', body, '', '', '', '', {
        timeout: listTimeout,
        params: { page },
    })

export const getRestaurantsFZList = ({ page, size, ...body }) =>
    format.generate.post( 'restaurant', 'dictionaries/franchisees/search', body, '', '', '', '', {
        timeout: listTimeout,
        params: {}
    })

export const getSameFranchisee = ( restaurantId ) =>
    format.generate.get( service, 'restaurants/same-franchisee-code', { restaurantId, })

/* GUEST CHECKS */

export const removeGuestChecks = ( params ) =>
    axios.delete( `${config.api.dashboard}admin/guest-checks`, {
        ...config.api.getOptions(),
        params,
    })


/* RESTAURANTS TYPES */

export const getRestaurantTypes = () => format.generate.get( service, 'dictionaries/restaurant-types' )

export const getFacilityTypes = () => format.generate.get( service, 'dictionaries/facility-types' )
