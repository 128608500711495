/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import Icon                 from '@ant-design/icons'

/* APPLICATION */
import { Icons } from 'components'

import './spoiler.scss'

class Spoiler extends Component {
    static propTypes = {
        header: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
        openHeader: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),

        open: PropTypes.bool,
    }

    constructor ( props ) {
        super( props )
        this.state = { open: props.open === undefined ? true : props.open, }
    }

    toggle = () =>
        this.setState({ open: !this.state.open, })

    render () {
        let cls = [ this.props.className || '', 'kfc-spoiler' ]

        this.state.open && cls.push( 'open' )

        return (
            <div className={cls.join( ' ' )}>
                <h4 onClick={this.toggle}>
                    {this.state.open && this.props.openHeader
                        ? this.props.openHeader
                        : this.props.header}

                    <Icon component={Icons.Arrow.active} />
                </h4>

                {this.state.open && this.props.children}
            </div>
        )
    }
}

export default Spoiler
