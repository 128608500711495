/* VENDOR */
import React, { Component } from 'react'
import { connect }          from 'react-redux'
import PropTypes            from 'prop-types'
import { Modal, Progress }  from 'antd'

/* APPLICATION */
import { format } from 'tools'
import './generate-progress.scss'

let _timer = null,
    steps = [
        {
            stop: 10,
            speed: 0.5,
            message: 'Ищем настройки для рабочих дней ресторана на выбранной неделе',
        },
        {
            stop: 20,
            speed: 1,
            message: 'Выбираем лучшую продолжительность смены',
        },
        {
            stop: 30,
            speed: 2,
            message: 'Извлекаем плановые значения товарооборота',
        },
        {
            stop: 40,
            speed: 1,
            message: 'Извлекаем параметры manning-чарта',
        },
        {
            stop: 50,
            speed: 0.3,
            message: 'Определяем требуемые позиции',
        },
        {
            stop: 65,
            speed: 0.2,
            message: 'Ищем подходящих сотрудников на требуемые позиции',
        },
        {
            stop: 80,
            speed: 0.5,
            message: 'Распределяем сотрудников по позициям',
        },
        {
            stop: 90,
            speed: 2,
            message: 'Применяем параметры фиксированных смен',
        },
        {
            stop: 99.9,
            speed: 0.2,
            message: 'Сохраняем результаты',
        },
        {
            stop: 100,
            speed: 1,
            message: 'УРА! Получилось!',
        },
    ]

class GenerateProgress extends Component {
    static propTypes = {
        active: PropTypes.bool,
        received: PropTypes.bool,
        error: PropTypes.object,

        onReady: PropTypes.func,
        onDone: PropTypes.func,
    }

    constructor ( props ) {
        super( props )

        this.state = {
            step: 0,
            percent: 0,
            ready: false,
        }
    }

    componentDidMount () {
        this.update( this.props.active )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        if ( nextProps.active !== this.props.active ) {
            this.update( nextProps.active )
        }
    }

    update = ( status ) => ( status ? this.start() : this.stop())

    current = () => {
        const { error, state, received } = this.props,
              res = steps[ this.state.step ]

        if ( res.stop < 100 ) {
            return res
        }

        if ( !received && !error ) {
            res.message = 'Сохраняем результаты'
        } else if ( error ) {
            res.message = format.update.getErrorMessage( error, state )
        }

        return res
    }

    getStep = ( percent ) => {
        for ( let i = 0; i < steps.length; i++ ) {
            if ( percent < steps[ i ].stop ) {
                return i
            }
        }

        return steps.length - 1
    }

    step = () => {
        const info = this.current(),
              percent = Math.min( 100, this.state.percent + info.speed ),
              step = this.getStep( percent )

        this.setState({
            step,
            percent,
        })

        if ( step === 8 && !this.state.ready ) {
            this.setState({ ready: true })
            this.props.onReady()
        }

        percent >= 100 && this.stop()
    }

    start = () =>
        ( _timer = setInterval( this.step, Math.round( Math.random() * 30 ) + 30 ))
    stop = () => clearInterval( _timer )

    message = () => (
        <span className="progress-message">
            {this.current().message}
            <aside>{this.state.step + 1 + ' / ' + steps.length}</aside>
        </span>
    )

    onDone = () => {
        setTimeout(
            () =>
                this.setState({
                    step: 0,
                    percent: 0,
                    ready: false,
                }),
            1000
        )

        this.props.onDone()
    }

    render () {
        const { active, received, error } = this.props,
              { percent } = this.state,
              cls = [ 'kfc-popup kfc-generate-progress' ]

        !!error && cls.push( 'error' )

        return (
            <Modal
                title="Формирование расписания"
                className={cls.join( ' ' )}
                closable={false}
                centered={true}
                open={active}
                okText={error ? 'Закрыть' : 'Посмотреть'}
                okButtonProps={{
                    className: 'wide-btn',
                    style: {
                        display:
              ( percent < 100 || !received ) && !error ? 'none' : 'inherit',
                    },
                }}
                onOk={this.onDone}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <Progress percent={percent} strokeColor="#8FC2FF" showInfo={false} />

                {this.message()}
            </Modal>
        )
    }
}

const mapStateToProps = ( state ) => ({ state, })

export default connect( mapStateToProps )( GenerateProgress )
