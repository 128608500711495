/* VENDOR */
import React, { Component }   from 'react'
import { connect }            from 'react-redux'
import { Card, Alert, Input } from 'antd'

/* APPLICATION */
import {
    AddButton,
    AddFixedShiftModal,
    Link,
    ReportTable,
    Spinner,
} from 'components'

import { staffActions, settingsActions } from 'services'

import { format } from 'tools'
import config     from 'config'

import './fixed-shifts.scss'

const { Search } = Input,
      prepitem = ( shift ) => {
          !shift.name && shift.employeeName && ( shift.name = shift.employeeName )
          !shift.position &&
      shift.positionName &&
      ( shift.position = shift.positionName )

          return shift
      },
      prepare = format.prepare.days( 'shifts', prepitem )

class FixedShifts extends Component {
    constructor ( props ) {
        super( props )
        this.state = {
            data: prepare( props.fixed || []),
            list: [],
            checkList: [],
            positions: [],
            add: false,
            search: '',
        }
    }

    componentDidMount () {
        const { request } = this.props
        this.props.fetchFixedShifts( request )
        this.props.fetchPositions( request )
        this.props.fetchStaff( request )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { fixed, positions, list, request } = this.props

        fixed !== nextProps.fixed && this.setData( nextProps.fixed )
        positions !== nextProps.positions && this.setPositions( nextProps.positions )
        list !== nextProps.list && this.setList( nextProps.list )
        format.check.request( request, nextProps.request, [], [ 'inactive' ]) &&
      this.reload( nextProps )
        request.restaurantId !== nextProps.request.restaurantId &&
      this.props.flushStaff()
    }

    reload = ( props ) => {
        const { request } = props

        this.props.fetchFixedShifts( request )
        this.props.fetchPositions( request )
        this.props.fetchStaff( request )
    }

    setData = ( data ) =>
        this.setState({ data: data ? prepare( data ) : null, })

    setPositions = ( data ) =>
        this.setState({ positions: this.positions( data ), })

    setList = ( data ) =>
        this.setState({
            list: this.list(
                data ? data.filter(( e ) => e.salary && e.rateDictionary ) : []
            ),
            checkList: data,
        })

    positions = ( data ) =>
        format.generate.options( data, 'positionGuid', 'position' )
    list = ( data ) => format.generate.options( data, 'id', 'name' )

    update = ( record, key, val ) => {
        const rec = format.copy.object( record )

        rec[ key ] = val
        key === 'name' && ( rec.employeeGuid = null )
        key === 'position' && ( rec.positionGuid = null )

        this.save( rec )
    }

    save = ( rec ) => {
        if ( rec.name && !rec.employeeGuid ) {
            rec.employeeGuid = rec.name
            rec.employeeName = format.find.byKey(
                this.props.list,
                'id',
                rec.name
            ).name
        }

        if ( rec.position && !rec.positionGuid ) {
            rec.positionGuid = rec.position
            rec.positionName = format.find.byKey(
                this.props.positions,
                'positionGuid',
                rec.position
            ).position
        }

        if ( rec.positionGuid && !rec.positionName ) {
            rec.positionName = format.find.byKey(
                this.props.positions,
                'positionGuid',
                rec.positionGuid
            ).position
        }

        this.props.updateFixedShift({
            data: rec,
            params: { employeeGuid: rec.employeeGuid, },
        })
    }

    showPopup = ( e ) => {
        e && e.preventDefault()

        this.setState({ add: true, })
    }

    hidePopup = () =>
        this.setState({ add: false, })

    add = ( data ) => {
        data.map( this.save )
        this.hidePopup()
    }

    remove = ( rec ) => this.props.removeFixedShift( rec )

    cellSearch = () => ( search, opt ) =>
        format.find.strInProp( opt.props, 'children', search )

    filter = ( e ) =>
        this.setState({ search: e.target.value, })

    search = ( arr ) =>
        this.state.search.length >= 2
            ? arr
                .map(( day ) => {
                    const shifts = day.shifts.filter(( s ) =>
                        format.find.strInProp( s, 'employeeName', this.state.search )
                    )

                    return {
                        ...day,
                        shifts,
                    }
                })
                .filter(( day ) => day.shifts.length > 0 )
            : arr

    render () {
        const { data, add, list, positions, search, checkList } = this.state,
              { loading, fixed } = this.props

        if ( !data || loading ) { return <Spinner /> }

        if ( data.length < 1 ) {
            return (
                <Card bordered={false}>
                    <div className="staff-fixed report-table">
                        <Alert
                            message={
                                <span>
                                    <Link onClick={this.showPopup}>
                    Закрепите смену за сотрудником
                                    </Link>
                  , чтобы он всегда выходил на выбранную позицию в определённый
                  день и время
                                </span>
                            }
                            type="warning"
                        />
                    </div>

                    <AddFixedShiftModal
                        visible={add}
                        employees={format.generate.keyval( list )}
                        positions={format.generate.keyval( positions )}
                        checkList={checkList}
                        onCancel={this.hidePopup}
                        onSubmit={this.add}
                    />
                </Card>
            )
        }

        return (
            <Card bordered={false}>
                <div className="staff-fixed report-table">
                    <Search
                        placeholder="Имя или фамилия"
                        className="fixed-search"
                        value={search}
                        onChange={this.filter}
                    />

                    <ReportTable
                        data={format.generate.noPager( this.search( data ))}
                        columns={config.tables.fixed.days}
                        select={{
                            positions: positions,
                            employees: list,
                        }}
                        rowKey="dayOfWeek"
                        hideHeader={true}
                        topHeader={true}
                        open={true}
                        loading={!fixed}
                        onChange={this.update}
                        onRemove={this.remove}
                        cellActions={{ insearch: this.cellSearch, }}
                        nested={{
                            data: 'shifts',
                            columns: config.tables.fixed.shifts,
                            rowKey: 'fixedShiftId',
                            onAdd: this.showPopup,
                        }}
                    />

                    <div className="tabbed-footer">
                        <AddButton
                            text="Добавить смену сотрудника"
                            action={this.showPopup}
                        />
                    </div>

                    <AddFixedShiftModal
                        visible={add}
                        employees={format.generate.keyval( list )}
                        positions={format.generate.keyval( positions )}
                        checkList={checkList}
                        onCancel={this.hidePopup}
                        onSubmit={this.add}
                    />
                </div>
            </Card>
        )
    }
}

const mapStateToProps = ( state ) => ({
    request: state.request,
    loading: state.staff.fixed_loading,
    fixed: state.staff.fixed,
    list: state.staff.list,
    positions: state.settings.positions,
}),

      allActions = {
          ...staffActions,
          ...settingsActions,
      }

export default connect( mapStateToProps, allActions )( FixedShifts )
