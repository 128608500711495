/* VENDOR */
import React, { Component }    from 'react'
import PropTypes               from 'prop-types'
import { Tooltip }             from 'antd'
import { ExclamationOutlined } from '@ant-design/icons'
import dayjs                   from 'dayjs'

/* APPLICATION */
import { Days } from 'components'
import config   from 'config'

import './schedule-days.scss'

class ScheduleDays extends Component {
    static propTypes = {
        data: PropTypes.object,
        day: PropTypes.string,
        show: PropTypes.bool,
        onDay: PropTypes.func,
        onClick: PropTypes.func,
    }

    constructor ( props ) {
        super( props )

        this.state = { alarm: false, }
    }

    componentDidMount () {
        this.initForceUpdate()
    }

    componentWillUnmount () {
        this.offForceUpdate()
    }

    componentDidUpdate () {
        setTimeout( this.tooltipUpdate, 1000 )
    }

    initForceUpdate = () => {
        const scroll = document.querySelector( '.inner-content' )

        if ( !scroll ) { setTimeout( this.initForceUpdate, 100 ) }

        scroll.addEventListener( 'scroll', this.tooltipUpdate )
        window.addEventListener( 'resize', this.tooltipUpdate )

        setTimeout(() => this.tooltipOnOff(), 1000 )
    }

    tooltipOnOff = () => {
        this.ttTimeout && clearTimeout( this.ttTimeout )

        this.setState({ alarm: false })
        this.ttTimeout = setTimeout(() => this.setState({ alarm: true }), 1000 )
    }

    tooltipUpdate = () => {
        const delay = 200,
              currentTime = new Date().valueOf()

        if ( this.ttUpdateTime > currentTime - delay ) { return }

        setTimeout(() => this.mcAlarm && this.mcAlarm.forceUpdate(), delay )
        this.ttUpdateTime = currentTime
    }

    offForceUpdate = () => {
        document
            .querySelector( '.inner-content' )
            .removeEventListener( 'scroll', this.tooltipUpdate )
        window.removeEventListener( 'resize', this.tooltipUpdate )
    }

    title = ( str, offset ) => {
        const { data } = this.props,
              day = data.days.find(( d ) => d.dayOfWeek === offset ),
              start = dayjs( str, config.format.dayAPI ),
              dow = dayjs().day( offset ).format( 'ddd' ),
              date = start.add( offset - 1, 'days' ).format( config.format.onlyDayView ),
              isToday = dayjs( this.props.data.dateStart )
                  .startOf( 'day' )
                  .add( day?.dayOfWeek - 1, 'days' )
                  .isSame( dayjs().startOf( 'day' )),
              isSelected = parseInt( this.state.day ) === day?.dayOfWeek,
              otext = date + ', ' + dow.toUpperCase(),
              text = isToday ? <b>{otext}</b> : otext

        return this.empty( day ) ? (
            <Tooltip
                ref={( node ) => ( this.mcAlarm = node )}
                open={this.state.alarm && !isSelected}
                placement="bottom"
                title={<ExclamationOutlined />}
                overlayClassName="mc-warning"
            >
                <b className="force-mc" title="На завтра не назначен главный по смене">
                    {text}
                </b>
            </Tooltip>
        ) : (
            text
        )
    }

    empty = ( day ) => {
        if (
            this.props.data.dateStart !==
      dayjs().startOf( 'week' ).format( config.format.dayAPI )
        ) { return false }

        const tomorrow = parseInt( dayjs().add( 1, 'day' ).format( 'd' )),
              dow = day?.dayOfWeek === 7 ? 0 : day?.dayOfWeek

        if ( tomorrow === dow && tomorrow < 7 ) {
            const position = day.positions.filter(
                ( p ) => p.positionGuid === config.mcGuid
            )[ 0 ]

            if ( !position ) { return true }

            const supervisors = position.shifts.filter(( s ) => s.shiftSupervisor )

            return supervisors.length === 0
        }

        return false
    }

    onChange = ( day ) => {
        this.tooltipOnOff()
        setTimeout(() => this.props.onDay( day ), 0 )
    }

    render () {
        const { show, data, day } = this.props

        return (
            <Days
                visible={show && !!data}
                start={data ? dayjs( data.dateStart ) : null}
                current={day}
                titleRender={this.title}
                onChange={this.onChange}
                onScroll={this.tooltipOnOff}
            />
        )
    }
}

export default ScheduleDays
