import column    from './helpers'
import shared    from './shared'
import * as defs from '../defs'

export default {
    settings: {
        shifts: [
            column( '', '', { render: ( text, record, index ) => index + 1, }),

            column( 'Начало', 'timeStart', {
                editable: true,
                as: 'time',
                width: 110,
            }),
            column( 'Окончание', 'timeEnd', {
                editable: true,
                as: 'time',
            }),
            shared.add,
            shared.remove,
        ],

        days: [
            shared.days( 'shifts', 'day', defs.shifts ),
            column( '', null, { as: 'addNested', }),
        ],

        positions: ( editable ) => [
            column( '', 'uuidChartPosition', {
                as: 'boolean',
                wait: true,
                disabled: () => !editable,
            }),
            column( 'Позиция', 'name' ),
            column( 'Сокращение', 'position' ),
            column( 'Описание', 'description' ),
        ],

        positionsOld: [
            column( '', 'chart', {
                as: 'boolean',
                wait: true,
                disabled: ( row ) =>
                    row.positionGuid === '44081e01-c6d1-4e00-b51c-0a86f7ac69b6',
            }),
            column( 'Позиция', 'name' ),
            column( 'Сокращенное', 'position' ),
            column( 'Описание', 'description' ),
        ],


        time: [
            column( 'День недели', 'dayOfWeek', {
                as: 'dayOfWeek',
                className: 'capitalize',
                full: true,
            }),
            column( 'Время открытия', 'timeStart', {
                editable: true,
                as: 'time',
            }),
            column( 'Время закрытия', 'timeEnd', {
                editable: true,
                as: 'time',
            }),
            column( 'Круглосуточно', 'noctidial', {
                as: 'boolean',
                wait: true,
            }),
            column( 'Выходной', 'holiday', {
                as: 'boolean',
                wait: true,
            }),
        ],
    },
}
