/* VENDOR */
import axios from 'axios'

/* APPLICATION */
import config     from 'config'
import { format } from 'tools'

const rid = [ 'restaurantId' ],
      service = 'planning'

export const getManning = ( params ) =>
    format.generate.get( service, 'chart/manning', params, '', rid )
export const getDuration = ( params ) =>
    format.generate.get( service, 'chart/duration', params, '', rid )
export const getBreaks = ( params ) =>
    format.generate.get( service, 'chart/breaks', params, '', rid )

/* MANNING */

export const setManning = ( request ) =>
    axios.post( config.api.planning + 'chart/manning', request.body, {
        ...config.api.getOptions(),
        ...format.generate.params( request.params, rid ),
    })

export const removeManning = ( request ) =>
    axios.delete(
        config.api.planning + 'chart/manning/' + request.turnoverChartId,
        {
            ...config.api.getOptions(),
            ...format.generate.params( request, rid ),
            data: request,
        }
    )

/* DURATION */

export const setDuration = ( request ) =>
    axios.post( config.api.planning + 'chart/duration', request.body, {
        ...config.api.getOptions(),
        ...format.generate.params( request.params, rid ),
    })

export const removeDuration = ( request ) =>
    axios.delete( config.api.planning + 'chart/duration/' + request.durationId, {
        ...config.api.getOptions(),
        ...format.generate.params( request, rid ),
        data: request,
    })

/* BREAKS */

export const setBreaks = ( request ) =>
    axios.post( config.api.planning + 'chart/breaks', request.body, {
        ...config.api.getOptions(),
        ...format.generate.params( request.params, rid ),
    })

export const removeBreaks = ( request ) =>
    axios.delete( config.api.planning + 'chart/breaks/' + request.breakId, {
        ...config.api.getOptions(),
        ...format.generate.params( request, rid ),
        data: request,
    })

export const getPositionsChartedByType = ( params ) =>
    format.generate.post( 'planning', 'settings/restaurant-types/positions/search', params, '', '', '', '' )

export const getPositionsChartedByTypeIsSelectedFalse = ( params ) =>
    format.generate.post( 'planning', 'settings/restaurant-types/positions/search', params, '', '', '', '' )


export const getChartByType = ( params ) =>
    format.generate.post( 'planning', 'chart/mannings/plan-transactions/search', params, '', '', '', '' )

export const createPositionsChartedByType = ( params ) =>
    format.generate.post( 'planning', 'settings/restaurant-types/chart-positions', params, '', '', '', '' )

export const deletePositionChartedByType = ({ uuidChartPosition }) =>
    format.generate.deleteApi( 'planning', `settings/restaurant-types/chart-positions/${uuidChartPosition}`, '', '' )

export const createTransactionChartByType = ( params ) =>
    format.generate.post( 'planning', 'chart/mannings/plan-transactions', params, '', '', '', '' )

export const deleteChartByType = ({ plannedTransactionNumberUuid }) =>
    format.generate.deleteApi( 'planning', `chart/mannings/plan-transactions/${plannedTransactionNumberUuid}`, '', '' )

export const createPositionChartByType = ( params ) =>
    format.generate.post( 'planning', 'chart/mannings/position-charts', params, '', '', '', {})

export const updatePositionChartByType = ({ positionTransactionNumberUuid, ...params }) =>
    format.generate.put( 'planning', `chart/mannings/position-charts/${positionTransactionNumberUuid}`, params, '', '', '', {})

export const updatePlannedTransaction = ({ plannedTransactionNumberUuid, ...params }) =>
    format.generate.put( 'planning', `chart/mannings/plan-transactions/${plannedTransactionNumberUuid}`, params, '', '', '', {})

export const getRestaurantsFilterInfo = ( params ) =>
    format.generate.post( 'restaurant', 'restaurants/search/unpaged', params, '', '', '', '' )
