import React        from 'react'
import { Col }      from 'antd'
import { PprTable } from 'components'
import './ppr-hr-data.scss'

const headers = {
    acNameSurname: 'AC',
    hrbpNameSurname: 'HR BP',
    hrtmNameSurname: 'HRM',
    yumfbmNameSurname: 'FBM',
    yumhrbpNameSurname: 'HR BP',
}

const PprHrData = ({ data, loading, error, onRetry }) => {
    return (
        <Col span={12}>
            <PprTable
                loading={loading}
                error={error}
                onRetry={() => onRetry( 'hr' )}
                horizontal
                className='ppr-hr-data'
            >
                <tbody>
                    {Object.entries( headers ).map(([ key, col ]) => ( <tr key={key}>
                        {key === 'yumfbmNameSurname' && <th rowSpan={2}>YUM!</th>}
                        <th colSpan={key.includes( 'yum' ) ? 1 : 2}>{col}</th>
                        <td>{data?.[ key ]}</td>
                    </tr> ))}
                </tbody>
            </PprTable>
        </Col>
    )
}

export default PprHrData
