/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import dayjs                from 'dayjs'
import { Card }             from 'antd'

import FullCalendar      from '@fullcalendar/react'
import dayGridPlugin     from '@fullcalendar/daygrid'
import ruLocale          from '@fullcalendar/core/locales/ru'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin    from '@fullcalendar/timegrid'

/* APPLICATION */
import { Spinner } from 'components'
import config      from 'config'

const views = {
    month: 'dayGridMonth',
    week: 'timeGridWeek',
}

class FCalendar extends Component {
    static propTypes = {
        view: PropTypes.string,
        date: PropTypes.object,
        loading: PropTypes.bool,

        events: PropTypes.array,
        selected: PropTypes.array,

        onDrop: PropTypes.func,
        onDrag: PropTypes.func,
        onDateSelect: PropTypes.func,
        onEventSelect: PropTypes.func,
    }

    /* REFS */

    calendarRef = React.createRef()
    calendar = () => this.calendarRef.current.getApi()
    getCalendar = () => this.calendarRef.current.calendar

    componentDidUpdate ( prevProps ) {
        const { view, date, events } = this.props

        if ( events ) {
            view !== prevProps.view && this.setView( view )
            // date !== prevProps.date && this.setDate( date )
        }
    }

    componentDidMount () {
        const savedDate = sessionStorage.getItem( 'calendarDate' )

        if ( savedDate ) {
            const parsedDate = dayjs( savedDate, config.format.dayAPI )
            this.setDate( parsedDate )
        }
    }


    /* EVENTS */

    setDate = ( date ) =>
        this.getCalendar().gotoDate( date.format( config.format.dayAPI ))
    setView = ( view ) => this.getCalendar().changeView( views[ view ])

    /* UI */

    headerDate = ( date ) => {
        return dayjs( date ).format( 'D MMMM, ' ) + dayjs( date ).format( 'ddd' ).toUpperCase()
    }

    render () {
        const { view, selected, events, loading } = this.props,
              wheight = window.innerHeight - 250

        return (
            <section className="section-cards-content" style={{ height: wheight }}>
                <Card bordered={false} className={'calendar-container fc-view-' + view}>
                    {( loading || !events ) && (
                        <div className="calendar-cover">
                            <Spinner />
                        </div>
                    )}
                    <FullCalendar
                        ref={this.calendarRef}
                        plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
                        defaultView={views[ view ]}
                        droppable
                        dragRevertDuration={0}
                        locale={ruLocale}
                        columnHeaderFormat={view === 'month' && { weekday: 'long' }}
                        columnHeaderText={view === 'week' && this.headerDate}
                        header={{ left: '', center: '', right: '' }}
                        height={() => wheight}
                        contentHeight={() => wheight - 24}
                        slotDuration="00:15:00"
                        allDaySlot={false}
                        slotLabelFormat={{
                            hour: 'numeric',
                            minute: '2-digit',
                            omitZeroMinute: false,
                            meridiem: 'none',
                        }}
                        events={events ? ( selected ? [ ...events, selected ] : events ) : []}
                        eventOrder="weight"
                        eventOverlap
                        eventStartEditable={view === 'week'}
                        eventReceive={this.props.onDrop}
                        eventDrop={this.props.onDrop}
                        eventDragStart={this.props.onDrag}
                        dateClick={this.props.onDateSelect}
                        eventClick={this.props.onEventSelect}
                    />
                </Card>
            </section>
        )
    }
}

export default FCalendar
