/* VENDOR */
import axios from 'axios'

/* APPLICATION */
import { format } from 'tools'
import config     from 'config'

const service = 'business'

export const getGroups = ( params ) =>
    format.generate.get( service, 'task-templates/categories', params )
export const getTemplates = ( params ) =>
    format.generate.get( service, 'task-templates', params )
export const getTemplate = ( id ) =>
    format.generate.get( service, 'task-templates/' + id, id )
export const postElementTypes = () =>
    format.generate.post( service, 'task-templates/component-types/search', null )

export const createTemplate = ( body ) =>
    axios.post( config.api.business + 'task-templates', body, config.api.getOptions())

export const postElementAttributes = ( componentTypeCode ) =>
    axios.post(
        config.api.business + 'task-templates/component-attributes/search',
        { componentTypeCode },
        config.api.getOptions()
    ).then( res => {
        if ( res.status === 200 ) {
            res.data = { [ componentTypeCode ]: res.data }
        }
        return res
    })

export const saveTemplate = ( body ) =>
    axios.post(
        config.api.business + 'task-templates/' + body.templateId,
        body,
        config.api.getOptions()
    )

export const removeTemplate = ( id ) =>
    axios.delete( config.api.business + 'task-templates/' + id, config.api.getOptions())
