import { IState } from 'store'

export const mapStateToProps = ( state: IState ) => ({
    summaryHappinessIndex: state.guestVote.happinessIndexesSummary,
    summaryHappinessIndexIsLoading: state.guestVote.happinessIndexesSummary_loading,
    happinessIndexesByHour: state.guestVote.happinessIndexesByHour,
    happinessIndexesByHourIsLoading: state.guestVote.happinessIndexesByHour_loading,
    metricsType: state.guestVote.metricsType
})

export type HappinessGaugesStoreProps = ReturnType<typeof mapStateToProps>
