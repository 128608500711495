/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Scrollbars }       from 'react-custom-scrollbars'

/* APPLICATION */
import config from 'config'

import './sync-scroll-v.scss'

class SyncScrollV extends Component {
    static propTypes = {
        container: PropTypes.object,
        watch: PropTypes.string,
        offset: PropTypes.number,
    }

    constructor ( props ) {
        super( props )

        this.state = { reassign: false, }

        this.bars = React.createRef()
    }

    componentDidMount () {
        window.addEventListener( 'resize', this.resize )
        this.assign( this.props )
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps () {
        this.setState({ reassign: true })
    }

    componentDidUpdate () {
        if ( this.state.reassign ) {
            this.assign( this.props, true )
        }
    }

    componentWillUnmount () {
        window.removeEventListener( 'resize', this.resize )
        this.target && this.target.removeEventListener( 'scroll', this.watch )
    }

    resize = () => this.setState({ reassign: true })

    assign ( props, force ) {
        if ( !props.container.current ) {
            setTimeout(() => this.assign( props ), 100 )
            return
        }

        const { offset, container } = this.props,
              { current } = container,
              height = current.offsetHeight - ( offset || 0 ) - 38,
              target = current.querySelector( props.watch ),
              scroll = target.scrollHeight

        if ( scroll === this.state.scroll && !force ) {
            return
        }

        target !== this.target &&
      this.target &&
      this.target.removeEventListener( 'scroll', this.watch )

        this.target = target
        target.addEventListener( 'scroll', this.watch )

        this.setState({
            height,
            scroll,
            reassign: false,
        })
    }

    watch = () => {
        if ( !this.bars.current ) { return }
        this.bars.current.scrollTop( this.target.scrollTop )
    }

    render () {
        const { style, container, width, offset } = this.props,
              { scroll, height } = this.state

        if ( !container ) { return null }

        return (
            <div
                className="sync-scroll-container-v"
                style={{
                    width: width,
                    height: height,
                    marginTop: offset,
                    marginLeft: -width + 2,
                    ...style,
                }}
            >
                <Scrollbars
                    {...config.ui.scrolls}
                    ref={this.bars}
                    hideTracksWhenNotNeeded={true}
                >
                    <div
                        className="sync-scroll-size"
                        style={{
                            width: 1,
                            height: scroll,
                        }}
                    />
                </Scrollbars>
            </div>
        )
    }
}

export default SyncScrollV
