import dayjs      from 'dayjs'
import { format } from 'tools'

export const report = ({
    restaurant,
    data,
    start,
    end
}) => {
    const font = { name: 'Arial', sz: '10', bold: false }
    const emptyCol = { title: '' }
    const excelHeader = {
        columns: [
            {
                title: `Фактическое рабочее время ${restaurant} ${dayjs( start ).format( 'DD' )} - ${dayjs( end ).format( 'DD MMMM YYYY' )}`,
                style: { font: { sz: '24', bold: true, name: 'Arial' } }
            }
        ],
        data: [ [ { value: '' } ] ]
    }

    const upperCols = [
        { title: 'Сотрудник', style: { font }, width: { wpx: 150 } },
        {
            title: 'Дневные часы',
            style: { font, border: { bottom: { style: 'thick' } } }
        },
        ...[ ...Array( 7 ) ].map(() => emptyCol ),
        { title: 'Всего', style: { font, border: { bottom: { style: 'thick' } } } },
        ...[ ...Array( 3 ) ].map(() => emptyCol ),
        { title: 'Ставка ч.', style: { font } },
        { title: 'Ротация', style: { font } },
    ]

    const daysCols = [
        emptyCol,
        ...[ ...Array( 7 ).keys() ].map(( day => {
            const date = dayjs( start ).add( day, 'days' )
            return { title: dayjs( date ).format( 'ddd' ).toUpperCase(), style: { font: { ...font, bold: true } } }
        })),
        emptyCol,
        ...[ 'Часов', 'Опл.', 'Смен' ].map(( title ) => ({ title, style: { font: { ...font, bold: true } } })),
        emptyCol,
        emptyCol,
        emptyCol
    ]

    return [
        excelHeader,
        {
            ySteps: -1,
            columns: upperCols,
            data: [ [ ...Array( 15 ) ].map(() => ({ value: '' })) ]
        },
        {
            ySteps: -1,
            columns: daysCols,
            data: [
                ...data.employees.map(( employee, index ) => {
                    const isLast = index === data.employees.length - 1
                    const style = { style: { border: { bottom: { style: 'thick' } } } }
                    return [
                        { value: employee.name, ...( isLast && style ) },
                        ...employee.days.map( day => ({ value: day.total ?? '-', ...( isLast && style ) })),
                        { value: '', ...( isLast && style ) },
                        { value: employee.summary.hours, ...( isLast && style ) },
                        { value: employee.summary.paid, ...( isLast && style ) },
                        { value: employee.summary.shifts, ...( isLast && style ) },
                        { value: '', ...( isLast && style ) },
                        { value: employee.hours, ...( isLast && style ) },
                        { value: employee.positions.join( ', ' ), ...( isLast && style ) },
                    ]
                }),
                [
                    { value: `${data.summary.employees} ${format.strings.num2str( data.summary.employees, [ 'человек', 'человека', 'человек' ])}` },
                    ...data.summary.days.map( day => ({ value: day.total ?? '-' })),
                    { value: '' },
                    { value: data.summary.total.hours },
                    { value: data.summary.total.paid },
                    { value: data.summary.total.shifts },
                    { value: '' },
                    { value: '' },
                    { value: '' },
                ]
            ]
        },
    ]
}
