import { FiltersConfig } from './type'

export const guestVoteFiltersConfig: FiltersConfig[] = [ {
    key: 'franchiseeGroupIds',
    title: 'Партнеры',
    group: 'restaurantFilters',
    searchFilterType: 'FRANCHISEE_GROUPS'
}, {
    key: 'facilityTypeIds',
    title: 'Типы ресторанов',
    group: 'restaurantFilters',
    searchFilterType: 'FACILITY_TYPES',
}, {
    key: 'cities',
    title: 'Города',
    group: 'restaurantFilters',
    searchFilterType: 'CITIES'
}, {
    key: 'regionCoachIds',
    title: 'Region coaches',
    group: 'restaurantFilters',
    searchFilterType: 'REGION_COACHES'
}, {
    key: 'marketCoachIds',
    title: 'Market coaches',
    group: 'restaurantFilters',
    searchFilterType: 'MARKET_COACHES'
}, {
    key: 'areaCoachIds',
    title: 'Area coaches',
    group: 'restaurantFilters',
    searchFilterType: 'AREA_COACHES'
}, {
    key: 'factsNumbers',
    title: 'Рестораны',
    group: 'restaurantFilters',
    searchFilterType: 'RESTAURANTS'
}, {
    key: 'feedbackTypes',
    title: 'Типы обращений',
    group: 'feedbackFilters',
    searchFilterType: 'FEEDBACK_TYPES'
}, {
    key: 'saleChannelIds',
    title: 'Каналы продаж',
    searchFilterType: 'SALE_CHANNELS',
}, {
    key: 'influenceLevels',
    title: 'Уровни влияния',
    group: 'feedbackFilters',
    searchFilterType: 'INFLUENCE_LEVELS'
}, {
    key: 'feedbackStatuses',
    title: 'Статусы обращений',
    group: 'feedbackFilters',
    searchFilterType: 'FEEDBACK_STATUSES'
}, {
    key: 'feedbackSources',
    title: 'Источники',
    group: 'feedbackFilters',
    searchFilterType: 'FEEDBACK_SOURCES'
}, {
    key: 'feedbackCategories',
    title: 'Категории',
    group: 'feedbackFilters',
    searchFilterType: 'FEEDBACK_CATEGORIES'
}, {
    key: 'feedbackSubcategories',
    title: 'Подкатегории',
    group: 'feedbackFilters',
    searchFilterType: 'FEEDBACK_SUBCATEGORIES'
},
//     {
//     key: 'feedbackReasons',
//     title: 'Причины',
//     group: 'feedbackFilters',
//     searchFilterType: 'FEEDBACK_REASONS'
// },
]
